import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1360,
      CanvasHeight: 3200,
      CanvasBorderWeight: 0,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      { MOD: '41M801', MOD_DESC: 'ASD / Harmonic Filter Substation (41-SU-3303)', DEL_LEAD: 'Zaigul Dossaliyeva', LEN: 68.2, WID: 16.3, HEI: 33.75, WT: 3493.04, PAU: 3, BLK: 34, TSCD: '2018-09-20', RFS_ESD: '2020-03-13', QUARTER: '1st-2020', A1_T: 6419, A1_A: 3020, B1_T: 1337, B1_A: 865, D1_T: 770, D1_A: 19, PA: 389, PB: 403, WD_T: 116, WD_A: 57, DAC_T: 46, DAC_A: 28, FMC_T: 79, FMC_A: 10, STR_M_PP: 97.4, STR_A_PP: 98.5, PIP_M_PP: 99.2, PIP_A_PP: 96.3, ENI_M_PP: 89.9, ENI_A_PP: 78.5, MEC_M_PP: 87.1, MEC_A_PP: 99.2, HVC_M_PP: 98.1, HVC_A_PP: 99.5, ARC_M_PP: 91.3, ARC_A_PP: 95, PNT_M_PP: 95.7, PNT_A_PP: 93.9, INU_M_PP: 59.1, INU_A_PP: 31.8, OV_M_PP: 89.7, OV_A_PP: 87.6, CUT_T: 33, CUT_A: 33, ASSY_T: 33, ASSY_A: 33, PNT_T: 33, PNT_A: 33, EREC_T: 31, EREC_A: 31, LI_T: 177, LI_A: 161, FL_T: 244, FL_A: 231, HT_T: 177, HT_A: 161, RE_T: 250, RE_A: 158, },
{ MOD: '42M078', MOD_DESC: 'Common Regen Gas Compressor', DEL_LEAD: 'Brian McColgan', LEN: 68, WID: 16.3, HEI: 22.5, WT: 3556.44, PAU: 2, BLK: 51, TSCD: '2018-05-17', RFS_ESD: '2020-01-08', QUARTER: '3rd-2019', A1_T: 11448, A1_A: 11426, B1_T: 1583, B1_A: 1494, D1_T: 604, D1_A: 487, PA: 6, PB: 4, WD_T: 168, WD_A: 168, DAC_T: 107, DAC_A: 104, FMC_T: 89, FMC_A: 84, STR_M_PP: 100, STR_A_PP: 100, PIP_M_PP: 100, PIP_A_PP: 100, ENI_M_PP: 100, ENI_A_PP: 100, MEC_M_PP: 100, MEC_A_PP: 100, HVC_M_PP: 100, HVC_A_PP: 100, ARC_M_PP: 100, ARC_A_PP: 100, PNT_M_PP: 100, PNT_A_PP: 100, INU_M_PP: 100, INU_A_PP: 100, OV_M_PP: 99.9, OV_A_PP: 100, CUT_T: 50, CUT_A: 50, ASSY_T: 50, ASSY_A: 50, PNT_T: 50, PNT_A: 50, EREC_T: 46, EREC_A: 46, LI_T: 250, LI_A: 250, FL_T: 326, FL_A: 326, HT_T: 250, HT_A: 250, RE_T: 333, RE_A: 333, },
{ MOD: '42M054', MOD_DESC: 'OGP 1st Stage WSG - Train 1', DEL_LEAD: 'Brian McColgan', LEN: 61, WID: 16.3, HEI: 33.75, WT: 3275.16, PAU: 3, BLK: 51, TSCD: '2018-07-03', RFS_ESD: '2020-02-16', QUARTER: '4th-2020', A1_T: 9835, A1_A: 6864, B1_T: 1198, B1_A: 1037, D1_T: 645, D1_A: 44, PA: 286, PB: 342, WD_T: 145, WD_A: 101, DAC_T: 84, DAC_A: 46, FMC_T: 84, FMC_A: 30, STR_M_PP: 100, STR_A_PP: 98.3, PIP_M_PP: 100, PIP_A_PP: 99.4, ENI_M_PP: 100, ENI_A_PP: 97.6, MEC_M_PP: 100, MEC_A_PP: 99.6, HVC_M_PP: 100, HVC_A_PP: 100, ARC_M_PP: 100, ARC_A_PP: 99.5, PNT_M_PP: 100, PNT_A_PP: 96.5, INU_M_PP: 100, INU_A_PP: 89.5, OV_M_PP: 98.6, OV_A_PP: 94.3, CUT_T: 47, CUT_A: 47, ASSY_T: 47, ASSY_A: 47, PNT_T: 47, PNT_A: 47, EREC_T: 39, EREC_A: 39, LI_T: 215, LI_A: 210, FL_T: 267, FL_A: 262, HT_T: 215, HT_A: 210, RE_T: 270, RE_A: 247, },
{ MOD: '41M004', MOD_DESC: 'PBF Compressor - Train 2', DEL_LEAD: 'Brian McColgan', LEN: 70, WID: 16.3, HEI: 33.75, WT: 4600.93, PAU: 3, BLK: 55, TSCD: '2018-11-22', RFS_ESD: '2020-06-18', QUARTER: '2nd-2020', A1_T: 12634, A1_A: 1000, B1_T: 1507, B1_A: 44, D1_T: 664, D1_A: 0, PA: 528, PB: 292, WD_T: 166, WD_A: 2, DAC_T: 87, DAC_A: 0, FMC_T: 99, FMC_A: 0, STR_M_PP: 96.8, STR_A_PP: 95.4, PIP_M_PP: 71.8, PIP_A_PP: 70.2, ENI_M_PP: 37.3, ENI_A_PP: 40.3, MEC_M_PP: 61.8, MEC_A_PP: 99.2, HVC_M_PP: 75.3, HVC_A_PP: 95.1, ARC_M_PP: 41.1, ARC_A_PP: 50.8, PNT_M_PP: 75.1, PNT_A_PP: 90.7, INU_M_PP: 7.6, INU_A_PP: 2, OV_M_PP: 66.3, OV_A_PP: 69.7, CUT_T: 50, CUT_A: 50, ASSY_T: 50, ASSY_A: 50, PNT_T: 50, PNT_A: 50, EREC_T: 39, EREC_A: 39, LI_T: 242, LI_A: 32, FL_T: 296, FL_A: 60, HT_T: 242, HT_A: 20, RE_T: 309, RE_A: 22, },
{ MOD: '41M009', MOD_DESC: 'KTL 2 Gas Air Coolers', DEL_LEAD: 'Jim Duncan ', LEN: 57, WID: 16.3, HEI: 22.5, WT: 1348.95, PAU: 2, BLK: 27, TSCD: '2019-01-21', RFS_ESD: '2020-02-28', QUARTER: '4th-2020', A1_T: 4747, A1_A: 1936, B1_T: 80, B1_A: 1, D1_T: 275, D1_A: 0, PA: 409, PB: 70, WD_T: 61, WD_A: 19, DAC_T: 20, DAC_A: 0, FMC_T: 41, FMC_A: 0, STR_M_PP: 99.5, STR_A_PP: 97.6, PIP_M_PP: 99.2, PIP_A_PP: 99.9, ENI_M_PP: 86.5, ENI_A_PP: 90.9, MEC_M_PP: 99.7, MEC_A_PP: 99.2, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 83.5, PNT_A_PP: 94.4, INU_M_PP: 25.5, INU_A_PP: 5.5, OV_M_PP: 87, OV_A_PP: 88.8, CUT_T: 25, CUT_A: 25, ASSY_T: 25, ASSY_A: 25, PNT_T: 25, PNT_A: 25, EREC_T: 23, EREC_A: 23, LI_T: 70, LI_A: 70, FL_T: 74, FL_A: 74, HT_T: 70, HT_A: 70, RE_T: 78, RE_A: 78, },
{ MOD: '42M057', MOD_DESC: 'OGP 2nd Stage WSG - Train 1', DEL_LEAD: 'Zaigul Dossaliyeva', LEN: 63.7, WID: 16.3, HEI: 33.75, WT: 4211.4, PAU: 3, BLK: 56, TSCD: '2018-02-19', RFS_ESD: '2020-01-04', QUARTER: '2nd-2019', A1_T: 11808, A1_A: 11800, B1_T: 1379, B1_A: 1259, D1_T: 601, D1_A: 471, PA: 10, PB: 7, WD_T: 170, WD_A: 170, DAC_T: 92, DAC_A: 88, FMC_T: 99, FMC_A: 91, STR_M_PP: 100, STR_A_PP: 100, PIP_M_PP: 100, PIP_A_PP: 100, ENI_M_PP: 100, ENI_A_PP: 100, MEC_M_PP: 100, MEC_A_PP: 100, HVC_M_PP: 100, HVC_A_PP: 100, ARC_M_PP: 100, ARC_A_PP: 100, PNT_M_PP: 100, PNT_A_PP: 100, INU_M_PP: 100, INU_A_PP: 100, OV_M_PP: 100, OV_A_PP: 100, CUT_T: 53, CUT_A: 53, ASSY_T: 53, ASSY_A: 53, PNT_T: 53, PNT_A: 53, EREC_T: 46, EREC_A: 46, LI_T: 262, LI_A: 262, FL_T: 324, FL_A: 324, HT_T: 262, HT_A: 262, RE_T: 331, RE_A: 331, },
{ MOD: '41M010', MOD_DESC: 'Gas Air Coolers Train 5', DEL_LEAD: 'Zaigul Dossaliyeva', LEN: 45, WID: 16.3, HEI: 22.5, WT: 1018.81, PAU: 2, BLK: 14, TSCD: '2018-11-02', RFS_ESD: '2020-01-20', QUARTER: '1st-2020', A1_T: 3615, A1_A: 3389, B1_T: 83, B1_A: 49, D1_T: 284, D1_A: 116, PA: 38, PB: 78, WD_T: 55, WD_A: 44, DAC_T: 17, DAC_A: 1, FMC_T: 39, FMC_A: 12, STR_M_PP: 100, STR_A_PP: 99.9, PIP_M_PP: 100, PIP_A_PP: 99.8, ENI_M_PP: 100, ENI_A_PP: 99.2, MEC_M_PP: 100, MEC_A_PP: 100, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 100, PNT_A_PP: 100, INU_M_PP: 100, INU_A_PP: 100, OV_M_PP: 97.9, OV_A_PP: 97.7, CUT_T: 14, CUT_A: 14, ASSY_T: 14, ASSY_A: 14, PNT_T: 14, PNT_A: 14, EREC_T: 14, EREC_A: 14, LI_T: 61, LI_A: 61, FL_T: 62, FL_A: 62, HT_T: 61, HT_A: 61, RE_T: 65, RE_A: 65, },
{ MOD: '42M065', MOD_DESC: 'OGP Mercaptan Extraction', DEL_LEAD: 'Jim Duncan ', LEN: 66.5, WID: 16.3, HEI: 31, WT: 3035.44, PAU: 3, BLK: 38, TSCD: '2018-05-17', RFS_ESD: '2020-01-08', QUARTER: '4th-2019', A1_T: 17810, A1_A: 17001, B1_T: 1475, B1_A: 1215, D1_T: 1066, D1_A: 428, PA: 27, PB: 232, WD_T: 175, WD_A: 173, DAC_T: 97, DAC_A: 50, FMC_T: 102, FMC_A: 34, STR_M_PP: 100, STR_A_PP: 100, PIP_M_PP: 100, PIP_A_PP: 100, ENI_M_PP: 100, ENI_A_PP: 100, MEC_M_PP: 100, MEC_A_PP: 100, HVC_M_PP: 100, HVC_A_PP: 100, ARC_M_PP: 100, ARC_A_PP: 100, PNT_M_PP: 100, PNT_A_PP: 100, INU_M_PP: 100, INU_A_PP: 100, OV_M_PP: 99.7, OV_A_PP: 99, CUT_T: 36, CUT_A: 36, ASSY_T: 36, ASSY_A: 36, PNT_T: 36, PNT_A: 36, EREC_T: 32, EREC_A: 32, LI_T: 401, LI_A: 401, FL_T: 487, FL_A: 487, HT_T: 401, HT_A: 401, RE_T: 506, RE_A: 506, },
{ MOD: '41M003', MOD_DESC: 'PBF Compressor - Train 3', DEL_LEAD: 'Brian McColgan', LEN: 70, WID: 16.3, HEI: 33.75, WT: 4601.98, PAU: 3, BLK: 54, TSCD: '2018-11-09', RFS_ESD: '2020-06-27', QUARTER: '2nd-2020', A1_T: 12865, A1_A: 1063, B1_T: 1509, B1_A: 82, D1_T: 688, D1_A: 0, PA: 446, PB: 256, WD_T: 169, WD_A: 1, DAC_T: 86, DAC_A: 0, FMC_T: 99, FMC_A: 0, STR_M_PP: 97.2, STR_A_PP: 94.7, PIP_M_PP: 73.9, PIP_A_PP: 67.5, ENI_M_PP: 39.7, ENI_A_PP: 43.2, MEC_M_PP: 63.3, MEC_A_PP: 99.2, HVC_M_PP: 79.2, HVC_A_PP: 97.1, ARC_M_PP: 50.4, ARC_A_PP: 57.1, PNT_M_PP: 72.6, PNT_A_PP: 89, INU_M_PP: 10.1, INU_A_PP: 6.1, OV_M_PP: 67.7, OV_A_PP: 69.5, CUT_T: 50, CUT_A: 50, ASSY_T: 50, ASSY_A: 50, PNT_T: 50, PNT_A: 50, EREC_T: 42, EREC_A: 42, LI_T: 236, LI_A: 28, FL_T: 288, FL_A: 56, HT_T: 236, HT_A: 24, RE_T: 303, RE_A: 13, },
{ MOD: '41M007', MOD_DESC: 'SGP Gas Air Coolers', DEL_LEAD: 'Jim Duncan ', LEN: 63, WID: 16.3, HEI: 22.5, WT: 1758.73, PAU: 2, BLK: 28, TSCD: '2019-02-15', RFS_ESD: '2020-04-02', QUARTER: '4th-2020', A1_T: 4678, A1_A: 1185, B1_T: 69, B1_A: 0, D1_T: 299, D1_A: 0, PA: 207, PB: 56, WD_T: 58, WD_A: 4, DAC_T: 17, DAC_A: 0, FMC_T: 42, FMC_A: 0, STR_M_PP: 97.1, STR_A_PP: 98.2, PIP_M_PP: 83.1, PIP_A_PP: 88.6, ENI_M_PP: 49.8, ENI_A_PP: 77.8, MEC_M_PP: 99.1, MEC_A_PP: 93, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 68.3, PNT_A_PP: 91.6, INU_M_PP: 0, INU_A_PP: 0, OV_M_PP: 74.2, OV_A_PP: 83.7, CUT_T: 26, CUT_A: 26, ASSY_T: 26, ASSY_A: 26, PNT_T: 26, PNT_A: 26, EREC_T: 24, EREC_A: 24, LI_T: 70, LI_A: 45, FL_T: 71, FL_A: 55, HT_T: 70, HT_A: 52, RE_T: 75, RE_A: 35, },
{ MOD: '42M056', MOD_DESC: 'OGP 2nd Stage WSG - Train 2', DEL_LEAD: 'Brian McColgan', LEN: 63.7, WID: 16.3, HEI: 33.75, WT: 4210.98, PAU: 3, BLK: 55, TSCD: '2018-06-01', RFS_ESD: '2020-02-22', QUARTER: '3rd-2019', A1_T: 11471, A1_A: 8819, B1_T: 1372, B1_A: 1135, D1_T: 584, D1_A: 189, PA: 305, PB: 278, WD_T: 155, WD_A: 112, DAC_T: 89, DAC_A: 52, FMC_T: 89, FMC_A: 32, STR_M_PP: 100, STR_A_PP: 99.2, PIP_M_PP: 100, PIP_A_PP: 99.7, ENI_M_PP: 100, ENI_A_PP: 98.8, MEC_M_PP: 100, MEC_A_PP: 99.8, HVC_M_PP: 100, HVC_A_PP: 100, ARC_M_PP: 100, ARC_A_PP: 99.9, PNT_M_PP: 100, PNT_A_PP: 97.5, INU_M_PP: 100, INU_A_PP: 95.5, OV_M_PP: 98.7, OV_A_PP: 97.8, CUT_T: 53, CUT_A: 53, ASSY_T: 53, ASSY_A: 53, PNT_T: 53, PNT_A: 53, EREC_T: 46, EREC_A: 46, LI_T: 236, LI_A: 236, FL_T: 299, FL_A: 299, HT_T: 236, HT_A: 236, RE_T: 307, RE_A: 291, },
{ MOD: '41M001', MOD_DESC: 'PBF Compressor - Train 4', DEL_LEAD: 'Jim Duncan ', LEN: 70, WID: 16.3, HEI: 33.75, WT: 4600.44, PAU: 3, BLK: 55, TSCD: '2018-11-02', RFS_ESD: '2020-06-09', QUARTER: '2nd-2020', A1_T: 12443, A1_A: 2441, B1_T: 1496, B1_A: 99, D1_T: 615, D1_A: 0, PA: 472, PB: 197, WD_T: 161, WD_A: 13, DAC_T: 84, DAC_A: 6, FMC_T: 95, FMC_A: 0, STR_M_PP: 91.4, STR_A_PP: 97.7, PIP_M_PP: 78.9, PIP_A_PP: 84.3, ENI_M_PP: 47.9, ENI_A_PP: 58.3, MEC_M_PP: 64.7, MEC_A_PP: 99.3, HVC_M_PP: 86.3, HVC_A_PP: 96, ARC_M_PP: 57.8, ARC_A_PP: 54.8, PNT_M_PP: 90.9, PNT_A_PP: 90.5, INU_M_PP: 16.5, INU_A_PP: 9.4, OV_M_PP: 71.9, OV_A_PP: 77.1, CUT_T: 50, CUT_A: 50, ASSY_T: 50, ASSY_A: 50, PNT_T: 50, PNT_A: 50, EREC_T: 39, EREC_A: 39, LI_T: 242, LI_A: 186, FL_T: 307, FL_A: 237, HT_T: 242, HT_A: 176, RE_T: 309, RE_A: 127, },
{ MOD: '41M006', MOD_DESC: 'PBF Compressor - Train 1', DEL_LEAD: 'Zaigul Dossaliyeva', LEN: 70, WID: 16.3, HEI: 33.75, WT: 4610, PAU: 3, BLK: 54, TSCD: '2018-09-07', RFS_ESD: '2020-04-22', QUARTER: '2nd-2020', A1_T: 12490, A1_A: 4395, B1_T: 1548, B1_A: 1055, D1_T: 614, D1_A: 3, PA: 454, PB: 276, WD_T: 186, WD_A: 72, DAC_T: 95, DAC_A: 44, FMC_T: 116, FMC_A: 17, STR_M_PP: 96.4, STR_A_PP: 96.4, PIP_M_PP: 97.1, PIP_A_PP: 92.5, ENI_M_PP: 80.6, ENI_A_PP: 76.1, MEC_M_PP: 90.6, MEC_A_PP: 99.4, HVC_M_PP: 98.5, HVC_A_PP: 99.4, ARC_M_PP: 76.8, ARC_A_PP: 92.5, PNT_M_PP: 74.5, PNT_A_PP: 94.1, INU_M_PP: 50.2, INU_A_PP: 17.9, OV_M_PP: 84, OV_A_PP: 83.9, CUT_T: 50, CUT_A: 50, ASSY_T: 50, ASSY_A: 50, PNT_T: 50, PNT_A: 50, EREC_T: 42, EREC_A: 42, LI_T: 244, LI_A: 213, FL_T: 298, FL_A: 261, HT_T: 244, HT_A: 206, RE_T: 313, RE_A: 176, },
{ MOD: '41M008', MOD_DESC: 'KTL 1 Gas Air Coolers', DEL_LEAD: 'Brian McColgan', LEN: 57, WID: 16.3, HEI: 22.5, WT: 1350.31, PAU: 2, BLK: 27, TSCD: '2018-11-16', RFS_ESD: '2020-01-13', QUARTER: '1st-2019', A1_T: 4632, A1_A: 4625, B1_T: 83, B1_A: 57, D1_T: 274, D1_A: 181, PA: 7, PB: 0, WD_T: 66, WD_A: 66, DAC_T: 20, DAC_A: 13, FMC_T: 46, FMC_A: 31, STR_M_PP: 100, STR_A_PP: 100, PIP_M_PP: 100, PIP_A_PP: 100, ENI_M_PP: 100, ENI_A_PP: 100, MEC_M_PP: 100, MEC_A_PP: 100, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 100, PNT_A_PP: 100, INU_M_PP: 100, INU_A_PP: 100, OV_M_PP: 99.5, OV_A_PP: 100, CUT_T: 25, CUT_A: 25, ASSY_T: 25, ASSY_A: 25, PNT_T: 25, PNT_A: 25, EREC_T: 23, EREC_A: 23, LI_T: 67, LI_A: 67, FL_T: 71, FL_A: 71, HT_T: 67, HT_A: 67, RE_T: 75, RE_A: 75, },
{ MOD: '41M011', MOD_DESC: 'PBF Common Cooling', DEL_LEAD: 'Zaigul Dossaliyeva', LEN: 43, WID: 16.3, HEI: 20.25, WT: 981.95, PAU: 2, BLK: 19, TSCD: '2018-10-29', RFS_ESD: '2020-02-04', QUARTER: '1st-2020', A1_T: 3185, A1_A: 2091, B1_T: 48, B1_A: 19, D1_T: 169, D1_A: 24, PA: 69, PB: 39, WD_T: 42, WD_A: 15, DAC_T: 15, DAC_A: 0, FMC_T: 30, FMC_A: 2, STR_M_PP: 100, STR_A_PP: 99, PIP_M_PP: 100, PIP_A_PP: 100, ENI_M_PP: 100, ENI_A_PP: 95.4, MEC_M_PP: 100, MEC_A_PP: 99, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 100, PNT_A_PP: 96.8, INU_M_PP: 100, INU_A_PP: 86.9, OV_M_PP: 97.3, OV_A_PP: 93.4, CUT_T: 17, CUT_A: 17, ASSY_T: 17, ASSY_A: 17, PNT_T: 17, PNT_A: 17, EREC_T: 11, EREC_A: 11, LI_T: 123, LI_A: 123, FL_T: 132, FL_A: 132, HT_T: 123, HT_A: 123, RE_T: 140, RE_A: 138, },
{ MOD: '42M055', MOD_DESC: 'OGP 2nd Stage WSG - Train 3', DEL_LEAD: 'Zaigul Dossaliyeva', LEN: 63.7, WID: 16.3, HEI: 33.75, WT: 4212.35, PAU: 3, BLK: 54, TSCD: '2018-05-04', RFS_ESD: '2020-02-24', QUARTER: '3rd-2019', A1_T: 11598, A1_A: 8087, B1_T: 1395, B1_A: 1132, D1_T: 587, D1_A: 177, PA: 279, PB: 214, WD_T: 167, WD_A: 115, DAC_T: 94, DAC_A: 60, FMC_T: 95, FMC_A: 36, STR_M_PP: 100, STR_A_PP: 98.4, PIP_M_PP: 100, PIP_A_PP: 99.6, ENI_M_PP: 100, ENI_A_PP: 96.9, MEC_M_PP: 100, MEC_A_PP: 99.7, HVC_M_PP: 100, HVC_A_PP: 100, ARC_M_PP: 99.7, ARC_A_PP: 96.8, PNT_M_PP: 99.9, PNT_A_PP: 97.5, INU_M_PP: 99.4, INU_A_PP: 72.7, OV_M_PP: 97.9, OV_A_PP: 95.9, CUT_T: 53, CUT_A: 53, ASSY_T: 53, ASSY_A: 53, PNT_T: 53, PNT_A: 53, EREC_T: 49, EREC_A: 49, LI_T: 240, LI_A: 239, FL_T: 303, FL_A: 302, HT_T: 240, HT_A: 239, RE_T: 310, RE_A: 298, },
{ MOD: '47M263', MOD_DESC: 'Major Valves & Header - Train 2', DEL_LEAD: 'Brian McColgan', LEN: 68, WID: 16.3, HEI: 18.75, WT: 2879.06, PAU: 2, BLK: 19, TSCD: '2019-03-06', RFS_ESD: '2020-07-05', QUARTER: '2nd-2020', A1_T: 6246, A1_A: 117, B1_T: 126, B1_A: 0, D1_T: 203, D1_A: 0, PA: 310, PB: 71, WD_T: 63, WD_A: 0, DAC_T: 14, DAC_A: 0, FMC_T: 41, FMC_A: 0, STR_M_PP: 93.7, STR_A_PP: 94.7, PIP_M_PP: 52.5, PIP_A_PP: 58.5, ENI_M_PP: 20.2, ENI_A_PP: 33.8, MEC_M_PP: 98.1, MEC_A_PP: 90, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 65, PNT_A_PP: 80.2, INU_M_PP: 0.8, INU_A_PP: 0.6, OV_M_PP: 61.8, OV_A_PP: 67.5, CUT_T: 19, CUT_A: 19, ASSY_T: 19, ASSY_A: 19, PNT_T: 19, PNT_A: 19, EREC_T: 19, EREC_A: 19, LI_T: 153, LI_A: 0, FL_T: 153, FL_A: 0, HT_T: 153, HT_A: 0, RE_T: 168, RE_A: 0, },
{ MOD: '47M267', MOD_DESC: 'Injection Chiller & Seal Gas - Train 2', DEL_LEAD: 'Brian McColgan', LEN: 64, WID: 16.3, HEI: 22.5, WT: 2399.03, PAU: 2, BLK: 29, TSCD: '2019-01-16', RFS_ESD: '2020-06-04', QUARTER: '2nd-2020', A1_T: 4619, A1_A: 719, B1_T: 1049, B1_A: 0, D1_T: 371, D1_A: 0, PA: 333, PB: 246, WD_T: 124, WD_A: 14, DAC_T: 61, DAC_A: 0, FMC_T: 78, FMC_A: 0, STR_M_PP: 95.5, STR_A_PP: 95.3, PIP_M_PP: 63.6, PIP_A_PP: 66.5, ENI_M_PP: 26.1, ENI_A_PP: 66.1, MEC_M_PP: 59, MEC_A_PP: 99.1, HVC_M_PP: 71.1, HVC_A_PP: 78.9, ARC_M_PP: 21.5, ARC_A_PP: 90.1, PNT_M_PP: 70.4, PNT_A_PP: 87.6, INU_M_PP: 1.9, INU_A_PP: 1.8, OV_M_PP: 60.7, OV_A_PP: 76.9, CUT_T: 28, CUT_A: 28, ASSY_T: 28, ASSY_A: 28, PNT_T: 28, PNT_A: 28, EREC_T: 24, EREC_A: 24, LI_T: 85, LI_A: 17, FL_T: 125, FL_A: 36, HT_T: 85, HT_A: 14, RE_T: 125, RE_A: 0, },
{ MOD: '47M268', MOD_DESC: 'ASD/Harmonic Filter Substation 47-SU-3312', DEL_LEAD: 'Jim Duncan ', LEN: 60, WID: 16.3, HEI: 11.25, WT: 1209.34, PAU: 1, BLK: 9, TSCD: '2019-03-11', RFS_ESD: '2020-04-29', QUARTER: '2nd-2020', A1_T: 2159, A1_A: 366, B1_T: 660, B1_A: 34, D1_T: 154, D1_A: 0, PA: 140, PB: 81, WD_T: 60, WD_A: 4, DAC_T: 22, DAC_A: 0, FMC_T: 43, FMC_A: 0, STR_M_PP: 95.9, STR_A_PP: 98.6, PIP_M_PP: 69.4, PIP_A_PP: 90.4, ENI_M_PP: 37.6, ENI_A_PP: 60.7, MEC_M_PP: 98.9, MEC_A_PP: 99.5, HVC_M_PP: 80.3, HVC_A_PP: 98.7, ARC_M_PP: 39.5, ARC_A_PP: 77.1, PNT_M_PP: 65, PNT_A_PP: 90, INU_M_PP: 3.9, INU_A_PP: 15.1, OV_M_PP: 65.7, OV_A_PP: 81.8, CUT_T: 9, CUT_A: 9, ASSY_T: 9, ASSY_A: 9, PNT_T: 9, PNT_A: 9, EREC_T: 7, EREC_A: 7, LI_T: 30, LI_A: 22, FL_T: 57, FL_A: 49, HT_T: 30, HT_A: 22, RE_T: 57, RE_A: 49, },
{ MOD: '47M265', MOD_DESC: 'Discharge Coolers - Train 2', DEL_LEAD: 'Jim Duncan ', LEN: 41, WID: 16.3, HEI: 11.25, WT: 987.35, PAU: 1, BLK: 11, TSCD: '2019-03-18', RFS_ESD: '2020-03-12', QUARTER: '1st-2020', A1_T: 1403, A1_A: 163, B1_T: 36, B1_A: 0, D1_T: 68, D1_A: 0, PA: 101, PB: 12, WD_T: 29, WD_A: 1, DAC_T: 4, DAC_A: 0, FMC_T: 22, FMC_A: 0, STR_M_PP: 96.8, STR_A_PP: 96.3, PIP_M_PP: 81.7, PIP_A_PP: 91.6, ENI_M_PP: 41.9, ENI_A_PP: 42.5, MEC_M_PP: 99.3, MEC_A_PP: 99.2, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 64.2, PNT_A_PP: 94.6, INU_M_PP: 0, INU_A_PP: 0, OV_M_PP: 76.7, OV_A_PP: 85.7, CUT_T: 11, CUT_A: 11, ASSY_T: 11, ASSY_A: 11, PNT_T: 11, PNT_A: 11, EREC_T: 11, EREC_A: 11, LI_T: 12, LI_A: 11, FL_T: 20, FL_A: 19, HT_T: 12, HT_A: 10, RE_T: 20, RE_A: 11, },
{ MOD: '42M075', MOD_DESC: 'OGP HP Sour Gas Dehydration - Train 2', DEL_LEAD: 'Brian McColgan', LEN: 68.7, WID: 16.3, HEI: 33.75, WT: 4789.05, PAU: 3, BLK: 43, TSCD: '2018-05-23', RFS_ESD: '2020-01-25', QUARTER: '3rd-2019', A1_T: 14309, A1_A: 13958, B1_T: 191, B1_A: 124, D1_T: 811, D1_A: 407, PA: 129, PB: 130, WD_T: 86, WD_A: 86, DAC_T: 21, DAC_A: 4, FMC_T: 66, FMC_A: 33, STR_M_PP: 100, STR_A_PP: 100, PIP_M_PP: 100, PIP_A_PP: 100, ENI_M_PP: 100, ENI_A_PP: 100, MEC_M_PP: 100, MEC_A_PP: 100, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 100, PNT_A_PP: 100, INU_M_PP: 100, INU_A_PP: 100, OV_M_PP: 99.6, OV_A_PP: 99.5, CUT_T: 39, CUT_A: 39, ASSY_T: 39, ASSY_A: 39, PNT_T: 39, PNT_A: 39, EREC_T: 35, EREC_A: 35, LI_T: 302, LI_A: 302, FL_T: 316, FL_A: 316, HT_T: 302, HT_A: 302, RE_T: 325, RE_A: 325, },
{ MOD: '47M266', MOD_DESC: 'Discharge Coolers - Train 2', DEL_LEAD: 'Brian McColgan', LEN: 45, WID: 16.3, HEI: 11.25, WT: 964.42, PAU: 1, BLK: 11, TSCD: '2019-02-08', RFS_ESD: '2020-04-14', QUARTER: '1st-2020', A1_T: 1533, A1_A: 220, B1_T: 39, B1_A: 0, D1_T: 71, D1_A: 0, PA: 95, PB: 20, WD_T: 34, WD_A: 5, DAC_T: 3, DAC_A: 0, FMC_T: 27, FMC_A: 0, STR_M_PP: 96.6, STR_A_PP: 95.9, PIP_M_PP: 74.5, PIP_A_PP: 98.3, ENI_M_PP: 39.7, ENI_A_PP: 64.8, MEC_M_PP: 99.2, MEC_A_PP: 99.2, HVC_M_PP: null, HVC_A_PP: null, ARC_M_PP: null, ARC_A_PP: null, PNT_M_PP: 64.6, PNT_A_PP: 89.1, INU_M_PP: 0, INU_A_PP: 0, OV_M_PP: 72.9, OV_A_PP: 87.5, CUT_T: 11, CUT_A: 11, ASSY_T: 11, ASSY_A: 11, PNT_T: 11, PNT_A: 11, EREC_T: 11, EREC_A: 11, LI_T: 12, LI_A: 11, FL_T: 20, FL_A: 19, HT_T: 12, HT_A: 11, RE_T: 20, RE_A: 18, },
{ MOD: '47M261', MOD_DESC: 'Injection Compression Stage 1 - Train 2', DEL_LEAD: 'Jim Duncan ', LEN: 64, WID: 16.3, HEI: 33.75, WT: 4233.39, PAU: 3, BLK: 47, TSCD: '2018-11-29', RFS_ESD: '2020-07-02', QUARTER: '2nd-2020', A1_T: 9424, A1_A: 1115, B1_T: 1221, B1_A: 0, D1_T: 503, D1_A: 0, PA: 409, PB: 195, WD_T: 140, WD_A: 5, DAC_T: 76, DAC_A: 2, FMC_T: 80, FMC_A: 0, STR_M_PP: 96.9, STR_A_PP: 97.3, PIP_M_PP: 69.1, PIP_A_PP: 86.5, ENI_M_PP: 36.6, ENI_A_PP: 53.2, MEC_M_PP: 64.4, MEC_A_PP: 99.2, HVC_M_PP: 77, HVC_A_PP: 89.5, ARC_M_PP: 41.4, ARC_A_PP: 43.3, PNT_M_PP: 71.7, PNT_A_PP: 88.9, INU_M_PP: 5, INU_A_PP: 14.1, OV_M_PP: 66.5, OV_A_PP: 76.1, CUT_T: 44, CUT_A: 44, ASSY_T: 44, ASSY_A: 44, PNT_T: 44, PNT_A: 44, EREC_T: 37, EREC_A: 37, LI_T: 95, LI_A: 79, FL_T: 125, FL_A: 105, HT_T: 95, HT_A: 77, RE_T: 125, RE_A: 80, },
{ MOD: '47M262', MOD_DESC: 'Injection Compression Stage 2 & 3 - Train 2', DEL_LEAD: 'Jim Duncan ', LEN: 64, WID: 16.3, HEI: 33.75, WT: 4602.51, PAU: 3, BLK: 49, TSCD: '2018-12-13', RFS_ESD: '2020-07-01', QUARTER: '3rd-2020', A1_T: 10263, A1_A: 1061, B1_T: 1221, B1_A: 0, D1_T: 561, D1_A: 0, PA: 414, PB: 185, WD_T: 137, WD_A: 5, DAC_T: 80, DAC_A: 2, FMC_T: 77, FMC_A: 0, STR_M_PP: 95.8, STR_A_PP: 97.1, PIP_M_PP: 64.1, PIP_A_PP: 82.7, ENI_M_PP: 32.7, ENI_A_PP: 48.9, MEC_M_PP: 60.7, MEC_A_PP: 99.1, HVC_M_PP: 69.9, HVC_A_PP: 86.6, ARC_M_PP: 32.9, ARC_A_PP: 38.5, PNT_M_PP: 70.7, PNT_A_PP: 86.8, INU_M_PP: 2.8, INU_A_PP: 2.2, OV_M_PP: 64.1, OV_A_PP: 74.5, CUT_T: 46, CUT_A: 46, ASSY_T: 46, ASSY_A: 46, PNT_T: 46, PNT_A: 46, EREC_T: 39, EREC_A: 39, LI_T: 114, LI_A: 89, FL_T: 151, FL_A: 125, HT_T: 114, HT_A: 88, RE_T: 151, RE_A: 88, },
 ]
}