import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 920,
      CanvasHeight: 150,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#F4F5F5',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 300,
      CanvasChartHeight: 300,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#fff',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      { WD_T: 2736, WD_A: 1524, DAC_T: 1345, DAC_A: 635, FMC_T: 1680, FMC_A: 545, A1_T: 200181, A1_A: 124381, A1_R: 74998, A1_PCOW: 802, B1_T: 20679, B1_A: 10743, B1_R: 9901, B1_PCOW: 35, D1_T: 11675, D1_A: 3992, D1_R: 7683, PA: 5146, PB: 3292, PA_PCOW: 2180, PB_PCOW: 758, },

    ],

    Queries: {
      SQL1: [
        { MOD: '42M078', PAU: 'M078-A', CERT: 'RFSC', shipment: 'Okpo-27', SHIP_NAME: 'CY2', SHIP_DATE: '2019-11-27', LO_PLAN: '2019-12-14', LO_ACTUAL: '2019-12-14', SA_PLAN: '2019-12-26', SA_ACTUAL: '2019-12-21', },
        { MOD: '42M078', PAU: 'M078-B', CERT: 'RFSC', shipment: 'Okpo-27', SHIP_NAME: 'CY2', SHIP_DATE: '2019-11-27', LO_PLAN: '2019-12-13', LO_ACTUAL: '2019-12-13', SA_PLAN: '2019-12-26', SA_ACTUAL: '2019-12-21', },
        { MOD: '41M008', PAU: 'M008-B', CERT: 'RFSC', shipment: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-20', LO_PLAN: '2019-12-18', LO_ACTUAL: '2019-12-23', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-30', },
        { MOD: '41M008', PAU: 'M008-A', CERT: 'RFSC', shipment: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-20', LO_PLAN: '2019-12-19', LO_ACTUAL: '2019-12-24', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-30', },
        { MOD: '42M057', PAU: 'M057-A', CERT: 'RFSC', shipment: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-20', LO_PLAN: '2019-12-10', LO_ACTUAL: '2019-12-22', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-30', },
        { MOD: '42M057', PAU: 'M057-C', CERT: 'RFSC', shipment: 'Okpo-29', SHIP_NAME: 'HJP', SHIP_DATE: '2019-12-25', LO_PLAN: '2019-12-08', LO_ACTUAL: '2019-12-27', SA_PLAN: '2019-12-20', SA_ACTUAL: '2019-12-31', },
        { MOD: '42M057', PAU: 'M057-B', CERT: 'RFSC', shipment: 'Okpo-29', SHIP_NAME: 'HJP', SHIP_DATE: '2019-12-25', LO_PLAN: '2019-12-09', LO_ACTUAL: '2019-12-26', SA_PLAN: '2019-12-20', SA_ACTUAL: '2019-12-31', },
        { MOD: '42M075', PAU: 'M075-C', CERT: 'RFSC', shipment: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2019-12-29', LO_ACTUAL: '2020-01-14', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
        { MOD: '42M075', PAU: 'M075-B', CERT: 'RFSC', shipment: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2019-12-30', LO_ACTUAL: '2020-01-14', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
        { MOD: '42M075', PAU: 'M075-A', CERT: 'RFSC', shipment: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2019-12-31', LO_ACTUAL: '2020-01-15', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
        { MOD: '42M065', PAU: 'M065-A', CERT: 'RFSC', shipment: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2019-12-28', LO_ACTUAL: '2020-01-15', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
        { MOD: '41M010', PAU: 'M010-B', CERT: 'RFSC', shipment: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-09', LO_PLAN: '2020-01-08', LO_ACTUAL: '2020-01-10', SA_PLAN: '2020-01-18', SA_ACTUAL: '2020-01-22', },
        { MOD: '42M065', PAU: 'M065-C', CERT: 'RFSC', shipment: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-09', LO_PLAN: '2019-12-26', LO_ACTUAL: '2020-01-17', SA_PLAN: '2020-01-18', SA_ACTUAL: '2020-01-22', },
        { MOD: '42M065', PAU: 'M065-B', CERT: 'RFSC', shipment: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-09', LO_PLAN: '2019-12-27', LO_ACTUAL: '2020-01-18', SA_PLAN: '2020-01-18', SA_ACTUAL: '2020-01-22', },
        { MOD: '41M010', PAU: 'M010-A', CERT: 'RFSC', shipment: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-09', LO_PLAN: '2020-01-09', LO_ACTUAL: '2020-01-11', SA_PLAN: '2020-01-18', SA_ACTUAL: '2020-01-22', },
        { MOD: '42M056', PAU: 'M056-B', CERT: 'RFL', shipment: 'Okpo-32', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-01-29', LO_PLAN: '2020-01-21', LO_ACTUAL: null, SA_PLAN: '2020-02-06', SA_ACTUAL: null, },
        { MOD: '42M054', PAU: 'M054-C', CERT: 'RFL', shipment: 'Okpo-32', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-01-29', LO_PLAN: '2020-02-03', LO_ACTUAL: null, SA_PLAN: '2020-02-14', SA_ACTUAL: null, },
        { MOD: '41M011', PAU: 'M011-A', CERT: 'RFL', shipment: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-01-23', LO_ACTUAL: null, SA_PLAN: '2020-02-04', SA_ACTUAL: null, },
        { MOD: '42M056', PAU: 'M056-A', CERT: 'RFL', shipment: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-01-28', LO_ACTUAL: null, SA_PLAN: '2020-02-06', SA_ACTUAL: null, },
        { MOD: '41M011', PAU: 'M011-B', CERT: 'RFL', shipment: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-01-22', LO_ACTUAL: null, SA_PLAN: '2020-02-04', SA_ACTUAL: null, },
        { MOD: '42M056', PAU: 'M056-C', CERT: 'RFL', shipment: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-01-20', LO_ACTUAL: null, SA_PLAN: '2020-02-06', SA_ACTUAL: null, },
        { MOD: '42M055', PAU: 'M055-C', CERT: 'RFL', shipment: 'Okpo-34', SHIP_NAME: 'DEB2', SHIP_DATE: '2020-02-08', LO_PLAN: '2020-02-11', LO_ACTUAL: null, SA_PLAN: '2020-02-23', SA_ACTUAL: null, },
        { MOD: '42M055', PAU: 'M055-B', CERT: 'RFL', shipment: 'Okpo-34', SHIP_NAME: 'DEB2', SHIP_DATE: '2020-02-08', LO_PLAN: '2020-02-12', LO_ACTUAL: null, SA_PLAN: '2020-02-23', SA_ACTUAL: null, },
        { MOD: '41M009', PAU: 'M009-B', CERT: 'IN PROGRESS', shipment: 'Okpo-35', SHIP_NAME: 'KSK', SHIP_DATE: '2020-02-13', LO_PLAN: '2020-02-16', LO_ACTUAL: null, SA_PLAN: '2020-02-27', SA_ACTUAL: null, },
        { MOD: '41M009', PAU: 'M009-A', CERT: 'IN PROGRESS', shipment: 'Okpo-35', SHIP_NAME: 'KSK', SHIP_DATE: '2020-02-13', LO_PLAN: '2020-02-17', LO_ACTUAL: null, SA_PLAN: '2020-02-27', SA_ACTUAL: null, },
        { MOD: '42M055', PAU: 'M055-A', CERT: 'RFL', shipment: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-02-21', LO_PLAN: '2020-02-13', LO_ACTUAL: null, SA_PLAN: '2020-02-23', SA_ACTUAL: null, },
        { MOD: '42M054', PAU: 'M054-B', CERT: 'RFL', shipment: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-02-21', LO_PLAN: '2020-02-04', LO_ACTUAL: null, SA_PLAN: '2020-02-14', SA_ACTUAL: null, },
        { MOD: '42M054', PAU: 'M054-A', CERT: 'RFL', shipment: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-02-21', LO_PLAN: '2020-02-05', LO_ACTUAL: null, SA_PLAN: '2020-02-14', SA_ACTUAL: null, },
        { MOD: '47M265', PAU: 'M265-A', CERT: 'IN PROGRESS', shipment: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-03-01', LO_ACTUAL: null, SA_PLAN: '2020-03-10', SA_ACTUAL: null, },
        { MOD: '41M801', PAU: 'M801-C', CERT: 'IN PROGRESS', shipment: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-02-23', LO_ACTUAL: null, SA_PLAN: '2020-03-06', SA_ACTUAL: null, },
        { MOD: '41M801', PAU: 'M801-B', CERT: 'IN PROGRESS', shipment: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-02-24', LO_ACTUAL: null, SA_PLAN: '2020-03-06', SA_ACTUAL: null, },
        { MOD: '41M801', PAU: 'M801-A', CERT: 'IN PROGRESS', shipment: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-02-25', LO_ACTUAL: null, SA_PLAN: '2020-03-06', SA_ACTUAL: null, },
        { MOD: '47M266', PAU: 'M266-A', CERT: 'IN PROGRESS', shipment: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-16', LO_PLAN: '2020-03-20', LO_ACTUAL: null, SA_PLAN: '2020-03-29', SA_ACTUAL: null, },
        { MOD: '41M007', PAU: 'M007-B', CERT: 'IN PROGRESS', shipment: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-16', LO_PLAN: '2020-03-17', LO_ACTUAL: null, SA_PLAN: '2020-03-28', SA_ACTUAL: null, },
        { MOD: '41M007', PAU: 'M007-A', CERT: 'IN PROGRESS', shipment: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-16', LO_PLAN: '2020-03-18', LO_ACTUAL: null, SA_PLAN: '2020-03-28', SA_ACTUAL: null, },
        { MOD: '41M006', PAU: 'M006-B', CERT: 'IN PROGRESS', shipment: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-27', LO_ACTUAL: null, SA_PLAN: '2020-04-07', SA_ACTUAL: null, },
        { MOD: '41M006', PAU: 'M006-C', CERT: 'IN PROGRESS', shipment: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-26', LO_ACTUAL: null, SA_PLAN: '2020-04-07', SA_ACTUAL: null, },
        { MOD: '47M268', PAU: 'M268-A', CERT: 'IN PROGRESS', shipment: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-04-04', LO_ACTUAL: null, SA_PLAN: '2020-04-13', SA_ACTUAL: null, },
        { MOD: '41M006', PAU: 'M006-A', CERT: 'IN PROGRESS', shipment: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-28', LO_ACTUAL: null, SA_PLAN: '2020-04-07', SA_ACTUAL: null, },
        { MOD: '41M001', PAU: 'M001-C', CERT: 'IN PROGRESS', shipment: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-13', LO_ACTUAL: null, SA_PLAN: '2020-05-25', SA_ACTUAL: null, },
        { MOD: '47M267', PAU: 'M267-A', CERT: 'IN PROGRESS', shipment: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-10', LO_ACTUAL: null, SA_PLAN: '2020-05-20', SA_ACTUAL: null, },
        { MOD: '47M267', PAU: 'M267-B', CERT: 'IN PROGRESS', shipment: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-09', LO_ACTUAL: null, SA_PLAN: '2020-05-20', SA_ACTUAL: null, },
        { MOD: '41M001', PAU: 'M001-B', CERT: 'IN PROGRESS', shipment: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-14', LO_ACTUAL: null, SA_PLAN: '2020-05-25', SA_ACTUAL: null, },
        { MOD: '41M004', PAU: 'M004-B', CERT: 'IN PROGRESS', shipment: 'Okpo-42', SHIP_NAME: 'CY1', SHIP_DATE: '2020-05-19', LO_PLAN: '2020-05-23', LO_ACTUAL: null, SA_PLAN: '2020-06-02', SA_ACTUAL: null, },
        { MOD: '41M004', PAU: 'M004-C', CERT: 'IN PROGRESS', shipment: 'Okpo-42', SHIP_NAME: 'CY1', SHIP_DATE: '2020-05-19', LO_PLAN: '2020-05-22', LO_ACTUAL: null, SA_PLAN: '2020-06-02', SA_ACTUAL: null, },
        { MOD: '41M004', PAU: 'M004-A', CERT: 'IN PROGRESS', shipment: 'Okpo-41', SHIP_NAME: 'CY2', SHIP_DATE: '2020-05-25', LO_PLAN: '2020-05-24', LO_ACTUAL: null, SA_PLAN: '2020-06-02', SA_ACTUAL: null, },
        { MOD: '41M001', PAU: 'M001-A', CERT: 'IN PROGRESS', shipment: 'Okpo-41', SHIP_NAME: 'CY2', SHIP_DATE: '2020-05-25', LO_PLAN: '2020-05-15', LO_ACTUAL: null, SA_PLAN: '2020-05-25', SA_ACTUAL: null, },
        { MOD: '41M003', PAU: 'M003-B', CERT: 'IN PROGRESS', shipment: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-15', LO_ACTUAL: null, SA_PLAN: '2020-06-25', SA_ACTUAL: null, },
        { MOD: '47M261', PAU: 'M261-C', CERT: 'IN PROGRESS', shipment: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-05', LO_ACTUAL: null, SA_PLAN: '2020-06-17', SA_ACTUAL: null, },
        { MOD: '47M261', PAU: 'M261-B', CERT: 'IN PROGRESS', shipment: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-06', LO_ACTUAL: null, SA_PLAN: '2020-06-17', SA_ACTUAL: null, },
        { MOD: '41M003', PAU: 'M003-C', CERT: 'IN PROGRESS', shipment: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-14', LO_ACTUAL: null, SA_PLAN: '2020-06-25', SA_ACTUAL: null, },
        { MOD: '47M261', PAU: 'M261-A', CERT: 'IN PROGRESS', shipment: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-07', LO_ACTUAL: null, SA_PLAN: '2020-06-17', SA_ACTUAL: null, },
        { MOD: '47M262', PAU: 'M262-C', CERT: 'IN PROGRESS', shipment: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-18', LO_ACTUAL: null, SA_PLAN: '2020-07-01', SA_ACTUAL: null, },
        { MOD: '47M262', PAU: 'M262-A', CERT: 'IN PROGRESS', shipment: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-20', LO_ACTUAL: null, SA_PLAN: '2020-07-01', SA_ACTUAL: null, },
        { MOD: '47M262', PAU: 'M262-B', CERT: 'IN PROGRESS', shipment: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-19', LO_ACTUAL: null, SA_PLAN: '2020-07-01', SA_ACTUAL: null, },
        { MOD: '47M263', PAU: 'M263-A', CERT: 'IN PROGRESS', shipment: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-06-24', LO_PLAN: '2020-06-10', LO_ACTUAL: null, SA_PLAN: '2020-06-20', SA_ACTUAL: null, },
        { MOD: '47M263', PAU: 'M263-B', CERT: 'IN PROGRESS', shipment: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-06-24', LO_PLAN: '2020-06-09', LO_ACTUAL: null, SA_PLAN: '2020-06-20', SA_ACTUAL: null, },
        { MOD: '41M003', PAU: 'M003-A', CERT: 'IN PROGRESS', shipment: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-06-24', LO_PLAN: '2020-06-16', LO_ACTUAL: null, SA_PLAN: '2020-06-25', SA_ACTUAL: null, },

      ],
    },
}