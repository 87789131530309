import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 240,
      CanvasHeight: 770,
      CanvasBorderWeight: .3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 180,
      CanvasChartHeight: 380,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'Y',
      NoteX: 10,
      NoteY: 350,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 10,
      NoteTitleColor: '#757575',

      NoteContentsText: 'QVDs completion status has reached higher than 85%;',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 9,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M006)', name: 'Electrical',       total: 230, pcow: 230, remain: 1, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M007)', name: 'Instrumentation',  total: 78,  pcow: 230, remain: 2, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M007)', name: 'Telecomm.',        total: 123, pcow: 230, remain: 3, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M007)', name: 'Electrical',       total: 230, pcow: 230, remain: 1, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M008)', name: 'Instrumentation',  total: 78,  pcow: 230, remain: 2, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M008)', name: 'Telecomm.',        total: 123, pcow: 230, remain: 3, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M009)', name: 'Electrical',       total: 230, pcow: 230, remain: 1, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M010)', name: 'Instrumentation',  total: 78,  pcow: 230, remain: 2, },
      { subsystem: '41-0100-GS-EEO04-HVC1-B(M010)', name: 'Telecomm.',        total: 123, pcow: 230, remain: 3, },
    ],
}