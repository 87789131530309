import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 900,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.05,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.1,

      CanvasChartX: 50,
      CanvasChartY: 50,
      CanvasChartWidth: 480,
      CanvasChartHeight: 250,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      { stage: 'Erection', name: '3AA', date: '2019-05-10', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3A2', date: '2019-03-05', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3A3', date: '2019-03-04', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3A4', date: '2019-02-25', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B2', date: '2019-04-24', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B3', date: '2019-04-30', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B4', date: '2019-04-23', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B5', date: '2019-04-29', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B6', date: '2019-04-30', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B7', date: '2019-04-22', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3B8', date: '2019-05-03', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3C2', date: '2019-05-08', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3C3', date: '2019-05-03', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3C4', date: '2019-05-07', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3C5', date: '2019-05-17', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3C9', date: '2019-04-23', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3DA', date: '2019-06-05', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3DB', date: '2019-05-31', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3D2', date: '2019-05-20', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3D9', date: '2019-05-20', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3F3', date: '2019-06-12', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3F4', date: '2019-06-03', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3G1', date: '2019-07-03', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3G2', date: '2019-06-19', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3G3', date: '2019-06-20', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3G4', date: '2019-06-27', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3G9', date: '2019-05-20', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3HA', date: '2019-07-26', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3H1', date: '2019-07-25', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3H2', date: '2019-07-24', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3JA', date: '2019-09-16', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3J1', date: '2019-09-19', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3J7', date: '2019-09-27', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3K1', date: '2019-08-23', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3K2', date: '2019-08-23', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3K3', date: '2019-08-21', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3K4', date: '2019-08-21', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3X3', date: '2019-05-21', STATUS: 'COMPLETED', PRO: 100, },
      { stage: 'Erection', name: '3X4', date: '2019-06-03', STATUS: 'COMPLETED', PRO: 100, },
            
  ],


  Queries: {
      SQL1: [
        { stage: 'Assembly', date: '2018-11-30', plan: 23.2, actual: 33.8, },
        { stage: 'Assembly', date: '2018-12-07', plan: 32.1, actual: 37.4, },
        { stage: 'Assembly', date: '2018-12-14', plan: 40.7, actual: 40.7, },
        { stage: 'Assembly', date: '2018-12-21', plan: 47.9, actual: 48.4, },
        { stage: 'Assembly', date: '2018-12-28', plan: 52.8, actual: 51.2, },
    
      ],
}

}