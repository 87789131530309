import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 1220,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems: [
    {SUBSYSTEM:"5555-55",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",ACT_AREA_VALID:-1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2019-09-20",SUBSYSTEM:"8610-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-09-27",SUBSYSTEM:"6820-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:77.27,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:101,PUNCH_NUMBER_VALID:92,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2019-09-27",SUBSYSTEM:"6820-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-09-27",SUBSYSTEM:"8740-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:80.95,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2019-10-04",SUBSYSTEM:"6030-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2019-10-04",SUBSYSTEM:"6720-18",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-10-04",SUBSYSTEM:"6820-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2019-10-04",SUBSYSTEM:"6820-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-10-04",SUBSYSTEM:"6820-42",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-10-04",SUBSYSTEM:"8610-11",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-10-11",SUBSYSTEM:"6010-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2019-10-11",SUBSYSTEM:"6020-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-10-11",SUBSYSTEM:"6070-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2019-10-11",SUBSYSTEM:"6720-19",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:60,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-10-11",SUBSYSTEM:"9560-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-10-11",SUBSYSTEM:"9560-18",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-10-18",SUBSYSTEM:"6010-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:80,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-10-18",SUBSYSTEM:"6720-31",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:66.67,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-10-18",SUBSYSTEM:"8740-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:74.19,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2019-10-18",SUBSYSTEM:"9560-19",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"6010-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:97.44,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"6030-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"6070-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:80,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"6820-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:69.23,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"6820-43",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"6820-44",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"8610-12",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"8740-22",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:88,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-10-25",SUBSYSTEM:"8740-23",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:82.76,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"6020-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",MOC_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"6030-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"6070-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:111,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"6070-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:28},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"6820-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"8610-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"8740-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:65.96,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"8740-25",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"9320-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:98.7,DISCIPLINE:"Marine",DBSPO:"Y",ACT_AREA_VALID:10,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:43},
    {MC_PLAN:"2019-11-01",SUBSYSTEM:"9999-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",FLOAT_OFF:"Y",ACT_AREA_VALID:0,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2019-11-08",SUBSYSTEM:"6010-11",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:71.43,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-11-08",SUBSYSTEM:"6020-11",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:81.48,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-11-08",SUBSYSTEM:"6070-11",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:76,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2019-11-08",SUBSYSTEM:"8740-24",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:89.47,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-11-15",SUBSYSTEM:"6010-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:96.46,DISCIPLINE:"ICSS",MOC_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:113,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2019-11-15",SUBSYSTEM:"6020-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:99.02,DISCIPLINE:"ICSS",MOC_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:307,PUNCH_NUMBER_VALID:117,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2019-11-15",SUBSYSTEM:"8610-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2019-11-22",SUBSYSTEM:"6010-12",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:81.58,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2019-11-22",SUBSYSTEM:"6020-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:81.82,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2019-11-22",SUBSYSTEM:"6020-12",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:80.95,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2019-11-22",SUBSYSTEM:"8023-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:42.86,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2019-11-22",SUBSYSTEM:"8610-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:88.24,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2019-12-20",SUBSYSTEM:"8012-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:94.74,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2019-12-27",SUBSYSTEM:"6070-12",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:88.79,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:93,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2019-12-27",SUBSYSTEM:"6720-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:95.45,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:64,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2019-12-27",SUBSYSTEM:"6720-25",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:33.33,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-12-27",SUBSYSTEM:"6720-26",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:33.33,DISCIPLINE:"Electrical",DBSPO:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2019-12-27",SUBSYSTEM:"6720-28",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:66.67,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-01-03",SUBSYSTEM:"9910-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:92.81,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:160,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"6010-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"6010-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"6070-16",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"6071-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Instrument",ACT_AREA_VALID:5,ACT_TAG_VALID:120,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"6720-20",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:33.33,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"6820-23",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"9350-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:99.37,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:8,ACT_TAG_VALID:432,PUNCH_NUMBER_VALID:615,PUNCH_TAG_VALID:220},
    {MC_PLAN:"2020-01-10",SUBSYSTEM:"9910-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:93.21,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:154,PUNCH_TAG_VALID:41},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6010-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6020-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:111,PUNCH_NUMBER_VALID:82,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6020-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:88,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6020-16",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Instrument",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6030-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6030-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:93.75,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6070-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6740-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"6740-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9350-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:99.29,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:394,PUNCH_NUMBER_VALID:537,PUNCH_TAG_VALID:189},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9350-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:99.43,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:408,PUNCH_NUMBER_VALID:405,PUNCH_TAG_VALID:170},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9350-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:99.28,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:8,ACT_TAG_VALID:345,PUNCH_NUMBER_VALID:387,PUNCH_TAG_VALID:151},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9355-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:96.34,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:71,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9380-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:98.94,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:531,PUNCH_NUMBER_VALID:813,PUNCH_TAG_VALID:298},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9380-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:98.94,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:531,PUNCH_NUMBER_VALID:723,PUNCH_TAG_VALID:268},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9380-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:98.08,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:524,PUNCH_NUMBER_VALID:458,PUNCH_TAG_VALID:217},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9380-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:98.94,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:530,PUNCH_NUMBER_VALID:599,PUNCH_TAG_VALID:246},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9910-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:92.77,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:139,PUNCH_TAG_VALID:32},
    {MC_PLAN:"2020-01-17",SUBSYSTEM:"9910-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:93.21,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:151,PUNCH_TAG_VALID:45},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6010-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6020-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:98.51,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:73,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6020-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:85,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6030-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:93.94,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6030-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:94.44,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6030-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6070-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6070-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:116,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6720-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:97.14,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:99,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6820-09",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:83.33,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:61,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6820-11",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6820-12",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:81.82,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:68,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-01-24",SUBSYSTEM:"6820-14",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:68,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6020-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:96.91,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:75,PUNCH_TAG_VALID:27},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6070-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6720-16",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6720-23",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:66.67,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6740-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:98.67,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:113,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:96.3,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:132,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:64.71,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-13",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-45",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:88.89,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-46",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.89,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-47",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:90,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"6820-61",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8200-04",SUBSYSTEM_STATUS:"ITRCOMPLETED",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Utility",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:140,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8610-09",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8610-13",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8610-14",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:5,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:39},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8610-15",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8610-16",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-01-31",SUBSYSTEM:"8790-01",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",MOC_OPEN:"Y",PMCS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:103,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6010-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:94.92,DISCIPLINE:"ICSS",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:118,PUNCH_NUMBER_VALID:116,PUNCH_TAG_VALID:37},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6030-12",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:95.49,DISCIPLINE:"ICSS",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6210-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:98.1,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:144,PUNCH_NUMBER_VALID:217,PUNCH_TAG_VALID:73},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6210-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:83.33,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6720-17",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6720-21",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:33.33,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6720-22",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:75,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6740-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6740-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6820-19",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6820-21",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6820-24",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:178,PUNCH_NUMBER_VALID:78,PUNCH_TAG_VALID:41},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6820-51",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"6820-52",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8120-07",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"A",A_PG:90.48,DISCIPLINE:"Mechanical",PMCS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8740-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:90.32,DISCIPLINE:"Electrical",PMCS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:106,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8740-04",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8740-05",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8740-06",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8740-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"8740-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9340-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Marine",ACT_AREA_VALID:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9560-01",SUBSYSTEM_STATUS:"ITRCOMPLETED",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Telecom",DBSPO:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:204,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9560-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9560-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:107,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9560-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:16},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9560-08",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9560-11",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-07",SUBSYSTEM:"9999-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",FLOAT_OFF:"Y",ACT_AREA_VALID:0,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6060-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:96.55,DISCIPLINE:"ICSS",ACT_AREA_VALID:2,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6072-01",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Instrument",ACT_AREA_VALID:9,ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6210-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:95.24,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:129,PUNCH_NUMBER_VALID:118,PUNCH_TAG_VALID:54},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6210-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:97.14,DISCIPLINE:"Telecom",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:144,PUNCH_NUMBER_VALID:158,PUNCH_TAG_VALID:70},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6710-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28.81,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-24",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:50,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-27",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:66.67,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-29",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:33.33,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-33",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-34",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6720-43",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:59.26,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6740-07",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6820-16",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6820-17",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"6820-20",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"8120-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.89,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"8120-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.85,DISCIPLINE:"Mechanical",PMCS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:151,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"8400-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:97.22,DISCIPLINE:"Utility",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"8760-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.33,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"8760-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:60.87,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"8760-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:65,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-14",SUBSYSTEM:"9255-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:54.12,DISCIPLINE:"HVAC",ACT_AREA_VALID:7,ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:179,PUNCH_TAG_VALID:30},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"4650-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75.34,DISCIPLINE:"Process",PMCS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:164,PUNCH_NUMBER_VALID:119,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6060-03",SUBSYSTEM_STATUS:"ITRCOMPLETED",PUNCH_STATUS:"A",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:2,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6060-04",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:70,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6060-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6070-17",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.27,DISCIPLINE:"Instrument",ACT_AREA_VALID:5,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6210-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:97.2,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:145,PUNCH_NUMBER_VALID:112,PUNCH_TAG_VALID:56},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6720-32",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6720-47",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"6820-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"8120-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:92.62,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"8610-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:89.47,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"8720-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:98.36,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:487,PUNCH_NUMBER_VALID:64,PUNCH_TAG_VALID:44},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"8740-09",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"8740-12",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:33.33,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"8740-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:42.86,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"9250-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:42.05,DISCIPLINE:"HVAC",ACT_AREA_VALID:4,ACT_TAG_VALID:183,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:30},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"9560-09",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"9560-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-21",SUBSYSTEM:"9560-12",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6060-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:71.43,DISCIPLINE:"ICSS",ACT_AREA_VALID:2,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6070-03",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:97.64,DISCIPLINE:"ICSS",DBSPO:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:212,PUNCH_NUMBER_VALID:81,PUNCH_TAG_VALID:30},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6079-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:80.65,DISCIPLINE:"Instrument",MOC_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6079-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:23.53,DISCIPLINE:"Instrument",MOC_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-02",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:95.35,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:36.84,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:18.06,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.94,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:38.71,DISCIPLINE:"Electrical",DBSPO:"Y",FLOAT_OFF:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-30",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:25,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-35",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6720-44",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:70.59,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6740-03",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6740-04",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6820-15",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"6820-18",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",DBSPO:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8120-11",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:95.83,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8610-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:82.61,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8610-06",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8610-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:87.5,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8610-08",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8740-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:33.33,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8740-15",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:14.29,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8740-17",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8740-26",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:33.33,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8760-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.77,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8760-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:30.43,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"8760-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:40.91,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"9340-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.81,DISCIPLINE:"Marine",ACT_AREA_VALID:2,ACT_TAG_VALID:290,PUNCH_NUMBER_VALID:115,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"9560-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"9921-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:72.73,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:128,PUNCH_TAG_VALID:47},
    {MC_PLAN:"2020-02-28",SUBSYSTEM:"9999-05",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Mechanical",ACT_AREA_VALID:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6070-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:72.8,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6070-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:60.55,DISCIPLINE:"Instrument",ACT_AREA_VALID:4,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6073-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:34.09,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:128,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6210-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:51.61,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6720-41",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:89.33,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:595,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:48},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6720-42",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:94.79,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:664,PUNCH_NUMBER_VALID:276,PUNCH_TAG_VALID:110},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6720-45",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:95.83,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6720-46",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:93.33,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"6730-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"8040-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:76.26,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:249,PUNCH_NUMBER_VALID:302,PUNCH_TAG_VALID:137},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"8040-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.96,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"8040-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.44,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:78,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"8120-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.54,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",DBSPO:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:196,PUNCH_NUMBER_VALID:190,PUNCH_TAG_VALID:56},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"8120-10",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"A",A_PG:91.94,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-03-06",SUBSYSTEM:"9560-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:90,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6010-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:60,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6010-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:51.61,DISCIPLINE:"Instrument",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6073-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Instrument",ACT_AREA_VALID:5,ACT_TAG_VALID:120,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6073-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:38.27,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6079-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:36.84,DISCIPLINE:"Instrument",ACT_AREA_VALID:3,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6210-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:8.33,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6320-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.59,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6320-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.59,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6630-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:78.64,DISCIPLINE:"Electrical",ACT_AREA_VALID:5,ACT_TAG_VALID:270,PUNCH_NUMBER_VALID:162,PUNCH_TAG_VALID:53},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"6820-26",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:156,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:31},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"8040-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:72.16,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"8080-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:48.05,DISCIPLINE:"HVAC",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:197,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:55},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"8110-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75.39,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:13,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"8720-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:90.75,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:463,PUNCH_NUMBER_VALID:100,PUNCH_TAG_VALID:67},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"8720-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:94.78,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:476,PUNCH_NUMBER_VALID:213,PUNCH_TAG_VALID:88},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"9250-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:68.22,DISCIPLINE:"HVAC",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:339,PUNCH_NUMBER_VALID:184,PUNCH_TAG_VALID:39},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"9254-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:47.63,DISCIPLINE:"HVAC",ACT_AREA_VALID:6,ACT_TAG_VALID:371,PUNCH_NUMBER_VALID:149,PUNCH_TAG_VALID:58},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"9254-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:62.2,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:204,PUNCH_NUMBER_VALID:112,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-03-13",SUBSYSTEM:"9921-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:67.53,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:38},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6073-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:15.14,DISCIPLINE:"Instrument",ACT_AREA_VALID:3,ACT_TAG_VALID:176,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6110-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:3.03,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6221-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6710-19",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:16.55,DISCIPLINE:"Electrical",ACT_AREA_VALID:7,ACT_TAG_VALID:145,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6710-20",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:10.98,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:164,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6720-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:95.32,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:584,PUNCH_NUMBER_VALID:135,PUNCH_TAG_VALID:62},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6730-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.16,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6820-25",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:93.75,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:208,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:27},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"6820-31",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.78,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"8012-03",SUBSYSTEM_STATUS:"MC",PUNCH_STATUS:"B",A_PG:94.74,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"8040-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.34,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:19},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"8063-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.22,DISCIPLINE:"Marine",ACT_AREA_VALID:4,ACT_TAG_VALID:206,PUNCH_NUMBER_VALID:209,PUNCH_TAG_VALID:64},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9340-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.63,DISCIPLINE:"Marine",ACT_AREA_VALID:2,ACT_TAG_VALID:289,PUNCH_NUMBER_VALID:122,PUNCH_TAG_VALID:28},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9380-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:83.2,DISCIPLINE:"Marine",ACT_AREA_VALID:4,ACT_TAG_VALID:249,PUNCH_NUMBER_VALID:82,PUNCH_TAG_VALID:64},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9851-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:99.46,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:208,PUNCH_TAG_VALID:56},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9851-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:94.05,DISCIPLINE:"Mechanical",ACT_AREA_VALID:6,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:169,PUNCH_TAG_VALID:48},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9924-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:40.59,DISCIPLINE:"HVAC",ACT_AREA_VALID:4,ACT_TAG_VALID:107,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9972-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:82.87,DISCIPLINE:"HVAC",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:678,PUNCH_NUMBER_VALID:269,PUNCH_TAG_VALID:99},
    {MC_PLAN:"2020-03-20",SUBSYSTEM:"9999-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Mechanical",ACT_AREA_VALID:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"6221-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"6710-28",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.64,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:480,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:55},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"6820-27",SUBSYSTEM_STATUS:"WD",PUNCH_STATUS:"B",A_PG:100,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:185,PUNCH_NUMBER_VALID:111,PUNCH_TAG_VALID:43},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8060-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:72.86,DISCIPLINE:"Marine",ACT_AREA_VALID:6,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:93,PUNCH_TAG_VALID:42},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8060-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:35.04,DISCIPLINE:"Marine",ACT_AREA_VALID:2,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8080-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:19.35,DISCIPLINE:"HVAC",ACT_AREA_VALID:1,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8080-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:12.9,DISCIPLINE:"HVAC",ACT_AREA_VALID:1,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8300-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:50,DISCIPLINE:"Utility",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8661-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.84,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:497,PUNCH_NUMBER_VALID:936,PUNCH_TAG_VALID:253},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8662-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:78.28,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:487,PUNCH_NUMBER_VALID:841,PUNCH_TAG_VALID:225},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"8720-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:96.71,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:485,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:46},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9260-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:70.49,DISCIPLINE:"HVAC",ACT_AREA_VALID:5,ACT_TAG_VALID:250,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:31},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9520-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:55.7,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9550-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9922-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.26,DISCIPLINE:"HVAC",ACT_AREA_VALID:5,ACT_TAG_VALID:174,PUNCH_NUMBER_VALID:127,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9972-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:74,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:399,PUNCH_NUMBER_VALID:239,PUNCH_TAG_VALID:54},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9973-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:78.63,DISCIPLINE:"HVAC",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:523,PUNCH_NUMBER_VALID:288,PUNCH_TAG_VALID:88},
    {MC_PLAN:"2020-03-27",SUBSYSTEM:"9999-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:97.06,DISCIPLINE:"Electrical",ACT_AREA_VALID:0,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"3200-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.46,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:445,PUNCH_NUMBER_VALID:364,PUNCH_TAG_VALID:44},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"4100-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:11.64,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6020-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:68.75,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6073-05",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Instrument",ACT_AREA_VALID:3,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6073-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:12,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6710-17",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:98.51,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:202,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:51},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6710-24",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:78.57,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6720-36",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6720-37",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6720-38",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:33.33,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6720-39",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6810-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:54.27,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:269,PUNCH_NUMBER_VALID:174,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6810-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:55.48,DISCIPLINE:"Electrical",ACT_AREA_VALID:5,ACT_TAG_VALID:289,PUNCH_NUMBER_VALID:168,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6820-49",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:40,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"6820-53",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"8080-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:21.71,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"8170-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:72.09,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"8720-12",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:46.72,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:119,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"8720-17",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9270-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:49.03,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:157,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9270-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:46.13,DISCIPLINE:"HVAC",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:161,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9280-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:38.71,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:16},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9355-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:67.33,DISCIPLINE:"Marine",DBSPO:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:27},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9510-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:50,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9580-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:83.33,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9580-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9924-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:76.43,DISCIPLINE:"HVAC",ACT_AREA_VALID:7,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:100,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9971-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75.29,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:628,PUNCH_NUMBER_VALID:231,PUNCH_TAG_VALID:70},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9973-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:80.9,DISCIPLINE:"HVAC",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:640,PUNCH_NUMBER_VALID:278,PUNCH_TAG_VALID:109},
    {MC_PLAN:"2020-04-03",SUBSYSTEM:"9999-01",SUBSYSTEM_STATUS:"ITRCOMPLETED",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Instrument",FLOAT_OFF:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"1030-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:28.57,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"1030-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:28.57,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"4330-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.97,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"4330-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:27.42,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"4620-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:92.61,DISCIPLINE:"Process",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:519,PUNCH_NUMBER_VALID:220,PUNCH_TAG_VALID:45},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"4730-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",DISCIPLINE:"Process",ACT_AREA_VALID:-1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6020-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:47.69,DISCIPLINE:"Instrument",ACT_AREA_VALID:5,ACT_TAG_VALID:226,PUNCH_NUMBER_VALID:51,PUNCH_TAG_VALID:41},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6030-15",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:2,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6030-16",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6030-17",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6073-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:40.65,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6073-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:26.09,DISCIPLINE:"Instrument",ACT_AREA_VALID:4,ACT_TAG_VALID:115,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6073-12",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6710-27",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:42.68,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:82,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6820-28",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:94.28,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:297,PUNCH_NUMBER_VALID:196,PUNCH_TAG_VALID:86},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"6820-60",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:41.03,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8021-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:39.45,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:140,PUNCH_NUMBER_VALID:157,PUNCH_TAG_VALID:47},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8021-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:42.88,DISCIPLINE:"Mechanical",DBSPO:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:306,PUNCH_NUMBER_VALID:88,PUNCH_TAG_VALID:69},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8021-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:64.91,DISCIPLINE:"Marine",ACT_AREA_VALID:5,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8023-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.1,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:115,PUNCH_NUMBER_VALID:99,PUNCH_TAG_VALID:28},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8060-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:7,DISCIPLINE:"Marine",ACT_AREA_VALID:7,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8060-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:21.23,DISCIPLINE:"Marine",ACT_AREA_VALID:10,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8080-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:32.61,DISCIPLINE:"HVAC",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:221,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:53},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8080-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:33.33,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8080-11",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:32.1,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8080-12",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:38.27,DISCIPLINE:"HVAC",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8120-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:26.53,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8720-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:54.55,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"8720-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:44.79,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:94,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9270-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:44.94,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:251,PUNCH_NUMBER_VALID:96,PUNCH_TAG_VALID:31},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9270-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:62.57,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:330,PUNCH_NUMBER_VALID:143,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9270-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.86,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:131,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9280-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:54.13,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9280-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.79,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:286,PUNCH_NUMBER_VALID:194,PUNCH_TAG_VALID:46},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9340-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:87.34,DISCIPLINE:"Marine",ACT_AREA_VALID:2,ACT_TAG_VALID:289,PUNCH_NUMBER_VALID:115,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9340-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:87.5,DISCIPLINE:"Marine",ACT_AREA_VALID:3,ACT_TAG_VALID:288,PUNCH_NUMBER_VALID:105,PUNCH_TAG_VALID:30},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9512-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9520-09",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:177,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9852-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"A",A_PG:0,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:127,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9922-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:72.51,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:39},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9923-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.08,DISCIPLINE:"HVAC",ACT_AREA_VALID:6,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2020-04-10",SUBSYSTEM:"9924-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.85,DISCIPLINE:"HVAC",ACT_AREA_VALID:5,ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:93,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6010-18",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:94.94,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:341,PUNCH_NUMBER_VALID:64,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6010-19",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:94.51,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6010-20",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:92.9,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6050-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.94,DISCIPLINE:"ICSS",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:484,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:65},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6073-10",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:154,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6073-11",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Instrument",ACT_AREA_VALID:3,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6120-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:8.11,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6210-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:72.76,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:629,PUNCH_NUMBER_VALID:358,PUNCH_TAG_VALID:210},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6710-15",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:67.65,DISCIPLINE:"Electrical",ACT_AREA_VALID:5,ACT_TAG_VALID:475,PUNCH_NUMBER_VALID:88,PUNCH_TAG_VALID:62},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6710-25",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:66.67,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:201,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:31},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"6820-58",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:47.44,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:215,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:18},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"8060-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:55.09,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:125,PUNCH_NUMBER_VALID:115,PUNCH_TAG_VALID:83},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"8120-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:31.55,DISCIPLINE:"Mechanical",DBSPO:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:16,ACT_TAG_VALID:139,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"8950-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:7.89,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:247,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"8950-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:27.37,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:152,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9250-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:58.96,DISCIPLINE:"HVAC",ACT_AREA_VALID:6,ACT_TAG_VALID:170,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9250-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:46.49,DISCIPLINE:"HVAC",ACT_AREA_VALID:5,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9255-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:50.54,DISCIPLINE:"HVAC",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:282,PUNCH_NUMBER_VALID:170,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9260-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.95,DISCIPLINE:"HVAC",ACT_AREA_VALID:8,ACT_TAG_VALID:1237,PUNCH_NUMBER_VALID:414,PUNCH_TAG_VALID:162},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9310-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.18,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9310-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.17,DISCIPLINE:"Structure",ACT_AREA_VALID:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9310-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:15.79,DISCIPLINE:"Structure",ACT_AREA_VALID:5,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9310-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:15.79,DISCIPLINE:"Structure",ACT_AREA_VALID:4,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9355-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:49.65,DISCIPLINE:"Marine",DBSPO:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:105,PUNCH_NUMBER_VALID:61,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9450-14",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:3,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9510-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9510-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9520-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:3.7,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9520-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:5.88,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9520-07",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9520-10",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9520-12",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9810-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:22.5,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9810-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:55.56,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9890-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20.97,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9890-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:41.18,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-04-17",SUBSYSTEM:"9922-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:76.43,DISCIPLINE:"HVAC",ACT_AREA_VALID:4,ACT_TAG_VALID:192,PUNCH_NUMBER_VALID:120,PUNCH_TAG_VALID:51},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"3000-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:43.86,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:127,PUNCH_NUMBER_VALID:344,PUNCH_TAG_VALID:34},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"3000-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:43.32,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:129,PUNCH_NUMBER_VALID:338,PUNCH_TAG_VALID:40},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"4610-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:64.69,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:322,PUNCH_NUMBER_VALID:343,PUNCH_TAG_VALID:99},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"4640-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.46,DISCIPLINE:"Process",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:511,PUNCH_NUMBER_VALID:111,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6010-21",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:92.31,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:245,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6160-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:86,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6160-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:84.11,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6710-21",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:53.29,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6710-22",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:42,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:175,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6710-23",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:22.37,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:295,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6720-12",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.06,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6720-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.97,DISCIPLINE:"Electrical",ACT_AREA_VALID:6,ACT_TAG_VALID:227,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6720-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:17.34,DISCIPLINE:"Electrical",ACT_AREA_VALID:6,ACT_TAG_VALID:173,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6820-29",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:64.45,DISCIPLINE:"Electrical",ACT_AREA_VALID:5,ACT_TAG_VALID:391,PUNCH_NUMBER_VALID:129,PUNCH_TAG_VALID:63},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6820-30",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:67.14,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:280,PUNCH_NUMBER_VALID:117,PUNCH_TAG_VALID:48},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"6820-39",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.77,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:947,PUNCH_NUMBER_VALID:51,PUNCH_TAG_VALID:46},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8011-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:65.81,DISCIPLINE:"Marine",ACT_AREA_VALID:6,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8012-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:31.4,DISCIPLINE:"Mechanical",ACT_AREA_VALID:7,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8110-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.94,DISCIPLINE:"Mechanical",DBSPO:"Y",ACT_AREA_VALID:8,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:97,PUNCH_TAG_VALID:94},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8663-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.76,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:487,PUNCH_NUMBER_VALID:761,PUNCH_TAG_VALID:218},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8720-11",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.71,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:111,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8720-15",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8720-16",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"8740-11",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:66.67,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9260-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.47,DISCIPLINE:"HVAC",ACT_AREA_VALID:7,ACT_TAG_VALID:628,PUNCH_NUMBER_VALID:326,PUNCH_TAG_VALID:76},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9260-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:43.16,DISCIPLINE:"HVAC",ACT_AREA_VALID:5,ACT_TAG_VALID:480,PUNCH_NUMBER_VALID:250,PUNCH_TAG_VALID:72},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9270-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:42.08,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:158,PUNCH_NUMBER_VALID:75,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9280-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:61.67,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:538,PUNCH_NUMBER_VALID:270,PUNCH_TAG_VALID:85},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9280-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.27,DISCIPLINE:"HVAC",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:582,PUNCH_NUMBER_VALID:269,PUNCH_TAG_VALID:80},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9310-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:32,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9310-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.77,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9310-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.43,DISCIPLINE:"Structure",ACT_AREA_VALID:4,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:8},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9310-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:26.92,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9355-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:64.54,DISCIPLINE:"Marine",DBSPO:"Y",FLOAT_OFF:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9520-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:13.64,DISCIPLINE:"Telecom",ACT_AREA_VALID:7,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9520-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9520-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9530-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9530-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9550-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28.57,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9550-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:64.29,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9550-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9580-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9580-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9580-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:200,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9820-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:2.85,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",ACT_AREA_VALID:18,ACT_TAG_VALID:1742,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-04-24",SUBSYSTEM:"9890-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:20,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"1911-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:84.32,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:115,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"2220-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:24.29,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:115,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"4100-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:20.86,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"4620-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.12,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:161,PUNCH_NUMBER_VALID:89,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"4730-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.1,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6073-15",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:82.65,DISCIPLINE:"Instrument",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:524,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:45},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6073-16",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:49.17,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:165,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6073-17",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:39.91,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:205,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6073-18",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:51.92,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:199,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6073-19",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:28.03,DISCIPLINE:"Instrument",ACT_AREA_VALID:4,ACT_TAG_VALID:234,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6074-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:1.92,DISCIPLINE:"Instrument",ACT_AREA_VALID:4,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6076-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:77.92,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:754,PUNCH_NUMBER_VALID:441,PUNCH_TAG_VALID:218},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6140-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:58.33,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6160-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:58.43,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6160-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:87.5,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6210-11",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6710-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.58,DISCIPLINE:"Electrical",ACT_AREA_VALID:6,ACT_TAG_VALID:517,PUNCH_NUMBER_VALID:133,PUNCH_TAG_VALID:79},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"6710-16",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:65.11,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:426,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:53},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"8011-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.36,DISCIPLINE:"Marine",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:36},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"8012-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:32.88,DISCIPLINE:"Mechanical",ACT_AREA_VALID:7,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"8016-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:62.27,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:27},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"8080-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:8,DISCIPLINE:"HVAC",ACT_AREA_VALID:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"8120-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:16.88,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9270-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:67.08,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:696,PUNCH_NUMBER_VALID:354,PUNCH_TAG_VALID:94},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9270-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.68,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:532,PUNCH_NUMBER_VALID:233,PUNCH_TAG_VALID:45},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9270-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.49,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:744,PUNCH_NUMBER_VALID:259,PUNCH_TAG_VALID:66},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9270-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:43.62,DISCIPLINE:"HVAC",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:302,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9270-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:47.91,DISCIPLINE:"HVAC",ACT_AREA_VALID:3,ACT_TAG_VALID:364,PUNCH_NUMBER_VALID:94,PUNCH_TAG_VALID:45},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9270-11",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:50,DISCIPLINE:"HVAC",ACT_AREA_VALID:5,ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:81,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9280-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:49.27,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:222,PUNCH_NUMBER_VALID:126,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9280-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:42.65,DISCIPLINE:"HVAC",MOC_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:229,PUNCH_NUMBER_VALID:82,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9390-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:60.42,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:135,PUNCH_TAG_VALID:36},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9450-09",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9450-16",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9451-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:4.55,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9540-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:14.12,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9540-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9540-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:44.44,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9882-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:5,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-01",SUBSYSTEM:"9923-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.85,DISCIPLINE:"HVAC",ACT_AREA_VALID:4,ACT_TAG_VALID:153,PUNCH_NUMBER_VALID:73,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"1090-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:28.57,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"2110-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:29.13,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"2110-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:17.99,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:129,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:27},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"2210-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:20.93,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"2210-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20.2,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"4100-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:43.46,DISCIPLINE:"Process",PMCS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:240,PUNCH_NUMBER_VALID:191,PUNCH_TAG_VALID:42},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"5210-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:33.33,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:82,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"5210-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:54.36,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6073-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:7.64,DISCIPLINE:"Instrument",ACT_AREA_VALID:3,ACT_TAG_VALID:118,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6073-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:35.91,DISCIPLINE:"Instrument",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:175,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6073-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:17.11,DISCIPLINE:"Instrument",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:187,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6073-20",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:7.18,DISCIPLINE:"Instrument",ACT_AREA_VALID:7,ACT_TAG_VALID:179,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6080-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:5,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6110-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.4,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6160-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.76,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:105,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6160-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:69.44,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6210-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:9.03,DISCIPLINE:"Telecom",ACT_AREA_VALID:9,ACT_TAG_VALID:576,PUNCH_NUMBER_VALID:203,PUNCH_TAG_VALID:64},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6320-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.59,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6340-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6440-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Mechanical",ACT_AREA_VALID:16,ACT_TAG_VALID:238,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6710-18",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.52,DISCIPLINE:"Electrical",ACT_AREA_VALID:5,ACT_TAG_VALID:447,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:31},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6710-26",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:31.25,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:192,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6720-15",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:3.08,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:227,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6820-32",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20.52,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:307,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6820-33",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.8,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:965,PUNCH_NUMBER_VALID:85,PUNCH_TAG_VALID:62},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6820-34",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:31.76,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:255,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:19},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6820-35",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:56.31,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:428,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:57},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"6820-38",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:19.19,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:396,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8011-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:51.61,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:328,PUNCH_TAG_VALID:190},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8014-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:93.57,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:137,PUNCH_TAG_VALID:36},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8400-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:70.09,DISCIPLINE:"Utility",ACT_AREA_VALID:3,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8400-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:58.25,DISCIPLINE:"Utility",ACT_AREA_VALID:6,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8400-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:68.22,DISCIPLINE:"Utility",ACT_AREA_VALID:3,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8400-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:68.57,DISCIPLINE:"Utility",ACT_AREA_VALID:4,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8400-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:61.46,DISCIPLINE:"Utility",ACT_AREA_VALID:3,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8400-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:57.29,DISCIPLINE:"Utility",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8500-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:78.2,DISCIPLINE:"Utility",ACT_AREA_VALID:7,ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8720-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.88,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:413,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:42},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8720-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:45.24,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:499,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"8720-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.44,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:331,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:18},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"9450-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:9.09,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"9450-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"9540-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:5,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-05-08",SUBSYSTEM:"9840-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"1911-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:42.47,DISCIPLINE:"Process",ACT_AREA_VALID:6,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:40},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"1912-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:85.29,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:420,PUNCH_NUMBER_VALID:137,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"1912-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:36.04,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"2210-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:17.02,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"2220-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.1,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"4100-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:20,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:101,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"4330-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:38.35,DISCIPLINE:"Process",ACT_AREA_VALID:7,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"4330-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:55.89,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:10,ACT_TAG_VALID:82,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:28},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"4330-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75.63,DISCIPLINE:"Process",ACT_AREA_VALID:6,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"5010-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:31,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:94,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:54},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"5010-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:61.28,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:193,PUNCH_NUMBER_VALID:140,PUNCH_TAG_VALID:58},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"5010-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:22.79,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:30},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6030-13",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:3,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6030-14",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:3,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6074-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:2,DISCIPLINE:"Instrument",ACT_AREA_VALID:3,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6075-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75.26,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:685,PUNCH_NUMBER_VALID:192,PUNCH_TAG_VALID:110},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6160-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:64.71,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6320-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:27.59,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6330-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6810-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.12,DISCIPLINE:"Mechanical",PMCS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6820-36",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:45.4,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:359,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:16},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6820-37",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:34.25,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:430,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6820-40",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:92.77,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:899,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"6820-59",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:95.68,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:162,PUNCH_NUMBER_VALID:134,PUNCH_TAG_VALID:48},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8030-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:69.84,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8200-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.3,DISCIPLINE:"Utility",ACT_AREA_VALID:1,ACT_TAG_VALID:174,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8200-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.89,DISCIPLINE:"Utility",ACT_AREA_VALID:1,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8200-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:88.95,DISCIPLINE:"Utility",ACT_AREA_VALID:2,ACT_TAG_VALID:178,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8300-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:76.34,DISCIPLINE:"Utility",ACT_AREA_VALID:5,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8400-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:46.08,DISCIPLINE:"Utility",ACT_AREA_VALID:4,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8720-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:59.44,DISCIPLINE:"Electrical",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:459,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:39},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8720-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:47.73,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"8740-21",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9240-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:22.22,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9240-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:4,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9250-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.35,DISCIPLINE:"HVAC",OBS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:1751,PUNCH_NUMBER_VALID:744,PUNCH_TAG_VALID:292},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9360-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.46,DISCIPLINE:"Mechanical",ACT_AREA_VALID:15,ACT_TAG_VALID:857,PUNCH_NUMBER_VALID:396,PUNCH_TAG_VALID:254},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9380-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:85.09,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:175,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:62},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9380-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:77.71,DISCIPLINE:"Marine",ACT_AREA_VALID:5,ACT_TAG_VALID:304,PUNCH_NUMBER_VALID:91,PUNCH_TAG_VALID:65},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9450-18",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9450-19",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9451-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9451-13",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9560-15",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:94.59,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:259,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9560-16",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:7.31,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:219,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9560-17",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:11.56,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:173,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9580-05",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:107,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9930-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-15",SUBSYSTEM:"9930-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"2220-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:34.01,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:16},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"5030-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:83.65,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"6077-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:74.74,DISCIPLINE:"Instrument",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:341,PUNCH_NUMBER_VALID:279,PUNCH_TAG_VALID:121},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"6110-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.32,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:289,PUNCH_NUMBER_VALID:222,PUNCH_TAG_VALID:72},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"6120-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:23.53,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:243,PUNCH_TAG_VALID:47},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"6150-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:70.15,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"6510-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:19.83,DISCIPLINE:"Electrical",ACT_AREA_VALID:4,ACT_TAG_VALID:82,PUNCH_NUMBER_VALID:133,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8011-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:28.04,DISCIPLINE:"Marine",ACT_AREA_VALID:6,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8016-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:56.44,DISCIPLINE:"Marine",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:8,ACT_TAG_VALID:142,PUNCH_NUMBER_VALID:105,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8021-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.81,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:106,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8023-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20,DISCIPLINE:"Mechanical",ACT_AREA_VALID:5,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8023-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:22.05,DISCIPLINE:"Mechanical",ACT_AREA_VALID:5,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8300-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:62.96,DISCIPLINE:"Utility",ACT_AREA_VALID:4,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8740-16",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8740-18",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"8740-20",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"9360-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.14,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"9380-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:80.33,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:201,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:57},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"9451-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:3.57,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"9560-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:34.98,DISCIPLINE:"Telecom",ACT_AREA_VALID:6,ACT_TAG_VALID:223,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:17},
    {MC_PLAN:"2020-05-22",SUBSYSTEM:"9560-14",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:39.78,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:357,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"1010-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:21.96,DISCIPLINE:"Process",ACT_AREA_VALID:4,ACT_TAG_VALID:192,PUNCH_NUMBER_VALID:134,PUNCH_TAG_VALID:46},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"1010-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:20.91,DISCIPLINE:"Process",ACT_AREA_VALID:4,ACT_TAG_VALID:188,PUNCH_NUMBER_VALID:78,PUNCH_TAG_VALID:40},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"1020-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:19.31,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:191,PUNCH_NUMBER_VALID:66,PUNCH_TAG_VALID:39},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"1020-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:19.42,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:184,PUNCH_NUMBER_VALID:78,PUNCH_TAG_VALID:41},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"2400-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:16.92,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:378,PUNCH_NUMBER_VALID:234,PUNCH_TAG_VALID:166},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"2400-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:2.2,DISCIPLINE:"Process",PMCS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:311,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"2400-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:2.2,DISCIPLINE:"Process",PMCS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:311,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"2400-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:2.2,DISCIPLINE:"Process",PMCS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:311,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"2400-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:2.2,DISCIPLINE:"Process",PMCS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:311,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"3030-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28.82,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:260,PUNCH_NUMBER_VALID:76,PUNCH_TAG_VALID:66},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"4340-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:40.35,DISCIPLINE:"Process",ACT_AREA_VALID:8,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:40},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"4620-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:49.34,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:353,PUNCH_NUMBER_VALID:1157,PUNCH_TAG_VALID:98},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6030-09",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:5,ACT_TAG_VALID:93,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6030-11",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"ICSS",ACT_AREA_VALID:2,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6078-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:74.87,DISCIPLINE:"Instrument",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:405,PUNCH_NUMBER_VALID:283,PUNCH_TAG_VALID:133},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6110-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:36.21,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6120-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:19.7,DISCIPLINE:"Mechanical",ACT_AREA_VALID:2,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:116,PUNCH_TAG_VALID:35},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6120-11",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:66.18,DISCIPLINE:"Mechanical",ACT_AREA_VALID:8,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:152,PUNCH_TAG_VALID:44},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6150-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:43.19,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6160-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:62.6,DISCIPLINE:"Instrument",ACT_AREA_VALID:2,ACT_TAG_VALID:87,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:37.5,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28.21,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:83.22,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:322,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:6,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-07",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:5,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-08",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:10,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6221-09",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6222-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:8,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6222-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"A",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6223-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:22.73,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6223-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"6223-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"8011-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:13.19,DISCIPLINE:"Marine",ACT_AREA_VALID:6,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"8150-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28.71,DISCIPLINE:"Mechanical",ACT_AREA_VALID:8,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:87,PUNCH_TAG_VALID:71},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"8150-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:3.92,DISCIPLINE:"Mechanical",ACT_AREA_VALID:6,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:38},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"8300-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.34,DISCIPLINE:"Utility",ACT_AREA_VALID:8,ACT_TAG_VALID:149,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"8740-19",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:50,DISCIPLINE:"Electrical",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"9380-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:71.96,DISCIPLINE:"Marine",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:304,PUNCH_NUMBER_VALID:143,PUNCH_TAG_VALID:66},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"9540-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:10.43,DISCIPLINE:"Telecom",ACT_AREA_VALID:9,ACT_TAG_VALID:153,PUNCH_NUMBER_VALID:104,PUNCH_TAG_VALID:23},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"9590-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:60,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"9590-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"9590-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.83,DISCIPLINE:"Telecom",ACT_AREA_VALID:1,ACT_TAG_VALID:599,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-05-29",SUBSYSTEM:"9590-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"2400-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:9.62,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"2400-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:42.98,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:191,PUNCH_NUMBER_VALID:96,PUNCH_TAG_VALID:55},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"3010-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:49.72,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:313,PUNCH_NUMBER_VALID:173,PUNCH_TAG_VALID:87},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"3010-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:48.64,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:313,PUNCH_NUMBER_VALID:149,PUNCH_TAG_VALID:84},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"3020-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28.67,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:369,PUNCH_NUMBER_VALID:126,PUNCH_TAG_VALID:101},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"3030-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:28,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:261,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:72},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"3040-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:52.3,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:252,PUNCH_NUMBER_VALID:159,PUNCH_TAG_VALID:77},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"4330-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:81.74,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:100,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:56},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"4330-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:69.41,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:136,PUNCH_NUMBER_VALID:104,PUNCH_TAG_VALID:61},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"4330-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:48.28,DISCIPLINE:"Process",ACT_AREA_VALID:5,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"4340-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:38.25,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:277,PUNCH_NUMBER_VALID:260,PUNCH_TAG_VALID:88},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6080-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:6.25,DISCIPLINE:"Telecom",ACT_AREA_VALID:2,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6080-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:63.83,DISCIPLINE:"Telecom",PMCS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6080-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:13.33,DISCIPLINE:"Telecom",ACT_AREA_VALID:4,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6120-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:5.63,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:158,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6120-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:32.65,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:138,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6120-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:35.71,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:208,PUNCH_TAG_VALID:38},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6120-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:47.37,DISCIPLINE:"Mechanical",ACT_AREA_VALID:4,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:139,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"6210-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:43.46,DISCIPLINE:"Telecom",OBS_OPEN:"Y",ACT_AREA_VALID:9,ACT_TAG_VALID:927,PUNCH_NUMBER_VALID:546,PUNCH_TAG_VALID:207},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8130-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:28.33,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:104,PUNCH_TAG_VALID:84},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8130-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:29.29,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:169,PUNCH_NUMBER_VALID:105,PUNCH_TAG_VALID:57},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8130-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.64,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:183,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:54},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8300-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.68,DISCIPLINE:"Utility",ACT_AREA_VALID:7,ACT_TAG_VALID:208,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8300-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:47.67,DISCIPLINE:"Utility",ACT_AREA_VALID:7,ACT_TAG_VALID:187,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8300-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.68,DISCIPLINE:"Utility",ACT_AREA_VALID:7,ACT_TAG_VALID:129,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8300-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:59.78,DISCIPLINE:"Utility",ACT_AREA_VALID:5,ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8300-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:18.71,DISCIPLINE:"Utility",ACT_AREA_VALID:9,ACT_TAG_VALID:144,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8430-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:44.9,DISCIPLINE:"Marine",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:203,PUNCH_NUMBER_VALID:214,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"8500-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.02,DISCIPLINE:"Utility",OBS_OPEN:"Y",ACT_AREA_VALID:8,ACT_TAG_VALID:1275,PUNCH_NUMBER_VALID:228,PUNCH_TAG_VALID:86},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"9380-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:91.49,DISCIPLINE:"Marine",FLOAT_OFF:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:10,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:71,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-06-05",SUBSYSTEM:"9930-05",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"2400-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:38.97,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"2400-05",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:32.64,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:605,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"2400-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:23.64,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:87,PUNCH_NUMBER_VALID:569,PUNCH_TAG_VALID:18},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"2400-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:25.3,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:87,PUNCH_NUMBER_VALID:580,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"2400-10",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:8.75,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"3010-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:8,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"3020-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:20.9,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"3020-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:25,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:374,PUNCH_NUMBER_VALID:125,PUNCH_TAG_VALID:93},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"3040-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:74.06,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:442,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:9},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"3040-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:75.25,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:441,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"4330-09",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:54.18,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:37},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"6120-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:21.66,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:206,PUNCH_NUMBER_VALID:83,PUNCH_TAG_VALID:34},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"8016-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:57.04,DISCIPLINE:"Marine",PMCS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:99,PUNCH_TAG_VALID:39},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"8021-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:24.84,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"8021-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:32.18,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:101,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:20},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"8150-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:67.21,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:202,PUNCH_NUMBER_VALID:179,PUNCH_TAG_VALID:19},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"9390-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",PMCS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"9390-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",PMCS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-06-12",SUBSYSTEM:"9520-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:65.82,DISCIPLINE:"Telecom",ACT_AREA_VALID:6,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"2120-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.41,DISCIPLINE:"Process",ACT_AREA_VALID:5,ACT_TAG_VALID:126,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:32},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"2210-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:29.67,DISCIPLINE:"Process",ACT_AREA_VALID:1,ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:49},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"3200-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:47.64,DISCIPLINE:"Process",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:225,PUNCH_NUMBER_VALID:114,PUNCH_TAG_VALID:74},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"3430-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:37.5,DISCIPLINE:"Process",ACT_AREA_VALID:3,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:11},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"3470-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:25.81,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:159,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:30},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"4620-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",DISCIPLINE:"Process",ACT_AREA_VALID:-1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"6120-04",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:5.26,DISCIPLINE:"Mechanical",ACT_AREA_VALID:3,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:125,PUNCH_TAG_VALID:25},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"6130-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.41,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:372,PUNCH_NUMBER_VALID:106,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"6130-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.41,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:372,PUNCH_NUMBER_VALID:106,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"6130-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.41,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:372,PUNCH_NUMBER_VALID:106,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"6130-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40.41,DISCIPLINE:"Mechanical",MOC_OPEN:"Y",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:372,PUNCH_NUMBER_VALID:106,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"8002-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:2.11,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:181,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"8070-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:80.69,DISCIPLINE:"Marine",PMCS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:122,PUNCH_NUMBER_VALID:124,PUNCH_TAG_VALID:6},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"8080-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:12,DISCIPLINE:"HVAC",ACT_AREA_VALID:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"8080-06",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:12.66,DISCIPLINE:"HVAC",ACT_AREA_VALID:2,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"8080-07",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:11.25,DISCIPLINE:"HVAC",ACT_AREA_VALID:1,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"9860-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:69.32,DISCIPLINE:"Mechanical",ACT_AREA_VALID:6,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:113,PUNCH_TAG_VALID:24},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"9860-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:70.77,DISCIPLINE:"Marine",ACT_AREA_VALID:8,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:38},
    {MC_PLAN:"2020-06-19",SUBSYSTEM:"9882-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:3,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"2120-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:30.34,DISCIPLINE:"Process",ACT_AREA_VALID:5,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"2120-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:37.5,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:87,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:33},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"3400-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:23.93,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:57},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"3400-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:29.81,DISCIPLINE:"Process",ACT_AREA_VALID:2,ACT_TAG_VALID:199,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:15},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"3430-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:14.29,DISCIPLINE:"Process",ACT_AREA_VALID:5,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:22},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"5030-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:33.58,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"5410-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:53.02,DISCIPLINE:"Process",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:26},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"6150-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:36.9,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:127,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"6150-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:41.96,DISCIPLINE:"Mechanical",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"8002-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:40,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"8002-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:22.22,DISCIPLINE:"Mechanical",ACT_AREA_VALID:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:179,PUNCH_TAG_VALID:21},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"8080-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:39.64,DISCIPLINE:"HVAC",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:326,PUNCH_NUMBER_VALID:281,PUNCH_TAG_VALID:61},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9133-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:26.47,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:101,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:5.26,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:3.7,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-05",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:4,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:4,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-11",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-12",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-13",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:10,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9450-20",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:25,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-06-26",SUBSYSTEM:"9860-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.73,DISCIPLINE:"Marine",PMCS_OPEN:"Y",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:12},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"8950-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:2.85,DISCIPLINE:"Electrical",ACT_AREA_VALID:2,ACT_TAG_VALID:318,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:7},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"9131-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:182,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"9132-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:56.5,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:330,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:29},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"9150-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:33.33,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"9220-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:1.82,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"9891-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Telecom",ACT_AREA_VALID:7,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-03",SUBSYSTEM:"9930-08",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-10",SUBSYSTEM:"4730-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:14.13,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:106,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:14},
    {MC_PLAN:"2020-07-10",SUBSYSTEM:"6170-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Mechanical",ACT_AREA_VALID:20,ACT_TAG_VALID:330,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-07-10",SUBSYSTEM:"9134-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:0.75,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-07-10",SUBSYSTEM:"9220-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:32.84,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:7,ACT_TAG_VALID:343,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:57},
    {MC_PLAN:"2020-07-10",SUBSYSTEM:"9220-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"A",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:10},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"4730-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:63.43,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:329,PUNCH_NUMBER_VALID:1035,PUNCH_TAG_VALID:90},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"4730-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:64.74,DISCIPLINE:"Process",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:323,PUNCH_NUMBER_VALID:1013,PUNCH_TAG_VALID:86},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"8950-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"N",A_PG:2.21,DISCIPLINE:"Electrical",ACT_AREA_VALID:3,ACT_TAG_VALID:401,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9220-03",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:125,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9220-05",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"A",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:406,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:13},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9220-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:147,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9220-07",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9240-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9451-05",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:3,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9451-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9451-09",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9451-10",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9451-11",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:5,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-17",SUBSYSTEM:"9930-04",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9450-10",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9450-15",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:3,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9450-17",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:3,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9450-21",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9451-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:1,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9451-12",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:3,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9930-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9930-06",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9930-07",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {MC_PLAN:"2020-07-24",SUBSYSTEM:"9930-09",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:2,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-31",SUBSYSTEM:"8400-02",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"B",A_PG:61.72,DISCIPLINE:"Utility",ACT_AREA_VALID:4,ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:4},
    {MC_PLAN:"2020-07-31",SUBSYSTEM:"9300-03",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:1.27,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-07-31",SUBSYSTEM:"9450-07",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:2,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-07-31",SUBSYSTEM:"9451-07",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"B",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:4,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {MC_PLAN:"2020-07-31",SUBSYSTEM:"9451-08",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:5,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-08-07",SUBSYSTEM:"9300-01",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:1.87,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:564,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:18},
    {MC_PLAN:"2020-08-07",SUBSYSTEM:"9300-02",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"A",A_PG:0,DISCIPLINE:"Structure",OBS_OPEN:"Y",ACT_AREA_VALID:6,ACT_TAG_VALID:144,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {MC_PLAN:"2020-08-14",SUBSYSTEM:"2400-08",SUBSYSTEM_STATUS:"INPROGRESS",PUNCH_STATUS:"A",A_PG:23.4,DISCIPLINE:"Process",ACT_AREA_VALID:4,ACT_TAG_VALID:167,PUNCH_NUMBER_VALID:90,PUNCH_TAG_VALID:45},
    {MC_PLAN:"2020-08-21",SUBSYSTEM:"9291-01",SUBSYSTEM_STATUS:"NOTSTATED",PUNCH_STATUS:"N",A_PG:0,DISCIPLINE:"Structure",ACT_AREA_VALID:3,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {MC_PLAN:"2020-08-21",SUBSYSTEM:"9999-07",SUBSYSTEM_STATUS:"ITRCOMPLETED",PUNCH_STATUS:"N",A_PG:100,DISCIPLINE:"Instrument",ACT_AREA_VALID:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1}
  ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2020-03-02' }
    ],
    SQL3: [
      {MC_PLAN:"2019-09-20",A_T:42,A_A:42,A_A_CUM:42,A_PG:100,A_CUM_PG:0.03,PA_O:0,PB_O:0},
      {MC_PLAN:"2019-09-27",A_T:143,A_A:114,A_A_CUM:156,A_O:29,A_PG:79.72,A_CUM_PG:0.1,PA_O:7,PB_O:16},
      {MC_PLAN:"2019-10-04",A_T:104,A_A:100,A_A_CUM:256,A_O:4,A_PG:96.15,A_CUM_PG:0.16,PA_O:0,PB_O:5},
      {MC_PLAN:"2019-10-11",A_T:164,A_A:160,A_A_CUM:416,A_O:4,A_PG:97.56,A_CUM_PG:0.26,PA_O:0,PB_O:23},
      {MC_PLAN:"2019-10-18",A_T:99,A_A:78,A_A_CUM:494,A_O:21,A_PG:78.79,A_CUM_PG:0.31,PA_O:0,PB_O:9},
      {MC_PLAN:"2019-10-25",A_T:266,A_A:245,A_A_CUM:739,A_O:21,A_PG:92.11,A_CUM_PG:0.46,PA_O:2,PB_O:7},
      {MC_PLAN:"2019-11-01",A_T:916,A_A:894,A_A_CUM:1633,A_O:22,A_PG:97.6,A_CUM_PG:1.02,PA_O:9,PB_O:20},
      {MC_PLAN:"2019-11-08",A_T:110,A_A:87,A_A_CUM:1720,A_O:23,A_PG:79.09,A_CUM_PG:1.07,PA_O:2,PB_O:6},
      {MC_PLAN:"2019-11-15",A_T:433,A_A:426,A_A_CUM:2146,A_O:7,A_PG:98.38,A_CUM_PG:1.34,PA_O:0,PB_O:3},
      {MC_PLAN:"2019-11-22",A_T:192,A_A:153,A_A_CUM:2299,A_O:39,A_PG:79.69,A_CUM_PG:1.43,PA_O:0,PB_O:3},
      {MC_PLAN:"2019-12-20",A_T:38,A_A:36,A_A_CUM:2335,A_O:2,A_PG:94.74,A_CUM_PG:1.45,PA_O:0,PB_O:0},
      {MC_PLAN:"2019-12-27",A_T:178,A_A:153,A_A_CUM:2488,A_O:25,A_PG:85.96,A_CUM_PG:1.55,PA_O:6,PB_O:31},
      {MC_PLAN:"2020-01-03",A_T:167,A_A:155,A_A_CUM:2643,A_O:12,A_PG:92.81,A_CUM_PG:1.65,PA_O:0,PB_O:8},
      {MC_PLAN:"2020-01-10",A_T:1484,A_A:1462,A_A_CUM:4105,A_O:22,A_PG:98.52,A_CUM_PG:2.56,PA_O:4,PB_O:35},
      {MC_PLAN:"2020-01-17",A_T:9003,A_A:8889,A_A_CUM:12994,A_O:114,A_PG:98.73,A_CUM_PG:8.09,PA_O:28,PB_O:261},
      {MC_PLAN:"2020-01-24",A_T:724,A_A:708,A_A_CUM:13702,A_O:16,A_PG:97.79,A_CUM_PG:8.54,PA_O:2,PB_O:138},
      {MC_PLAN:"2020-01-31",A_T:734,A_A:710,A_A_CUM:14412,A_O:24,A_PG:96.73,A_CUM_PG:8.98,PA_O:25,PB_O:142},
      {MC_PLAN:"2020-02-07",A_T:1171,A_A:1135,A_A_CUM:15547,A_O:36,A_PG:96.93,A_CUM_PG:9.68,PA_O:40,PB_O:345},
      {MC_PLAN:"2020-02-14",A_T:1548,A_A:1191,A_A_CUM:16738,A_O:357,A_PG:76.94,A_CUM_PG:10.43,PA_O:38,PB_O:142},
      {MC_PLAN:"2020-02-21",A_T:1706,A_A:1400,A_A_CUM:18138,A_O:306,A_PG:82.06,A_CUM_PG:11.3,PA_O:35,PB_O:167},
      {MC_PLAN:"2020-02-28",A_T:1799,A_A:1337,A_A_CUM:19475,A_O:462,A_PG:74.32,A_CUM_PG:12.13,PA_O:25,PB_O:112},
      {MC_PLAN:"2020-03-06",A_T:2981,A_A:2353,A_A_CUM:21828,A_O:628,A_PG:78.93,A_CUM_PG:13.6,PA_O:94,PB_O:198},
      {MC_PLAN:"2020-03-13",A_T:4404,A_A:2972,A_A_CUM:24800,A_O:1432,A_PG:67.48,A_CUM_PG:15.45,PA_O:71,PB_O:218},
      {MC_PLAN:"2020-03-20",A_T:4979,A_A:3807,A_A_CUM:28607,A_O:1172,A_PG:76.46,A_CUM_PG:17.82,PA_O:85,PB_O:150},
      {MC_PLAN:"2020-03-27",A_T:5611,A_A:4423,A_A_CUM:33030,A_O:1188,A_PG:78.83,A_CUM_PG:20.58,PA_O:195,PB_O:416},
      {MC_PLAN:"2020-04-03",A_T:6386,A_A:3793,A_A_CUM:36823,A_O:2593,A_PG:59.4,A_CUM_PG:22.94,PA_O:153,PB_O:144},
      {MC_PLAN:"2020-04-10",A_T:8638,A_A:4766,A_A_CUM:41589,A_O:3872,A_PG:55.17,A_CUM_PG:25.91,PA_O:80,PB_O:221},
      {MC_PLAN:"2020-04-17",A_T:7989,A_A:4189,A_A_CUM:45778,A_O:3800,A_PG:52.43,A_CUM_PG:28.52,PA_O:82,PB_O:228},
      {MC_PLAN:"2020-04-24",A_T:13020,A_A:6737,A_A_CUM:52515,A_O:6283,A_PG:51.74,A_CUM_PG:32.71,PA_O:284,PB_O:478},
      {MC_PLAN:"2020-05-01",A_T:11078,A_A:6356,A_A_CUM:58871,A_O:4722,A_PG:57.37,A_CUM_PG:36.67,PA_O:81,PB_O:196},
      {MC_PLAN:"2020-05-08",A_T:10148,A_A:4494,A_A_CUM:63365,A_O:5654,A_PG:44.28,A_CUM_PG:39.47,PA_O:81,PB_O:181},
      {MC_PLAN:"2020-05-15",A_T:15023,A_A:9426,A_A_CUM:72791,A_O:5597,A_PG:62.74,A_CUM_PG:45.34,PA_O:149,PB_O:381},
      {MC_PLAN:"2020-05-22",A_T:3985,A_A:2080,A_A_CUM:74871,A_O:1905,A_PG:52.2,A_CUM_PG:46.64,PA_O:74,PB_O:202},
      {MC_PLAN:"2020-05-29",A_T:10698,A_A:3570,A_A_CUM:78441,A_O:7128,A_PG:33.37,A_CUM_PG:48.86,PA_O:457,PB_O:374},
      {MC_PLAN:"2020-06-05",A_T:13428,A_A:6690,A_A_CUM:85131,A_O:6738,A_PG:49.82,A_CUM_PG:53.03,PA_O:222,PB_O:267},
      {MC_PLAN:"2020-06-12",A_T:5224,A_A:2539,A_A_CUM:87670,A_O:2685,A_PG:48.6,A_CUM_PG:54.61,PA_O:577,PB_O:235},
      {MC_PLAN:"2020-06-19",A_T:5476,A_A:2321,A_A_CUM:89991,A_O:3155,A_PG:42.38,A_CUM_PG:56.06,PA_O:236,PB_O:164},
      {MC_PLAN:"2020-06-26",A_T:3504,A_A:1216,A_A_CUM:91207,A_O:2288,A_PG:34.7,A_CUM_PG:56.82,PA_O:187,PB_O:157},
      {MC_PLAN:"2020-07-03",A_T:1105,A_A:201,A_A_CUM:91408,A_O:904,A_PG:18.19,A_CUM_PG:56.94,PA_O:1,PB_O:12},
      {MC_PLAN:"2020-07-10",A_T:1317,A_A:206,A_A_CUM:91614,A_O:1111,A_PG:15.64,A_CUM_PG:57.07,PA_O:2,PB_O:44},
      {MC_PLAN:"2020-07-17",A_T:2673,A_A:680,A_A_CUM:92294,A_O:1993,A_PG:25.44,A_CUM_PG:57.49,PA_O:580,PB_O:203},
      {MC_PLAN:"2020-07-24",A_T:230,A_A_CUM:92294,A_O:230,A_CUM_PG:57.49,PA_O:0,PB_O:2},
      {MC_PLAN:"2020-07-31",A_T:537,A_A:159,A_A_CUM:92453,A_O:378,A_PG:29.61,A_CUM_PG:57.59,PA_O:1,PB_O:16},
      {MC_PLAN:"2020-08-07",A_T:741,A_A:11,A_A_CUM:92464,A_O:730,A_PG:1.48,A_CUM_PG:57.6,PA_O:2,PB_O:35},
      {MC_PLAN:"2020-08-14",A_T:282,A_A:66,A_A_CUM:92530,A_O:216,A_PG:23.4,A_CUM_PG:57.64,PA_O:7,PB_O:21},
      {MC_PLAN:"2020-08-21",A_T:54,A_A:16,A_A_CUM:92546,A_O:38,A_PG:29.63,A_CUM_PG:57.65}
    ],
    SQL4: [
      {MC_PLAN:"2019-09-20",A_T:157306,A_CUM:29511,A_CUM_PG:18.76},
      {MC_PLAN:"2019-09-27",A_T:157306,A_CUM:31174,A_CUM_PG:19.82},
      {MC_PLAN:"2019-10-04",A_T:157306,A_CUM:33122,A_CUM_PG:21.06},
      {MC_PLAN:"2019-10-11",A_T:157306,A_CUM:35041,A_CUM_PG:22.28},
      {MC_PLAN:"2019-10-18",A_T:157306,A_CUM:38270,A_CUM_PG:24.33},
      {MC_PLAN:"2019-10-25",A_T:157306,A_CUM:40245,A_CUM_PG:25.58},
      {MC_PLAN:"2019-11-01",A_T:157306,A_CUM:42381,A_CUM_PG:26.94},
      {MC_PLAN:"2019-11-08",A_T:157306,A_CUM:45123,A_CUM_PG:28.68},
      {MC_PLAN:"2019-11-15",A_T:157306,A_CUM:47685,A_CUM_PG:30.31},
      {MC_PLAN:"2019-11-22",A_T:157306,A_CUM:50315,A_CUM_PG:31.99},
      {MC_PLAN:"2019-12-20",A_T:157306,A_CUM:62050,A_CUM_PG:39.45},
      {MC_PLAN:"2019-12-27",A_T:157306,A_CUM:64797,A_CUM_PG:41.19},
      {MC_PLAN:"2020-01-03",A_T:157306,A_CUM:66726,A_CUM_PG:42.42},
      {MC_PLAN:"2020-01-10",A_T:157306,A_CUM:69253,A_CUM_PG:44.02},
      {MC_PLAN:"2020-01-17",A_T:157306,A_CUM:72502,A_CUM_PG:46.09},
      {MC_PLAN:"2020-01-24",A_T:157306,A_CUM:75037,A_CUM_PG:47.7},
      {MC_PLAN:"2020-01-31",A_T:157306,A_CUM:76621,A_CUM_PG:48.71},
      {MC_PLAN:"2020-02-07",A_T:157306,A_CUM:79383,A_CUM_PG:50.46},
      {MC_PLAN:"2020-02-14",A_T:157306,A_CUM:83492,A_CUM_PG:53.08},
      {MC_PLAN:"2020-02-21",A_T:157306,A_CUM:86839,A_CUM_PG:55.2},
      {MC_PLAN:"2020-02-28",A_T:157306,A_CUM:90245,A_CUM_PG:57.37},
      {MC_PLAN:"2020-03-06",A_T:157306,A_CUM:94185,A_CUM_PG:59.87},
      {MC_PLAN:"2020-03-13",A_T:157306,A_CUM:98936,A_CUM_PG:62.89},
      {MC_PLAN:"2020-03-20",A_T:157306,A_CUM:104314,A_CUM_PG:66.31},
      {MC_PLAN:"2020-03-27",A_T:157306,A_CUM:105110,A_CUM_PG:66.82},
      {MC_PLAN:"2020-04-03",A_T:157306,A_CUM:105110,A_CUM_PG:66.82},
      {MC_PLAN:"2020-04-10",A_T:157306,A_CUM:105110,A_CUM_PG:66.82},
      {MC_PLAN:"2020-04-17",A_T:157306,A_CUM:105110,A_CUM_PG:66.82},
      {MC_PLAN:"2020-04-24",A_T:157306,A_CUM:105110,A_CUM_PG:66.82},
      {MC_PLAN:"2020-05-01",A_T:157306,A_CUM:105110,A_CUM_PG:66.82}
    ]
  }
}