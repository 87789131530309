// Common
import Comm_TabModalContainer_Data from '../01_ModalContainer/includes/primitives/Data'

// Planning
import Plan_Milestones_Data from '../../09_Projects/TCO/03_Planning/Milestones/includes/primitives/Data'
// Engineering

// Procurement
import Proc_Equipment3D_Data from '../../09_Projects/TCO/05_Procurement/Equipment/includes/primitives/Data'

// Construction
import Const_BlockDivision_Data from '../../09_Projects/TCO/07_Construction/BlockDivision/includes/primitives/Data'
import Const_Module_Data from '../../09_Projects/TCO/07_Construction/Module/includes/primitives/Data'

// System Completion
import SC_Module_Data from '../../09_Projects/TCO/08_SystemCompletion/Module/includes/primitives/Data'





// ------------------------------------------------------------------------------------------------------------------------------------------------

export default {
  Comm_TabModalContainer_Data,
  
  // Planning
  Plan_Milestones_Data,

  // Engineering

  // Procurement
  Proc_Equipment3D_Data,

  // Construction
  Const_BlockDivision_Data,
  Const_Module_Data,
  
  // System Completion
  SC_Module_Data,
}

