import JLibChart from '../../lib/jin/svgchartlib/01_Charts/00_Customs'
import JLibPage from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs'

import JLibChartData from '../../lib/jin/svgchartlib/01_Charts/00_Customs/data'
import JLibPageData from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs/data'

import JLibV2Chart from '../../lib/jin/svgchartlib-v2/export'
import JLibV2ChartData from '../../lib/jin/svgchartlib-v2/export/data'

export default {
  Component: {
    ...JLibChart,
    ...JLibPage,
    ...JLibV2Chart,
  },
  Data: {
    ...JLibChartData,
    ...JLibPageData,
    ...JLibV2ChartData,
  }
}

