
export default {
  Canvas: {
    CanvasWidth: 1500,
    CanvasHeight: 300,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },


  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },
  

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },


  Queries: {

    // --> this.style
    SQL1:  [
      {
        // TITLE
        TITLE_VISIBLE: 'Y',
        TITLE_V_PADDING: 5,
        TITLE_TEXT_SIZE: 11,
        TITLE_TEXT_COLOR: '#333',
        TITLE_BOX_COLOR: '#E0E0DF',
        TITLE_BOX_RADIUS: 3,
        PLUS_Y: 1,

        // PROGRESS
        PROG_VISIBLE: 'Y',
        PROG_TEXT_SIZE: 15,
        PROG_TEXT_COLOR: '#44A9DF',
        PROG_TEXT_UNIT: '%',

        // TABLE
        TABLE_BOX_WIDTH: 150,
        TABLE_BOX_HEIGHT: 40,
        TABLE_BOX_COLOR: '#83D2F5',
        TABLE_BOX_OPACITY: .7,
        TABLE_RADIUS: 3,

        TABLE_HEADER_Y: 5,
        TABLE_HEADER_TEXT_SIZE: 9,
        TABLE_HEADER_TEXT_COLOR: '#333',
        
        TABLE_VALUE_Y: 20,
        TABLE_VALUE_TEXT_SIZE: 11,
      },
    ],
  },

  // --> this.dataSet
  DataItems: [ 
    {
      COUNT: 3,
      TITLE: 'WorkDown',

      TEXT1: 'Total',
      TEXT2: 'Issued$',
      TEXT3: 'Remain%',
      TEXT4: 'Prog1%',
      TEXT5: 'Prog2%',
      TEXT6: 'Prog2%',

      COLOR1: '#fff',
      COLOR2: '#fff',
      COLOR3: '#F35E90',
      COLOR4: '#44A9DF',
      COLOR5: '#44A9DF',
      COLOR6: '#44A9DF',
      
      VALUE1: 1111,
      VALUE2: 2222,
      VALUE3: 3333,
      VALUE4: 4444,
      VALUE5: 5555,
      VALUE6: 6666,

      FILTER1: 'filter1',
      FILTER2: 'filter2',
      FILTER3: 'filter3',
      FILTER4: 'filter4',
      FILTER5: 'filter5',
      FILTER6: 'filter6',

      PROG: 75,
    }
  ]
}