import __C from '../../../../../../includes/primitives/_constant_'

import MasterProgress from './MasterProgress'
import BlockStatus from './BlockStatus'
import DisciplineStatus from './DisciplineStatus'
import OutfittingStatus from './OutfittingStatus'
import ModuleDiscipline from './ModuleDiscipline'


export default {
    Canvas: {
      CanvasWidth: 1300,
      CanvasHeight: 920,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Queries: {
      // Status of Blocks
      SQL_MasterProg: MasterProgress,

      SQL2: BlockStatus,

      // Rada Chart for Phase by Area
      SQL3: DisciplineStatus,

      // Bar Chart for the Module by Module
      SQL4: OutfittingStatus,

      // Rada Chart for the construction and Engineering by module
      SQL5: ModuleDiscipline,

      // Rada Chart for the construction and Engineering by module
      SQL_OverallProgress: [
        { TYPE: 'Phase'  , PHASE: 'ENG', P_PG: 99.99, A_PG: 99.99, D_PG: 0,     TOTAL: null,    ACTUAL: null,   REMAIN: null,  PROG: null, },
        { TYPE: 'Phase'  , PHASE: 'PRO', P_PG: 99.52, A_PG: 99.52, D_PG: 0,     TOTAL: null,    ACTUAL: null,   REMAIN: null,  PROG: null, },
        { TYPE: 'Phase'  , PHASE: 'CON', P_PG: 83.6,  A_PG: 83.42, D_PG: -0.18, TOTAL: null,    ACTUAL: null,   REMAIN: null,  PROG: null, },
        { TYPE: 'Phase'  , PHASE: 'INT', P_PG: 72.51, A_PG: 72.23, D_PG: -0.28, TOTAL: null,    ACTUAL: null,   REMAIN: null,  PROG: null, },
        { TYPE: 'Phase'  , PHASE: 'MC' , P_PG: 44.99, A_PG: 45.08, D_PG: 0.09,  TOTAL: 157784,  ACTUAL: 84608,  REMAIN: 73176, PROG: 53.62, },
        { TYPE: 'Phase'  , PHASE: 'COM', P_PG: 8.74,  A_PG: 9.38,  D_PG: 0.64,  TOTAL: 24633,   ACTUAL: 1661,   REMAIN: 22972, PROG: 6.74, },
        { TYPE: 'Overall', PHASE: 'OV' , P_PG: 89.22, A_PG: 89.16, D_PG: -0.06, TOTAL: null,    ACTUAL: null,   REMAIN: null,  PROG: null, },
        { TYPE: 'Overall', PHASE: 'PA' , P_PG: null,  A_PG: null,  D_PG: null,   TOTAL: 20200,  ACTUAL: 15501,  REMAIN: 4699,  PROG: 76.74, },
        { TYPE: 'Overall', PHASE: 'PB' , P_PG: null,  A_PG: null,  D_PG: null,  TOTAL: 28813,   ACTUAL: 22900,  REMAIN: 5913,  PROG: 79.48, },
        { TYPE: 'Overall', PHASE: 'WD' , P_PG: null,  A_PG: null,  D_PG: null,  TOTAL: 773,     ACTUAL: 139,    REMAIN: 634,   PROG: 82.02, },
        { TYPE: 'Overall', PHASE: 'MCC', P_PG: null,  A_PG: null,  D_PG: null,  TOTAL: 773,     ACTUAL: 130,    REMAIN: 643,   PROG: 83.18, },
      ],       
      
      // Quantity
      SQL_Quantity: [
        {type: 'WT',  title: 'Hull',     value: 61.2,   qty: 52625,},
        {type: 'WT',  title: 'Deckbox',  value: 22.6,   qty: 19422,},
        {type: 'WT',  title: 'Topside',  value: 16.2,   qty: 13933.7,},

        {type: 'BLK', title: 'Hull',     value: 40.7,   qty: 154,},
        {type: 'BLK', title: 'Deckbox',  value: 29.9,   qty: 113,},
        {type: 'BLK', title: 'Topside',  value: 29.4,   qty: 111,},

        {type: 'ITR', title: 'Hull',     value: 7.2,    qty: 13162,},
        {type: 'ITR', title: 'Deckbox',  value: 58.1,   qty: 106150,},
        {type: 'ITR', title: 'Topside',  value: 32.8,   qty: 59934,},
      ],
      
    },



    DataItems: [
      { NO: '1', MODULE: 'NP1UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'NP1UH', DESCR: 'NW NODE/PONTOON P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '2', MODULE: 'NP1UH', PE3: 'P110H', PE1: null, AREA: 'Hull', BLOCK: 'P110H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '3', MODULE: 'NP1UH', PE3: 'P120H', PE1: null, AREA: 'Hull', BLOCK: 'P120H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '4', MODULE: 'NP1UH', PE3: 'P130H', PE1: null, AREA: 'Hull', BLOCK: 'P130H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '5', MODULE: 'NP1UH', PE3: 'P140H', PE1: null, AREA: 'Hull', BLOCK: 'P140H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '6', MODULE: 'NP1UH', PE3: 'P15PH', PE1: null, AREA: 'Hull', BLOCK: 'P15PH', DESCR: 'NORTH PONTOON 1st P.E (1)', BLOCK_STATUS: '3PE', PE: 'Y', },
      { NO: '7', MODULE: 'NP1UH', PE3: 'P15PH', PE1: 'P150H', AREA: 'Hull', BLOCK: 'P150H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '8', MODULE: 'NP1UH', PE3: 'P15PH', PE1: 'P160H', AREA: 'Hull', BLOCK: 'P160H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '9', MODULE: 'NP1UH', PE3: 'P170H', PE1: null, AREA: 'Hull', BLOCK: 'P170H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '10', MODULE: 'NP1UH', PE3: 'P180H', PE1: null, AREA: 'Hull', BLOCK: 'P180H', DESCR: 'NORTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '11', MODULE: 'NP1UH', PE3: 'N120H', PE1: null, AREA: 'Hull', BLOCK: 'N120H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '12', MODULE: 'NP1UH', PE3: 'N130H', PE1: null, AREA: 'Hull', BLOCK: 'N130H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '13', MODULE: 'NP1UH', PE3: 'N110H', PE1: null, AREA: 'Hull', BLOCK: 'N110H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '14', MODULE: 'NP1UH', PE3: 'N140H', PE1: null, AREA: 'Hull', BLOCK: 'N140H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '15', MODULE: 'NP1UH', PE3: 'N160H', PE1: null, AREA: 'Hull', BLOCK: 'N160H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '16', MODULE: 'NP1UH', PE3: 'N170H', PE1: null, AREA: 'Hull', BLOCK: 'N170H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '17', MODULE: 'NP1UH', PE3: 'N150H', PE1: null, AREA: 'Hull', BLOCK: 'N150H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '18', MODULE: 'NP1UH', PE3: 'N180H', PE1: null, AREA: 'Hull', BLOCK: 'N180H', DESCR: 'NW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '19', MODULE: 'NP1UH', PE3: 'Y710H', PE1: null, AREA: 'Hull', BLOCK: 'Y710H', DESCR: 'FENDER(무도장, 제거용)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '20', MODULE: 'NP1UH', PE3: 'R110H', PE1: null, AREA: 'Hull', BLOCK: 'R110H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '21', MODULE: 'NP1UH', PE3: 'R120H', PE1: null, AREA: 'Hull', BLOCK: 'R120H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '22', MODULE: 'NP1UH', PE3: 'R130H', PE1: null, AREA: 'Hull', BLOCK: 'R130H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '23', MODULE: 'NP1UH', PE3: 'R140H', PE1: null, AREA: 'Hull', BLOCK: 'R140H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '24', MODULE: 'NP1UH', PE3: 'R150H', PE1: null, AREA: 'Hull', BLOCK: 'R150H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '25', MODULE: 'NP2UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'NP2UH', DESCR: 'NE NODE/PONTOON P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '26', MODULE: 'NP2UH', PE3: 'P25PH', PE1: null, AREA: 'Hull', BLOCK: 'P25PH', DESCR: 'EAST PONTOON 1st P.E (1)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '27', MODULE: 'NP2UH', PE3: 'P25PH', PE1: 'P250H', AREA: 'Hull', BLOCK: 'P250H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '28', MODULE: 'NP2UH', PE3: 'P25PH', PE1: 'P260H', AREA: 'Hull', BLOCK: 'P260H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '29', MODULE: 'NP2UH', PE3: 'P270H', PE1: null, AREA: 'Hull', BLOCK: 'P270H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '30', MODULE: 'NP2UH', PE3: 'P280H', PE1: null, AREA: 'Hull', BLOCK: 'P280H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '31', MODULE: 'NP2UH', PE3: 'P21PH', PE1: null, AREA: 'Hull', BLOCK: 'P21PH', DESCR: 'EAST PONTOON 1st P.E (2)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '32', MODULE: 'NP2UH', PE3: 'P21PH', PE1: 'P210H', AREA: 'Hull', BLOCK: 'P210H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '33', MODULE: 'NP2UH', PE3: 'P21PH', PE1: 'P220H', AREA: 'Hull', BLOCK: 'P220H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '34', MODULE: 'NP2UH', PE3: 'P230H', PE1: null, AREA: 'Hull', BLOCK: 'P230H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '35', MODULE: 'NP2UH', PE3: 'P240H', PE1: null, AREA: 'Hull', BLOCK: 'P240H', DESCR: 'EAST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '36', MODULE: 'NP2UH', PE3: 'N240H', PE1: null, AREA: 'Hull', BLOCK: 'N240H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '37', MODULE: 'NP2UH', PE3: 'N230H', PE1: null, AREA: 'Hull', BLOCK: 'N230H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '38', MODULE: 'NP2UH', PE3: 'N210H', PE1: null, AREA: 'Hull', BLOCK: 'N210H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '39', MODULE: 'NP2UH', PE3: 'N220H', PE1: null, AREA: 'Hull', BLOCK: 'N220H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '40', MODULE: 'NP2UH', PE3: 'N280H', PE1: null, AREA: 'Hull', BLOCK: 'N280H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '41', MODULE: 'NP2UH', PE3: 'N270H', PE1: null, AREA: 'Hull', BLOCK: 'N270H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '42', MODULE: 'NP2UH', PE3: 'N260H', PE1: null, AREA: 'Hull', BLOCK: 'N260H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '43', MODULE: 'NP2UH', PE3: 'N250H', PE1: null, AREA: 'Hull', BLOCK: 'N250H', DESCR: 'NE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '44', MODULE: 'NP2UH', PE3: 'R210H', PE1: null, AREA: 'Hull', BLOCK: 'R210H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '45', MODULE: 'NP2UH', PE3: 'R220H', PE1: null, AREA: 'Hull', BLOCK: 'R220H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '46', MODULE: 'NP2UH', PE3: 'R230H', PE1: null, AREA: 'Hull', BLOCK: 'R230H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '47', MODULE: 'NP2UH', PE3: 'R240H', PE1: null, AREA: 'Hull', BLOCK: 'R240H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '48', MODULE: 'NP2UH', PE3: 'R250H', PE1: null, AREA: 'Hull', BLOCK: 'R250H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '49', MODULE: 'NP2UH', PE3: 'R260H', PE1: null, AREA: 'Hull', BLOCK: 'R260H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '50', MODULE: 'NP4UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'NP4UH', DESCR: 'SW NODE/PONTOON P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '51', MODULE: 'NP4UH', PE3: 'P41PH', PE1: null, AREA: 'Hull', BLOCK: 'P41PH', DESCR: 'WEST PONTOON 1st P.E (1)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '52', MODULE: 'NP4UH', PE3: 'P41PH', PE1: 'P410H', AREA: 'Hull', BLOCK: 'P410H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '53', MODULE: 'NP4UH', PE3: 'P41PH', PE1: 'P420H', AREA: 'Hull', BLOCK: 'P420H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '54', MODULE: 'NP4UH', PE3: 'P430H', PE1: null, AREA: 'Hull', BLOCK: 'P430H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '55', MODULE: 'NP4UH', PE3: 'P440H', PE1: null, AREA: 'Hull', BLOCK: 'P440H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '56', MODULE: 'NP4UH', PE3: 'P45PH', PE1: null, AREA: 'Hull', BLOCK: 'P45PH', DESCR: 'WEST PONTOON 1st P.E (2)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '57', MODULE: 'NP4UH', PE3: 'P45PH', PE1: 'P450H', AREA: 'Hull', BLOCK: 'P450H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '58', MODULE: 'NP4UH', PE3: 'P45PH', PE1: 'P460H', AREA: 'Hull', BLOCK: 'P460H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '59', MODULE: 'NP4UH', PE3: 'P470H', PE1: null, AREA: 'Hull', BLOCK: 'P470H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '60', MODULE: 'NP4UH', PE3: 'P480H', PE1: null, AREA: 'Hull', BLOCK: 'P480H', DESCR: 'WEST PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '61', MODULE: 'NP4UH', PE3: 'N440H', PE1: null, AREA: 'Hull', BLOCK: 'N440H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '62', MODULE: 'NP4UH', PE3: 'N430H', PE1: null, AREA: 'Hull', BLOCK: 'N430H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '63', MODULE: 'NP4UH', PE3: 'N410H', PE1: null, AREA: 'Hull', BLOCK: 'N410H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '64', MODULE: 'NP4UH', PE3: 'N420H', PE1: null, AREA: 'Hull', BLOCK: 'N420H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '65', MODULE: 'NP4UH', PE3: 'N480H', PE1: null, AREA: 'Hull', BLOCK: 'N480H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '66', MODULE: 'NP4UH', PE3: 'N470H', PE1: null, AREA: 'Hull', BLOCK: 'N470H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '67', MODULE: 'NP4UH', PE3: 'N460H', PE1: null, AREA: 'Hull', BLOCK: 'N460H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '68', MODULE: 'NP4UH', PE3: 'N450H', PE1: null, AREA: 'Hull', BLOCK: 'N450H', DESCR: 'SW NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '69', MODULE: 'NP4UH', PE3: 'Y720H', PE1: null, AREA: 'Hull', BLOCK: 'Y720H', DESCR: 'FENDER(무도장, 제거용)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '70', MODULE: 'NP4UH', PE3: 'Y730H', PE1: null, AREA: 'Hull', BLOCK: 'Y730H', DESCR: 'FENDER(무도장, 제거용)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '71', MODULE: 'NP3UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'NP3UH', DESCR: 'SE NODE/PONTOON P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '72', MODULE: 'NP3UH', PE3: 'P35PH', PE1: null, AREA: 'Hull', BLOCK: 'P35PH', DESCR: 'SOUTH PONTOON 1st P.E (1)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '73', MODULE: 'NP3UH', PE3: 'P35PH', PE1: 'P350H', AREA: 'Hull', BLOCK: 'P350H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '74', MODULE: 'NP3UH', PE3: 'P35PH', PE1: 'P360H', AREA: 'Hull', BLOCK: 'P360H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '75', MODULE: 'NP3UH', PE3: 'P370H', PE1: null, AREA: 'Hull', BLOCK: 'P370H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '76', MODULE: 'NP3UH', PE3: 'P380H', PE1: null, AREA: 'Hull', BLOCK: 'P380H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '77', MODULE: 'NP3UH', PE3: 'P31PH', PE1: null, AREA: 'Hull', BLOCK: 'P31PH', DESCR: 'SOUTH PONTOON 1st P.E (2)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '78', MODULE: 'NP3UH', PE3: 'P31PH', PE1: 'P310H', AREA: 'Hull', BLOCK: 'P310H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '79', MODULE: 'NP3UH', PE3: 'P31PH', PE1: 'P320H', AREA: 'Hull', BLOCK: 'P320H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '80', MODULE: 'NP3UH', PE3: 'P330H', PE1: null, AREA: 'Hull', BLOCK: 'P330H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '81', MODULE: 'NP3UH', PE3: 'P340H', PE1: null, AREA: 'Hull', BLOCK: 'P340H', DESCR: 'SOUTH PONTOON', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '82', MODULE: 'NP3UH', PE3: 'N320H', PE1: null, AREA: 'Hull', BLOCK: 'N320H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '83', MODULE: 'NP3UH', PE3: 'N330H', PE1: null, AREA: 'Hull', BLOCK: 'N330H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '84', MODULE: 'NP3UH', PE3: 'N310H', PE1: null, AREA: 'Hull', BLOCK: 'N310H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '85', MODULE: 'NP3UH', PE3: 'N340H', PE1: null, AREA: 'Hull', BLOCK: 'N340H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '86', MODULE: 'NP3UH', PE3: 'N360H', PE1: null, AREA: 'Hull', BLOCK: 'N360H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '87', MODULE: 'NP3UH', PE3: 'N370H', PE1: null, AREA: 'Hull', BLOCK: 'N370H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '88', MODULE: 'NP3UH', PE3: 'N350H', PE1: null, AREA: 'Hull', BLOCK: 'N350H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '89', MODULE: 'NP3UH', PE3: 'N380H', PE1: null, AREA: 'Hull', BLOCK: 'N380H', DESCR: 'SE NODE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '90', MODULE: 'NP3UH', PE3: 'R310H', PE1: null, AREA: 'Hull', BLOCK: 'R310H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '91', MODULE: 'NP3UH', PE3: 'R320H', PE1: null, AREA: 'Hull', BLOCK: 'R320H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '92', MODULE: 'NP3UH', PE3: 'R330H', PE1: null, AREA: 'Hull', BLOCK: 'R330H', DESCR: 'RISER PORCH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '93', MODULE: 'C40UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'C40UH', DESCR: 'SW COLUMN P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '94', MODULE: 'C40UH', PE3: 'C420H', PE1: null, AREA: 'Hull', BLOCK: 'C420H', DESCR: 'SW COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '95', MODULE: 'C40UH', PE3: 'C410H', PE1: null, AREA: 'Hull', BLOCK: 'C410H', DESCR: 'SW COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '96', MODULE: 'C40UH', PE3: 'C43PH', PE1: null, AREA: 'Hull', BLOCK: 'C43PH', DESCR: 'SW COLUMN 1st P.E (2nd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '97', MODULE: 'C40UH', PE3: 'C43PH', PE1: 'C440H', AREA: 'Hull', BLOCK: 'C440H', DESCR: 'SW COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '98', MODULE: 'C40UH', PE3: 'C43PH', PE1: 'C430H', AREA: 'Hull', BLOCK: 'C430H', DESCR: 'SW COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '99', MODULE: 'C40UH', PE3: 'C45PH', PE1: null, AREA: 'Hull', BLOCK: 'C45PH', DESCR: 'SW COLUMN 1st P.E (3rd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '100', MODULE: 'C40UH', PE3: 'C45PH', PE1: 'C460H', AREA: 'Hull', BLOCK: 'C460H', DESCR: 'SW COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '101', MODULE: 'C40UH', PE3: 'C45PH', PE1: 'C450H', AREA: 'Hull', BLOCK: 'C450H', DESCR: 'SW COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '102', MODULE: 'C40UH', PE3: 'Y970H', PE1: null, AREA: 'Hull', BLOCK: 'Y970H', DESCR: 'SW Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '103', MODULE: 'C40UH', PE3: 'Y980H', PE1: null, AREA: 'Hull', BLOCK: 'Y980H', DESCR: 'SW Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '104', MODULE: 'C40UH', PE3: 'Y430H', PE1: null, AREA: 'Hull', BLOCK: 'Y430H', DESCR: 'OVERBOARD COLLECTOR CAISSON(SW, Out도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '105', MODULE: 'C30UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'C30UH', DESCR: 'SE COLUMN P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '106', MODULE: 'C30UH', PE3: 'C320H', PE1: null, AREA: 'Hull', BLOCK: 'C320H', DESCR: 'SE COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '107', MODULE: 'C30UH', PE3: 'C310H', PE1: null, AREA: 'Hull', BLOCK: 'C310H', DESCR: 'SE COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '108', MODULE: 'C30UH', PE3: 'C33PH', PE1: null, AREA: 'Hull', BLOCK: 'C33PH', DESCR: 'SE COLUMN 1st P.E (2nd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '109', MODULE: 'C30UH', PE3: 'C33PH', PE1: 'C340H', AREA: 'Hull', BLOCK: 'C340H', DESCR: 'SE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '110', MODULE: 'C30UH', PE3: 'C33PH', PE1: 'C330H', AREA: 'Hull', BLOCK: 'C330H', DESCR: 'SE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '111', MODULE: 'C30UH', PE3: 'C35PH', PE1: null, AREA: 'Hull', BLOCK: 'C35PH', DESCR: 'SE COLUMN 1st P.E (3rd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '112', MODULE: 'C30UH', PE3: 'C35PH', PE1: 'C360H', AREA: 'Hull', BLOCK: 'C360H', DESCR: 'SE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '113', MODULE: 'C30UH', PE3: 'C35PH', PE1: 'C350H', AREA: 'Hull', BLOCK: 'C350H', DESCR: 'SE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '114', MODULE: 'C30UH', PE3: 'Y950H', PE1: null, AREA: 'Hull', BLOCK: 'Y950H', DESCR: 'SE Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '115', MODULE: 'C30UH', PE3: 'Y960H', PE1: null, AREA: 'Hull', BLOCK: 'Y960H', DESCR: 'SE Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '116', MODULE: 'C30UH', PE3: 'Y310H', PE1: null, AREA: 'Hull', BLOCK: 'Y310H', DESCR: 'SEAWATER LIFT CAISSON(SE, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '117', MODULE: 'C30UH', PE3: 'Y320H', PE1: null, AREA: 'Hull', BLOCK: 'Y320H', DESCR: 'SEAWATER LIFT CAISSON(SE, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '118', MODULE: 'C30UH', PE3: 'Y330H', PE1: null, AREA: 'Hull', BLOCK: 'Y330H', DESCR: 'SEAWATER LIFT CAISSON(SE, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '119', MODULE: 'C30UH', PE3: 'Y340H', PE1: null, AREA: 'Hull', BLOCK: 'Y340H', DESCR: 'OVERBOARD COLLECTOR CAISSON(SE, Out도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '120', MODULE: 'C30UH', PE3: 'Y350H', PE1: null, AREA: 'Hull', BLOCK: 'Y350H', DESCR: 'FIRE WATER PUMP CAISSON(SE, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '121', MODULE: 'C30UH', PE3: 'Y360H', PE1: null, AREA: 'Hull', BLOCK: 'Y360H', DESCR: 'OVERBOARD FIRE WATER TEST CAISSON(SE, 의장ITEM Out도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '122', MODULE: 'Y420H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y420H', DESCR: 'BALLAST UTILITY PUMP CAISSON(SW, In/Out 도장)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '123', MODULE: 'Y440H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y440H', DESCR: 'DRAFT GAUGE PIPE(SW, 의장ITEM, Out 도장)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '124', MODULE: 'Y370H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y370H', DESCR: 'DRAFT GAUGE PIPE(SE, 의장ITEM, Out 도장)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '125', MODULE: 'Y660H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y660H', DESCR: 'FENDER(SE, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '126', MODULE: 'Y670H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y670H', DESCR: 'FENDER(SW, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '127', MODULE: 'Y680H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y680H', DESCR: 'FENDER(SW, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '128', MODULE: 'Y510H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y510H', DESCR: 'PUSH STRUCTURE(NW)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '129', MODULE: 'Y520H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y520H', DESCR: 'PUSH STRUCTURE(NW)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '130', MODULE: 'Y530H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y530H', DESCR: 'PUSH STRUCTURE(SW)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '131', MODULE: 'Y540H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y540H', DESCR: 'PUSH STRUCTURE(SW)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '132', MODULE: 'C20UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'C20UH', DESCR: 'NE COLUMN P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '133', MODULE: 'C20UH', PE3: 'C220H', PE1: null, AREA: 'Hull', BLOCK: 'C220H', DESCR: 'NE COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '134', MODULE: 'C20UH', PE3: 'C210H', PE1: null, AREA: 'Hull', BLOCK: 'C210H', DESCR: 'NE COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '135', MODULE: 'C20UH', PE3: 'C23PH', PE1: null, AREA: 'Hull', BLOCK: 'C23PH', DESCR: 'NE COLUMN 1st P.E (2nd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '136', MODULE: 'C20UH', PE3: 'C23PH', PE1: 'C240H', AREA: 'Hull', BLOCK: 'C240H', DESCR: 'NE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '137', MODULE: 'C20UH', PE3: 'C23PH', PE1: 'C230H', AREA: 'Hull', BLOCK: 'C230H', DESCR: 'NE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '138', MODULE: 'C20UH', PE3: 'C25PH', PE1: null, AREA: 'Hull', BLOCK: 'C25PH', DESCR: 'NE COLUMN 1st P.E (3rd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '139', MODULE: 'C20UH', PE3: 'C25PH', PE1: 'C260H', AREA: 'Hull', BLOCK: 'C260H', DESCR: 'NE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '140', MODULE: 'C20UH', PE3: 'C25PH', PE1: 'C250H', AREA: 'Hull', BLOCK: 'C250H', DESCR: 'NE COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '141', MODULE: 'C20UH', PE3: 'Y930H', PE1: null, AREA: 'Hull', BLOCK: 'Y930H', DESCR: 'NE Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '142', MODULE: 'C20UH', PE3: 'Y940H', PE1: null, AREA: 'Hull', BLOCK: 'Y940H', DESCR: 'NE Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '143', MODULE: 'C20UH', PE3: 'Y220H', PE1: null, AREA: 'Hull', BLOCK: 'Y220H', DESCR: 'OVERBOARD COLLECTOR CAISSON(NE, Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '144', MODULE: 'C10UH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'C10UH', DESCR: 'NW COLUMN P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '145', MODULE: 'C10UH', PE3: 'C120H', PE1: null, AREA: 'Hull', BLOCK: 'C120H', DESCR: 'NW COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '146', MODULE: 'C10UH', PE3: 'C110H', PE1: null, AREA: 'Hull', BLOCK: 'C110H', DESCR: 'NW COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '147', MODULE: 'C10UH', PE3: 'C13PH', PE1: null, AREA: 'Hull', BLOCK: 'C13PH', DESCR: 'NW COLUMN 1st P.E (2nd Floor)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '148', MODULE: 'C10UH', PE3: 'C140H', PE1: null, AREA: 'Hull', BLOCK: 'C140H', DESCR: 'NW COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '149', MODULE: 'C10UH', PE3: 'C130H', PE1: null, AREA: 'Hull', BLOCK: 'C130H', DESCR: 'NW COLUMN', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '150', MODULE: 'C10UH', PE3: 'C15PH', PE1: null, AREA: 'Hull', BLOCK: 'C15PH', DESCR: 'NW COLUMN 1st P.E (3rd Floor)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '151', MODULE: 'C10UH', PE3: 'C15PH', PE1: 'C160H', AREA: 'Hull', BLOCK: 'C160H', DESCR: 'NW COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '152', MODULE: 'C10UH', PE3: 'C15PH', PE1: 'C150H', AREA: 'Hull', BLOCK: 'C150H', DESCR: 'NW COLUMN', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '153', MODULE: 'C10UH', PE3: 'Y910H', PE1: null, AREA: 'Hull', BLOCK: 'Y910H', DESCR: 'NW Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '154', MODULE: 'C10UH', PE3: 'Y920H', PE1: null, AREA: 'Hull', BLOCK: 'Y920H', DESCR: 'NW Temp. Platform', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '155', MODULE: 'C10UH', PE3: 'Y110H', PE1: null, AREA: 'Hull', BLOCK: 'Y110H', DESCR: 'FIRE WATER PUMP CAISSON(NW, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '156', MODULE: 'C10UH', PE3: 'Y120H', PE1: null, AREA: 'Hull', BLOCK: 'Y120H', DESCR: 'OVERBOARD COLLECTOR CAISSON(NW, Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '157', MODULE: 'C10UH', PE3: 'Y130H', PE1: null, AREA: 'Hull', BLOCK: 'Y130H', DESCR: 'COOLING SEAWATER LIFT PUMP CAISSON(NW, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '158', MODULE: 'C10UH', PE3: 'Y140H', PE1: null, AREA: 'Hull', BLOCK: 'Y140H', DESCR: 'COOLING SEAWATER LIFT PUMP CAISSON(NW, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '159', MODULE: 'C10UH', PE3: 'Y150H', PE1: null, AREA: 'Hull', BLOCK: 'Y150H', DESCR: 'COOLING SEAWATER LIFT PUMP CAISSON(NW, In/Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '160', MODULE: 'C10UH', PE3: 'Y160H', PE1: null, AREA: 'Hull', BLOCK: 'Y160H', DESCR: 'OVERBOARD FIRE WATER TEST CAISSON(NW, 의장ITEM, Out 도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '161', MODULE: 'R160H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'R160H', DESCR: 'RISER PORCH(NE, NP1/2UH SEAM 간섭)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '162', MODULE: 'Y170H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y170H', DESCR: 'BALLAST UTILITY PUMP CAISSON(NW, In/Out 도장)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '163', MODULE: 'Y180H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y180H', DESCR: 'DRAFT GAUGE PIPE(NW, 의장ITEM, Out 도장)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '164', MODULE: 'Y230H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y230H', DESCR: 'DRAFT GAUGE PIPE(NE, 의장ITEM, Out 도장)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '165', MODULE: 'Y610H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y610H', DESCR: 'FENDER(NW, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '166', MODULE: 'Y620H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y620H', DESCR: 'FENDER(NW, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '167', MODULE: 'Y630H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y630H', DESCR: 'FENDER(NE, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '168', MODULE: 'Y640H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y640H', DESCR: 'FENDER(NE, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '169', MODULE: 'Y650H', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'Y650H', DESCR: 'FENDER(SE, PERMANENT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '170', MODULE: 'N10UH', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'N10UH', DESCR: 'NORTH DECK P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '171', MODULE: 'N10UH', PE3: 'L620H', PE1: null, AREA: 'Deckbox', BLOCK: 'L620H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '172', MODULE: 'N10UH', PE3: 'L520H', PE1: null, AREA: 'Deckbox', BLOCK: 'L520H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '173', MODULE: 'N10UH', PE3: 'L630H', PE1: null, AREA: 'Deckbox', BLOCK: 'L630H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '174', MODULE: 'N10UH', PE3: 'L530H', PE1: null, AREA: 'Deckbox', BLOCK: 'L530H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '175', MODULE: 'N10UH', PE3: 'L610H', PE1: null, AREA: 'Deckbox', BLOCK: 'L610H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '176', MODULE: 'N10UH', PE3: 'L510H', PE1: null, AREA: 'Deckbox', BLOCK: 'L510H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '177', MODULE: 'N10UH', PE3: 'L640H', PE1: null, AREA: 'Deckbox', BLOCK: 'L640H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '178', MODULE: 'N10UH', PE3: 'L540H', PE1: null, AREA: 'Deckbox', BLOCK: 'L540H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '179', MODULE: 'N10UH', PE3: 'M620H', PE1: null, AREA: 'Deckbox', BLOCK: 'M620H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '180', MODULE: 'N10UH', PE3: 'M520H', PE1: null, AREA: 'Deckbox', BLOCK: 'M520H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '181', MODULE: 'N10UH', PE3: 'M630H', PE1: null, AREA: 'Deckbox', BLOCK: 'M630H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '182', MODULE: 'N10UH', PE3: 'M530H', PE1: null, AREA: 'Deckbox', BLOCK: 'M530H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '183', MODULE: 'N10UH', PE3: 'T710H', PE1: null, AREA: 'Deckbox', BLOCK: 'T710H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '184', MODULE: 'N10UH', PE3: 'T610H', PE1: null, AREA: 'Deckbox', BLOCK: 'T610H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '185', MODULE: 'N10UH', PE3: 'T720H', PE1: null, AREA: 'Deckbox', BLOCK: 'T720H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '186', MODULE: 'N10UH', PE3: 'T620H', PE1: null, AREA: 'Deckbox', BLOCK: 'T620H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '187', MODULE: 'N10UH', PE3: 'E310H', PE1: null, AREA: 'Deckbox', BLOCK: 'E310H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '188', MODULE: 'N10UH', PE3: 'E410H', PE1: null, AREA: 'Deckbox', BLOCK: 'E410H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '189', MODULE: 'N10UH', PE3: 'M610H', PE1: null, AREA: 'Deckbox', BLOCK: 'M610H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '190', MODULE: 'N10UH', PE3: 'M510H', PE1: null, AREA: 'Deckbox', BLOCK: 'M510H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '191', MODULE: 'N10UH', PE3: 'M640H', PE1: null, AREA: 'Deckbox', BLOCK: 'M640H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '192', MODULE: 'N10UH', PE3: 'M540H', PE1: null, AREA: 'Deckbox', BLOCK: 'M540H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '193', MODULE: 'N10UH', PE3: 'NP20H', PE1: null, AREA: 'Deckbox', BLOCK: 'NP20H', DESCR: 'NORTH LIFEBOAT PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '194', MODULE: 'N10UH', PE3: 'NP10H', PE1: null, AREA: 'Deckbox', BLOCK: 'NP10H', DESCR: 'NORTH LIFEBOAT PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '195', MODULE: 'N10UH', PE3: 'M940H', PE1: null, AREA: 'Deckbox', BLOCK: 'M940H', DESCR: 'FIRE WALL', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '196', MODULE: 'N10UH', PE3: 'M950H', PE1: null, AREA: 'Deckbox', BLOCK: 'M950H', DESCR: 'FIRE WALL', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '197', MODULE: 'N10UH', PE3: 'M960H', PE1: null, AREA: 'Deckbox', BLOCK: 'M960H', DESCR: 'FIRE WALL', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '198', MODULE: 'N10UH', PE3: 'M930H', PE1: null, AREA: 'Deckbox', BLOCK: 'M930H', DESCR: 'FIRE WALL(PM탑재 후)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '199', MODULE: 'Y210H', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'Y210H', DESCR: 'CAISSON(NE) 발주처구매품', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '200', MODULE: 'E10UH', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'E10UH', DESCR: 'EAST DECK P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '201', MODULE: 'E10UH', PE3: 'L470H', PE1: null, AREA: 'Deckbox', BLOCK: 'L470H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '202', MODULE: 'E10UH', PE3: 'L350H', PE1: null, AREA: 'Deckbox', BLOCK: 'L350H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '203', MODULE: 'E10UH', PE3: 'L450H', PE1: null, AREA: 'Deckbox', BLOCK: 'L450H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '204', MODULE: 'E10UH', PE3: 'L460H', PE1: null, AREA: 'Deckbox', BLOCK: 'L460H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '205', MODULE: 'E10UH', PE3: 'L340H', PE1: null, AREA: 'Deckbox', BLOCK: 'L340H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '206', MODULE: 'E10UH', PE3: 'L480H', PE1: null, AREA: 'Deckbox', BLOCK: 'L480H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '207', MODULE: 'E10UH', PE3: 'L360H', PE1: null, AREA: 'Deckbox', BLOCK: 'L360H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '208', MODULE: 'E10UH', PE3: 'M450H', PE1: null, AREA: 'Deckbox', BLOCK: 'M450H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '209', MODULE: 'E10UH', PE3: 'M350H', PE1: null, AREA: 'Deckbox', BLOCK: 'M350H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '210', MODULE: 'E10UH', PE3: 'M440H', PE1: null, AREA: 'Deckbox', BLOCK: 'M440H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '211', MODULE: 'E10UH', PE3: 'M340H', PE1: null, AREA: 'Deckbox', BLOCK: 'M340H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '212', MODULE: 'E10UH', PE3: 'M460H', PE1: null, AREA: 'Deckbox', BLOCK: 'M460H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '213', MODULE: 'E10UH', PE3: 'M360H', PE1: null, AREA: 'Deckbox', BLOCK: 'M360H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '214', MODULE: 'E10UH', PE3: 'M920H', PE1: null, AREA: 'Deckbox', BLOCK: 'M920H', DESCR: 'PAINT LOCKER', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '215', MODULE: 'W10UH', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'W10UH', DESCR: 'WEST DECK P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '216', MODULE: 'W10UH', PE3: 'L420H', PE1: null, AREA: 'Deckbox', BLOCK: 'L420H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '217', MODULE: 'W10UH', PE3: 'L320H', PE1: null, AREA: 'Deckbox', BLOCK: 'L320H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '218', MODULE: 'W10UH', PE3: 'L410H', PE1: null, AREA: 'Deckbox', BLOCK: 'L410H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '219', MODULE: 'W10UH', PE3: 'L310H', PE1: null, AREA: 'Deckbox', BLOCK: 'L310H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '220', MODULE: 'W10UH', PE3: 'L430H', PE1: null, AREA: 'Deckbox', BLOCK: 'L430H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '221', MODULE: 'W10UH', PE3: 'L440H', PE1: null, AREA: 'Deckbox', BLOCK: 'L440H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '222', MODULE: 'W10UH', PE3: 'L330H', PE1: null, AREA: 'Deckbox', BLOCK: 'L330H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '223', MODULE: 'W10UH', PE3: 'T410H', PE1: null, AREA: 'Deckbox', BLOCK: 'T410H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '224', MODULE: 'W10UH', PE3: 'M320H', PE1: null, AREA: 'Deckbox', BLOCK: 'M320H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '225', MODULE: 'W10UH', PE3: 'M310H', PE1: null, AREA: 'Deckbox', BLOCK: 'M310H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '226', MODULE: 'W10UH', PE3: 'M41PH', PE1: null, AREA: 'Deckbox', BLOCK: 'M41PH', DESCR: 'MAIN DECK 1st P.E', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '227', MODULE: 'W10UH', PE3: 'M420H', PE1: null, AREA: 'Deckbox', BLOCK: 'M420H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '228', MODULE: 'W10UH', PE3: 'M410H', PE1: null, AREA: 'Deckbox', BLOCK: 'M410H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '229', MODULE: 'W10UH', PE3: 'M330H', PE1: null, AREA: 'Deckbox', BLOCK: 'M330H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '230', MODULE: 'W10UH', PE3: 'M430H', PE1: null, AREA: 'Deckbox', BLOCK: 'M430H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '231', MODULE: 'W10UH', PE3: 'Q160H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q160H', DESCR: 'LQ COMPANION(대조도장)', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '232', MODULE: 'W10UH', PE3: 'M910H', PE1: null, AREA: 'Deckbox', BLOCK: 'M910H', DESCR: 'MAIN DECK 설치 PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '233', MODULE: 'S10UH', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'S10UH', DESCR: 'SOUTH DECK P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '234', MODULE: 'S10UH', PE3: 'L120H', PE1: null, AREA: 'Deckbox', BLOCK: 'L120H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '235', MODULE: 'S10UH', PE3: 'L220H', PE1: null, AREA: 'Deckbox', BLOCK: 'L220H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '236', MODULE: 'S10UH', PE3: 'L130H', PE1: null, AREA: 'Deckbox', BLOCK: 'L130H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '237', MODULE: 'S10UH', PE3: 'L230H', PE1: null, AREA: 'Deckbox', BLOCK: 'L230H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '238', MODULE: 'S10UH', PE3: 'L110H', PE1: null, AREA: 'Deckbox', BLOCK: 'L110H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '239', MODULE: 'S10UH', PE3: 'L210H', PE1: null, AREA: 'Deckbox', BLOCK: 'L210H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '240', MODULE: 'S10UH', PE3: 'L140H', PE1: null, AREA: 'Deckbox', BLOCK: 'L140H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '241', MODULE: 'S10UH', PE3: 'L240H', PE1: null, AREA: 'Deckbox', BLOCK: 'L240H', DESCR: 'CELLAR DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '242', MODULE: 'S10UH', PE3: 'T12PH', PE1: null, AREA: 'Deckbox', BLOCK: 'T12PH', DESCR: 'TWEEN DECK 1차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '243', MODULE: 'S10UH', PE3: 'T12PH', PE1: 'T120H', AREA: 'Deckbox', BLOCK: 'T120H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '244', MODULE: 'S10UH', PE3: 'T12PH', PE1: 'T130H', AREA: 'Deckbox', BLOCK: 'T130H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '245', MODULE: 'S10UH', PE3: 'T22PH', PE1: null, AREA: 'Deckbox', BLOCK: 'T22PH', DESCR: 'TWEEN DECK 1차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '246', MODULE: 'S10UH', PE3: 'T22PH', PE1: 'T220H', AREA: 'Deckbox', BLOCK: 'T220H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '247', MODULE: 'S10UH', PE3: 'T22PH', PE1: 'T230H', AREA: 'Deckbox', BLOCK: 'T230H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '248', MODULE: 'S10UH', PE3: 'T210H', PE1: null, AREA: 'Deckbox', BLOCK: 'T210H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '249', MODULE: 'S10UH', PE3: 'T110H', PE1: null, AREA: 'Deckbox', BLOCK: 'T110H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '250', MODULE: 'S10UH', PE3: 'T240H', PE1: null, AREA: 'Deckbox', BLOCK: 'T240H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '251', MODULE: 'S10UH', PE3: 'T140H', PE1: null, AREA: 'Deckbox', BLOCK: 'T140H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '252', MODULE: 'S10UH', PE3: 'T310H', PE1: null, AREA: 'Deckbox', BLOCK: 'T310H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '253', MODULE: 'S10UH', PE3: 'T320H', PE1: null, AREA: 'Deckbox', BLOCK: 'T320H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '254', MODULE: 'S10UH', PE3: 'E110H', PE1: null, AREA: 'Deckbox', BLOCK: 'E110H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '255', MODULE: 'S10UH', PE3: 'E210H', PE1: null, AREA: 'Deckbox', BLOCK: 'E210H', DESCR: 'TWEEN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '256', MODULE: 'S10UH', PE3: 'M22PH', PE1: null, AREA: 'Deckbox', BLOCK: 'M22PH', DESCR: 'MAIN DEKC 1차P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '257', MODULE: 'S10UH', PE3: 'M22PH', PE1: 'M220H', AREA: 'Deckbox', BLOCK: 'M220H', DESCR: 'MAIN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '258', MODULE: 'S10UH', PE3: 'M22PH', PE1: 'M230H', AREA: 'Deckbox', BLOCK: 'M230H', DESCR: 'MAIN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '259', MODULE: 'S10UH', PE3: 'M12PH', PE1: null, AREA: 'Deckbox', BLOCK: 'M12PH', DESCR: 'MAIN DEKC 1차P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '260', MODULE: 'S10UH', PE3: 'M12PH', PE1: 'M120H', AREA: 'Deckbox', BLOCK: 'M120H', DESCR: 'MAIN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '261', MODULE: 'S10UH', PE3: 'M12PH', PE1: 'M130H', AREA: 'Deckbox', BLOCK: 'M130H', DESCR: 'MAIN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '262', MODULE: 'S10UH', PE3: 'M11PH', PE1: null, AREA: 'Deckbox', BLOCK: 'M11PH', DESCR: 'MAIN DEKC 1차P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '263', MODULE: 'S10UH', PE3: 'M11PH', PE1: 'M110H', AREA: 'Deckbox', BLOCK: 'M110H', DESCR: 'MAIN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '264', MODULE: 'S10UH', PE3: 'M11PH', PE1: 'M210H', AREA: 'Deckbox', BLOCK: 'M210H', DESCR: 'MAIN DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '265', MODULE: 'S10UH', PE3: 'M24PH', PE1: null, AREA: 'Deckbox', BLOCK: 'M24PH', DESCR: 'MAIN DEKC 1차P.E', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '266', MODULE: 'S10UH', PE3: 'M24PH', PE1: 'M240H', AREA: 'Deckbox', BLOCK: 'M240H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '267', MODULE: 'S10UH', PE3: 'M24PH', PE1: 'M250H', AREA: 'Deckbox', BLOCK: 'M250H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '268', MODULE: 'S10UH', PE3: 'M14PH', PE1: null, AREA: 'Deckbox', BLOCK: 'M14PH', DESCR: 'MAIN DEKC 1차P.E', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '269', MODULE: 'S10UH', PE3: 'M14PH', PE1: 'M140H', AREA: 'Deckbox', BLOCK: 'M140H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '270', MODULE: 'S10UH', PE3: 'M14PH', PE1: 'M150H', AREA: 'Deckbox', BLOCK: 'M150H', DESCR: 'MAIN DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '271', MODULE: 'S10UH', PE3: 'SP10H', PE1: null, AREA: 'Deckbox', BLOCK: 'SP10H', DESCR: 'SOUTH LIFEBOAT PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '272', MODULE: 'S10UH', PE3: 'M980H', PE1: null, AREA: 'Deckbox', BLOCK: 'M980H', DESCR: 'FIRE WALL', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '273', MODULE: 'Y410H', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'Y410H', DESCR: 'CAISSON(SW) 발주처구매품', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '274', MODULE: 'LQ1UH', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'LQ1UH', DESCR: 'DECK HOUSE', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '275', MODULE: 'LQ1UH', PE3: 'Q110H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q110H', DESCR: 'LQ LEVEL2', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '276', MODULE: 'LQ1UH', PE3: 'Q120H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q120H', DESCR: 'LQ LEVEL2', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '277', MODULE: 'LQ1UH', PE3: 'Q130H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q130H', DESCR: 'LQ LEVEL2', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '278', MODULE: 'LQ1UH', PE3: 'Q140H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q140H', DESCR: 'LQ LEVEL2', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '279', MODULE: 'LQ1UH', PE3: 'Q150H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q150H', DESCR: 'LQ LEVEL2', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '280', MODULE: 'LQ1UH', PE3: 'Q210H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q210H', DESCR: 'LQ LEVEL3', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '281', MODULE: 'LQ1UH', PE3: 'Q250H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q250H', DESCR: 'LQ LEVEL3', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '282', MODULE: 'LQ1UH', PE3: 'Q220H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q220H', DESCR: 'LQ LEVEL3', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '283', MODULE: 'LQ1UH', PE3: 'Q230H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q230H', DESCR: 'LQ LEVEL3', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '284', MODULE: 'LQ1UH', PE3: 'Q240H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q240H', DESCR: 'LQ LEVEL3', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '285', MODULE: 'LQ1UH', PE3: 'Q310H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q310H', DESCR: 'LQ LEVEL4', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '286', MODULE: 'LQ1UH', PE3: 'Q320H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q320H', DESCR: 'LQ LEVEL4', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '287', MODULE: 'LQ1UH', PE3: 'Q330H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q330H', DESCR: 'LQ LEVEL4', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '288', MODULE: 'LQ1UH', PE3: 'Q340H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q340H', DESCR: 'LQ LEVEL4', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '289', MODULE: 'LQ1UH', PE3: 'Q410H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q410H', DESCR: 'LQ LEVEL5', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '290', MODULE: 'LQ1UH', PE3: 'Q420H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q420H', DESCR: 'LQ LEVEL5', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '291', MODULE: 'LQ1UH', PE3: 'Q430H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q430H', DESCR: 'LQ LEVEL5', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '292', MODULE: 'LQ1UH', PE3: 'Q510H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q510H', DESCR: 'LQ LEVEL6', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '293', MODULE: 'LQ1UH', PE3: 'Q520H', PE1: null, AREA: 'Deckbox', BLOCK: 'Q520H', DESCR: 'COMPANION', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '294', MODULE: 'LQ1UH', PE3: 'HD30H', PE1: null, AREA: 'Deckbox', BLOCK: 'HD30H', DESCR: 'HELIDECK PARKING', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '295', MODULE: 'HD1UH', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'HD1UH', DESCR: 'HELIDECK P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '296', MODULE: 'HD1UH', PE3: 'HD10H', PE1: null, AREA: 'Deckbox', BLOCK: 'HD10H', DESCR: 'HELIDECK PANCAKE', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '297', MODULE: 'HD1UH', PE3: 'HD20H', PE1: null, AREA: 'Deckbox', BLOCK: 'HD20H', DESCR: 'HELIDECK SUPPORT', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '298', MODULE: 'M970H', PE3: null, PE1: null, AREA: 'Deckbox', BLOCK: 'M970H', DESCR: 'FIRE WALL(GM탑재 후)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '299', MODULE: 'BS1PH', PE3: null, PE1: null, AREA: 'Hull', BLOCK: 'BS1PH', DESCR: 'Bent Support 1st P.E', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '300', MODULE: 'BS1PH', PE3: 'BS10H', PE1: null, AREA: 'Hull', BLOCK: 'BS10H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '301', MODULE: 'BS1PH', PE3: 'BS20H', PE1: null, AREA: 'Hull', BLOCK: 'BS20H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '302', MODULE: 'BS1PH', PE3: 'BS30H', PE1: null, AREA: 'Hull', BLOCK: 'BS30H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '303', MODULE: 'BS1PH', PE3: 'BS40H', PE1: null, AREA: 'Hull', BLOCK: 'BS40H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '304', MODULE: 'BS1PH', PE3: 'BS50H', PE1: null, AREA: 'Hull', BLOCK: 'BS50H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '305', MODULE: 'BS1PH', PE3: 'BS60H', PE1: null, AREA: 'Hull', BLOCK: 'BS60H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '306', MODULE: 'BS1PH', PE3: 'BS70H', PE1: null, AREA: 'Hull', BLOCK: 'BS70H', DESCR: 'Bent Support', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '307', MODULE: 'BS1PH', PE3: 'BS80H', PE1: null, AREA: 'Hull', BLOCK: 'BS80H', DESCR: 'Bent Support Connection Beam', BLOCK_STATUS: 'ASSY', PE: null, },
      { NO: '308', MODULE: 'GM1UT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'GM1UT', DESCR: 'GENERATION MODULE P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '309', MODULE: 'GM1UT', PE3: 'G140T', PE1: null, AREA: 'Topside', BLOCK: 'G140T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '310', MODULE: 'GM1UT', PE3: 'G120T', PE1: null, AREA: 'Topside', BLOCK: 'G120T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '311', MODULE: 'GM1UT', PE3: 'G130T', PE1: null, AREA: 'Topside', BLOCK: 'G130T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '312', MODULE: 'GM1UT', PE3: 'G110T', PE1: null, AREA: 'Topside', BLOCK: 'G110T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '313', MODULE: 'GM1UT', PE3: 'G10PT', PE1: null, AREA: 'Topside', BLOCK: 'G10PT', DESCR: 'ELECTRIC BUILDING 1st P.E', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '314', MODULE: 'GM1UT', PE3: 'G110E', PE1: null, AREA: 'Topside', BLOCK: 'G110E', DESCR: 'ELECTRIC BUILDING ROOM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '315', MODULE: 'GM1UT', PE3: 'G120E', PE1: null, AREA: 'Topside', BLOCK: 'G120E', DESCR: 'ELECTRIC BUILDING ROOM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '316', MODULE: 'GM1UT', PE3: 'G230F', PE1: null, AREA: 'Topside', BLOCK: 'G230F', DESCR: 'ELECTRIC BUILDING PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '317', MODULE: 'GM1UT', PE3: 'G240F', PE1: null, AREA: 'Topside', BLOCK: 'G240F', DESCR: 'ELECTRIC BUILDING PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '318', MODULE: 'GM1UT', PE3: 'G110F', PE1: null, AREA: 'Topside', BLOCK: 'G110F', DESCR: 'PLATFORM DUMMY BLOCK', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '319', MODULE: 'GM1UT', PE3: 'G120F', PE1: null, AREA: 'Topside', BLOCK: 'G120F', DESCR: 'PLATFORM DUMMY BLOC', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '320', MODULE: 'GM1UT', PE3: 'G130F', PE1: null, AREA: 'Topside', BLOCK: 'G130F', DESCR: 'PLATFORM DUMMY BLOC', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '321', MODULE: 'GM1UT', PE3: 'G20PT', PE1: null, AREA: 'Topside', BLOCK: 'G20PT', DESCR: 'UPPER DECK 2차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '322', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G210R', AREA: 'Topside', BLOCK: 'G210R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '323', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G230R', AREA: 'Topside', BLOCK: 'G230R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '324', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G240R', AREA: 'Topside', BLOCK: 'G240R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '325', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G250R', AREA: 'Topside', BLOCK: 'G250R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '326', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G260R', AREA: 'Topside', BLOCK: 'G260R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '327', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G280R', AREA: 'Topside', BLOCK: 'G280R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '328', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G290R', AREA: 'Topside', BLOCK: 'G290R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '329', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G210G', AREA: 'Topside', BLOCK: 'G210G', DESCR: 'UPPER DECK-PLATFORM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '330', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G220G', AREA: 'Topside', BLOCK: 'G220G', DESCR: 'UPPER DECK-PLATFORM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '331', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G230G', AREA: 'Topside', BLOCK: 'G230G', DESCR: 'UPPER DECK-PLATFORM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '332', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G210T', AREA: 'Topside', BLOCK: 'G210T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '333', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G220T', AREA: 'Topside', BLOCK: 'G220T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '334', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G230T', AREA: 'Topside', BLOCK: 'G230T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '335', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G240T', AREA: 'Topside', BLOCK: 'G240T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '336', MODULE: 'GM1UT', PE3: 'G20PT', PE1: 'G270R', AREA: 'Topside', BLOCK: 'G270R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '337', MODULE: 'GM1UT', PE3: 'G210F', PE1: null, AREA: 'Topside', BLOCK: 'G210F', DESCR: 'UPPER DECK-PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '338', MODULE: 'GM1UT', PE3: 'G220F', PE1: null, AREA: 'Topside', BLOCK: 'G220F', DESCR: 'UPPER DECK-PLATFORM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '339', MODULE: 'GM1UT', PE3: 'G310V', PE1: null, AREA: 'Topside', BLOCK: 'G310V', DESCR: 'STAIR TOWER-SOUTH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '340', MODULE: 'GM1UT', PE3: 'G320V', PE1: null, AREA: 'Topside', BLOCK: 'G320V', DESCR: 'STAIR TOWER-EAST', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '341', MODULE: 'GM1UT', PE3: 'G25PT', PE1: null, AREA: 'Topside', BLOCK: 'G25PT', DESCR: 'UPPER DECK 1차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '342', MODULE: 'GM1UT', PE3: 'G25PT', PE1: 'G250T', AREA: 'Topside', BLOCK: 'G250T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '343', MODULE: 'GM1UT', PE3: 'G25PT', PE1: 'G220R', AREA: 'Topside', BLOCK: 'G220R', DESCR: 'UPPER DECK PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '344', MODULE: 'GM1UT', PE3: 'G260T', PE1: null, AREA: 'Topside', BLOCK: 'G260T', DESCR: 'UPPER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '345', MODULE: 'GM1UT', PE3: 'G910T', PE1: null, AREA: 'Topside', BLOCK: 'G910T', DESCR: 'S10UH 설치(Dummy Block)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '346', MODULE: 'CM1UT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'CM1UT', DESCR: 'COMPRESSION MODULE P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '347', MODULE: 'CM1UT', PE3: 'B110T', PE1: null, AREA: 'Topside', BLOCK: 'B110T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '348', MODULE: 'CM1UT', PE3: 'B120T', PE1: null, AREA: 'Topside', BLOCK: 'B120T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '349', MODULE: 'CM1UT', PE3: 'B130T', PE1: null, AREA: 'Topside', BLOCK: 'B130T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '350', MODULE: 'CM1UT', PE3: 'B110F', PE1: null, AREA: 'Topside', BLOCK: 'B110F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '351', MODULE: 'CM1UT', PE3: 'B120F', PE1: null, AREA: 'Topside', BLOCK: 'B120F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '352', MODULE: 'CM1UT', PE3: 'B130F', PE1: null, AREA: 'Topside', BLOCK: 'B130F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '353', MODULE: 'CM1UT', PE3: 'B230T', PE1: null, AREA: 'Topside', BLOCK: 'B230T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '354', MODULE: 'CM1UT', PE3: 'B240T', PE1: null, AREA: 'Topside', BLOCK: 'B240T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '355', MODULE: 'CM1UT', PE3: 'B20PT', PE1: null, AREA: 'Topside', BLOCK: 'B20PT', DESCR: 'MIDDLE DECK 1st P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '356', MODULE: 'CM1UT', PE3: 'B20PT', PE1: 'B210T', AREA: 'Topside', BLOCK: 'B210T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '357', MODULE: 'CM1UT', PE3: 'B20PT', PE1: 'B220T', AREA: 'Topside', BLOCK: 'B220T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '358', MODULE: 'CM1UT', PE3: 'B210F', PE1: null, AREA: 'Topside', BLOCK: 'B210F', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '359', MODULE: 'CM1UT', PE3: 'B220F', PE1: null, AREA: 'Topside', BLOCK: 'B220F', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '360', MODULE: 'CM1UT', PE3: 'B230F', PE1: null, AREA: 'Topside', BLOCK: 'B230F', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '361', MODULE: 'CM1UT', PE3: 'B240F', PE1: null, AREA: 'Topside', BLOCK: 'B240F', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '362', MODULE: 'CM1UT', PE3: 'B250F', PE1: null, AREA: 'Topside', BLOCK: 'B250F', DESCR: 'MIDDLE DECK-P/F(Dummy)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '363', MODULE: 'CM1UT', PE3: 'B30PT', PE1: null, AREA: 'Topside', BLOCK: 'B30PT', DESCR: 'UPPER DECK 1차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '364', MODULE: 'CM1UT', PE3: 'B30PT', PE1: 'B310G', AREA: 'Topside', BLOCK: 'B310G', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '365', MODULE: 'CM1UT', PE3: 'B30PT', PE1: 'B310R', AREA: 'Topside', BLOCK: 'B310R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '366', MODULE: 'CM1UT', PE3: 'B30PT', PE1: 'B320R', AREA: 'Topside', BLOCK: 'B320R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '367', MODULE: 'CM1UT', PE3: 'B30PT', PE1: 'B310T', AREA: 'Topside', BLOCK: 'B310T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '368', MODULE: 'CM1UT', PE3: 'B30PT', PE1: 'B320T', AREA: 'Topside', BLOCK: 'B320T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '369', MODULE: 'CM1UT', PE3: 'B30PT', PE1: 'B330T', AREA: 'Topside', BLOCK: 'B330T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '370', MODULE: 'CM1UT', PE3: 'B40PT', PE1: null, AREA: 'Topside', BLOCK: 'B40PT', DESCR: 'Electric buliding 1st P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '371', MODULE: 'CM1UT', PE3: 'B40PT', PE1: 'B410E', AREA: 'Topside', BLOCK: 'B410E', DESCR: 'ROOM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '372', MODULE: 'CM1UT', PE3: 'B40PT', PE1: 'B420E', AREA: 'Topside', BLOCK: 'B420E', DESCR: 'ROOM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '373', MODULE: 'CM1UT', PE3: 'B310F', PE1: null, AREA: 'Topside', BLOCK: 'B310F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '374', MODULE: 'CM1UT', PE3: 'B320F', PE1: null, AREA: 'Topside', BLOCK: 'B320F', DESCR: 'MIDDLE DECK-P/F(Dummy)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '375', MODULE: 'CM1UT', PE3: 'B330F', PE1: null, AREA: 'Topside', BLOCK: 'B330F', DESCR: 'MIDDLE DECK-P/F(Dummy)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '376', MODULE: 'CM1UT', PE3: 'B410V', PE1: null, AREA: 'Topside', BLOCK: 'B410V', DESCR: 'STAIR TOWER-NORTH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '377', MODULE: 'CM1UT', PE3: 'B910T', PE1: null, AREA: 'Topside', BLOCK: 'B910T', DESCR: 'N10UH 설치(Dummy Block)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '378', MODULE: 'BR20T', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'BR20T', DESCR: 'BOOM REST(CM1UT)', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '379', MODULE: 'PM1UT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'PM1UT', DESCR: 'PRODUCTION MODULE P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '380', MODULE: 'PM1UT', PE3: 'A110T', PE1: null, AREA: 'Topside', BLOCK: 'A110T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '381', MODULE: 'PM1UT', PE3: 'A120T', PE1: null, AREA: 'Topside', BLOCK: 'A120T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '382', MODULE: 'PM1UT', PE3: 'A130T', PE1: null, AREA: 'Topside', BLOCK: 'A130T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '383', MODULE: 'PM1UT', PE3: 'A110F', PE1: null, AREA: 'Topside', BLOCK: 'A110F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '384', MODULE: 'PM1UT', PE3: 'A120F', PE1: null, AREA: 'Topside', BLOCK: 'A120F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '385', MODULE: 'PM1UT', PE3: 'A210G', PE1: null, AREA: 'Topside', BLOCK: 'A210G', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '386', MODULE: 'PM1UT', PE3: 'A220G', PE1: null, AREA: 'Topside', BLOCK: 'A220G', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '387', MODULE: 'PM1UT', PE3: 'A210T', PE1: null, AREA: 'Topside', BLOCK: 'A210T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '388', MODULE: 'PM1UT', PE3: 'A22PT', PE1: null, AREA: 'Topside', BLOCK: 'A22PT', DESCR: 'BULK OIL TREATER TRANSFORMER 설치', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '389', MODULE: 'PM1UT', PE3: 'A22PT', PE1: 'A220T', AREA: 'Topside', BLOCK: 'A220T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '390', MODULE: 'PM1UT', PE3: 'A240T', PE1: null, AREA: 'Topside', BLOCK: 'A240T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '391', MODULE: 'PM1UT', PE3: 'A230T', PE1: null, AREA: 'Topside', BLOCK: 'A230T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '392', MODULE: 'PM1UT', PE3: 'A210F', PE1: null, AREA: 'Topside', BLOCK: 'A210F', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '393', MODULE: 'PM1UT', PE3: 'A30PT', PE1: null, AREA: 'Topside', BLOCK: 'A30PT', DESCR: 'UPPER DECK 1차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '394', MODULE: 'PM1UT', PE3: 'A30PT', PE1: 'A310R', AREA: 'Topside', BLOCK: 'A310R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '395', MODULE: 'PM1UT', PE3: 'A30PT', PE1: 'A320R', AREA: 'Topside', BLOCK: 'A320R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '396', MODULE: 'PM1UT', PE3: 'A30PT', PE1: 'A310G', AREA: 'Topside', BLOCK: 'A310G', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '397', MODULE: 'PM1UT', PE3: 'A30PT', PE1: 'A320T', AREA: 'Topside', BLOCK: 'A320T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '398', MODULE: 'PM1UT', PE3: 'A30PT', PE1: 'A310T', AREA: 'Topside', BLOCK: 'A310T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '399', MODULE: 'PM1UT', PE3: 'A30PT', PE1: 'A330T', AREA: 'Topside', BLOCK: 'A330T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '400', MODULE: 'PM1UT', PE3: 'A310F', PE1: null, AREA: 'Topside', BLOCK: 'A310F', DESCR: 'MIDDLE DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '401', MODULE: 'PM1UT', PE3: 'A320F', PE1: null, AREA: 'Topside', BLOCK: 'A320F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '402', MODULE: 'PM1UT', PE3: 'A330F', PE1: null, AREA: 'Topside', BLOCK: 'A330F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '403', MODULE: 'PM1UT', PE3: 'A340F', PE1: null, AREA: 'Topside', BLOCK: 'A340F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '404', MODULE: 'PM1UT', PE3: 'A410V', PE1: null, AREA: 'Topside', BLOCK: 'A410V', DESCR: 'STAIR TOWER-NORTH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '405', MODULE: 'PM1UT', PE3: 'A420V', PE1: null, AREA: 'Topside', BLOCK: 'A420V', DESCR: 'STAIR TOWER-SOURTH', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '406', MODULE: 'PM1UT', PE3: 'A910T', PE1: null, AREA: 'Topside', BLOCK: 'A910T', DESCR: 'N10UH 설치(Dummy Block)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '407', MODULE: 'BR10T', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'BR10T', DESCR: 'BOOM REST', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '408', MODULE: 'CP1PT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'CP1PT', DESCR: 'Crane Pedestal', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '409', MODULE: 'CP1PT', PE3: 'CP10T', PE1: null, AREA: 'Topside', BLOCK: 'CP10T', DESCR: 'PM MODULE PEDESTAL', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '410', MODULE: 'CP1PT', PE3: 'A350F', PE1: null, AREA: 'Topside', BLOCK: 'A350F', DESCR: 'PLATFORM DUMMY BLOCK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '411', MODULE: 'WM1UT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'WM1UT', DESCR: 'WATER INJECTION MODULE P.E', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '412', MODULE: 'WM1UT', PE3: 'W140T', PE1: null, AREA: 'Topside', BLOCK: 'W140T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '413', MODULE: 'WM1UT', PE3: 'W130T', PE1: null, AREA: 'Topside', BLOCK: 'W130T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '414', MODULE: 'WM1UT', PE3: 'W120T', PE1: null, AREA: 'Topside', BLOCK: 'W120T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '415', MODULE: 'WM1UT', PE3: 'W110T', PE1: null, AREA: 'Topside', BLOCK: 'W110T', DESCR: 'LOWER DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '416', MODULE: 'WM1UT', PE3: 'W110F', PE1: null, AREA: 'Topside', BLOCK: 'W110F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '417', MODULE: 'WM1UT', PE3: 'W120F', PE1: null, AREA: 'Topside', BLOCK: 'W120F', DESCR: 'LOWER DECK-P/F', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '418', MODULE: 'WM1UT', PE3: 'W20PT', PE1: null, AREA: 'Topside', BLOCK: 'W20PT', DESCR: 'MIDDLE DECK 1st P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '419', MODULE: 'WM1UT', PE3: 'W20PT', PE1: 'W210T', AREA: 'Topside', BLOCK: 'W210T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '420', MODULE: 'WM1UT', PE3: 'W20PT', PE1: 'W220T', AREA: 'Topside', BLOCK: 'W220T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '421', MODULE: 'WM1UT', PE3: 'W230T', PE1: null, AREA: 'Topside', BLOCK: 'W230T', DESCR: 'MIDDLE DECK', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '422', MODULE: 'WM1UT', PE3: 'W30PT', PE1: null, AREA: 'Topside', BLOCK: 'W30PT', DESCR: 'UPPER DECK 1차 P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '423', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W310R', AREA: 'Topside', BLOCK: 'W310R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '424', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W320R', AREA: 'Topside', BLOCK: 'W320R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '425', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W330R', AREA: 'Topside', BLOCK: 'W330R', DESCR: 'PIPERACK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '426', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W310G', AREA: 'Topside', BLOCK: 'W310G', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '427', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W310T', AREA: 'Topside', BLOCK: 'W310T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '428', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W330T', AREA: 'Topside', BLOCK: 'W330T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '429', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W320T', AREA: 'Topside', BLOCK: 'W320T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '430', MODULE: 'WM1UT', PE3: 'W30PT', PE1: 'W340T', AREA: 'Topside', BLOCK: 'W340T', DESCR: 'UPPER DECK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '431', MODULE: 'WM1UT', PE3: 'W40PT', PE1: null, AREA: 'Topside', BLOCK: 'W40PT', DESCR: 'Electric Building 1st P.E', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '432', MODULE: 'WM1UT', PE3: 'W40PT', PE1: 'W410E', AREA: 'Topside', BLOCK: 'W410E', DESCR: 'ROOM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '433', MODULE: 'WM1UT', PE3: 'W40PT', PE1: 'W420E', AREA: 'Topside', BLOCK: 'W420E', DESCR: 'ROOM', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '434', MODULE: 'WM1UT', PE3: 'W310F', PE1: null, AREA: 'Topside', BLOCK: 'W310F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '435', MODULE: 'WM1UT', PE3: 'W320F', PE1: null, AREA: 'Topside', BLOCK: 'W320F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '436', MODULE: 'WM1UT', PE3: 'W330F', PE1: null, AREA: 'Topside', BLOCK: 'W330F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '437', MODULE: 'WM1UT', PE3: 'W340F', PE1: null, AREA: 'Topside', BLOCK: 'W340F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '438', MODULE: 'WM1UT', PE3: 'W350F', PE1: null, AREA: 'Topside', BLOCK: 'W350F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '439', MODULE: 'WM1UT', PE3: 'W360F', PE1: null, AREA: 'Topside', BLOCK: 'W360F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '440', MODULE: 'WM1UT', PE3: 'W370F', PE1: null, AREA: 'Topside', BLOCK: 'W370F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '441', MODULE: 'WM1UT', PE3: 'W380F', PE1: null, AREA: 'Topside', BLOCK: 'W380F', DESCR: 'UPPER DECK-P/F', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '442', MODULE: 'WM1UT', PE3: 'W910T', PE1: null, AREA: 'Topside', BLOCK: 'W910T', DESCR: 'E10UH 설치(Dummy Block)', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '443', MODULE: 'CP2PT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'CP2PT', DESCR: 'Crane Pedepstal', BLOCK_STATUS: 'EREC', PE: null, },
      { NO: '444', MODULE: 'CP2PT', PE3: 'CP20T', PE1: null, AREA: 'Topside', BLOCK: 'CP20T', DESCR: 'WM MODULE PEDESTAL', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '445', MODULE: 'CP2PT', PE3: 'W390F', PE1: null, AREA: 'Topside', BLOCK: 'W390F', DESCR: 'PLATFORM DUMMY BLOCK', BLOCK_STATUS: '1PE', PE: null, },
      { NO: '446', MODULE: 'FB1UT', PE3: null, PE1: null, AREA: 'Topside', BLOCK: 'FB1UT', DESCR: 'FLARE BOOM', BLOCK_STATUS: 'NOT', PE: null, },
      { NO: '447', MODULE: 'FB1UT', PE3: 'F130T', PE1: null, AREA: 'Topside', BLOCK: 'F130T', DESCR: 'FLARE BOOM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '448', MODULE: 'FB1UT', PE3: 'F120T', PE1: null, AREA: 'Topside', BLOCK: 'F120T', DESCR: 'FLARE BOOM', BLOCK_STATUS: '3PE', PE: null, },
      { NO: '449', MODULE: 'FB1UT', PE3: 'F110T', PE1: null, AREA: 'Topside', BLOCK: 'F110T', DESCR: 'FLARE BOOM', BLOCK_STATUS: '3PE', PE: null, },


    ],
}