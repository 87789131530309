import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 295,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 50,
      CanvasChartY: 50,
      CanvasChartWidth: 480,
      CanvasChartHeight: 250,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    

    DataItems: [
      { name: 'Electrical', total: 5979, completed: 769, pcow: 0, rem: 5210, },
      { name: 'Instrument.', total: 2786, completed: 833, pcow: 0, rem: 1953, },
      { name: 'Piping', total: 1421, completed: 1048, pcow: 0, rem: 373, },
      { name: 'General', total: 630, completed: 87, pcow: 0, rem: 543, },
      { name: 'Fire & Gas', total: 340, completed: 137, pcow: 0, rem: 203, },
      { name: 'Insulation', total: 264, completed: 4, pcow: 0, rem: 260, },
      { name: 'Painting', total: 263, completed: 0, pcow: 0, rem: 263, },
      { name: 'Pipelines', total: 242, completed: 19, pcow: 0, rem: 223, },
      { name: 'Mechanical', total: 196, completed: 116, pcow: 0, rem: 80, },
      { name: 'HVAC', total: 140, completed: 139, pcow: 0, rem: 1, },
      { name: 'Structural', total: 77, completed: 49, pcow: 0, rem: 28, },
      { name: 'Telecom.', total: 53, completed: 32, pcow: 0, rem: 21, },
      { name: 'Architectural', total: 42, completed: 0, pcow: 0, rem: 42, },
      { name: 'Civil', total: 9, completed: 0, pcow: 0, rem: 9, },
      { name: 'Safety', total: 8, completed: 0, pcow: 0, rem: 8, },
    ],

}