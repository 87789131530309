import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 960,
      CanvasHeight: 790,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
        {DISC_DESCR:"Structure",TARGET_PROG:100,PLAN_PROG:41.45,ACTUAL_PROG:38.67,DELTA:-2.77,DISC:"36",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Piping",TARGET_PROG:83.83,PLAN_PROG:0.03,ACTUAL_PROG:0,DELTA:-0.03,DISC:"13",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Electrical",TARGET_PROG:91.11,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"16",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Instrument",TARGET_PROG:93.26,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"15",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Mechanical",TARGET_PROG:100,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"68",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Painting",TARGET_PROG:96.37,PLAN_PROG:9.57,ACTUAL_PROG:1.46,DELTA:-8.1,DISC:"23",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Insulation",TARGET_PROG:1.86,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"22",GWBS_LV3:"MW05"},
        {DISC_DESCR:"Fire & Safety",TARGET_PROG:8.2,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"61",GWBS_LV3:"MW05"}
      ],
    Queries: {
      SQL1: [
        {COMMODITY:"Equipment",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"22"},
        {COMMODITY:"Fire Protection System",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"61"},
        {COMMODITY:"Prefabrication",PLAN_PROG:63.68,ACTUAL_CUM_PROG:0.63,DELTA:63.05,DISC:"36"},
        {COMMODITY:"Steel Structure",PLAN_PROG:15.54,ACTUAL_CUM_PROG:0.02,DELTA:15.52,DISC:"23"},
        {COMMODITY:"Support",PLAN_PROG:0.42,ACTUAL_CUM_PROG:0,DELTA:0.42,DISC:"13"},
        {COMMODITY:"Support",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"16"},
        {COMMODITY:"Vessels & Exchangers",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"68"},
        {COMMODITY:"Assembly&Erection",PLAN_PROG:33.14,ACTUAL_CUM_PROG:0.28,DELTA:32.86,DISC:"36"},
        {COMMODITY:"Cable Pulling",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"15"},
        {COMMODITY:"Cable Tray",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"16"},
        {COMMODITY:"Machinery Erection - Rotating",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"68"},
        {COMMODITY:"Piping",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"22"},
        {COMMODITY:"Piping",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"23"},
        {COMMODITY:"Safety Equipment",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"61"},
        {COMMODITY:"Spool",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"13"},
        {COMMODITY:"Cable Pulling",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"16"},
        {COMMODITY:"Cable Termination",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"15"},
        {COMMODITY:"Pressure Test",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"13"},
        {COMMODITY:"Skid, Packages & Misc.",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"68"},
        {COMMODITY:"Tertiary",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"36"},
        {COMMODITY:"Cable Termination",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"16"},
        {COMMODITY:"Field Instrumentation",PLAN_PROG:0,ACTUAL_CUM_PROG:0,DELTA:0,DISC:"15"}
      ],
      SQL2: [
        {WC_DESCRIPTION:"AREA PAINTING",UOM:"SQM",INITIALQTY:3645.5,TARGET_QTY:3645.5,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:3645.5,REMAIN_PROG:-100,DISC:"23",COMMODITY:"Steel Structure",WCCODE:"23120",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"EICT AREA OUTFITTINGS FABRICATION - SUPPORT",UOM:"KG",INITIALQTY:8107.14,TARGET_QTY:8107.1,TARGET_PROG:100,ACTUAL_CUM_QTY:74.6,ACTUAL_CUM_PROG:0.91,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:8032.6,REMAIN_PROG:-99.09,DISC:"16",COMMODITY:"Support",WCCODE:"16450",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - SUPPORT",UOM:"KG",INITIALQTY:8978.95,TARGET_QTY:8979,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:8979,REMAIN_PROG:-100,DISC:"16",COMMODITY:"Support",WCCODE:"16460",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"FIRE PROTECTION SYSTEM",UOM:"U",INITIALQTY:49.59,TARGET_QTY:9.9,TARGET_PROG:20,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:9.9,REMAIN_PROG:-20,DISC:"61",COMMODITY:"Fire Protection System",WCCODE:"61100",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"HVAC AREA OUTFITTINGS FABRICATION",UOM:"KG",INITIALQTY:6400,TARGET_QTY:6400,TARGET_PROG:100,ACTUAL_CUM_QTY:5093.1,ACTUAL_CUM_PROG:79.57,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1306.9,REMAIN_PROG:-20.43,DISC:"21",COMMODITY:"Outfitting",WCCODE:"21330",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"HVAC AREA OUTFITTINGS INSTALLATION",UOM:"KG",INITIALQTY:6400,TARGET_QTY:6400,TARGET_PROG:100,ACTUAL_CUM_QTY:1219.8,ACTUAL_CUM_PROG:19.05,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:5180.2,REMAIN_PROG:-80.95,DISC:"21",COMMODITY:"Outfitting",WCCODE:"21340",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"STEEL STRUCTURE PAINTING",UOM:"SQM",INITIALQTY:13437,TARGET_QTY:13437,TARGET_PROG:100,ACTUAL_CUM_QTY:5228.3,ACTUAL_CUM_PROG:38.9,ACTUAL_WEEK_QTY:1159.6,ACTUAL_WEEK_PROG:8.62,REMAIN_QTY:8208.7,REMAIN_PROG:-61.1,DISC:"23",COMMODITY:"Steel Structure",WCCODE:"23110",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT FABRICATION",UOM:"KG",INITIALQTY:2718.75,TARGET_QTY:2718.8,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2718.8,REMAIN_PROG:-100,DISC:"13",COMMODITY:"Support",WCCODE:"13810",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT INSTALLATION",UOM:"KG",INITIALQTY:2714.29,TARGET_QTY:2714.3,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2714.3,REMAIN_PROG:-100,DISC:"13",COMMODITY:"Support",WCCODE:"13820",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES PREFABRICATION PRIMARY SECONDARY",UOM:"TONS",INITIALQTY:821.3,TARGET_QTY:821.3,TARGET_PROG:100,ACTUAL_CUM_QTY:713.3,ACTUAL_CUM_PROG:86.85,ACTUAL_WEEK_QTY:18,ACTUAL_WEEK_PROG:2.19,REMAIN_QTY:108,REMAIN_PROG:-13.15,DISC:"36",COMMODITY:"Prefabrication",WCCODE:"36210",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - CABLE TRAY",UOM:"LM",INITIALQTY:2880,TARGET_QTY:2880,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2880,REMAIN_PROG:-100,DISC:"16",COMMODITY:"Cable Tray",WCCODE:"16430",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"HVAC DUCTS NETWORK FABRICATION",UOM:"SQM",INITIALQTY:1810,TARGET_QTY:1810,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1810,REMAIN_PROG:-100,DISC:"21",COMMODITY:"Duct",WCCODE:"21210",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"HVAC DUCTS NETWORK INSTALLATION",UOM:"SQM",INITIALQTY:1810,TARGET_QTY:1810,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1810,REMAIN_PROG:-100,DISC:"21",COMMODITY:"Duct",WCCODE:"21220",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"INDOOR - INSTRUMENT CABLES & MULTIINSTRUMENT CABLES",UOM:"LM",INITIALQTY:5246,TARGET_QTY:5246,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:5246,REMAIN_PROG:-100,DISC:"15",COMMODITY:"Cable Pulling",WCCODE:"15120",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"PIPING INSULATION WORKS",UOM:"SQM",INITIALQTY:16.6,TARGET_QTY:16.6,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:16.6,REMAIN_PROG:-100,DISC:"22",COMMODITY:"Piping",WCCODE:"22210",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"PIPING PAINTING",UOM:"SQM",INITIALQTY:27,TARGET_QTY:27,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:27,REMAIN_PROG:-100,DISC:"23",COMMODITY:"Piping",WCCODE:"23200",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"PIPING SUPPORT PAINTING",UOM:"SQM",INITIALQTY:572.4,TARGET_QTY:572.4,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:572.4,REMAIN_PROG:-100,DISC:"23",COMMODITY:"Piping",WCCODE:"23210",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"SAFETY EQUIPMENT",UOM:"U",INITIALQTY:93,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0,DISC:"61",COMMODITY:"Safety Equipment",WCCODE:"61200",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES ASSEMBLY/ERECTION PRIMARY SECONDARY",UOM:"TONS",INITIALQTY:792.3,TARGET_QTY:792.3,TARGET_PROG:100,ACTUAL_CUM_QTY:449,ACTUAL_CUM_PROG:56.67,ACTUAL_WEEK_QTY:2.9,ACTUAL_WEEK_PROG:0.37,REMAIN_QTY:343.3,REMAIN_PROG:-43.33,DISC:"36",COMMODITY:"Assembly&Erection",WCCODE:"36220",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING FABRICATION",UOM:"DIA",INITIALQTY:120,TARGET_QTY:120,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:120,REMAIN_PROG:-100,DISC:"13",COMMODITY:"Spool",WCCODE:"13410",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING INSTALLATION",UOM:"DIA",INITIALQTY:69,TARGET_QTY:62.3,TARGET_PROG:90.21,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:62.3,REMAIN_PROG:-90.21,DISC:"13",COMMODITY:"Spool",WCCODE:"13420",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"WALL AND CEILING INSTALLATION - PANELS",UOM:"SQM",INITIALQTY:1151.5,TARGET_QTY:1151.5,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1151.5,REMAIN_PROG:-100,DISC:"20",COMMODITY:"Wall & Ceiling",WCCODE:"20440",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"COMPARTMENT / BLOCK INSULATION",UOM:"SQM",INITIALQTY:7151,TARGET_QTY:7151,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:7151,REMAIN_PROG:-100,DISC:"20",COMMODITY:"Compartment",WCCODE:"20460",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"HVAC EQUIPMENT",UOM:"U",INITIALQTY:187,TARGET_QTY:187,TARGET_PROG:100,ACTUAL_CUM_QTY:18.8,ACTUAL_CUM_PROG:10.04,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:168.2,REMAIN_PROG:-89.96,DISC:"21",COMMODITY:"Equipment",WCCODE:"21100",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"INDOOR - INSTRUMENT CABLES CONNECTIONS",UOM:"U",INITIALQTY:328,TARGET_QTY:328,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:328,REMAIN_PROG:-100,DISC:"15",COMMODITY:"Cable Termination",WCCODE:"15130",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"INDOOR- ELECTRICAL CABLES",UOM:"LM",INITIALQTY:460,TARGET_QTY:460,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:460,REMAIN_PROG:-100,DISC:"16",COMMODITY:"Cable Pulling",WCCODE:"16250",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"PRESSURE TEST",UOM:"U",INITIALQTY:41,TARGET_QTY:26.3,TARGET_PROG:64.12,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:26.3,REMAIN_PROG:-64.12,DISC:"13",COMMODITY:"Pressure Test",WCCODE:"13900",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"TOPSIDES ASSEMBLY/ERECTION TERTIARY",UOM:"TONS",INITIALQTY:118.1,TARGET_QTY:118.1,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:118.1,REMAIN_PROG:-100,DISC:"36",COMMODITY:"Tertiary",WCCODE:"36230",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"EQUIPMENT AND FURNITURE INSTALLATION",UOM:"U",INITIALQTY:3,TARGET_QTY:3,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:3,REMAIN_PROG:-100,DISC:"20",COMMODITY:"Equipment & Furniture",WCCODE:"20510",GWBS_LV3:"ME05"},
        {WC_DESCRIPTION:"FIELD INSTRUMENTATION WORKS",UOM:"U",INITIALQTY:328.5,TARGET_QTY:42.4,TARGET_PROG:12.9,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:42.4,REMAIN_PROG:-12.9,DISC:"15",COMMODITY:"Field Instrumentation",WCCODE:"15310",GWBS_LV3:"ME05"}
      ]
    }
}