export default {
    Canvas: {
      CanvasWidth: 1250,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 145,
      CanvasChartY: 250,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        {text:'Weekly Open/Closed Status for CDRE',x: 70,y: 42,font: 'roboto',size: 14,}
      ],
      Filter: [],
      Sql: [
        {
          query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
          text: 'Datasource: Shop Drawing Index 04-Oct-20',
          x: 70,
          y: 62,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    DataItems: [
      {CUTOFF:'2020-05-15', CUM_SUBMITTED:93,  WK_SUBMITTED:0, CUM_APPROVED:45 , WK_APPROVED:0},
      {CUTOFF:'2020-05-22', CUM_SUBMITTED:93,  WK_SUBMITTED:0, CUM_APPROVED:45 , WK_APPROVED:0},
      {CUTOFF:'2020-05-29', CUM_SUBMITTED:95,  WK_SUBMITTED:2, CUM_APPROVED:47 , WK_APPROVED:0},
      {CUTOFF:'2020-06-05', CUM_SUBMITTED:96,  WK_SUBMITTED:0, CUM_APPROVED:49 , WK_APPROVED:0},
      {CUTOFF:'2020-06-12', CUM_SUBMITTED:98,  WK_SUBMITTED:1, CUM_APPROVED:49 , WK_APPROVED:0},
      {CUTOFF:'2020-06-19', CUM_SUBMITTED:101, WK_SUBMITTED:0, CUM_APPROVED:25 , WK_APPROVED:0},
      {CUTOFF:'2020-06-26', CUM_SUBMITTED:102, WK_SUBMITTED:0, CUM_APPROVED:40 , WK_APPROVED:1},
      {CUTOFF:'2020-07-03', CUM_SUBMITTED:105, WK_SUBMITTED:1, CUM_APPROVED:38 , WK_APPROVED:0},
      {CUTOFF:'2020-07-10', CUM_SUBMITTED:107, WK_SUBMITTED:0, CUM_APPROVED:35 , WK_APPROVED:0},
      {CUTOFF:'2020-07-17', CUM_SUBMITTED:110, WK_SUBMITTED:0, CUM_APPROVED:25 , WK_APPROVED:0},
      {CUTOFF:'2020-07-24', CUM_SUBMITTED:125, WK_SUBMITTED:9, CUM_APPROVED:94 , WK_APPROVED:2},
      {CUTOFF:'2020-07-31', CUM_SUBMITTED:127, WK_SUBMITTED:2, CUM_APPROVED:71 , WK_APPROVED:4},
      {CUTOFF:'2020-08-07', CUM_SUBMITTED:133, WK_SUBMITTED:6, CUM_APPROVED:85 , WK_APPROVED:4},
      {CUTOFF:'2020-08-14', CUM_SUBMITTED:141, WK_SUBMITTED:5, CUM_APPROVED:85 , WK_APPROVED:0},
      {CUTOFF:'2020-08-21', CUM_SUBMITTED:146, WK_SUBMITTED:0, CUM_APPROVED:85 , WK_APPROVED:0},
      {CUTOFF:'2020-08-28', CUM_SUBMITTED:146, WK_SUBMITTED:0, CUM_APPROVED:85 , WK_APPROVED:0},
      {CUTOFF:'2020-09-04', CUM_SUBMITTED:152, WK_SUBMITTED:0, CUM_APPROVED:85 , WK_APPROVED:3},
      {CUTOFF:'2020-09-11', CUM_SUBMITTED:152, WK_SUBMITTED:2, CUM_APPROVED:88 , WK_APPROVED:0},
      {CUTOFF:'2020-09-18', CUM_SUBMITTED:152, WK_SUBMITTED:2, CUM_APPROVED:89 , WK_APPROVED:0},
      {CUTOFF:'2020-09-25', CUM_SUBMITTED:152, WK_SUBMITTED:0, CUM_APPROVED:95 , WK_APPROVED:0},
      {CUTOFF:'2020-10-02', CUM_SUBMITTED:152, WK_SUBMITTED:0, CUM_APPROVED:80 , WK_APPROVED:0},
    ],    
    Queries: {
      // Table Headers
      SQL1: [
      ],       
      SQL2: [
        {TOTAL: 164, OPEN: 164, BP_REVIEW: 27, TFMC_DEV: 46, },
      ],       
    },
}