
export default {
  Canvas: {
    CanvasWidth: 1500,
    CanvasHeight: 300,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },


  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },
  

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },


  Queries: {

    // --> this.style
    SQL1:  [
      {
        // FILTERSTRING (OFF, ON) for link action        
        FILTERSTRING: "OFF",
        
        WIDTH: 320,

        PADDING: 10,
        PLUS_Y: 1,

        BOX_WIDTH: 50,
        BOX_HEIGHT: 15,
        BOX_PADDING: 5,
        BOX_FILL_COLOR: 'Transparent',
        BOX_STROKE_COLOR: '#BCBCBC',
        BOX_STROKE_SIZE: 1,
        BOX_OPACITY: 0.5,
        BOX_RADIUS: 2,
        
        // Column
        TEXT_COLOR: '#757575',
        TEXT_SIZE: 10,
        TEXT_ALIGN: 'end',
        TEXT_WEIGHT: 400,
        
        // Value
        VALUE_TEXT_SIZE: 10,
        VALUE_TEXT_WEIGHT: 400,
      },
    ],
  },

  // --> this.dataSet
  DataItems: [ 
    {
      COUNT: 6,
      TEXT1: 'MDRs width No Key MS Assigned:',
      TEXT2: 'MDRs with IFU Target datas:',
      TEXT3: 'Remain:',
      TEXT4: 'Prog1:',
      TEXT5: 'Prog2:',
      TEXT6: 'Prog2:',

      COLOR1: '#757575',
      COLOR2: '#44A9DF',
      COLOR3: '#F35E90',
      COLOR4: '#44A9DF',
      COLOR5: '#44A9DF',
      COLOR6: '#44A9DF',
      
      VALUE1: 1111,
      VALUE2: 2222,
      VALUE3: 3333,
      VALUE4: 4444,
      VALUE5: 5555,
      VALUE6: 5555,

      FILTER1: 'filter1',
      FILTER2: 'filter2',
      FILTER3: 'filter3',
      FILTER4: 'filter4',
      FILTER5: 'filter5',
      FILTER6: 'filter6',
    }
  ]
}