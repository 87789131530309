import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1570,
      CanvasHeight: 934,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#EEEEEE',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Note: {
      NoteDisplay: 'N',
      NoteX: 633,
      NoteY: 358,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',
  
      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC'
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { No: 1, Title: 'Bypassing Safety Controls', 
      SubTitle: 'Obtain authorisation before overriding or disabling safety controls', 
      Contents1: '• I understand and use safety-critical equipment and procedures which apply to my task', 
      Contents2: ' • I obtain authorisation before: ', 
      Contents3: null, 
      Contents4: null, 
      Contents5: null, 
      Contents6: null, 
      Contents1_Sub1: null,
      Contents1_Sub2: null, 
      Contents1_Sub3: null, 
      Contents1_Sub4: null, 
      Contents2_Sub1: '– disabling or overriding safety equipment ',
      Contents2_Sub2: '– deviating from procedures ', 
      Contents2_Sub3: '– crossing a barrier', 
      Contents3_Sub1: null, 
      Contents3_Sub2: null, X: 55, Y: 140, },
      { No: 2, Title: 'Confined Space', SubTitle: 'Obtain authorisation before entering a confined space', Contents1: '• I confirm energy sources are isolated ', Contents2: '• I confirm the atmosphere has been tested and is monitored ', Contents3: '• I check and use my breathing apparatus when required ', Contents4: '• I confirm there is an attendant standing by ', Contents5: '• I confirm a rescue plan is in place ', Contents6: '• I obtain authorisation to enter', Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 387, Y: 140, },
      { No: 3, Title: 'Driving', SubTitle: 'Follow safe driving rules', Contents1: '• I always wear a seatbelt ', Contents2: '• I do not exceed the speed limit, and reduce my speed for road conditions ', Contents3: '• I do not use phones or operate devices while driving ', Contents4: '• I am fit, rested and fully alert while driving ', Contents5: '• I follow journey management requirements ', Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 720, Y: 140, },
      { No: 4, Title: 'Energy Isolation', SubTitle: 'Verify isolation and zero energy before work begins', Contents1: '• I have identified all energy sources ', Contents2: '• I confirm that hazardous energy sources have been isolated, locked, and tagged ', Contents3: '• I have checked there is zero energy and tested for residual or stored energy', Contents4: null, Contents5: null, Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 55, Y: 555, },
      { No: 5, Title: 'Hot Work', SubTitle: 'Control flammables and ignition sources', Contents1: '• I identify and control ignition sources', Contents2: '• Before starting any hot work:', Contents3: '• Before starting hot work in a hazardous area I confirm:', Contents4: null, Contents5: null, Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: ' – I confirm flammable material has been removed or isolated ', Contents2_Sub2: '– I obtain authorisation ', Contents2_Sub3: null, Contents3_Sub1: ' – a gas test has been completed ', Contents3_Sub2: '– gas will be monitored continually', X: 387, Y: 555, },
      { No: 6, Title: 'Line of Fire', SubTitle: 'Keep yourself and others out of the line of fire', Contents1: '• I position myself to avoid: ', Contents2: '• I establish and obey barriers and exclusion zones ', Contents3: '• I take action to secure loose objects and report potential dropped objects', Contents4: null, Contents5: null, Contents6: null, Contents1_Sub1: '– moving objects', Contents1_Sub2: ' – vehicles', Contents1_Sub3: ' – pressure releases ', Contents1_Sub4: '– dropped objects ', Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 720, Y: 555, },
      { No: 7, Title: 'Safe Mechanical Lifting', SubTitle: 'Plan lifting operations and control the area', Contents1: '•  I confirm that the equipment and load have been inspected and are fit for purpose ', Contents2: '• I only operate equipment that I am qualified to use ', Contents3: '• I establish and obey barriers and exclusion zones ', Contents4: '• I never walk under a suspended load', Contents5: null, Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 55, Y: 970, },
      { No: 8, Title: 'Work Authorisation', SubTitle: 'Work with a valid permit when required', Contents1: '• I have confirmed if a permit is required ', Contents2: '• I am authorised to perform the work ', Contents3: '• I understand the permit ', Contents4: '• I have confirmed that hazards are controlled and it is safe to start ', Contents5: '• I stop and reassess if conditions change', Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 387, Y: 970, },
      { No: 9, Title: 'Working at Height', SubTitle: 'Protect yourself against a fall when working at height', Contents1: '• I inspect my fall protection equipment before use ', Contents2: '• I secure tools and work materials to prevent dropped objects ', Contents3: '• I tie off 100% to approved anchor points while outside a protected area', Contents4: null, Contents5: null, Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 720, Y: 970, },
    ],
}