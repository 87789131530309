import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 550,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },


    DataItems: [
      {AREA:"IG0X",TOTAL:258,FAB:0,DEL:0,INST:0,REM:258},
      {AREA:"IG2X",TOTAL:117,FAB:0,DEL:0,INST:0,REM:117},
      {AREA:"IG3X",TOTAL:255,FAB:0,DEL:0,INST:0,REM:255},
      {AREA:"KO01",TOTAL:98,FAB:0,DEL:0,INST:0,REM:98},
      {AREA:"M000",TOTAL:320,FAB:0,DEL:0,INST:0,REM:320},
      {AREA:"ME01",TOTAL:928,FAB:0,DEL:0,INST:0,REM:928},
      {AREA:"ME02",TOTAL:1014,FAB:0,DEL:0,INST:0,REM:1014},
      {AREA:"ME04",TOTAL:440,FAB:0,DEL:0,INST:0,REM:440},
      {AREA:"ME05",TOTAL:32,FAB:0,DEL:0,INST:0,REM:32},
      {AREA:"MW01",TOTAL:750,FAB:0,DEL:0,INST:0,REM:750},
      {AREA:"MW02",TOTAL:767,FAB:0,DEL:0,INST:0,REM:767},
      {AREA:"MW04",TOTAL:444,FAB:0,DEL:0,INST:0,REM:444},
      {AREA:"MW05",TOTAL:1013,FAB:0,DEL:0,INST:0,REM:1013},
      {AREA:"PR00",TOTAL:12,FAB:0,DEL:0,INST:0,REM:12},
      {AREA:"RCxx",TOTAL:754,FAB:0,DEL:0,INST:0,REM:754},
      {AREA:"Total",TOTAL:7202,FAB:0,DEL:0,INST:0,REM:7202}
      ],
}