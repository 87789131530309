const ModuleDiscipline = [
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Structure', STG1: 64, STG2: 64, STG3: 64, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Piping', STG1: 60, STG2: 60, STG3: 60, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C10UH', DISC: 'E&I', STG1: 33, STG2: 33, STG3: 33, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Mechanical', STG1: 6, STG2: 6, STG3: 6, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C10UH', DISC: 'HVAC', STG1: 15, STG2: 15, STG3: 15, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Overall', STG1: 178, STG2: 178, STG3: 178, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Structure', STG1: 63, STG2: 63, STG3: 63, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Piping', STG1: 45, STG2: 45, STG3: 45, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C20UH', DISC: 'E&I', STG1: 33, STG2: 33, STG3: 33, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Mechanical', STG1: 11, STG2: 11, STG3: 11, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C20UH', DISC: 'HVAC', STG1: 15, STG2: 15, STG3: 15, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Overall', STG1: 167, STG2: 167, STG3: 167, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Structure', STG1: 63, STG2: 63, STG3: 63, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Piping', STG1: 60, STG2: 60, STG3: 60, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C30UH', DISC: 'E&I', STG1: 35, STG2: 35, STG3: 35, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Mechanical', STG1: 6, STG2: 6, STG3: 6, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C30UH', DISC: 'HVAC', STG1: 15, STG2: 15, STG3: 15, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Overall', STG1: 179, STG2: 179, STG3: 179, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Structure', STG1: 54, STG2: 54, STG3: 54, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Piping', STG1: 43, STG2: 43, STG3: 43, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C40UH', DISC: 'E&I', STG1: 33, STG2: 33, STG3: 33, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Mechanical', STG1: 11, STG2: 11, STG3: 11, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C40UH', DISC: 'HVAC', STG1: 15, STG2: 15, STG3: 15, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Overall', STG1: 156, STG2: 156, STG3: 156, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Hull', DISC: 'Structure', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Hull', DISC: 'Overall', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Structure', STG1: 169, STG2: 169, STG3: 169, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Piping', STG1: 93, STG2: 93, STG3: 93, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'E&I', STG1: 55, STG2: 55, STG3: 55, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Mechanical', STG1: 20, STG2: 20, STG3: 20, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'HVAC', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Overall', STG1: 345, STG2: 345, STG3: 345, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Structure', STG1: 179, STG2: 179, STG3: 179, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Piping', STG1: 99, STG2: 99, STG3: 99, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'E&I', STG1: 56, STG2: 56, STG3: 56, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Mechanical', STG1: 22, STG2: 22, STG3: 22, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'HVAC', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Overall', STG1: 364, STG2: 364, STG3: 364, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Structure', STG1: 157, STG2: 157, STG3: 157, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Piping', STG1: 92, STG2: 92, STG3: 92, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'E&I', STG1: 56, STG2: 56, STG3: 56, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Mechanical', STG1: 19, STG2: 19, STG3: 19, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'HVAC', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Overall', STG1: 332, STG2: 332, STG3: 332, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Structure', STG1: 156, STG2: 156, STG3: 156, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Piping', STG1: 87, STG2: 87, STG3: 87, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'E&I', STG1: 54, STG2: 54, STG3: 54, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Mechanical', STG1: 14, STG2: 14, STG3: 14, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'HVAC', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Overall', STG1: 319, STG2: 319, STG3: 319, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Overall', DISC: 'Overall', STG1: 2067, STG2: 2067, STG3: 2067, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'R160H', DISC: 'Structure', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'R160H', DISC: 'Mechanical', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'R160H', DISC: 'Overall', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y170H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y170H', DISC: 'Piping', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y170H', DISC: 'Overall', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y210H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y210H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y410H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y410H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y420H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y420H', DISC: 'Piping', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y420H', DISC: 'Overall', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y510H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y510H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y520H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y520H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y530H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y530H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y540H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y540H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y610H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y610H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y620H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y620H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y630H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },

  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y630H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y640H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y640H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y650H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y650H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y660H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y660H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y670H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y670H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y680H', DISC: 'Structure', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Hull', MODULE: 'Y680H', DISC: 'Overall', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'Deckbox', DISC: 'Structure', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'Deckbox', DISC: 'Architecture', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'Deckbox', DISC: 'Overall', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Structure', STG1: 68, STG2: 68, STG3: 68, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Piping', STG1: 184, STG2: 184, STG3: 184, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'E&I', STG1: 120, STG2: 120, STG3: 120, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Mechanical', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'HVAC', STG1: 66, STG2: 66, STG3: 66, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Architecture', STG1: 42, STG2: 42, STG3: 42, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Overall', STG1: 488, STG2: 488, STG3: 488, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Structure', STG1: 75, STG2: 75, STG3: 75, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Piping', STG1: 146, STG2: 146, STG3: 146, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'E&I', STG1: 32, STG2: 32, STG3: 32, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'HVAC', STG1: 57, STG2: 57, STG3: 57, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Architecture', STG1: 17, STG2: 17, STG3: 17, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Overall', STG1: 327, STG2: 327, STG3: 327, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'M930H', DISC: 'Structure', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'M930H', DISC: 'Overall', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'M970H', DISC: 'Structure', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'M970H', DISC: 'Overall', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Structure', STG1: 163, STG2: 163, STG3: 163, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Piping', STG1: 339, STG2: 339, STG3: 339, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'E&I', STG1: 219, STG2: 219, STG3: 219, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Mechanical', STG1: 26, STG2: 26, STG3: 26, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'HVAC', STG1: 143, STG2: 143, STG3: 143, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Architecture', STG1: 120, STG2: 120, STG3: 120, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Overall', STG1: 1010, STG2: 1010, STG3: 1010, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'Overall', DISC: 'Overall', STG1: 3556, STG2: 3556, STG3: 3556, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Structure', STG1: 170, STG2: 170, STG3: 170, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Piping', STG1: 403, STG2: 403, STG3: 403, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'E&I', STG1: 245, STG2: 245, STG3: 245, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Mechanical', STG1: 16, STG2: 16, STG3: 16, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'HVAC', STG1: 217, STG2: 217, STG3: 217, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Architecture', STG1: 118, STG2: 118, STG3: 118, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Overall', STG1: 1169, STG2: 1169, STG3: 1169, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Structure', STG1: 65, STG2: 65, STG3: 65, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Piping', STG1: 198, STG2: 198, STG3: 198, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'E&I', STG1: 129, STG2: 129, STG3: 129, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Mechanical', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'HVAC', STG1: 88, STG2: 88, STG3: 88, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Architecture', STG1: 64, STG2: 64, STG3: 64, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Overall', STG1: 552, STG2: 552, STG3: 552, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR10T', DISC: 'Structure', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR10T', DISC: 'Piping', STG1: 8, STG2: 8, STG3: 8, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR10T', DISC: 'E&I', STG1: 5, STG2: 5, STG3: 5, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR10T', DISC: 'Overall', STG1: 15, STG2: 15, STG3: 15, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR20T', DISC: 'Structure', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR20T', DISC: 'Piping', STG1: 9, STG2: 9, STG3: 9, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR20T', DISC: 'E&I', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'BR20T', DISC: 'Overall', STG1: 15, STG2: 15, STG3: 15, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Structure', STG1: 76, STG2: 76, STG3: 76, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Piping', STG1: 286, STG2: 286, STG3: 286, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'E&I', STG1: 203, STG2: 203, STG3: 203, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Mechanical', STG1: 14, STG2: 14, STG3: 14, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'HVAC', STG1: 145, STG2: 145, STG3: 145, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Architecture', STG1: 19, STG2: 19, STG3: 19, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Overall', STG1: 743, STG2: 743, STG3: 743, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Structure', STG1: 6, STG2: 6, STG3: 6, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Piping', STG1: 21, STG2: 21, STG3: 21, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'E&I', STG1: 16, STG2: 16, STG3: 16, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Mechanical', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Overall', STG1: 46, STG2: 46, STG3: 46, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Structure', STG1: 6, STG2: 6, STG3: 6, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Piping', STG1: 22, STG2: 22, STG3: 22, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'E&I', STG1: 16, STG2: 16, STG3: 16, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Mechanical', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Overall', STG1: 45, STG2: 45, STG3: 45, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Structure', STG1: 9, STG2: 9, STG3: 9, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Piping', STG1: 35, STG2: 35, STG3: 35, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'E&I', STG1: 20, STG2: 20, STG3: 20, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Mechanical', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Overall', STG1: 67, STG2: 67, STG3: 67, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Structure', STG1: 91, STG2: 91, STG3: 91, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Piping', STG1: 385, STG2: 385, STG3: 385, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'E&I', STG1: 262, STG2: 262, STG3: 262, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Mechanical', STG1: 5, STG2: 5, STG3: 5, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'HVAC', STG1: 159, STG2: 159, STG3: 159, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Architecture', STG1: 24, STG2: 24, STG3: 24, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Overall', STG1: 926, STG2: 926, STG3: 926, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'Overall', DISC: 'Overall', STG1: 3397, STG2: 3397, STG3: 3397, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Structure', STG1: 74, STG2: 74, STG3: 74, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Piping', STG1: 273, STG2: 273, STG3: 273, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'E&I', STG1: 192, STG2: 192, STG3: 192, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Mechanical', STG1: 16, STG2: 16, STG3: 16, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'HVAC', STG1: 130, STG2: 130, STG3: 130, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Overall', STG1: 685, STG2: 685, STG3: 685, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'Topside', DISC: 'Structure', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'Topside', DISC: 'Piping', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'Topside', DISC: 'Architecture', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'Topside', DISC: 'Overall', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Structure', STG1: 82, STG2: 82, STG3: 82, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Piping', STG1: 323, STG2: 323, STG3: 323, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'E&I', STG1: 229, STG2: 229, STG3: 229, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Mechanical', STG1: 13, STG2: 13, STG3: 13, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'HVAC', STG1: 170, STG2: 170, STG3: 170, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Architecture', STG1: 34, STG2: 34, STG3: 34, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Overall', STG1: 851, STG2: 851, STG3: 851, PROG: 100, },
  { PHASE: 'ENG', AREA: 'Overall', MODULE: 'Overall', DISC: 'Overall', STG1: 9020, STG2: 9020, STG3: 9020, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Piping', STG1: 42, STG2: 0, STG3: 42, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Overall', STG1: 42, STG2: 0, STG3: 42, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Piping', STG1: 49, STG2: 0, STG3: 49, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C20UH', DISC: 'E&I', STG1: 1, STG2: 0, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C20UH', DISC: 'HVAC', STG1: 2, STG2: 0, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Architectural', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Overall', STG1: 53, STG2: 1, STG3: 53, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Piping', STG1: 59, STG2: 0, STG3: 59, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Overall', STG1: 59, STG2: 0, STG3: 59, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Piping', STG1: 51, STG2: 0, STG3: 51, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C40UH', DISC: 'E&I', STG1: 2, STG2: 0, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C40UH', DISC: 'HVAC', STG1: 2, STG2: 0, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Architectural', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Overall', STG1: 56, STG2: 1, STG3: 56, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Piping', STG1: 142, STG2: 0, STG3: 142, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Mechanical', STG1: 21, STG2: 21, STG3: 21, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Architectural', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Overall', STG1: 165, STG2: 23, STG3: 165, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Piping', STG1: 146, STG2: 0, STG3: 146, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Mechanical', STG1: 22, STG2: 22, STG3: 22, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Architectural', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Overall', STG1: 170, STG2: 24, STG3: 170, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Piping', STG1: 178, STG2: 0, STG3: 178, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Mechanical', STG1: 23, STG2: 23, STG3: 23, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Architectural', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Overall', STG1: 203, STG2: 25, STG3: 203, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Piping', STG1: 164, STG2: 0, STG3: 164, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Mechanical', STG1: 24, STG2: 24, STG3: 24, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Architectural', STG1: 2, STG2: 2, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Overall', STG1: 190, STG2: 26, STG3: 190, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Hull', MODULE: 'Overall', DISC: 'Overall', STG1: 938, STG2: 100, STG3: 938, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Piping', STG1: 556, STG2: 0, STG3: 554, PROG: 99.64, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'E&I', STG1: 51, STG2: 0, STG3: 51, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Mechanical', STG1: 28, STG2: 28, STG3: 28, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'HVAC', STG1: 24, STG2: 0, STG3: 22, PROG: 91.67, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Architectural', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Overall', STG1: 660, STG2: 29, STG3: 656, PROG: 99.39, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'HD1UH', DISC: 'Piping', STG1: 14, STG2: 0, STG3: 14, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'HD1UH', DISC: 'Overall', STG1: 14, STG2: 0, STG3: 14, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Piping', STG1: 261, STG2: 0, STG3: 259, PROG: 99.23, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'E&I', STG1: 24, STG2: 0, STG3: 24, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Mechanical', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'HVAC', STG1: 34, STG2: 0, STG3: 30, PROG: 88.24, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Overall', STG1: 320, STG2: 1, STG3: 314, PROG: 98.13, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Piping', STG1: 1140, STG2: 0, STG3: 1136, PROG: 99.65, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'E&I', STG1: 91, STG2: 0, STG3: 91, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Mechanical', STG1: 92, STG2: 92, STG3: 85, PROG: 92.39, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'HVAC', STG1: 27, STG2: 0, STG3: 26, PROG: 96.3, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Architectural', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Overall', STG1: 1354, STG2: 96, STG3: 1342, PROG: 99.11, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'Overall', DISC: 'Overall', STG1: 4660, STG2: 245, STG3: 4618, PROG: 99.1, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Piping', STG1: 1047, STG2: 0, STG3: 1036, PROG: 98.95, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'E&I', STG1: 222, STG2: 0, STG3: 222, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Mechanical', STG1: 82, STG2: 82, STG3: 78, PROG: 95.12, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'HVAC', STG1: 64, STG2: 0, STG3: 64, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Architectural', STG1: 4, STG2: 4, STG3: 4, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Overall', STG1: 1419, STG2: 86, STG3: 1404, PROG: 98.94, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Piping', STG1: 801, STG2: 0, STG3: 796, PROG: 99.38, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'E&I', STG1: 26, STG2: 0, STG3: 26, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Mechanical', STG1: 32, STG2: 32, STG3: 32, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'HVAC', STG1: 33, STG2: 0, STG3: 33, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Architectural', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Overall', STG1: 893, STG2: 33, STG3: 888, PROG: 99.44, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Piping', STG1: 2807, STG2: 0, STG3: 2789, PROG: 99.36, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'E&I', STG1: 61, STG2: 0, STG3: 61, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Mechanical', STG1: 49, STG2: 49, STG3: 47, PROG: 95.92, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'HVAC', STG1: 6, STG2: 0, STG3: 6, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Overall', STG1: 2923, STG2: 49, STG3: 2903, PROG: 99.32, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Piping', STG1: 6, STG2: 0, STG3: 6, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Mechanical', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CP1PT', DISC: 'Overall', STG1: 7, STG2: 1, STG3: 7, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Piping', STG1: 1, STG2: 0, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Mechanical', STG1: 1, STG2: 1, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'CP2PT', DISC: 'Overall', STG1: 2, STG2: 1, STG3: 2, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Piping', STG1: 4, STG2: 0, STG3: 4, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Mechanical', STG1: 3, STG2: 3, STG3: 3, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Overall', STG1: 7, STG2: 3, STG3: 7, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Piping', STG1: 609, STG2: 0, STG3: 609, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'E&I', STG1: 57, STG2: 0, STG3: 57, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Mechanical', STG1: 5, STG2: 5, STG3: 5, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'HVAC', STG1: 14, STG2: 0, STG3: 14, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Overall', STG1: 685, STG2: 5, STG3: 685, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'N10UH', DISC: 'Piping', STG1: 1, STG2: 0, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'N10UH', DISC: 'Overall', STG1: 1, STG2: 0, STG3: 1, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'Overall', DISC: 'Overall', STG1: 7368, STG2: 113, STG3: 7319, PROG: 99.33, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Piping', STG1: 2557, STG2: 0, STG3: 2545, PROG: 99.53, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'E&I', STG1: 3, STG2: 0, STG3: 3, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Mechanical', STG1: 35, STG2: 34, STG3: 35, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Overall', STG1: 2595, STG2: 34, STG3: 2583, PROG: 99.54, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Piping', STG1: 1061, STG2: 0, STG3: 1044, PROG: 98.4, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'E&I', STG1: 53, STG2: 0, STG3: 53, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Mechanical', STG1: 20, STG2: 20, STG3: 20, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'HVAC', STG1: 14, STG2: 0, STG3: 14, PROG: 100, },
  { PHASE: 'PRO', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Overall', STG1: 1148, STG2: 20, STG3: 1131, PROG: 98.52, },
  { PHASE: 'PRO', AREA: 'Overall', MODULE: 'Overall', DISC: 'Overall', STG1: 12966, STG2: 458, STG3: 12875, PROG: 99.3, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C10UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C20UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C30UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'C40UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP1UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP2UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP3UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Hull', MODULE: 'NP4UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 97.7, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 99.39, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 88.46, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'E10UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 98.86, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'LQ1UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 98.18, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 97.77, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'N10UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 99.76, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 98.97, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 99.57, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 99.1, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'S10UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 99.89, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 96.35, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 99.51, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 95.84, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Deckbox', MODULE: 'W10UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 99.4, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UH', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UH', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 96.19, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 99.48, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 98.64, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 88.84, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'CM1UT', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 97.53, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 99.38, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'FB1UT', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 99.81, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 99.93, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 99.63, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 96.69, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'GM1UT', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 99.86, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 91.45, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 99.91, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'PM1UT', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 95.61, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Structure', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Painting', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Piping', STG1: null, STG2: null, STG3: null, PROG: 91.87, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'E&I', STG1: null, STG2: null, STG3: null, PROG: 98.93, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Mechanical', STG1: null, STG2: null, STG3: null, PROG: 99.13, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'HVAC', STG1: null, STG2: null, STG3: null, PROG: 82.91, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Architecture', STG1: null, STG2: null, STG3: null, PROG: 100, },
  { PHASE: 'CON', AREA: 'Topside', MODULE: 'WM1UT', DISC: 'Overall', STG1: null, STG2: null, STG3: null, PROG: 97.31, },

]

export default ModuleDiscipline