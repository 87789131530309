import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 1540,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#F4F5F5',
      CanvasFillColor: '#F4F5F5', //Transparent
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 860,
      CanvasChartHeight: 530,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      { disc: 'Architectural',  activity: 'Doors Installation',           plan: 0,      actual: 18.5},
      { disc: 'Architectural',  activity: 'Floor/Wall/Roof Install',      plan: 0,      actual: 11.4},
      { disc: 'Architectural',  activity: 'Cladding Wall/Roof Install',   plan: 74.2,   actual: 57.1},
      { disc: 'Architectural',  activity: 'Architectural M/C',            plan: 0,      actual: 0},
      { disc: 'E&I',            activity: 'Cable Tray Supt/Seat Install', plan: 85.5,   actual: 79.2},
      { disc: 'E&I',            activity: 'Cable Tray Installation',      plan: 83.5,   actual: 76.8},
      { disc: 'E&I',            activity: 'E&I EQ Installation(Major)',   plan: 100,    actual: 60.8},
      { disc: 'E&I',            activity: 'E&I EQ Installation(Minor)',   plan: 14.4,   actual: 15},
      { disc: 'E&I',            activity: 'Cable Pulling',                plan: 37.3,   actual: 32.8},
      { disc: 'E&I',            activity: 'Cable Connection',             plan: 9.7,    actual: 10.8},
      { disc: 'E&I',            activity: 'Tubing Installation',          plan: 4.7,    actual: 0},
      { disc: 'E&I',            activity: 'Megger Test/Continuity Check', plan: 1.7,    actual: 0.9},
      { disc: 'E&I',            activity: 'Heat Tracing Installation',    plan: 0,      actual: 0},
      { disc: 'E&I',            activity: 'E&I M/C (ITR sign-off)',       plan: 1.7,    actual: 1.2},
      { disc: 'HVAC',           activity: 'HVAC Support Installation',    plan: 87.4,   actual: 47.4},
      { disc: 'HVAC',           activity: 'Duct/Fittings Installation',   plan: 84.1,   actual: 67.3},
      { disc: 'HVAC',           activity: 'HVAC Ctrl./EQ Install(Major)', plan: 100,    actual: 99.9},
      { disc: 'HVAC',           activity: 'HVAC Ctrl./EQ Install(Minor)', plan: 85.7,   actual: 59.5},
      { disc: 'HVAC',           activity: 'HVAC Leak Test',               plan: 71.9,   actual: 100},
      { disc: 'HVAC',           activity: 'HVAC M/C',                     plan: 6,      actual: 33.3},
      { disc: 'Insulation',     activity: 'Steelwork Surface Insulation', plan: 34.2,   actual: 0},
      { disc: 'Insulation',     activity: 'HVAC Insulation',              plan: 2.3,    actual: 0},
      { disc: 'Insulation',     activity: 'Piping Insulation',            plan: 0,      actual: 0},
      { disc: 'Insulation',     activity: 'Mechanical Insulation',        plan: 29.6,   actual: 0},
      { disc: 'Insulation',     activity: 'Insulation M/C',               plan: 0,      actual: 1.1},
      { disc: 'Mechanical',     activity: 'Equipment Seat Install',       plan: 100,    actual: 92},
      { disc: 'Mechanical',     activity: 'Equipment Installation',       plan: 100,    actual: 91.1},
      { disc: 'Mechanical',     activity: 'Mechanical M/C ',              plan: 58.6,   actual: 55.8},
      { disc: 'Others',         activity: 'Module Disconnection',         plan: 0,      actual: 0},
      { disc: 'Others',         activity: 'De-stacking',                  plan: 0,      actual: 0},
      { disc: 'Others',         activity: 'Weighing',                     plan: 0,      actual: 0},
      { disc: 'Others',         activity: 'Protection',                   plan: 100,      actual: 100},
      { disc: 'Others',         activity: 'Ready for Load-out',           plan: 0,      actual: 0},
      { disc: 'Others',         activity: 'Sea-Fastening',                plan: 0,      actual: 0},
      { disc: 'Others',         activity: 'MWS Certification Issued',     plan: 0,      actual: 0},
      { disc: 'Others',         activity: 'Ready for Shipment',           plan: 0,      actual: 0},
      { disc: 'Piping',         activity: 'Pipe Spool Fabrication',       plan: 98.5,   actual: 99.9},
      { disc: 'Piping',         activity: 'Pipe Support Installation',    plan: 93.8,   actual: 85.2},
      { disc: 'Piping',         activity: 'Spool Installation',           plan: 86.3,   actual: 86.8},
      { disc: 'Piping',         activity: 'Valve Installation',           plan: 67.8,   actual: 21.5},
      { disc: 'Piping',         activity: 'Specialty Item Install',       plan: 10.6,   actual: 15.4},
      { disc: 'Piping',         activity: 'Line Inspection',              plan: 40.5,   actual: 36.7},
      { disc: 'Piping',         activity: 'Flushing',                     plan: 25.9,   actual: 16},
      { disc: 'Piping',         activity: 'Hydro Test',                   plan: 14.6,   actual: 13.8},
      { disc: 'Piping',         activity: 'Re-instatement',               plan: 8.6,    actual: 9.4},
      { disc: 'Piping',         activity: 'Piping M/C',                   plan: 9.4,    actual: 18.5},
      { disc: 'Painting & PFP', activity: 'Block Painting & PFP',         plan: 100,    actual: 100},
      { disc: 'Painting & PFP', activity: 'Touch-up Painting',            plan: 72,     actual: 68},
      { disc: 'Painting & PFP', activity: 'Painting & PFP M/C',           plan: 0,      actual: 0},
      { disc: 'Structural',     activity: 'Steel Cutting',                plan: 100,    actual: 100},
      { disc: 'Structural',     activity: 'Steel Outfittings Fab.',       plan: 94,     actual: 99.6},
      { disc: 'Structural',     activity: 'Block Assembly',               plan: 100,    actual: 100},
      { disc: 'Structural',     activity: 'Joint Welding',                plan: 100,    actual: 100},
      { disc: 'Structural',     activity: 'Steel Outfittings Install',    plan: 83.6,   actual: 86},
      { disc: 'Structural',     activity: 'Block Erection',               plan: 100,    actual: 100},
      { disc: 'Structural',     activity: 'Structural M/C',               plan: 75.1,   actual: 42.6},

    ],
}