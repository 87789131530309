import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 290,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { TYPE: 'A', DISC: 'AR',      TOTAL: '140',   ACTUAL: 139, REMAIN: 1, PROG: 99.29, },
      { TYPE: 'A', DISC: 'CO',      TOTAL: '21',    ACTUAL: 13, REMAIN: 8, PROG: 61.9, },
      { TYPE: 'A', DISC: 'EL',      TOTAL: '5967',  ACTUAL: 5075, REMAIN: 892, PROG: 85.05, },
      { TYPE: 'A', DISC: 'HV',      TOTAL: '2981',  ACTUAL: 2828, REMAIN: 153, PROG: 94.87, },
      { TYPE: 'A', DISC: 'IC',      TOTAL: '6697',  ACTUAL: 4778, REMAIN: 1919, PROG: 71.35, },
      { TYPE: 'A', DISC: 'IN',      TOTAL: '8',     ACTUAL: 2, REMAIN: 6, PROG: 25, },
      { TYPE: 'A', DISC: 'ME',      TOTAL: '3140',  ACTUAL: 2722, REMAIN: 418, PROG: 86.69, },
      { TYPE: 'A', DISC: 'PI',      TOTAL: '1471',  ACTUAL: 1210, REMAIN: 261, PROG: 82.26, },
      { TYPE: 'A', DISC: 'SE',      TOTAL: '15',    ACTUAL: 8, REMAIN: 7, PROG: 53.33, },
      { TYPE: 'A', DISC: 'ST',      TOTAL: '6',     ACTUAL: 5, REMAIN: 1, PROG: 83.33, },
      { TYPE: 'A', DISC: 'TE',      TOTAL: '888',   ACTUAL: 771, REMAIN: 117, PROG: 86.82, },
      { TYPE: 'A', DISC: 'Overall', TOTAL: '21334', ACTUAL: 17551, REMAIN: 3783, PROG: 82.27, },
      { TYPE: 'B', DISC: 'AR',      TOTAL: '425',   ACTUAL: 392, REMAIN: 33, PROG: 92.24, },
      { TYPE: 'B', DISC: 'CO',      TOTAL: '88',    ACTUAL: 55, REMAIN: 33, PROG: 62.5, },
      { TYPE: 'B', DISC: 'EL',      TOTAL: '8489',  ACTUAL: 6531, REMAIN: 1958, PROG: 76.93, },
      { TYPE: 'B', DISC: 'HV',      TOTAL: '1833',  ACTUAL: 1457, REMAIN: 376, PROG: 79.49, },
      { TYPE: 'B', DISC: 'IC',      TOTAL: '8947',  ACTUAL: 7191, REMAIN: 1756, PROG: 80.37, },
      { TYPE: 'B', DISC: 'IN',      TOTAL: '25',    ACTUAL: 5, REMAIN: 20, PROG: 20, },
      { TYPE: 'B', DISC: 'ME',      TOTAL: '4851',  ACTUAL: 4207, REMAIN: 644, PROG: 86.72, },
      { TYPE: 'B', DISC: 'NA',      TOTAL: '2',     ACTUAL: 2, REMAIN: 0, PROG: 100, },
      { TYPE: 'B', DISC: 'PI',      TOTAL: '4981',  ACTUAL: 4266, REMAIN: 715, PROG: 85.65, },
      { TYPE: 'B', DISC: 'SE',      TOTAL: '43',    ACTUAL: 31, REMAIN: 12, PROG: 72.09, },
      { TYPE: 'B', DISC: 'ST',      TOTAL: '44',    ACTUAL: 9, REMAIN: 35, PROG: 20.45, },
      { TYPE: 'B', DISC: 'TE',      TOTAL: '1608',  ACTUAL: 1169, REMAIN: 439, PROG: 72.7, },
      { TYPE: 'B', DISC: 'Overall', TOTAL: '31336', ACTUAL: 25315, REMAIN: 6021, PROG: 80.79, },
    ],


    Queries: {
      SQL1: [
              { TYPE: 'A', LINK1: 'PI', DISC: 'Piping', TOTAL: 35215, ACTUAL: 21704, REMAIN: 13511, PROG: 61.63, },
      ],       
      
      
      
    },
}