import System_ChartOptions_Data from    '../../09_Projects/TCO/00_System/ChartOptions/includes/primitives/Data'
import System_Dropdown_Data from        '../../09_Projects/TCO/00_System/Dropdown/includes/primitives/Data'
import System_SubMenu_01_Data from      '../../09_Projects/TCO/00_System/SubMenu_01/includes/primitives/Data'

// Common
import CMN_HistoryTable_Data from       '../../01_Charts/99_Common/HistoryTable/includes/primitives/Data'
import CMN_TBD_Data from                '../../01_Charts/99_Common/TBD/includes/primitives/Data'
import CMN_Up2UText_Data from           '../../01_Charts/99_Common/Up2UText/includes/primitives/Data'
import CMN_3DViewerEmbedded_Data from   '../../01_Charts/99_Common/3DViewerEmbedded/includes/primitives/Data'

// ### Home ###
import Home_ModuleStatus_Data from        '../../09_Projects/TCO/00_Homepage/ModuleStatus/includes/primitives/Data'
import Home_OncomingSailaway_Data from    '../../09_Projects/TCO/00_Homepage/OncomingSailaway/includes/primitives/Data'
import Home_OverallProgress_Data from     '../../09_Projects/TCO/00_Homepage/OverallProgress/includes/primitives/Data'
import Home_OverallSummary_Data from      '../../09_Projects/TCO/00_Homepage/OverallSummary/includes/primitives/Data'
import Home_SailawayStatus_Data from      '../../09_Projects/TCO/00_Homepage/SailawayStatus/includes/primitives/Data'
import Home_ConstructOutfitting_Data from '../../09_Projects/TCO/07_Construction/Outfitting/includes/primitives/Data'

// ### Construction ###
import Con_BlockSkyline_Data from           '../../09_Projects/Tortue/01_Planning/ConstructionBlockSkyline/includes/primitives/Data'
import Con_BlockDivision_Data from          '../../09_Projects/Tortue/04_Construction/BlockDivision/includes/primitives/Data' // FPSO
import Con_BlockDivisionFGP_Data from       '../../09_Projects/MD2/08_Construction/BlockDivision/includes/primitives/Data'
import Const_Skyline_Erec_Data from         '../../09_Projects/TCO/07_Construction/Skyline_Erec/includes/primitives/Data'
import Const_BlockSummary_Data from         '../../09_Projects/TCO/07_Construction/BlockSummary/includes/primitives/Data'
import Const_ActivityGanttChart_Data from   '../../09_Projects/TCO/07_Construction/ActivityGanttChart/includes/primitives/Data'
import Const_MeasurementBlock_Data from     '../../09_Projects/TCO/07_Construction/MeasurementBlock/includes/primitives/Data'
import Con_BlockDivisionFP_Data from  '../../09_Projects/DEMO/07_Construction/BlockDivisionHebron/includes/primitives/Data'
import Const_MilestonesModule_Data from     '../../09_Projects/TCO/03_Planning/MilestonesModule/includes/primitives/Data'
import Const_MeasurementDashboard_Data from '../../09_Projects/TCO/07_Construction/MeasurementDashboard/includes/primitives/Data'
import Const_Skyline_Module_Data from       '../../09_Projects/TCO/03_Planning/Skyline_Module/includes/primitives/Data'
import Const_Outfitting_Data from           '../../09_Projects/TCO/07_Construction/Outfitting/includes/primitives/Data'

// ### System Completion ###
import SC_MCSkyline_Data from             '../../09_Projects/MD2/09_SystemCompletion/MC_Skyline/includes/primitives/Data'
import SC_RfsSkyline_Data from            '../../09_Projects/TCO/08_SystemCompletion/RFS_Skyline/includes/primitives/Data'
import SC_CommissioningSkyline_Data from  '../../09_Projects/MD2/09_SystemCompletion/CommissioningSkyline/includes/primitives/Data'
import SC_SubsystemList_Data from         '../../09_Projects/TCO/08_SystemCompletion/SubsystemList/includes/primitives/Data'
import SC_SystemBoundary_Data from        '../../09_Projects/TCO/08_SystemCompletion/SystemBoundary/includes/primitives/Data'
import SC_Skyline_Data from               '../../09_Projects/TCO/08_SystemCompletion/Overall_Skyline/includes/primitives/Data'
import SC_ModuleSummary_Data from         '../../09_Projects/TCO/08_SystemCompletion/ModuleSummary/includes/primitives/Data'
import SC_SheetSummary_Data from          '../../09_Projects/TCO/08_SystemCompletion/SheetSummary/includes/primitives/Data'
import SC_WalkdownAvailable_Data from     '../../09_Projects/TCO/08_SystemCompletion/WalkdownAvailable/includes/primitives/Data'
import SC_DiscTrendChart_Data from        '../../09_Projects/TCO/08_SystemCompletion/DiscTrendChart/includes/primitives/Data'

// ### Belows will be removed or updated or the demo website. ###

//HomePage
import Home_ProjectProgress_Data from '../../09_Projects/Tortue/00_Homepage/OverallProgress/includes/primitives/Data'
import Home_KeyMilestone_Data from    '../../09_Projects/Tortue/00_Homepage/KeyMilestone/includes/primitives/Data'
// import Home_Observation_Data from     '../../09_Projects/Tortue/00_Homepage/Observation/includes/primitives/Data'
// import Home_OverallSummary_Data from '../../09_Projects/Tortue/00_Homepage/OverallSummary/includes/primitives/Data'
// import Home_OncomingSailaway_Data from '../../09_Projects/Tortue/00_Homepage/OncomingSailaway/includes/primitives/Data'
import Home_Yard_Data from                   '../../09_Projects/Tortue/00_Homepage/Yard/includes/primitives/Data'
import Home_DataSource_Data from             '../../09_Projects/Tortue/00_Homepage/DataSource/includes/primitives/Data'
import Home_Milestones_Data from '../../09_Projects/Tortue/00_Homepage/MilestoneImg/includes/primitives/Data'
// import Home_Milestones_R_Data from '../../09_Projects/Tortue/00_Homepage/Milestone/includes/primitives/Data'

// HSSE
import HSSE_SafetyPyramid_Data from '../../09_Projects/Tortue/01_HSSE/Pyramid/includes/primitives/Data'
import HSSE_LSR_Data from '../../09_Projects/Tortue/01_HSSE/LSR_IMG/includes/primitives/Data'
import HSSE_TSC_Data from '../../09_Projects/Tortue/01_HSSE/tortueSafetyCharter/includes/primitives/Data'
import HSSE_Dashboard_Data from '../../09_Projects/Tortue/01_HSSE/HSSEDashboard/includes/primitives/Data'

//Planning
import Plan_ProgressSummaryTable_Data from '../../09_Projects/Tortue/01_Planning/ProgressSummaryTable/includes/primitives/Data'
import Plan_ModuleSummarySchedule_Data from '../../09_Projects/Tortue/01_Planning/ModuleSummarySchedule/includes/primitives/Data'
import Plan_CommoditySummarySchedule_Data from '../../09_Projects/Tortue/01_Planning/CommoditySummarySchedule/includes/primitives/Data'
import Plan_SummaryByArea_Data from '../../09_Projects/Tortue/01_Planning/SummaryByArea/includes/primitives/Data'
import Plan_ModuleKeyMilestones_Data from '../../09_Projects/Tortue/01_Planning/SummaryByArea/includes/primitives/Data'
import TopsidesGanttChart_Data from '../../09_Projects/Tortue/01_Planning/TopsidesGanttChart/includes/primitives/Data'
import Plan_FilterPhase_Data from '../../09_Projects/Tortue/01_Planning/FilterPhase/includes/primitives/Data'

//Engineering
import Eng_CMDRStatus_Data from '../../09_Projects/Tortue/02_Engineering/CMDRStatus/includes/primitives/Data'
import Eng_IFCDrawingList_Data from '../../09_Projects/Tortue/02_Engineering/IFCDrawingList/includes/primitives/Data'
import Eng_TotalPlanActualRemainL_Data from '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainL/includes/primitives/Data'
import Eng_TotalPlanActualRemainM_Data from '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainM/includes/primitives/Data'
import Eng_TotalPlanActualRemainS_Data from '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainS/includes/primitives/Data'
import Eng_CDREHistoryChart_Data from '../../09_Projects/Tortue/02_Engineering/CDREHistoryChart/includes/primitives/Data'
import Eng_TQHistoryChart_Data from '../../09_Projects/Tortue/02_Engineering/TQHistoryChart/includes/primitives/Data'
import Eng_SDIDashboard_Data from '../../09_Projects/Tortue/02_Engineering/SDIDashboard/includes/primitives/Data'
import Eng_Interface_Data from '../../09_Projects/DEMO/02_Engineering/Interface/includes/primitives/Data'
import Eng_AreaInfo_Data from '../../09_Projects/DEMO/02_Engineering/AreaInfo/includes/primitives/Data'
import Eng_AreaTable_Data from '../../09_Projects/DEMO/02_Engineering/AreaTable/includes/primitives/Data'

// Procurement
import Proc_GanttChart_Data from '../../09_Projects/DEMO/05_Procurement/GanttChart/includes/primitives/Data'
import Proc_EquipmentLayout_Data from '../../09_Projects/DEMO/05_Procurement/Equipment_Layout/includes/primitives/Data'
import Proc_EquipmentLayout_V1_Data from '../../09_Projects/DEMO/05_Procurement/EquipmentLayout_V1/includes/primitives/Data'
import Proc_EquipmentTable_Data from '../../09_Projects/Tortue/03_Procurement/EquipmentTable/includes/primitives/Data'
import Proc_Equipment3DLayout_Data from '../../09_Projects/Tortue/03_Procurement/Equipment3DLayout/includes/primitives/Data'
import Proc_EquipmentRoom_Data from '../../09_Projects/Tortue/03_Procurement/EquipmentRoom/includes/primitives/Data'
import Proc_ProgressBar_Data from '../../09_Projects/Tortue/03_Procurement/progressBar/includes/primitives/Data'
import Proc_Lookahead_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'
import Proc_OWL_Data from '../../09_Projects/Tortue/03_Procurement/owl/includes/primitives/Data'
import Proc_OverallScurve_Data from '../../09_Projects/Tortue/03_Procurement/Overall_Scurve/includes/primitives/Data'
import Proc_FATScurve_Data from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve/includes/primitives/Data'
import Proc_IRNScurve_Data from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve/includes/primitives/Data'
import Proc_ETAScurve_Data from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve/includes/primitives/Data'
import Proc_IRNLookahead_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'
import Proc_ETALookahead_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'
import Proc_EquipmentHullCommon_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'

// Construction
import Con_BlockSummary_Data from           '../../09_Projects/Tortue/04_Construction/BlockSummary/includes/primitives/Data'
import Con_ActivityGanttChart_Data from     '../../09_Projects/TCO/07_Construction/ActivityGanttChart/includes/primitives/Data'
import Con_ActivityGanttChart_R1_Data from     '../../09_Projects/Tortue/04_Construction/ActivityGanttChart_R1/includes/primitives/Data'
import Con_ActivityGanttChartLegend_Data from     '../../09_Projects/Tortue/04_Construction/ActivityGanttChartLegend/includes/primitives/Data'
import Con_MeasurementBlock_Data from       '../../09_Projects/Tortue/04_Construction/MeasurementBlock/includes/primitives/Data'
import Con_ShopDrawingActivities_Data from  '../../09_Projects/Tortue/04_Construction/ShopDrawingActivities/includes/primitives/Data'
import Con_ShopDrawingStatus_Data from  '../../09_Projects/Tortue/04_Construction/ShopDrawingStatus/includes/primitives/Data'
import Con_ShopDrawingStatusOther_Data from  '../../09_Projects/Tortue/04_Construction/ShopDrawingStatusOther/includes/primitives/Data'
import Con_OutfittingStatus_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingStatus/includes/primitives/Data'
import Con_OutfittingStatusNew_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingStatus_new/includes/primitives/Data'
import Con_Outfitting_Data from             '../../09_Projects/Tortue/04_Construction/Outfitting/includes/primitives/Data'
import Con_ErectionTimeline_Data from       '../../09_Projects/Tortue/04_Construction/ErectionTimeline/includes/primitives/Data'
import Con_OverallDashboard_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverall/includes/primitives/Data'
import Con_OverallDashboardTopside_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside/includes/primitives/Data'
import Con_OverallDashboardTopside_L_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_L/includes/primitives/Data'
import Con_OverallDashboardTopside_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_S/includes/primitives/Data'
import Con_OverallDashboardHull_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallHull/includes/primitives/Data'
import Con_OverallDashboardHull_L_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallHull/includes/primitives/Data'
import Con_OverallDashboardHull_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallHull_S/includes/primitives/Data'
import Con_OverallDashboardModules_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallModules_S/includes/primitives/Data'
import Con_OverallDashboardModule_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallModule_S/includes/primitives/Data'
import Con_OverallDashboardCommon_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallCommon_S/includes/primitives/Data'
import Con_OverallDashboardLQ_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ_S/includes/primitives/Data'
import Con_OverallDashboardLQ_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ/includes/primitives/Data'
import Con_ProgressSummaryOverall_Data from       '../../09_Projects/Tortue/04_Construction/ProgressSummaryOverall/includes/primitives/Data'
import Con_ProgressSummaryTopside_Data from       '../../09_Projects/Tortue/04_Construction/ProgressSummaryTopside/includes/primitives/Data'
import Con_ProgressSummaryHull_Data from       '../../09_Projects/Tortue/04_Construction/ProgressSummaryHull/includes/primitives/Data'
import Con_OutfittingCommoditiesStatus_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingCommoditiesStatus/includes/primitives/Data'
import Con_OutfittingCommoditiesStatusL_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_L/includes/primitives/Data'
import Con_OgmDashboardHull_Data from      '../../09_Projects/Tortue/04_Construction/OgmDashboardHull/includes/primitives/Data'
import Con_OgmDashboardTopside_Data from      '../../09_Projects/Tortue/04_Construction/OgmDashboardTopside/includes/primitives/Data'
import Con_SimpleText_Data from      '../../09_Projects/Tortue/04_Construction/SimpleText/includes/primitives/Data'
import Con_OgmDisc_Data from      '../../09_Projects/Tortue/04_Construction/OgmDisc/includes/primitives/Data'
import Con_OutfittingCommStatus_Data from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus/includes/primitives/Data'
import Con_OutfittingCommStatus_S_Data from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_S/includes/primitives/Data'
import Con_SpoolSummarySize_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize/includes/primitives/Data'
import Con_SpoolSummarySize_S_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_S/includes/primitives/Data'
import Con_SpoolSummarySize_L_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_L/includes/primitives/Data'
import Con_SpoolSummaryArea_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummaryArea/includes/primitives/Data'
import Con_AreaDashboard_Hull_AFT_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Hull/includes/primitives/Data'
import Con_AreaDashboard_Hull_FOR_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_HullFOR/includes/primitives/Data'
import Con_AreaDashboard_Module_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Module/includes/primitives/Data'
import Con_AreaDashboard_Common_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Common/includes/primitives/Data'
import Con_AreaDashboard_L3_ME01_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME04/includes/primitives/Data'
import Con_AreaDashboard_L3_ME02_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME02/includes/primitives/Data'
import Con_AreaDashboard_L3_ME04_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME04/includes/primitives/Data'
import Con_AreaDashboard_L3_ME05_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME05/includes/primitives/Data'
import Con_AreaDashboard_L3_MW01_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW01/includes/primitives/Data'
import Con_AreaDashboard_L3_MW02_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW02/includes/primitives/Data'
import Con_AreaDashboard_L3_MW04_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW04/includes/primitives/Data'
import Con_AreaDashboard_L3_MW05_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW05/includes/primitives/Data'
import Con_AreaDashboard_L3_Common_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_Common/includes/primitives/Data'
import Con_MoreBtn_Data from      '../../09_Projects/Tortue/04_Construction/MoreBtn/includes/primitives/Data'
import Con_AreaDashboardBarchart_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboardBarchart/includes/primitives/Data'
import Con_LQCOM_OGM_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM/includes/primitives/Data'
import Con_LQCOM_ImgModal_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_ImgModal/includes/primitives/Data'
import Con_LQCOM_OGM_Modal_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM_Modal/includes/primitives/Data'
import Con_LQCOM_Level_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_Level/includes/primitives/Data'
import Con_LQCOM_OvBlockBar_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvBlockBar/includes/primitives/Data'
import Con_LQCOM_OvEqBar_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvEqBar/includes/primitives/Data'
import Con_LQCOM_OVIMG_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OVIMG/includes/primitives/Data'
import Con_HULLFORCOM_OVIMG_Data from      '../../09_Projects/Tortue/04_Construction/HULLFORCOM_OVIMG/includes/primitives/Data'
import Con_HULLAFTCOM_OVIMG_Data from      '../../09_Projects/Tortue/04_Construction/HULLAFTCOM_OVIMG/includes/primitives/Data'
import Con_LQCOM_Shop_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop/includes/primitives/Data'
import Con_LQCOM_Shop_Modal_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop_Modal/includes/primitives/Data'
import Con_LQCOM_ProgChart_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_ProgChart/includes/primitives/Data'
import Con_SimpleRect_Data from      '../../09_Projects/Tortue/04_Construction/SimpleRect/includes/primitives/Data'
import Con_ImagesBox_Data from              '../../09_Projects/Tortue/04_Construction/ImagesBox/includes/primitives/Data'
import Con_ImagesBox_6_Data from              '../../09_Projects/Tortue/04_Construction/ImagesBox_6/includes/primitives/Data'
import Con_ImagesBox_8_Data from              '../../09_Projects/Tortue/04_Construction/ImagesBox_8/includes/primitives/Data'
import Con_ModuleSummary_Data from              '../../09_Projects/Tortue/04_Construction/ModuleSummary/includes/primitives/Data'
import Con_PipingItem_Data from             '../../09_Projects/Tortue/04_Construction/PipingItem/includes/primitives/Data'
import Con_PipingOverall_Data from          '../../09_Projects/Tortue/04_Construction/PipingOverall/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_architecture_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_architecture/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_electrical_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_architecture/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_hvac_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_hvac/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_instrument_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_instrument/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_insulation_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_insulation/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_mechanical_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_mechanical/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_painting_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_painting/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_safety_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_safety/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_structure_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_structure/includes/primitives/Data'
import Con_DiscItemSummary_Data from '../../09_Projects/Tortue/04_Construction/DiscItemSummary/includes/primitives/Data'
import Con_DiscItemSummaryModal_Data from '../../09_Projects/Tortue/04_Construction/DiscItemSummaryModal/includes/primitives/Data'
import Con_DiscStatusSummaryTopsides_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusSummaryTopsides/includes/primitives/Data'
import Con_TopsideLevel_Data from '../../09_Projects/Tortue/04_Construction/TopsideLevel/includes/primitives/Data'
import Con_TopsideModule_ME01_Data from '../../09_Projects/Tortue/04_Construction/TopsideModules_ME01/includes/primitives/Data'
import Con_BlockTable_Data from '../../09_Projects/Tortue/04_Construction/BlockTable/includes/primitives/Data'
import Con_HullCommonDashboard_Data from '../../09_Projects/Tortue/04_Construction/HullCommonDashboard/includes/primitives/Data'
import Con_HullCommonDivision_Data from '../../09_Projects/Tortue/04_Construction/HullCommonDivision/includes/primitives/Data'
import Con_CommoditySummary_Data from '../../09_Projects/Tortue/04_Construction/CommoditySummary/includes/primitives/Data'
import Con_CommoditySummaryHull_Data from '../../09_Projects/Tortue/04_Construction/CommoditySummaryHull/includes/primitives/Data'
import Con_CommoditySummaryTable_Data from '../../09_Projects/Tortue/04_Construction/CommoditySummaryTable/includes/primitives/Data'
import Con_DiscIconStatusChart_Data from '../../09_Projects/Tortue/04_Construction/DiscIconStatusChart/includes/primitives/Data'
import Con_TopsideModulesChartAll_Data from '../../09_Projects/Tortue/04_Construction/TopsideModulesChartAll/includes/primitives/Data'

// Quality Control
import QC_WeldingRepairTrendChart_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairTrendChart/includes/primitives/Data'
import QC_WeldingRepairSummaryUT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryUT/includes/primitives/Data'
import QC_WeldingRepairSummaryRT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryRT/includes/primitives/Data'
import QC_WeldingRepairSummaryPAUT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryPAUT/includes/primitives/Data'
import QC_WeldingRepairSummaryMT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryMT/includes/primitives/Data'
import QC_3DBlockDivision_Data from    '../../09_Projects/Tortue/06_QualityControl/3DBlockDivision/includes/primitives/Data'
import QC_DashboardBarchart_Data from    '../../09_Projects/Tortue/06_QualityControl/DashboardBarchart/includes/primitives/Data'

// System Completion
// import SC_MCSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline/includes/primitives/Data'
// import SC_CommissioningSkyline_Data from    '../../09_Projects/MD2/09_SystemCompletion/CommissioningSkyline/includes/primitives/Data'
  
// import SC_MC_DB_SysStatus_Data from         '../../09_Projects/MD2/09_SystemCompletion/MC_DB_SysStatus/includes/primitives/Data'
import SC_MC_DB_ITRStatus_Data from         '../../09_Projects/MD2/09_SystemCompletion/MC_DB_ITRStatus/includes/primitives/Data'
import SC_MC_DB_PunchTrends_Data from       '../../09_Projects/MD2/09_SystemCompletion/MC_DB_PunchTrends/includes/primitives/Data'
  
import SC_Table_Lookahead_Data from         '../../09_Projects/MD2/09_SystemCompletion/Table_Lookahead/includes/primitives/Data'
import SC_Table_MostOpen_Data from          '../../09_Projects/MD2/09_SystemCompletion/Table_MostOpen/includes/primitives/Data'
import SC_Table_PassedPunch_Data from       '../../09_Projects/MD2/09_SystemCompletion/Table_PassedPunch/includes/primitives/Data'
  
import SC_Disc_DB_ITRStatus_Data from       '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_ITRStatus/includes/primitives/Data'
import SC_Disc_DB_Package_Data from         '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_Package/includes/primitives/Data'
  
import SC_SChart_ITR_Data from              '../../09_Projects/MD2/09_SystemCompletion/SChart_ITR/includes/primitives/Data'
import SC_SChart_Punch_Data from            '../../09_Projects/MD2/09_SystemCompletion/SChart_Punch/includes/primitives/Data'
import SC_Chart_Punch_Data from             '../../09_Projects/MD2/09_SystemCompletion/Chart_Punch/includes/primitives/Data'
import SC_Punch_Disc_Data from              '../../09_Projects/MD2/09_SystemCompletion/Punch_Discipline/includes/primitives/Data'
  
import SC_Sys_MC_Overall_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall/includes/primitives/Data'
import SC_Sys_MC_ITRA_Data from             '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_ITRA/includes/primitives/Data'
import SC_Sys_MC_PunchChart_Data from       '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchChart/includes/primitives/Data'
import SC_Sys_MC_PunchDisc_Data from        '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchDisc/includes/primitives/Data'
import SC_Sys_SC_Overall_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Overall/includes/primitives/Data'
import SC_Sys_SC_ITRB_Data from             '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_ITRB/includes/primitives/Data'
import SC_Sys_SC_Boundary_Data from         '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Boundary/includes/primitives/Data'
  
import SC_Reg_COISkyline_Data from          '../../09_Projects/MD2/11_Regulatory/COI_Skyline/includes/primitives/Data'
  
// Commissioning  
import COM_OverallSummary_Data from         '../../09_Projects/MD2/10_Commissioning/OverallSummary/includes/primitives/Data'
import COM_Procedures_Data from             '../../09_Projects/MD2/10_Commissioning/Procedures/includes/primitives/Data'
import COM_ITR_B_Data from                  '../../09_Projects/MD2/10_Commissioning/ITR_B/includes/primitives/Data'
import COM_Discipline_Data from             '../../09_Projects/MD2/10_Commissioning/Discipline/includes/primitives/Data'
  
// Commissioning  
import RGT_COI_Skyline_Data from            '../../09_Projects/MD2/11_Regulatory/COI_Skyline/includes/primitives/Data'
import RGT_Milestones_Data from             '../../09_Projects/MD2/11_Regulatory/Milestones/includes/primitives/Data'
import RGT_OverallSummary_Data from         '../../09_Projects/MD2/11_Regulatory/OverallSummary/includes/primitives/Data'
import RGT_RTP_Data from                    '../../09_Projects/MD2/11_Regulatory/RTP/includes/primitives/Data'
import RGT_USCG_Data from                   '../../09_Projects/MD2/11_Regulatory/USCG/includes/primitives/Data'

/* 
  ### Chart Customized - From the TCO Project ### 
*/
//HomePage
// import Home_ModuleStatus_Data from '../../09_Projects/TCO/00_Homepage/ModuleStatus/includes/primitives/Data'
// import Home_OverallProgress_Data from '../../09_Projects/TCO/00_Homepage/OverallProgress/includes/primitives/Data'
// import Home_SailawayStatus_Data from '../../09_Projects/TCO/00_Homepage/SailawayStatus/includes/primitives/Data'

//Planning
import Const_Milestones_Data from '../../09_Projects/TCO/03_Planning/Milestones/includes/primitives/Data'

//Safety
import SA_Hsse_Data from '../../09_Projects/TCO/02_Safety/Hsse/includes/primitives/Data'

export default {
  // Common
  CMN_HistoryTable_Data,  
  CMN_TBD_Data,
  CMN_Up2UText_Data,
  CMN_3DViewerEmbedded_Data,

  // System
  System_ChartOptions_Data,
  System_Dropdown_Data,
  System_SubMenu_01_Data,

  //Homepage
  Home_ProjectProgress_Data,
  Home_KeyMilestone_Data,
  // Home_Observation_Data,
  Home_DataSource_Data,
  Home_Milestones_Data,
  // Home_Milestones_R_Data,
  
  // HSSE
  HSSE_SafetyPyramid_Data,
  HSSE_LSR_Data,
  HSSE_TSC_Data,
  HSSE_Dashboard_Data,
  
  //Planning
  Plan_ProgressSummaryTable_Data,
  Plan_ModuleSummarySchedule_Data,
  Plan_CommoditySummarySchedule_Data,
  Plan_SummaryByArea_Data,
  Plan_ModuleKeyMilestones_Data,
  TopsidesGanttChart_Data,
  Plan_FilterPhase_Data,

  //Engineering
  Eng_CMDRStatus_Data,
  Eng_IFCDrawingList_Data,
  Eng_TotalPlanActualRemainL_Data,
  Eng_TotalPlanActualRemainM_Data,
  Eng_TotalPlanActualRemainS_Data,
  Eng_CDREHistoryChart_Data,
  Eng_TQHistoryChart_Data,
  Eng_SDIDashboard_Data,

  Eng_Interface_Data,
  Eng_AreaInfo_Data, 
  Eng_AreaTable_Data,

  // Procurement
  Proc_GanttChart_Data,
  Proc_EquipmentLayout_Data,
  Proc_EquipmentLayout_V1_Data,
  Proc_EquipmentTable_Data,
  Proc_Equipment3DLayout_Data,
  Proc_EquipmentRoom_Data,
  Proc_ProgressBar_Data,
  Proc_Lookahead_Data,
  Proc_OWL_Data,
  Proc_OverallScurve_Data,
  Proc_FATScurve_Data,
  Proc_IRNScurve_Data,
  Proc_ETAScurve_Data,
  Proc_IRNLookahead_Data,
  Proc_ETALookahead_Data,
  Proc_EquipmentHullCommon_Data,

  // Construction
  Con_BlockDivision_Data,
  Con_BlockSummary_Data,
  Con_ActivityGanttChart_Data,
  Con_ActivityGanttChart_R1_Data,
  Con_ActivityGanttChartLegend_Data,
  Con_MeasurementBlock_Data,
  Con_ShopDrawingActivities_Data,
  Con_ShopDrawingStatus_Data,
  Con_ShopDrawingStatusOther_Data,
  Con_OutfittingStatus_Data,
  Con_OutfittingStatusNew_Data,
  Con_Outfitting_Data,
  Con_ErectionTimeline_Data,
  Con_OverallDashboard_Data,
  Con_OverallDashboardTopside_Data,
  Con_OverallDashboardTopside_L_Data,
  Con_OverallDashboardTopside_S_Data,
  Con_OverallDashboardHull_Data,
  Con_OverallDashboardHull_L_Data,
  Con_OverallDashboardHull_S_Data,
  Con_OverallDashboardModules_S_Data,
  Con_OverallDashboardLQ_S_Data,
  Con_OverallDashboardLQ_Data,
  Con_ProgressSummaryOverall_Data,
  Con_ProgressSummaryTopside_Data,
  Con_ProgressSummaryHull_Data,
  Con_OutfittingCommoditiesStatus_Data,
  Con_OutfittingCommoditiesStatusL_Data,
  Con_OgmDisc_Data,
  Con_OgmDashboardHull_Data,
  Con_OgmDashboardTopside_Data,
  Con_SimpleText_Data,
  Con_OutfittingCommStatus_Data,
  Con_OutfittingCommStatus_S_Data,
  Con_SpoolSummarySize_Data,
  Con_SpoolSummarySize_S_Data,
  Con_SpoolSummarySize_L_Data,
  Con_SpoolSummaryArea_Data,

  Con_AreaDashboard_Hull_AFT_Data,
  Con_AreaDashboard_Hull_FOR_Data,

  Con_AreaDashboard_L3_ME01_Data,
  Con_AreaDashboard_L3_ME02_Data,
  Con_AreaDashboard_L3_ME04_Data,
  Con_AreaDashboard_L3_ME05_Data,

  Con_AreaDashboard_L3_MW01_Data,
  Con_AreaDashboard_L3_MW02_Data,
  Con_AreaDashboard_L3_MW04_Data,
  Con_AreaDashboard_L3_MW05_Data,

  Con_MoreBtn_Data,
  Con_AreaDashboardBarchart_Data,

  Con_LQCOM_OGM_Data,
  Con_LQCOM_OGM_Modal_Data,
  Con_LQCOM_ImgModal_Data,
  Con_LQCOM_Level_Data,
  Con_LQCOM_OvBlockBar_Data,
  Con_LQCOM_OvEqBar_Data,
  Con_HULLFORCOM_OVIMG_Data,
  Con_LQCOM_OVIMG_Data,
  Con_HULLAFTCOM_OVIMG_Data,
  Con_LQCOM_Shop_Data,
  Con_LQCOM_Shop_Modal_Data,
  Con_LQCOM_ProgChart_Data,

  Con_SimpleRect_Data,

  Con_ImagesBox_Data,   
  Con_ImagesBox_6_Data,
  Con_ImagesBox_8_Data,
  Con_ModuleSummary_Data,

  Con_PipingItem_Data,   
  Con_PipingOverall_Data,
  Con_DiscStatusTrackingIcon_Data,
  Con_DiscStatusTrackingIcon_architecture_Data,
  Con_DiscStatusTrackingIcon_electrical_Data,
  Con_DiscStatusTrackingIcon_hvac_Data,
  Con_DiscStatusTrackingIcon_instrument_Data,
  Con_DiscStatusTrackingIcon_insulation_Data,
  Con_DiscStatusTrackingIcon_mechanical_Data,
  Con_DiscStatusTrackingIcon_painting_Data,
  Con_DiscStatusTrackingIcon_safety_Data,
  Con_DiscStatusTrackingIcon_structure_Data,
  Con_DiscItemSummary_Data,
  Con_DiscItemSummaryModal_Data,
  Con_DiscStatusSummaryTopsides_Data,
  Con_TopsideLevel_Data,
  Con_TopsideModule_ME01_Data,
  Con_BlockTable_Data,
  Con_HullCommonDashboard_Data,
  Con_HullCommonDivision_Data,
  Con_AreaDashboard_Module_Data,
  Con_AreaDashboard_Common_Data,
  Con_AreaDashboard_L3_Common_Data,
  Con_OverallDashboardModule_S_Data,
  Con_OverallDashboardCommon_S_Data,
  Con_CommoditySummary_Data,
  Con_CommoditySummaryTable_Data,
  Con_CommoditySummaryHull_Data,
  Con_DiscIconStatusChart_Data,
  Con_TopsideModulesChartAll_Data,

  Const_Skyline_Erec_Data,
  Const_BlockSummary_Data,
  Const_ActivityGanttChart_Data,
  Const_MeasurementBlock_Data,
  Con_BlockDivisionFP_Data,
  Const_MilestonesModule_Data,
  Const_MeasurementDashboard_Data,
  Const_Skyline_Module_Data,
  Const_Outfitting_Data,


  // Quality Control
  QC_WeldingRepairTrendChart_Data,
  QC_WeldingRepairSummaryUT_Data,
  QC_WeldingRepairSummaryRT_Data,
  QC_WeldingRepairSummaryPAUT_Data,
  QC_WeldingRepairSummaryMT_Data,
  QC_3DBlockDivision_Data,
  QC_DashboardBarchart_Data,

  // System Completion
  SC_SubsystemList_Data,
  SC_SystemBoundary_Data,
  SC_Skyline_Data,
  SC_MCSkyline_Data,
  SC_ModuleSummary_Data,
  SC_SheetSummary_Data,
  SC_WalkdownAvailable_Data,
  SC_DiscTrendChart_Data,

  SC_CommissioningSkyline_Data,
  // SC_MC_DB_SysStatus_Data,
  SC_MC_DB_ITRStatus_Data,
  SC_MC_DB_PunchTrends_Data,

  SC_Table_Lookahead_Data,
  SC_Table_MostOpen_Data,
  SC_Table_PassedPunch_Data,

  SC_Disc_DB_ITRStatus_Data,
  SC_Disc_DB_Package_Data,

  SC_SChart_ITR_Data,
  SC_SChart_Punch_Data,
  SC_Chart_Punch_Data,
  SC_Punch_Disc_Data,

  SC_Sys_MC_Overall_Data,
  SC_Sys_MC_ITRA_Data,
  SC_Sys_MC_PunchChart_Data,
  SC_Sys_MC_PunchDisc_Data,
  
  SC_Sys_SC_Overall_Data,
  SC_Sys_SC_ITRB_Data,
  SC_Sys_SC_Boundary_Data,

  SC_Reg_COISkyline_Data,

  // Commissioning
  COM_OverallSummary_Data,
  COM_Procedures_Data,
  COM_ITR_B_Data,
  COM_Discipline_Data,

  // Regulatory
  RGT_COI_Skyline_Data,
  RGT_Milestones_Data,
  RGT_OverallSummary_Data,
  RGT_RTP_Data,
  RGT_USCG_Data, 

  // ### For the DEMO Project -----------
  // Home
  Home_ModuleStatus_Data,           // TCO
  Home_OncomingSailaway_Data,       // TCO
  Home_OverallProgress_Data,        // TCO
  Home_OverallSummary_Data,         // TCO
  Home_SailawayStatus_Data,         // TCO
  Home_ConstructOutfitting_Data,    // TCO
  Home_Yard_Data,                   // TORTUE

  // Construction
  Con_BlockSkyline_Data,
  Con_BlockDivisionFGP_Data,

  // System Completion
  SC_RfsSkyline_Data,

  // ### For the Tortue Project
  // Safety
  SA_Hsse_Data,
  //Planning
  Const_Milestones_Data,
}
