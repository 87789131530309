const DisciplineStatus = [
  
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Piping'        , TOTAL: 4944, ACTUAL: 4829, REMAIN: 115, PROG: 97.67, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Electrical'    , TOTAL: 1712, ACTUAL: 1617, REMAIN: 95, PROG: 94.45, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Instrument'    , TOTAL: 3409, ACTUAL: 3346, REMAIN: 63, PROG: 98.15, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Telecom'       , TOTAL: 341, ACTUAL: 264, REMAIN: 77, PROG: 77.42, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Mechanical'    , TOTAL: 1361, ACTUAL: 1296, REMAIN: 65, PROG: 95.22, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'HVAC'          , TOTAL: 108, ACTUAL: 87, REMAIN: 21, PROG: 80.56, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Architecture'  , TOTAL: 44, ACTUAL: 24, REMAIN: 20, PROG: 54.55, },
  { AREA: 'Hull'   , PHASE: 'MC'  , DISC: 'Safety'        , TOTAL: 59, ACTUAL: 26, REMAIN: 33, PROG: 44.07, },
  { AREA: 'Hull'   , PHASE: 'COM' , DISC: 'Electrical'    , TOTAL: 226, ACTUAL: 8, REMAIN: 218, PROG: 3.54, },
  { AREA: 'Hull'   , PHASE: 'COM' , DISC: 'Instrument'    , TOTAL: 778, ACTUAL: 149, REMAIN: 629, PROG: 19.15, },
  { AREA: 'Hull'   , PHASE: 'COM' , DISC: 'Telecom'       , TOTAL: 68, ACTUAL: 0, REMAIN: 68, PROG: 0, },
  { AREA: 'Hull'   , PHASE: 'COM' , DISC: 'Mechanical'    , TOTAL: 108, ACTUAL: 16, REMAIN: 92, PROG: 14.81, },
  { AREA: 'Hull'   , PHASE: 'COM' , DISC: 'HVAC'          , TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
  { AREA: 'Hull'   , PHASE: 'PKG' , DISC: 'Line Walk'     , TOTAL: 824, ACTUAL: 824, REMAIN: 0, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'PKG' , DISC: 'Flushing'      , TOTAL: 824, ACTUAL: 810, REMAIN: 14, PROG: 98.3, },
  { AREA: 'Hull'   , PHASE: 'PKG' , DISC: 'Pressure Test' , TOTAL: 824, ACTUAL: 800, REMAIN: 24, PROG: 97.09, },
  { AREA: 'Hull'   , PHASE: 'PKG' , DISC: 'Cleanliness'   , TOTAL: 824, ACTUAL: 800, REMAIN: 24, PROG: 97.09, },
  { AREA: 'Hull'   , PHASE: 'PKG' , DISC: 'Reinstatement' , TOTAL: 824, ACTUAL: 800, REMAIN: 24, PROG: 97.09, },
  { AREA: 'Hull'   , PHASE: 'PKG' , DISC: 'Certificate'   , TOTAL: 824, ACTUAL: 795, REMAIN: 29, PROG: 96.48, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'Structure'     , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'Mechanical'    , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'Piping'        , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'E&I'           , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'HVAC'          , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'Architecture'  , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Hull'   , PHASE: 'CON' , DISC: 'Painting'      , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Piping'        , TOTAL: 17358, ACTUAL: 9385, REMAIN: 7973, PROG: 54.07, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Electrical'    , TOTAL: 29524, ACTUAL: 12040, REMAIN: 17484, PROG: 40.78, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Instrument'    , TOTAL: 28101, ACTUAL: 15093, REMAIN: 13008, PROG: 53.71, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Telecom'       , TOTAL: 7035, ACTUAL: 1980, REMAIN: 5055, PROG: 28.14, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Mechanical'    , TOTAL: 3395, ACTUAL: 2149, REMAIN: 1246, PROG: 63.3, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'HVAC'          , TOTAL: 5224, ACTUAL: 3521, REMAIN: 1703, PROG: 67.4, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Architecture'  , TOTAL: 1283, ACTUAL: 188, REMAIN: 1095, PROG: 14.65, },
  { AREA: 'Deckbox', PHASE: 'MC'  , DISC: 'Safety'        , TOTAL: 501, ACTUAL: 4, REMAIN: 497, PROG: 0.8, },
  { AREA: 'Deckbox', PHASE: 'COM' , DISC: 'Electrical'    , TOTAL: 4372, ACTUAL: 105, REMAIN: 4267, PROG: 2.4, },
  { AREA: 'Deckbox', PHASE: 'COM' , DISC: 'Instrument'    , TOTAL: 5528, ACTUAL: 113, REMAIN: 5415, PROG: 2.04, },
  { AREA: 'Deckbox', PHASE: 'COM' , DISC: 'Telecom'       , TOTAL: 2016, ACTUAL: 0, REMAIN: 2016, PROG: 0, },
  { AREA: 'Deckbox', PHASE: 'COM' , DISC: 'Mechanical'    , TOTAL: 1053, ACTUAL: 8, REMAIN: 1045, PROG: 0.76, },
  { AREA: 'Deckbox', PHASE: 'COM' , DISC: 'HVAC'          , TOTAL: 760, ACTUAL: 0, REMAIN: 760, PROG: 0, },
  { AREA: 'Deckbox', PHASE: 'PKG' , DISC: 'Line Walk'     , TOTAL: 2893, ACTUAL: 2274, REMAIN: 619, PROG: 78.6, },
  { AREA: 'Deckbox', PHASE: 'PKG' , DISC: 'Flushing'      , TOTAL: 2893, ACTUAL: 1615, REMAIN: 1278, PROG: 55.82, },
  { AREA: 'Deckbox', PHASE: 'PKG' , DISC: 'Pressure Test' , TOTAL: 2893, ACTUAL: 1593, REMAIN: 1300, PROG: 55.06, },
  { AREA: 'Deckbox', PHASE: 'PKG' , DISC: 'Cleanliness'   , TOTAL: 2893, ACTUAL: 1528, REMAIN: 1365, PROG: 52.82, },
  { AREA: 'Deckbox', PHASE: 'PKG' , DISC: 'Reinstatement' , TOTAL: 2893, ACTUAL: 1318, REMAIN: 1575, PROG: 45.56, },
  { AREA: 'Deckbox', PHASE: 'PKG' , DISC: 'Certificate'   , TOTAL: 2893, ACTUAL: 1057, REMAIN: 1836, PROG: 36.54, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'Structure'     , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'Mechanical'    , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 99.6980420478173, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'Piping'        , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 97.9984566512692, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'E&I'           , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'HVAC'          , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 96.3755645559329, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'Architecture'  , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Deckbox', PHASE: 'CON' , DISC: 'Painting'      , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Piping'        , TOTAL: 11896, ACTUAL: 4954, REMAIN: 6942, PROG: 41.64, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Electrical'    , TOTAL: 12728, ACTUAL: 8727, REMAIN: 4001, PROG: 68.57, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Instrument'    , TOTAL: 22523, ACTUAL: 11771, REMAIN: 10752, PROG: 52.26, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Telecom'       , TOTAL: 1320, ACTUAL: 906, REMAIN: 414, PROG: 68.64, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Mechanical'    , TOTAL: 1593, ACTUAL: 760, REMAIN: 833, PROG: 47.71, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'HVAC'          , TOTAL: 774, ACTUAL: 505, REMAIN: 269, PROG: 65.25, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Architecture'  , TOTAL: 64, ACTUAL: 0, REMAIN: 64, PROG: 0, },
  { AREA: 'Topside', PHASE: 'MC'  , DISC: 'Safety'        , TOTAL: 301, ACTUAL: 0, REMAIN: 301, PROG: 0, },
  { AREA: 'Topside', PHASE: 'COM' , DISC: 'Electrical'    , TOTAL: 2469, ACTUAL: 380, REMAIN: 2089, PROG: 15.39, },
  { AREA: 'Topside', PHASE: 'COM' , DISC: 'Instrument'    , TOTAL: 5138, ACTUAL: 768, REMAIN: 4370, PROG: 14.95, },
  { AREA: 'Topside', PHASE: 'COM' , DISC: 'Telecom'       , TOTAL: 234, ACTUAL: 63, REMAIN: 171, PROG: 26.92, },
  { AREA: 'Topside', PHASE: 'COM' , DISC: 'Mechanical'    , TOTAL: 742, ACTUAL: 0, REMAIN: 742, PROG: 0, },
  { AREA: 'Topside', PHASE: 'COM' , DISC: 'HVAC'          , TOTAL: 152, ACTUAL: 0, REMAIN: 152, PROG: 0, },
  { AREA: 'Topside', PHASE: 'PKG' , DISC: 'Line Walk'     , TOTAL: 1977, ACTUAL: 1295, REMAIN: 682, PROG: 65.5, },
  { AREA: 'Topside', PHASE: 'PKG' , DISC: 'Flushing'      , TOTAL: 1977, ACTUAL: 916, REMAIN: 1061, PROG: 46.33, },
  { AREA: 'Topside', PHASE: 'PKG' , DISC: 'Pressure Test' , TOTAL: 1977, ACTUAL: 903, REMAIN: 1074, PROG: 45.68, },
  { AREA: 'Topside', PHASE: 'PKG' , DISC: 'Cleanliness'   , TOTAL: 1977, ACTUAL: 862, REMAIN: 1115, PROG: 43.6, },
  { AREA: 'Topside', PHASE: 'PKG' , DISC: 'Reinstatement' , TOTAL: 1977, ACTUAL: 612, REMAIN: 1365, PROG: 30.96, },
  { AREA: 'Topside', PHASE: 'PKG' , DISC: 'Certificate'   , TOTAL: 1977, ACTUAL: 366, REMAIN: 1611, PROG: 18.51, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'Structure'     , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'Mechanical'    , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 99.459020512407, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'Piping'        , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 93.46655676344, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'E&I'           , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 99.4001823346739, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'HVAC'          , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 90.4582674114875, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'Architecture'  , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
  { AREA: 'Topside', PHASE: 'CON' , DISC: 'Painting'      , TOTAL: null, ACTUAL: null, REMAIN: null, PROG: 100, },
]

export default DisciplineStatus