const MasterProgress = [
  
  { AREA: 'Topside', 
    CON_P_PG: 98, CON_A_PG: 97.52, CON_D_PG: -0.48, 
    MOD: 9, BLK: 9, WT: 13933.7, 
    A_T: 51199, A_A: 27623, A_O: 23576, A_PG: 53.95, 
    B_T: 8735, B_A: 1211, B_O: 7524, B_PG: 13.86, 
    PA_T: 9388, PA_A: 5865, PA_O: 3523, 
    PB_T: 11176, PB_A: 8315, PB_O: 2861, 
  },

  { AREA: 'Deckbox', 
    CON_P_PG: 99.64, CON_A_PG: 99.57, CON_D_PG: -0.07, 
    MOD: 9, BLK: 9, WT: 19422, 
    A_T: 92421, A_A: 44360, A_O: 48061, A_PG: 48, 
    B_T: 13729, B_A: 226, B_O: 13503, B_PG: 1.65, 
    PA_T: 8821, PA_A: 7769, PA_O: 1052, 
    PB_T: 10984, PB_A: 8483, PB_O: 2501, 
  },

  { 
    AREA: 'Hull', CON_P_PG: 100, CON_A_PG: 100, CON_D_PG: 0,
    MOD: 27, BLK: 27, WT: 52625, 
    A_T: 11978, A_A: 11489, A_O: 489, A_PG: 95.92, 
    B_T: 1184, B_A: 173, B_O: 1011, B_PG: 14.61, 
    PA_T: 1852, PA_A: 1743, PA_O: 109, 
    PB_T: 5448, PB_A: 4960, PB_O: 488, 
  },

  { 
    AREA: 'Overall', 
    CON_P_PG: 83.6, CON_A_PG: 83.42, CON_D_PG: -0.18, 
    MOD: 45, BLK: 45, WT: 85980.7, 
    A_T: 157784, A_A: 84608, A_O: 73176, A_PG: 53.62, 
    B_T: 24633, B_A: 1661, B_O: 22972, B_PG: 6.74, 
    PA_T: 20200, PA_A: 15501, PA_O: 4699, 
    PB_T: 28813, PB_A: 22900, PB_O: 5913, 
  },
]

export default MasterProgress