const BlockStatus = [
  { AREA: 'Overall', MODULE: 'Overall', TITLE: 'Cutting Commenced', TOTAL: 378, ACTUAL: 378, },
  { AREA: 'Overall', MODULE: 'Overall', TITLE: 'Fab. Commenced', TOTAL: 371, ACTUAL: 371, },
  { AREA: 'Overall', MODULE: 'Overall', TITLE: 'Assembly 100%', TOTAL: 378, ACTUAL: 378, },
  { AREA: 'Overall', MODULE: 'Overall', TITLE: 'Pre-Outfitting 100%', TOTAL: 297, ACTUAL: 297, },
  { AREA: 'Overall', MODULE: 'Overall', TITLE: 'Block Painting 100%', TOTAL: 318, ACTUAL: 318, },
  { AREA: 'Overall', MODULE: 'Overall', TITLE: 'Erection', TOTAL: 291, ACTUAL: 291, },
  
  { AREA: 'Hull'   , MODULE: 'Overall', TITLE: 'Cutting Commenced', TOTAL: 154, ACTUAL: 154, },
  { AREA: 'Hull'   , MODULE: 'Overall', TITLE: 'Fab. Commenced', TOTAL: 149, ACTUAL: 149, },
  { AREA: 'Hull'   , MODULE: 'Overall', TITLE: 'Assembly 100%', TOTAL: 154, ACTUAL: 154, },
  { AREA: 'Hull'   , MODULE: 'Overall', TITLE: 'Pre-Outfitting 100%', TOTAL: 75, ACTUAL: 75, },
  { AREA: 'Hull'   , MODULE: 'Overall', TITLE: 'Block Painting 100%', TOTAL: 113, ACTUAL: 113, },
  { AREA: 'Hull'   , MODULE: 'Overall', TITLE: 'Erection', TOTAL: 113, ACTUAL: 113, },
  { AREA: 'Hull'   , MODULE: 'BS1PH'  , TITLE: 'Cutting Commenced', TOTAL: 8, ACTUAL: 8, },
  { AREA: 'Hull'   , MODULE: 'BS1PH'  , TITLE: 'Fab. Commenced', TOTAL: 8, ACTUAL: 8, },
  { AREA: 'Hull'   , MODULE: 'BS1PH'  , TITLE: 'Assembly 100%', TOTAL: 8, ACTUAL: 8, },
  { AREA: 'Hull'   , MODULE: 'BS1PH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'BS1PH'  , TITLE: 'Block Painting 100%', TOTAL: 7, ACTUAL: 7, },
  { AREA: 'Hull'   , MODULE: 'BS1PH'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'C10UH'  , TITLE: 'Cutting Commenced', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Hull'   , MODULE: 'C10UH'  , TITLE: 'Fab. Commenced', TOTAL: 13, ACTUAL: 13, },
  { AREA: 'Hull'   , MODULE: 'C10UH'  , TITLE: 'Assembly 100%', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Hull'   , MODULE: 'C10UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 11, ACTUAL: 11, },
  { AREA: 'Hull'   , MODULE: 'C10UH'  , TITLE: 'Block Painting 100%', TOTAL: 12, ACTUAL: 12, },
  { AREA: 'Hull'   , MODULE: 'C10UH'  , TITLE: 'Erection', TOTAL: 13, ACTUAL: 13, },
  { AREA: 'Hull'   , MODULE: 'C20UH'  , TITLE: 'Cutting Commenced', TOTAL: 9, ACTUAL: 9, },
  { AREA: 'Hull'   , MODULE: 'C20UH'  , TITLE: 'Fab. Commenced', TOTAL: 9, ACTUAL: 9, },
  { AREA: 'Hull'   , MODULE: 'C20UH'  , TITLE: 'Assembly 100%', TOTAL: 9, ACTUAL: 9, },
  { AREA: 'Hull'   , MODULE: 'C20UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 6, ACTUAL: 6, },
  { AREA: 'Hull'   , MODULE: 'C20UH'  , TITLE: 'Block Painting 100%', TOTAL: 6, ACTUAL: 6, },
  { AREA: 'Hull'   , MODULE: 'C20UH'  , TITLE: 'Erection', TOTAL: 7, ACTUAL: 7, },
  { AREA: 'Hull'   , MODULE: 'C30UH'  , TITLE: 'Cutting Commenced', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Hull'   , MODULE: 'C30UH'  , TITLE: 'Fab. Commenced', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Hull'   , MODULE: 'C30UH'  , TITLE: 'Assembly 100%', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Hull'   , MODULE: 'C30UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 7, ACTUAL: 7, },
  { AREA: 'Hull'   , MODULE: 'C30UH'  , TITLE: 'Block Painting 100%', TOTAL: 12, ACTUAL: 12, },
  { AREA: 'Hull'   , MODULE: 'C30UH'  , TITLE: 'Erection', TOTAL: 12, ACTUAL: 12, },
  { AREA: 'Hull'   , MODULE: 'C40UH'  , TITLE: 'Cutting Commenced', TOTAL: 9, ACTUAL: 9, },
  { AREA: 'Hull'   , MODULE: 'C40UH'  , TITLE: 'Fab. Commenced', TOTAL: 9, ACTUAL: 9, },
  { AREA: 'Hull'   , MODULE: 'C40UH'  , TITLE: 'Assembly 100%', TOTAL: 9, ACTUAL: 9, },
  { AREA: 'Hull'   , MODULE: 'C40UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 6, ACTUAL: 6, },
  { AREA: 'Hull'   , MODULE: 'C40UH'  , TITLE: 'Block Painting 100%', TOTAL: 7, ACTUAL: 7, },
  { AREA: 'Hull'   , MODULE: 'C40UH'  , TITLE: 'Erection', TOTAL: 7, ACTUAL: 7, },
  { AREA: 'Hull'   , MODULE: 'NP1UH'  , TITLE: 'Cutting Commenced', TOTAL: 22, ACTUAL: 22, },
  { AREA: 'Hull'   , MODULE: 'NP1UH'  , TITLE: 'Fab. Commenced', TOTAL: 22, ACTUAL: 22, },
  { AREA: 'Hull'   , MODULE: 'NP1UH'  , TITLE: 'Assembly 100%', TOTAL: 22, ACTUAL: 22, },
  { AREA: 'Hull'   , MODULE: 'NP1UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 10, ACTUAL: 10, },
  { AREA: 'Hull'   , MODULE: 'NP1UH'  , TITLE: 'Block Painting 100%', TOTAL: 19, ACTUAL: 19, },
  { AREA: 'Hull'   , MODULE: 'NP1UH'  , TITLE: 'Erection', TOTAL: 21, ACTUAL: 21, },
  { AREA: 'Hull'   , MODULE: 'NP2UH'  , TITLE: 'Cutting Commenced', TOTAL: 22, ACTUAL: 22, },
  { AREA: 'Hull'   , MODULE: 'NP2UH'  , TITLE: 'Fab. Commenced', TOTAL: 22, ACTUAL: 22, },
  { AREA: 'Hull'   , MODULE: 'NP2UH'  , TITLE: 'Assembly 100%', TOTAL: 22, ACTUAL: 22, },
  { AREA: 'Hull'   , MODULE: 'NP2UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 13, ACTUAL: 13, },
  { AREA: 'Hull'   , MODULE: 'NP2UH'  , TITLE: 'Block Painting 100%', TOTAL: 18, ACTUAL: 18, },
  { AREA: 'Hull'   , MODULE: 'NP2UH'  , TITLE: 'Erection', TOTAL: 20, ACTUAL: 20, },
  { AREA: 'Hull'   , MODULE: 'NP3UH'  , TITLE: 'Cutting Commenced', TOTAL: 19, ACTUAL: 19, },
  { AREA: 'Hull'   , MODULE: 'NP3UH'  , TITLE: 'Fab. Commenced', TOTAL: 19, ACTUAL: 19, },
  { AREA: 'Hull'   , MODULE: 'NP3UH'  , TITLE: 'Assembly 100%', TOTAL: 19, ACTUAL: 19, },
  { AREA: 'Hull'   , MODULE: 'NP3UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 10, ACTUAL: 10, },
  { AREA: 'Hull'   , MODULE: 'NP3UH'  , TITLE: 'Block Painting 100%', TOTAL: 15, ACTUAL: 15, },
  { AREA: 'Hull'   , MODULE: 'NP3UH'  , TITLE: 'Erection', TOTAL: 17, ACTUAL: 17, },
  { AREA: 'Hull'   , MODULE: 'NP4UH'  , TITLE: 'Cutting Commenced', TOTAL: 18, ACTUAL: 18, },
  { AREA: 'Hull'   , MODULE: 'NP4UH'  , TITLE: 'Fab. Commenced', TOTAL: 18, ACTUAL: 18, },
  { AREA: 'Hull'   , MODULE: 'NP4UH'  , TITLE: 'Assembly 100%', TOTAL: 18, ACTUAL: 18, },
  { AREA: 'Hull'   , MODULE: 'NP4UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 10, ACTUAL: 10, },
  { AREA: 'Hull'   , MODULE: 'NP4UH'  , TITLE: 'Block Painting 100%', TOTAL: 12, ACTUAL: 12, },
  { AREA: 'Hull'   , MODULE: 'NP4UH'  , TITLE: 'Erection', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Hull'   , MODULE: 'R160H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'R160H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'R160H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'R160H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'R160H'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'R160H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y170H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y170H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y170H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y170H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y170H'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y170H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y180H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y180H'  , TITLE: 'Fab. Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y180H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y180H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y180H'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y180H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y230H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y230H'  , TITLE: 'Fab. Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y230H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y230H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y230H'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y230H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y370H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y370H'  , TITLE: 'Fab. Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y370H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y370H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y370H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y370H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y420H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y420H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y420H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y420H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y420H'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y420H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y440H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y440H'  , TITLE: 'Fab. Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y440H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y440H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y440H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y440H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y510H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y510H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y510H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y510H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y510H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y510H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y520H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y520H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y520H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y520H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y520H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y520H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y530H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y530H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y530H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y530H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y530H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y530H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y540H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y540H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y540H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y540H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y540H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y540H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y610H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y610H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y610H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y610H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y610H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y610H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y620H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y620H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y620H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y620H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y620H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y620H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y630H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y630H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y630H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y630H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y630H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y630H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y640H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y640H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y640H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y640H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y640H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y640H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y650H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y650H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y650H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y650H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y650H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y650H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y660H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y660H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y660H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y660H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y660H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y660H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y670H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y670H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y670H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y670H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y670H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y670H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y680H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y680H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y680H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Hull'   , MODULE: 'Y680H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y680H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Hull'   , MODULE: 'Y680H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },

  { AREA: 'Deckbox', MODULE: 'Overall', TITLE: 'Cutting Commenced', TOTAL: 113, ACTUAL: 113, },
  { AREA: 'Deckbox', MODULE: 'Overall', TITLE: 'Fab. Commenced', TOTAL: 111, ACTUAL: 111, },
  { AREA: 'Deckbox', MODULE: 'Overall', TITLE: 'Assembly 100%', TOTAL: 113, ACTUAL: 113, },
  { AREA: 'Deckbox', MODULE: 'Overall', TITLE: 'Pre-Outfitting 100%', TOTAL: 113, ACTUAL: 113, },
  { AREA: 'Deckbox', MODULE: 'Overall', TITLE: 'Block Painting 100%', TOTAL: 94, ACTUAL: 94, },
  { AREA: 'Deckbox', MODULE: 'Overall', TITLE: 'Erection', TOTAL: 107, ACTUAL: 107, },
  { AREA: 'Deckbox', MODULE: 'E10UH'  , TITLE: 'Cutting Commenced', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Deckbox', MODULE: 'E10UH'  , TITLE: 'Fab. Commenced', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Deckbox', MODULE: 'E10UH'  , TITLE: 'Assembly 100%', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Deckbox', MODULE: 'E10UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Deckbox', MODULE: 'E10UH'  , TITLE: 'Block Painting 100%', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Deckbox', MODULE: 'E10UH'  , TITLE: 'Erection', TOTAL: 14, ACTUAL: 14, },
  { AREA: 'Deckbox', MODULE: 'HD1UH'  , TITLE: 'Cutting Commenced', TOTAL: 2, ACTUAL: 2, },
  { AREA: 'Deckbox', MODULE: 'HD1UH'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'HD1UH'  , TITLE: 'Assembly 100%', TOTAL: 2, ACTUAL: 2, },
  { AREA: 'Deckbox', MODULE: 'HD1UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 2, ACTUAL: 2, },
  { AREA: 'Deckbox', MODULE: 'HD1UH'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'HD1UH'  , TITLE: 'Erection', TOTAL: 2, ACTUAL: 2, },
  { AREA: 'Deckbox', MODULE: 'LQ1UH'  , TITLE: 'Cutting Commenced', TOTAL: 20, ACTUAL: 20, },
  { AREA: 'Deckbox', MODULE: 'LQ1UH'  , TITLE: 'Fab. Commenced', TOTAL: 19, ACTUAL: 19, },
  { AREA: 'Deckbox', MODULE: 'LQ1UH'  , TITLE: 'Assembly 100%', TOTAL: 20, ACTUAL: 20, },
  { AREA: 'Deckbox', MODULE: 'LQ1UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 20, ACTUAL: 20, },
  { AREA: 'Deckbox', MODULE: 'LQ1UH'  , TITLE: 'Block Painting 100%', TOTAL: 2, ACTUAL: 2, },
  { AREA: 'Deckbox', MODULE: 'LQ1UH'  , TITLE: 'Erection', TOTAL: 20, ACTUAL: 20, },
  { AREA: 'Deckbox', MODULE: 'M970H'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'M970H'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'M970H'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'M970H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'M970H'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Deckbox', MODULE: 'M970H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'N10UH'  , TITLE: 'Cutting Commenced', TOTAL: 28, ACTUAL: 28, },
  { AREA: 'Deckbox', MODULE: 'N10UH'  , TITLE: 'Fab. Commenced', TOTAL: 28, ACTUAL: 28, },
  { AREA: 'Deckbox', MODULE: 'N10UH'  , TITLE: 'Assembly 100%', TOTAL: 28, ACTUAL: 28, },
  { AREA: 'Deckbox', MODULE: 'N10UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 28, ACTUAL: 28, },
  { AREA: 'Deckbox', MODULE: 'N10UH'  , TITLE: 'Block Painting 100%', TOTAL: 28, ACTUAL: 28, },
  { AREA: 'Deckbox', MODULE: 'N10UH'  , TITLE: 'Erection', TOTAL: 28, ACTUAL: 28, },
  { AREA: 'Deckbox', MODULE: 'S10UH'  , TITLE: 'Cutting Commenced', TOTAL: 32, ACTUAL: 32, },
  { AREA: 'Deckbox', MODULE: 'S10UH'  , TITLE: 'Fab. Commenced', TOTAL: 32, ACTUAL: 32, },
  { AREA: 'Deckbox', MODULE: 'S10UH'  , TITLE: 'Assembly 100%', TOTAL: 32, ACTUAL: 32, },
  { AREA: 'Deckbox', MODULE: 'S10UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 32, ACTUAL: 32, },
  { AREA: 'Deckbox', MODULE: 'S10UH'  , TITLE: 'Block Painting 100%', TOTAL: 32, ACTUAL: 32, },
  { AREA: 'Deckbox', MODULE: 'S10UH'  , TITLE: 'Erection', TOTAL: 27, ACTUAL: 27, },
  { AREA: 'Deckbox', MODULE: 'W10UH'  , TITLE: 'Cutting Commenced', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Deckbox', MODULE: 'W10UH'  , TITLE: 'Fab. Commenced', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Deckbox', MODULE: 'W10UH'  , TITLE: 'Assembly 100%', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Deckbox', MODULE: 'W10UH'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Deckbox', MODULE: 'W10UH'  , TITLE: 'Block Painting 100%', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Deckbox', MODULE: 'W10UH'  , TITLE: 'Erection', TOTAL: 16, ACTUAL: 16, },
  { AREA: 'Deckbox', MODULE: 'Y210H'  , TITLE: 'Cutting Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y210H'  , TITLE: 'Fab. Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y210H'  , TITLE: 'Assembly 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y210H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y210H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y210H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y410H'  , TITLE: 'Cutting Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y410H'  , TITLE: 'Fab. Commenced', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y410H'  , TITLE: 'Assembly 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y410H'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y410H'  , TITLE: 'Block Painting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Deckbox', MODULE: 'Y410H'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },

  { AREA: 'Topside', MODULE: 'BR10T'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR10T'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR10T'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR10T'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Topside', MODULE: 'BR10T'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR10T'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Topside', MODULE: 'BR20T'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR20T'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR20T'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR20T'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Topside', MODULE: 'BR20T'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'BR20T'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Topside', MODULE: 'CM1UT'  , TITLE: 'Cutting Commenced', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'CM1UT'  , TITLE: 'Fab. Commenced', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'CM1UT'  , TITLE: 'Assembly 100%', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'CM1UT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'CM1UT'  , TITLE: 'Block Painting 100%', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'CM1UT'  , TITLE: 'Erection', TOTAL: 17, ACTUAL: 17, },
  { AREA: 'Topside', MODULE: 'CP1PT'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP1PT'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP1PT'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP1PT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP1PT'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP1PT'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Topside', MODULE: 'CP2PT'  , TITLE: 'Cutting Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP2PT'  , TITLE: 'Fab. Commenced', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP2PT'  , TITLE: 'Assembly 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP2PT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP2PT'  , TITLE: 'Block Painting 100%', TOTAL: 1, ACTUAL: 1, },
  { AREA: 'Topside', MODULE: 'CP2PT'  , TITLE: 'Erection', TOTAL: 0, ACTUAL: 0, },
  { AREA: 'Topside', MODULE: 'FB1UT'  , TITLE: 'Cutting Commenced', TOTAL: 3, ACTUAL: 3, },
  { AREA: 'Topside', MODULE: 'FB1UT'  , TITLE: 'Fab. Commenced', TOTAL: 3, ACTUAL: 3, },
  { AREA: 'Topside', MODULE: 'FB1UT'  , TITLE: 'Assembly 100%', TOTAL: 3, ACTUAL: 3, },
  { AREA: 'Topside', MODULE: 'FB1UT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 3, ACTUAL: 3, },
  { AREA: 'Topside', MODULE: 'FB1UT'  , TITLE: 'Block Painting 100%', TOTAL: 3, ACTUAL: 3, },
  { AREA: 'Topside', MODULE: 'FB1UT'  , TITLE: 'Erection', TOTAL: 3, ACTUAL: 3, },
  { AREA: 'Topside', MODULE: 'GM1UT'  , TITLE: 'Cutting Commenced', TOTAL: 30, ACTUAL: 30, },
  { AREA: 'Topside', MODULE: 'GM1UT'  , TITLE: 'Fab. Commenced', TOTAL: 30, ACTUAL: 30, },
  { AREA: 'Topside', MODULE: 'GM1UT'  , TITLE: 'Assembly 100%', TOTAL: 30, ACTUAL: 30, },
  { AREA: 'Topside', MODULE: 'GM1UT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 30, ACTUAL: 30, },
  { AREA: 'Topside', MODULE: 'GM1UT'  , TITLE: 'Block Painting 100%', TOTAL: 30, ACTUAL: 30, },
  { AREA: 'Topside', MODULE: 'GM1UT'  , TITLE: 'Erection', TOTAL: 15, ACTUAL: 15, },
  { AREA: 'Topside', MODULE: 'Overall', TITLE: 'Cutting Commenced', TOTAL: 111, ACTUAL: 111, },
  { AREA: 'Topside', MODULE: 'Overall', TITLE: 'Fab. Commenced', TOTAL: 111, ACTUAL: 111, },
  { AREA: 'Topside', MODULE: 'Overall', TITLE: 'Assembly 100%', TOTAL: 111, ACTUAL: 111, },
  { AREA: 'Topside', MODULE: 'Overall', TITLE: 'Pre-Outfitting 100%', TOTAL: 109, ACTUAL: 109, },
  { AREA: 'Topside', MODULE: 'Overall', TITLE: 'Block Painting 100%', TOTAL: 111, ACTUAL: 111, },
  { AREA: 'Topside', MODULE: 'Overall', TITLE: 'Erection', TOTAL: 71, ACTUAL: 71, },
  { AREA: 'Topside', MODULE: 'PM1UT'  , TITLE: 'Cutting Commenced', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'PM1UT'  , TITLE: 'Fab. Commenced', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'PM1UT'  , TITLE: 'Assembly 100%', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'PM1UT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'PM1UT'  , TITLE: 'Block Painting 100%', TOTAL: 24, ACTUAL: 24, },
  { AREA: 'Topside', MODULE: 'PM1UT'  , TITLE: 'Erection', TOTAL: 19, ACTUAL: 19, },
  { AREA: 'Topside', MODULE: 'WM1UT'  , TITLE: 'Cutting Commenced', TOTAL: 26, ACTUAL: 26, },
  { AREA: 'Topside', MODULE: 'WM1UT'  , TITLE: 'Fab. Commenced', TOTAL: 26, ACTUAL: 26, },
  { AREA: 'Topside', MODULE: 'WM1UT'  , TITLE: 'Assembly 100%', TOTAL: 26, ACTUAL: 26, },
  { AREA: 'Topside', MODULE: 'WM1UT'  , TITLE: 'Pre-Outfitting 100%', TOTAL: 26, ACTUAL: 26, },
  { AREA: 'Topside', MODULE: 'WM1UT'  , TITLE: 'Block Painting 100%', TOTAL: 26, ACTUAL: 26, },
  { AREA: 'Topside', MODULE: 'WM1UT'  , TITLE: 'Erection', TOTAL: 17, ACTUAL: 17, },
]

export default BlockStatus