import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 960,
      CanvasHeight: 450,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
        {COMMODITY:"Support",PLAN_PROG:45.02,ACTUAL_CUM_PROG:0.35,DELTA:44.67,DISC:"13",DISC_DESCR:"Piping"},
        {COMMODITY:"Spool",PLAN_PROG:15.8,ACTUAL_CUM_PROG:0.08,DELTA:15.72,DISC:"13",DISC_DESCR:"Piping"},
        {COMMODITY:"Pressure Test",PLAN_PROG:0.85,ACTUAL_CUM_PROG:0,DELTA:0.85,DISC:"13",DISC_DESCR:"Piping"}

      ],

    Queries: {
      SQL1: [
        {WC_DESCRIPTION:"BLOCK PIPING SUPPORT FABRICATION",UOM:"KG",INITIALQTY:199796.93,TARGET_QTY:131567.4,TARGET_PROG:77.76,ACTUAL_CUM_QTY:105696,ACTUAL_CUM_PROG:63.56,ACTUAL_WEEK_QTY:448.6,ACTUAL_WEEK_PROG:0.24,REMAIN_QTY:25871.4,REMAIN_PROG:-14.2,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"BLOCK PIPING SUPPORT INSTALLATION",UOM:"KG",INITIALQTY:199796.93,TARGET_QTY:130015.5,TARGET_PROG:77.34,ACTUAL_CUM_QTY:100823.7,ACTUAL_CUM_PROG:61.27,ACTUAL_WEEK_QTY:1029.4,ACTUAL_WEEK_PROG:0.54,REMAIN_QTY:29191.8,REMAIN_PROG:-16.07,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT FABRICATION",UOM:"KG",INITIALQTY:570731.25,TARGET_QTY:520387.7999999999,TARGET_PROG:91.17,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:520387.7999999999,REMAIN_PROG:-91.17,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT INSTALLATION",UOM:"KG",INITIALQTY:574307.1499999999,TARGET_QTY:515129.8,TARGET_PROG:89.69,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:515129.8,REMAIN_PROG:-89.69,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"AREA PIPING FABRICATION",UOM:"DIA",INITIALQTY:167466.34,TARGET_QTY:90649.7,TARGET_PROG:54.22,ACTUAL_CUM_QTY:61524.9,ACTUAL_CUM_PROG:36.31,ACTUAL_WEEK_QTY:1676.7,ACTUAL_WEEK_PROG:1,REMAIN_QTY:29124.8,REMAIN_PROG:-17.91,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"AREA PIPING INSTALLATION",UOM:"DIA",INITIALQTY:85374.20999999999,TARGET_QTY:23857.9,TARGET_PROG:34.17,ACTUAL_CUM_QTY:9850.4,ACTUAL_CUM_PROG:13.57,ACTUAL_WEEK_QTY:1294.1,ACTUAL_WEEK_PROG:1.69,REMAIN_QTY:14007.5,REMAIN_PROG:-20.6,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"TOPSIDES PIPING FABRICATION",UOM:"DIA",INITIALQTY:298989.8,TARGET_QTY:239203.5,TARGET_PROG:80.6,ACTUAL_CUM_QTY:39.2,ACTUAL_CUM_PROG:0.01,ACTUAL_WEEK_QTY:39.2,ACTUAL_WEEK_PROG:0.01,REMAIN_QTY:239164.3,REMAIN_PROG:-80.59,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"TOPSIDES PIPING INSTALLATION",UOM:"DIA",INITIALQTY:112536.7,TARGET_QTY:60311.50000000001,TARGET_PROG:52.29,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:60311.50000000001,REMAIN_PROG:-52.29,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"PRESSURE TEST",UOM:"U",INITIALQTY:10890.2,TARGET_QTY:2753.799999999999,TARGET_PROG:24.21,ACTUAL_CUM_QTY:1.2,ACTUAL_CUM_PROG:0.03,ACTUAL_WEEK_QTY:0.2,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2752.5,REMAIN_PROG:-24.18,DISC:"13",COMMODITY:"Pressure Test"}
      ],
      SQL2: [
        {DISC:"13",DISC_DESCR:"Piping",PLAN_PROG:19.19,ACTUAL_CUM_PROG:0.11,DELTA:19.08}
      ]
    }
}