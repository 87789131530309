import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1542,
    CanvasHeight: 4600,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#f5f5f5',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 310,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [
      { text: 'Module', x: 118, y: 50, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 40, y: 50, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
        x: 40,
        y: 67,
        font: 'roboto',
        style: 'regular',
        size: 14,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  // -----------------------------------------------------------

  DataItems: [
    {NO:1,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.3,PLAN_SD:"2019-04-11",PLAN_FD:"2020-05-16",PLAN_PROG:99.15,ACTUAL_SD:"2020-03-01",ACTUAL_PROG:7.87,CUTOFF:"2020-05-08"},
    {NO:2,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:1.3,PLAN_SD:"2020-04-26",PLAN_FD:"2020-12-27",PLAN_PROG:0.47,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:3,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:1.3,PLAN_SD:"2020-07-07",PLAN_FD:"2021-05-04",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:4,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:1.3,PLAN_SD:"2020-08-25",PLAN_FD:"2021-02-28",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:5,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:1.3,PLAN_SD:"2021-01-25",PLAN_FD:"2021-04-18",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:6,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.38,PLAN_SD:"2020-02-27",PLAN_FD:"2020-06-03",PLAN_PROG:94.36,ACTUAL_SD:"2020-02-27",ACTUAL_PROG:10.55,CUTOFF:"2020-05-08"},
    {NO:7,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:1.38,PLAN_SD:"2020-05-11",PLAN_FD:"2021-01-20",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:8,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:1.38,PLAN_SD:"2020-07-26",PLAN_FD:"2021-05-24",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:9,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:1.38,PLAN_SD:"2020-09-27",PLAN_FD:"2021-04-16",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:10,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:1.38,PLAN_SD:"2021-02-22",PLAN_FD:"2021-05-02",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:11,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:2.53,PLAN_SD:"2020-03-03",PLAN_FD:"2020-07-16",PLAN_PROG:47.8,ACTUAL_SD:"2020-03-03",ACTUAL_PROG:17.12,CUTOFF:"2020-05-08"},
    {NO:12,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:2.53,PLAN_SD:"2020-04-23",PLAN_FD:"2020-11-20",PLAN_PROG:0.6,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:13,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:2.53,PLAN_SD:"2020-06-19",PLAN_FD:"2021-02-01",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:14,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:2.53,PLAN_SD:"2020-07-08",PLAN_FD:"2021-01-12",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:15,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:2.53,PLAN_SD:"2021-01-20",PLAN_FD:"2021-03-14",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:16,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.76,PLAN_SD:"2019-12-16",PLAN_FD:"2020-04-08",PLAN_PROG:100,ACTUAL_SD:"2019-12-16",ACTUAL_PROG:81.77,CUTOFF:"2020-05-08"},
    {NO:17,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:1.76,PLAN_SD:"2020-03-29",PLAN_FD:"2020-10-21",PLAN_PROG:2.6,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:18,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"36110",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.76,PLAN_SD:"2020-04-05",PLAN_FD:"2020-07-19",PLAN_PROG:13.87,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:19,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:1.76,PLAN_SD:"2020-06-09",PLAN_FD:"2020-12-26",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:20,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:1.76,PLAN_SD:"2020-10-04",PLAN_FD:"2020-11-25",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:21,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:1.76,PLAN_SD:"2020-11-29",PLAN_FD:"2020-12-12",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:22,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.44,PLAN_SD:"2020-02-25",PLAN_FD:"2020-06-15",PLAN_PROG:87.31,ACTUAL_SD:"2020-02-25",ACTUAL_PROG:7.87,CUTOFF:"2020-05-08"},
    {NO:23,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:1.44,PLAN_SD:"2020-05-09",PLAN_FD:"2020-12-28",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:24,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:1.44,PLAN_SD:"2020-07-07",PLAN_FD:"2021-05-25",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:25,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:1.44,PLAN_SD:"2020-09-28",PLAN_FD:"2021-03-06",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:26,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:1.44,PLAN_SD:"2021-01-27",PLAN_FD:"2021-04-25",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:27,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.4,PLAN_SD:"2020-02-27",PLAN_FD:"2020-05-27",PLAN_PROG:97.04,ACTUAL_SD:"2020-02-27",ACTUAL_PROG:9.28,CUTOFF:"2020-05-08"},
    {NO:28,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:1.4,PLAN_SD:"2020-05-11",PLAN_FD:"2021-01-27",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:29,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:1.4,PLAN_SD:"2020-06-26",PLAN_FD:"2021-06-05",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:30,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:1.4,PLAN_SD:"2020-09-13",PLAN_FD:"2021-03-26",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:31,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:1.4,PLAN_SD:"2021-01-31",PLAN_FD:"2021-04-29",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:32,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:1.74,PLAN_SD:"2020-03-10",PLAN_FD:"2020-07-06",PLAN_PROG:51.25,ACTUAL_SD:"2020-03-10",ACTUAL_PROG:11.25,CUTOFF:"2020-05-08"},
    {NO:33,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:1.74,PLAN_SD:"2020-05-09",PLAN_FD:"2020-12-03",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:34,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:1.74,PLAN_SD:"2020-06-19",PLAN_FD:"2021-02-01",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:35,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:1.74,PLAN_SD:"2020-07-19",PLAN_FD:"2021-01-12",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:36,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:1.74,PLAN_SD:"2021-01-15",PLAN_FD:"2021-03-14",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:37,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"36210",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:2.19,PLAN_SD:"2020-01-15",PLAN_FD:"2020-07-16",PLAN_PROG:81.76,ACTUAL_SD:"2020-01-15",ACTUAL_PROG:16.28,CUTOFF:"2020-05-08"},
    {NO:38,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"36220",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:2.19,PLAN_SD:"2020-04-13",PLAN_FD:"2021-01-22",PLAN_PROG:0.73,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:39,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:2.19,PLAN_SD:"2020-04-20",PLAN_FD:"2021-03-18",PLAN_PROG:0.48,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:40,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:2.19,PLAN_SD:"2020-07-03",PLAN_FD:"2021-02-01",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:41,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:2.19,PLAN_SD:"2021-01-20",PLAN_FD:"2021-03-19",PLAN_PROG:0,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:42,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"AFT Ship",WCCODE:"36110",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:53.61,PLAN_SD:"2019-11-15",PLAN_FD:"2020-06-07",PLAN_PROG:96.89,ACTUAL_SD:"2019-11-15",ACTUAL_PROG:95.37,CUTOFF:"2020-05-08"},
    {NO:43,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"AFT Ship",WCCODE:"36120",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:53.61,PLAN_SD:"2019-10-27",PLAN_FD:"2020-09-02",PLAN_PROG:50,ACTUAL_SD:"2019-10-27",ACTUAL_PROG:41.45,CUTOFF:"2020-05-08"},
    {NO:44,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"AFT Ship",WCCODE:"36130",WCDESCRIPTION:"Block Erection",CON_PROG:53.61,PLAN_SD:"2020-01-01",PLAN_FD:"2020-11-10",PLAN_PROG:45.45,ACTUAL_SD:"2020-01-01",ACTUAL_PROG:39.42,CUTOFF:"2020-05-08"},
    {NO:45,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"AFT Ship",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:53.61,PLAN_SD:"2019-10-25",PLAN_FD:"2020-09-09",PLAN_PROG:60.07,ACTUAL_SD:"2019-10-25",ACTUAL_PROG:59.03,CUTOFF:"2020-05-08"},
    {NO:46,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"AFT Ship",WCCODE:"13320",WCDESCRIPTION:"Piping Installation",CON_PROG:53.61,PLAN_SD:"2020-01-08",PLAN_FD:"2021-01-18",PLAN_PROG:16.66,ACTUAL_SD:"2020-01-08",ACTUAL_PROG:1.36,CUTOFF:"2020-05-08"},
    {NO:47,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"AFT Ship",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:53.61,PLAN_SD:"2020-03-10",PLAN_FD:"2020-11-04",PLAN_PROG:22.08,ACTUAL_SD:"2020-03-10",ACTUAL_PROG:1.22,CUTOFF:"2020-05-08"},
    {NO:48,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"FOR Ship",WCCODE:"36110",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:45.42,PLAN_SD:"2019-11-08",PLAN_FD:"2020-06-10",PLAN_PROG:99.59,ACTUAL_SD:"2019-11-08",ACTUAL_PROG:98.31,CUTOFF:"2020-05-08"},
    {NO:49,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"FOR Ship",WCCODE:"36120",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:45.42,PLAN_SD:"2019-10-25",PLAN_FD:"2020-08-12",PLAN_PROG:74,ACTUAL_SD:"2019-10-25",ACTUAL_PROG:50.76,CUTOFF:"2020-05-08"},
    {NO:50,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"FOR Ship",WCCODE:"36130",WCDESCRIPTION:"Block Erection",CON_PROG:45.42,PLAN_SD:"2019-12-16",PLAN_FD:"2020-10-02",PLAN_PROG:62.94,ACTUAL_SD:"2019-12-16",ACTUAL_PROG:47.35,CUTOFF:"2020-05-08"},
    {NO:51,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"FOR Ship",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:45.42,PLAN_SD:"2019-10-25",PLAN_FD:"2020-08-19",PLAN_PROG:78.64,ACTUAL_SD:"2019-10-25",ACTUAL_PROG:52.42,CUTOFF:"2020-05-08"},
    {NO:52,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"FOR Ship",WCCODE:"13320",WCDESCRIPTION:"Piping Installation",CON_PROG:45.42,PLAN_SD:"2020-01-13",PLAN_FD:"2021-02-04",PLAN_PROG:14.71,ACTUAL_SD:"2020-01-13",ACTUAL_PROG:0.91,CUTOFF:"2020-05-08"},
    {NO:53,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"FOR Ship",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:45.42,PLAN_SD:"2020-02-10",PLAN_FD:"2020-11-20",PLAN_PROG:8.38,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:54,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"Living Quartors",WCCODE:"36110",WCDESCRIPTION:"Block Pre(T)/Fabrication(H)",CON_PROG:14.23,PLAN_SD:"2019-11-02",PLAN_FD:"2020-07-05",PLAN_PROG:95.98,ACTUAL_SD:"2019-11-02",ACTUAL_PROG:95.23,CUTOFF:"2020-05-08"},
    {NO:55,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"Living Quartors",WCCODE:"36120",WCDESCRIPTION:"Block Assembly(H)/Erection(T)",CON_PROG:14.23,PLAN_SD:"2019-11-28",PLAN_FD:"2020-11-19",PLAN_PROG:97.81,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:56,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"Living Quartors",WCCODE:"36130",WCDESCRIPTION:"Block Erection",CON_PROG:14.23,PLAN_SD:"2020-01-11",PLAN_FD:"2020-11-29",PLAN_PROG:63.03,ACTUAL_SD:"2020-01-11",ACTUAL_PROG:84.96,CUTOFF:"2020-05-08"},
    {NO:57,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"Living Quartors",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:14.23,PLAN_SD:"2019-10-25",PLAN_FD:"2020-08-20",PLAN_PROG:96.37,ACTUAL_SD:"2019-10-25",ACTUAL_PROG:89.47,CUTOFF:"2020-05-08"},
    {NO:58,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"Living Quartors",WCCODE:"13320",WCDESCRIPTION:"Piping Installation",CON_PROG:14.23,PLAN_SD:"2020-02-20",PLAN_FD:"2021-01-17",PLAN_PROG:0.06,ACTUAL_PROG:0,CUTOFF:"2020-05-08"},
    {NO:59,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"Living Quartors",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:14.23,PLAN_SD:"2020-04-23",PLAN_FD:"2020-09-25",PLAN_PROG:9.46,ACTUAL_PROG:0,CUTOFF:"2020-05-08"}
  ],
}