
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1200,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  [],
  },
  
  DataItems: [
    { no: 1,   system: 'IPQ-953',  type: 'KEY_MILESTONE', name: 'PP Substation Energized', },
    { no: 2,   system: '600',      type: 'BUILDING',      name: 'Substation 60SS07', },
    { no: 3,   system: 'EQ-801',   type: 'KEY_MILESTONE', name: 'PP RIE Commd', },
    { no: 4,   system: '601',      type: 'BUILDING',      name: 'RIE', },
    { no: 5,   system: '738',      type: 'UTILITY',       name: 'Distributed Contro1 System (DCS)', },
    { no: 6,   system: '739',      type: 'UTILITY',       name: 'SIS/BMS/HIPPS/TMC', },
    { no: 7,   system: 'KM-P2-19', type: 'KEY_MILESTONE', name: 'PP2:IA & CW comm.', },
    { no: 8,   system: 'LJN-188',  type: 'TEMP',          name: 'TEMP - Hoses/ JO for IA', },
    { no: 9,   system: 'IK-107',   type: 'TEMP',          name: 'TEMP - Hoses/ JO for CW flush', },
    { no: 10,  system: 'FQ-46',    type: 'INTERFACES',    name: 'QY WWTP', },
    { no: 11,  system: 'TEMP1',    type: 'TEMP',          name: 'TEMP - Potable Water', },
    { no: 12,  system: '404',      type: 'UTILITY',       name: 'Instrument Air System ', },
    { no: 13,  system: '405',      type: 'UTILITY',       name: 'Cooling Water System', },
    { no: 14,  system: '436',      type: 'UTILITY',       name: 'Storm Water Pond', },
    { no: 15,  system: '420',      type: 'UTILITY',       name: 'Clean Waste Water', },
    { no: 16,  system: '342',      type: 'PROCESS',       name: 'Process waste water', },
    { no: 17,  system: '606',      type: 'BUILDING',      name: 'Operator Shelter', },
    { no: 18,  system: 'ZEC-340',  type: 'KEY_MILESTONE', name: 'Utilities in Service', },
    { no: 19,  system: '508',      type: 'UTILITY',       name: 'LP Steam', },
    { no: 20,  system: '506',      type: 'UTILITY',       name: 'HP Steam ', },
    { no: 21,  system: '412',      type: 'UTILITY',       name: 'Firewater System', },
    { no: 22,  system: '406',      type: 'UTILITY',       name: 'Industrial Water System', },
    { no: 23,  system: 'KM-P2-28', type: 'KEY_MILESTONE', name: 'PP2: Extruder DA/DS Motor Solo Run comp.', },
    { no: 24,  system: 'ACX-682',  type: 'PROCESS',       name: 'DS/DA LO System', },
    { no: 25,  system: 'FT-165',   type: 'BUILDING',      name: 'Finishing Building Ready', },
    { no: 26,  system: 'KBJ-883',  type: 'INTERFACES',    name: 'Vent from GPPE', },
    { no: 27,  system: 'ZDT-035',  type: 'INTERFACES',    name: 'P3 NG Avail', },
    { no: 28,  system: 'MUR-657',  type: 'KEY_MILESTONE', name: 'RTO RFU', },
    { no: 29,  system: '428',      type: 'UTILITY',       name: 'Natural Gas Import', },
    { no: 30,  system: 'WTP-392',  type: 'PROCESS',       name: 'RTO Bed Load', },
    { no: 31,  system: '345',      type: 'PROCESS',       name: 'PP Vent to Therm. Oxid.', },
    { no: 32,  system: '403',      type: 'UTILITY',       name: 'Plant Air System', },
    { no: 33,  system: 'YR-069',   type: 'KEY_MILESTONE', name: 'Dryer Loading Completed', },
    { no: 34,  system: 'L-037',    type: 'TEMP',          name: 'Loading Contractor', },
    { no: 35,  system: '504',      type: 'UTILITY',       name: 'Safety Shower & Eyewash', },
    { no: 36,  system: '424',      type: 'UTILITY',       name: 'Potable Water', },
    { no: 37,  system: 'QR-804',   type: 'INTERFACES',    name: ' ', },
    { no: 38,  system: 'ZW-525',   type: 'KEY_MILESTONE', name: 'N2 Introduction', },
    { no: 39,  system: 'CFT-175',  type: 'KEY_MILESTONE', name: 'PP2: Granules Conveying Test', },
    { no: 40,  system: '315',      type: 'PROCESS',       name: 'PP Granules and Conveying', },
    { no: 41,  system: 'EAS-855',  type: 'PROCESS',       name: 'tt', },
    { no: 42,  system: '407-1',    type: 'UTILITY',       name: 'LP Nitrogen Import / Distr.', },
    { no: 43,  system: 'FH-805',   type: 'TEMP',          name: 'TEMP: Facilities to transfer granules', },
    { no: 44,  system: 'TYT-976',  type: 'KEY_MILESTONE', name: 'Dryer Regen ', },
    { no: 45,  system: '435-1',    type: 'UTILITY',       name: 'LP Flare (Elevated Flare)', },
    { no: 46,  system: '407',      type: 'PROCESS',       name: 'LP N2 Import / Distribution', },
    { no: 47,  system: '508-2',    type: 'UTILITY',       name: 'LP Steam', },
    { no: 48,  system: 'SXD-606',  type: 'KEY_MILESTONE', name: 'PCW Test ', },
    { no: 49,  system: '335-1',    type: 'PROCESS',       name: 'PP Extruder - Pelletizer', },
    { no: 50,  system: '336',      type: 'PROCESS',       name: 'Pellet water & Drying system', },
    { no: 51,  system: '513',      type: 'UTILITY',       name: 'Demin Water', },
    { no: 52,  system: '336-2',    type: 'PROCESS',       name: 'Pellet Water Coolrt ', },
    { no: 53,  system: '336-1',    type: 'PROCESS',       name: 'Pellet water & Drying system', },
    { no: 54,  system: '183-1',    type: 'UTILITY',       name: 'LP Steam', },
    { no: 55,  system: 'EZR-576',  type: 'KEY_MILESTONE', name: 'H2 Compressor Run-in w. Air/N2', },
    { no: 56,  system: '143-1',    type: 'PROCESS',       name: 'Hydrogen', },
    { no: 57,  system: '435-2',    type: 'UTILITY',       name: 'LP Flare (Elevated Flare)', },
    { no: 58,  system: '143',      type: 'INTERFACES',    name: 'H2 to GPPE', },
    { no: 59,  system: 'KM-P2-27', type: 'KEY_MILESTONE', name: 'PP2:Pellet Convey Test to ', },
    { no: 60,  system: '337',      type: 'PROCESS',       name: 'PP Pellet Conveying', },
    { no: 61,  system: 'NII-175',  type: 'PROCESS',       name: 'Extruder to PP2 Blender', },
    { no: 62,  system: 'H-453',    type: 'PROCESS',       name: 'PP2 Blender to Elutriator', },
    { no: 63,  system: 'X-717',    type: 'PROCESS',       name: 'Elutriator to Silo', },
    { no: 64,  system: '338',      type: 'PROCESS',       name: 'Pellet transfer vent', },
    { no: 65,  system: 'MG-688',   type: 'TEMP',          name: 'Pellet Transfer', },
    { no: 66,  system: 'I-097',    type: 'KEY_MILESTONE', name: 'C3 Recycle Comp Run-in w. Air/N2', },
    { no: 67,  system: '311-1',    type: 'PROCESS',       name: 'Recovered Propylene', },
    { no: 68,  system: '308',      type: 'PROCESS',       name: 'Mineral Oil', },
    { no: 69,  system: 'PPD-464',  type: 'KEY_MILESTONE', name: 'Refrigeration Comp Run-in', },
    { no: 70,  system: '344',      type: 'UTILITY',       name: 'Chilled water', },
    { no: 71,  system: 'DSX-395',  type: 'KEY_MILESTONE', name: 'Additive Unloading', },
    { no: 72,  system: '332',      type: 'PROCESS',       name: 'Add. unload station vent', },
    { no: 73,  system: '333',      type: 'PROCESS',       name: 'Additives Conveying Air to Vacuum system', },
    { no: 74,  system: 'PZD-542',  type: 'INTERFACES',    name: 'C3 Return to Olenfins (P1)', },
    { no: 75,  system: 'AMR-352',  type: 'KEY_MILESTONE', name: 'Reactor System (+Blowdown)RFSU', },
    { no: 76,  system: '302',      type: 'PROCESS',       name: 'PP2_Ethylene Comp Run -in', },
    { no: 77,  system: '311',      type: 'PROCESS',       name: 'Recovered Propylene (Feed)', },
    { no: 78,  system: '312',      type: 'PROCESS',       name: 'Pre-polymer & Poly slurry', },
    { no: 79,  system: 'IQ-460',   type: 'TEMP',          name: 'HP N2 (leak test) temp line', },
    { no: 80,  system: '340',      type: 'PROCESS',       name: 'CO Kill', },
    { no: 81,  system: '343',      type: 'UTILITY',       name: 'Closed Circuit CW', },
    { no: 82,  system: 'E-332',    type: 'INTERFACES',    name: 'Chemicals for jacket CW', },
    { no: 83,  system: '408-2',    type: 'UTILITY',       name: 'HP Flare (Ground)', },
    { no: 84,  system: '435-3',    type: 'UTILITY',       name: 'LP Flare (Elevated Flare)', },
    { no: 85,  system: '407-2',    type: 'UTILITY',       name: 'LP Nitrogen Import / ', },
    { no: 86,  system: 'KM-P2-26', type: 'KEY_MILESTONE', name: 'PP2: Add. Conveying & Feeding to Extr.', },
    { no: 87,  system: '324',      type: 'PROCESS',       name: 'PP1_PILL 5/6/7', },
    { no: 88,  system: '325',      type: 'PROCESS',       name: 'PP1_PILL 2', },
    { no: 89,  system: '326',      type: 'PROCESS',       name: 'PP1_Additive 13', },
    { no: 90,  system: '329',      type: 'PROCESS',       name: 'PP1_Additive 6', },
    { no: 91,  system: '328',      type: 'PROCESS',       name: 'PP1_TALC', },
    { no: 92,  system: '317',      type: 'PROCESS',       name: 'PP1/2_Additive 4', },
    { no: 93,  system: '320',      type: 'PROCESS',       name: 'PP2_Additive 9', },
    { no: 94,  system: '334-2',    type: 'PROCESS',       name: 'PP2_Peroxide', },
    { no: 95,  system: '407-3',    type: 'UTILITY',       name: 'LP N2 Import / Distr.', },
    { no: 96,  system: 'DM-535',   type: 'BUILDING',      name: 'Additives Warehouse', },
    { no: 97,  system: '321',      type: 'PROCESS',       name: 'PP1_Additive 11', },
    { no: 98,  system: '322',      type: 'PROCESS',       name: 'PP1_Additive 12', },
    { no: 99,  system: '330',      type: 'PROCESS',       name: 'PP1_Additive 7', },
    { no: 100, system: 'XXX-1',    type: 'PROCESS',       name: 'PP1/2_Additive 8', },
    { no: 101, system: '318',      type: 'PROCESS',       name: 'PP1/2_Additive 5', },
    { no: 102, system: 'XXX-2',    type: 'PROCESS',       name: 'PP2_PILL 4', },
    { no: 103, system: '323',      type: 'PROCESS',       name: 'PP2_Additive 3', },
    { no: 104, system: '334-1',    type: 'PROCESS',       name: 'PP1_Peroxide', },
    { no: 105, system: 'XXX-3',    type: 'PROCESS',       name: 'feed', },
    { no: 106, system: 'X-892',    type: 'KEY_MILESTONE', name: 'Catalyst Systems RFSU', },
    { no: 107, system: '305',      type: 'PROCESS',       name: 'ATMER', },
    { no: 108, system: '306',      type: 'PROCESS',       name: 'Donor', },
    { no: 109, system: '304',      type: 'PROCESS',       name: 'PP Ziegler- Natta Catal.', },
    { no: 110, system: '309',      type: 'PROCESS',       name: 'Grease Mixture', },
    { no: 111, system: '348',      type: 'PROCESS',       name: 'Exhaust Oil and Spent Oil', },
    { no: 112, system: '348-1',    type: 'PROCESS',       name: 'Exhaust Oil & Spent Oil', },
    { no: 113, system: '307',      type: 'PROCESS',       name: 'PP TEAL', },
    { no: 114, system: '310',      type: 'PROCESS',       name: 'PP LPPN', },
    { no: 115, system: '280',      type: 'INTERFACES',    name: 'GPPE LPPN', },
    { no: 116, system: 'KPB-214',  type: 'INTERFACES',    name: 'TEAL to GPPE', },
    { no: 117, system: 'GNB-228',  type: 'KEY_MILESTONE', name: 'PP2: Extruder Test Run Completed', },
    { no: 118, system: 'RAW-771',  type: 'PROCESS',       name: 'Barrel Pump (PP1 Only)', },
    { no: 119, system: '188',      type: 'UTILITY',       name: 'Demin Water', },
    { no: 120, system: '181',      type: 'UTILITY',       name: 'HP Steam ', },
    { no: 121, system: '518',      type: 'UTILITY',       name: 'LPS Condensate', },
    { no: 122, system: 'GSS-428',  type: 'UTILITY',       name: 'Molten Poly Collection Sump', },
    { no: 123, system: 'RTH-848',  type: 'TEMP',          name: 'Extruder Melt Clean ', },
    { no: 124, system: '335',      type: 'PROCESS',       name: 'PP Extruder  system', },
    { no: 125, system: '335-2',    type: 'PROCESS',       name: 'PP Extruder  system - Hot Oil', },
    { no: 126, system: '335-3',    type: 'PROCESS',       name: 'PP Extruder  system - Hyd Oil', },
    { no: 127, system: '335-4',    type: 'PROCESS',       name: 'PP Extruder  system - fd convey', },
    { no: 128, system: 'VN-464',   type: 'TEMP',          name: 'Change Barrel Pump', },
    { no: 129, system: 'OCJ-508',  type: 'KEY_MILESTONE', name: 'Propylene circulation', },
    { no: 130, system: '315-1',    type: 'PROCESS',       name: 'PP Granules and Conveying (STRUCTURE)', },
    { no: 131, system: 'B-814',    type: 'KEY_MILESTONE', name: 'PP1_Steamer Offgas Comp Run-In', },
    { no: 132, system: '316',      type: 'PROCESS',       name: 'Steamer off gas', },
    { no: 133, system: 'YJH-319',  type: 'KEY_MILESTONE', name: 'PP1: Gas Phase Reactors', },
    { no: 134, system: '313',      type: 'PROCESS',       name: 'Gas Phase Reactors', },
    { no: 135, system: '314',      type: 'PROCESS',       name: 'Recovered Monomer (Ethylene)', },
    { no: 136, system: '341',      type: 'PROCESS',       name: 'Nitrogen / Air mia', },
    { no: 137, system: 'VT-040',   type: 'KEY_MILESTONE', name: 'PP1: Extruder Test Run Completed', },
    { no: 138, system: 'KM-P2-31', type: 'KEY_MILESTONE', name: 'PP2: Start up', },
    { no: 139, system: 'xxx',      type: 'KEY_MILESTONE', name: 'pp1: Start up', },

  ]
}