
export default {
  Canvas: {
    CanvasWidth: 1500,
    CanvasHeight: 1050,
    CanvasBorderWeight: 0.01,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  Queries: {
    SQL1: [
      {LV1:null      ,LV2:null           ,LV3:null     ,LV4: null,       ID:null       ,NAME:'Hebron UPM', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 373,FABSTART_ACTUAL: 373, FABRICATION_TOTAL: 273,FABRICATION_ACTUAL: 273, PAINTING_TOTAL: 173,PAINTING_ACTUAL: 173, ASSEMBLY_TOTAL: 148,ASSEMBLY_ACTUAL: 148, ERECTION_TOTAL: 148,ERECTION_ACTUAL: 148,},
      {LV1:"TOPSIDES",LV2:"UPPERDECK"    ,LV3:null     ,LV4: null,       ID:'S2L1_UD'  ,NAME:'Upper Deck', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 37,FABSTART_ACTUAL: 37, FABRICATION_TOTAL: 37,FABRICATION_ACTUAL: 37, PAINTING_TOTAL: 37,PAINTING_ACTUAL: 37, ASSEMBLY_TOTAL: 37,ASSEMBLY_ACTUAL: 37, ERECTION_TOTAL: 37,ERECTION_ACTUAL: 37,},
      {LV1:"TOPSIDES",LV2:"MAINDECK"     ,LV3:null     ,LV4: null,       ID:'S2L2_MD'  ,NAME:'Main Deck',  PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 30,FABSTART_ACTUAL: 30, FABRICATION_TOTAL: 30,FABRICATION_ACTUAL: 30, PAINTING_TOTAL: 30,PAINTING_ACTUAL: 30, ASSEMBLY_TOTAL: 30,ASSEMBLY_ACTUAL: 30, ERECTION_TOTAL: 30,ERECTION_ACTUAL: 30,},
      {LV1:"TOPSIDES",LV2:"LOWERDECK"    ,LV3:null     ,LV4: null,       ID:'S2R1_LD'  ,NAME:'Lower Deck', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 35,FABSTART_ACTUAL: 35, FABRICATION_TOTAL: 35,FABRICATION_ACTUAL: 35, PAINTING_TOTAL: 35,PAINTING_ACTUAL: 35, ASSEMBLY_TOTAL: 35,ASSEMBLY_ACTUAL: 35, ERECTION_TOTAL: 35,ERECTION_ACTUAL: 35,},
      {LV1:"TOPSIDES",LV2:"CELLARDECK"   ,LV3:null     ,LV4: null,       ID:'S2R2_CD'  ,NAME:'Cellar Deck',PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 29,FABSTART_ACTUAL: 29, FABRICATION_TOTAL: 29,FABRICATION_ACTUAL: 29, PAINTING_TOTAL: 29,PAINTING_ACTUAL: 29, ASSEMBLY_TOTAL: 29,ASSEMBLY_ACTUAL: 29, ERECTION_TOTAL: 29,ERECTION_ACTUAL: 29,},
      {LV1:"TOPSIDES",LV2:"UPPERDECK_UD" ,LV3:'S2L1_UD',LV4: null       ,ID:'S2L1_UD'  ,NAME:'Upper Mezz', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 23,FABSTART_ACTUAL: 23, FABRICATION_TOTAL: 23,FABRICATION_ACTUAL: 23, PAINTING_TOTAL: 23,PAINTING_ACTUAL: 23, ASSEMBLY_TOTAL: 23,ASSEMBLY_ACTUAL: 23, ERECTION_TOTAL: 23,ERECTION_ACTUAL: 23,},
      {LV1:"TOPSIDES",LV2:"UPPERDECK_UD" ,LV3:'S2L1_UD',LV4: null       ,ID:'S2L1_UD'  ,NAME:'Upper Deck', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 14,FABSTART_ACTUAL: 14, FABRICATION_TOTAL: 14,FABRICATION_ACTUAL: 14, PAINTING_TOTAL: 14,PAINTING_ACTUAL: 14, ASSEMBLY_TOTAL: 14,ASSEMBLY_ACTUAL: 14, ERECTION_TOTAL: 14,ERECTION_ACTUAL: 14,},
      {LV1:"TOPSIDES",LV2:"MAINDECK_MD"  ,LV3:'S2L2_MD',LV4: null       ,ID:'S2L2_MD'  ,NAME:'Main Mezz',  PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 18,FABSTART_ACTUAL: 18, FABRICATION_TOTAL: 18,FABRICATION_ACTUAL: 18, PAINTING_TOTAL: 18,PAINTING_ACTUAL: 18, ASSEMBLY_TOTAL: 18,ASSEMBLY_ACTUAL: 18, ERECTION_TOTAL: 18,ERECTION_ACTUAL: 18,},
      {LV1:"TOPSIDES",LV2:"MAINDECK_MD"  ,LV3:'S2L2_MD',LV4: null       ,ID:'S2L2_MD'  ,NAME:'Main Deck',  PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 12,FABSTART_ACTUAL: 12, FABRICATION_TOTAL: 12,FABRICATION_ACTUAL: 12, PAINTING_TOTAL: 12,PAINTING_ACTUAL: 12, ASSEMBLY_TOTAL: 12,ASSEMBLY_ACTUAL: 12, ERECTION_TOTAL: 12,ERECTION_ACTUAL: 12,},
      {LV1:"TOPSIDES",LV2:"LOWERDECK_LD" ,LV3:'S2R1_LD',LV4: null       ,ID:'S2R1_LD'  ,NAME:'Lower Mezz', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 20,FABSTART_ACTUAL: 20, FABRICATION_TOTAL: 20,FABRICATION_ACTUAL: 20, PAINTING_TOTAL: 20,PAINTING_ACTUAL: 20, ASSEMBLY_TOTAL: 20,ASSEMBLY_ACTUAL: 20, ERECTION_TOTAL: 20,ERECTION_ACTUAL: 20,},
      {LV1:"TOPSIDES",LV2:"LOWERDECK_LD" ,LV3:'S2R1_LD',LV4: null       ,ID:'S2R1_LD'  ,NAME:'Lower Deck', PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 15,FABSTART_ACTUAL: 15, FABRICATION_TOTAL: 15,FABRICATION_ACTUAL: 15, PAINTING_TOTAL: 15,PAINTING_ACTUAL: 15, ASSEMBLY_TOTAL: 15,ASSEMBLY_ACTUAL: 15, ERECTION_TOTAL: 15,ERECTION_ACTUAL: 15,},
      {LV1:"TOPSIDES",LV2:"CELLARDECK_CD",LV3:'S2R2_CD',LV4: null       ,ID:'S2R2_CD'  ,NAME:'Cellar Mezz',PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 16,FABSTART_ACTUAL: 16, FABRICATION_TOTAL: 16,FABRICATION_ACTUAL: 16, PAINTING_TOTAL: 16,PAINTING_ACTUAL: 16, ASSEMBLY_TOTAL: 16,ASSEMBLY_ACTUAL: 16, ERECTION_TOTAL: 16,ERECTION_ACTUAL: 16,},
      {LV1:"TOPSIDES",LV2:"CELLARDECK_CD",LV3:'S2R2_CD',LV4: null       ,ID:'S2R2_CD'  ,NAME:'Cellar Deck',PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 13,FABSTART_ACTUAL: 13, FABRICATION_TOTAL: 13,FABRICATION_ACTUAL: 13, PAINTING_TOTAL: 13,PAINTING_ACTUAL: 13, ASSEMBLY_TOTAL: 13,ASSEMBLY_ACTUAL: 13, ERECTION_TOTAL: 13,ERECTION_ACTUAL: 13,},
      {LV1:"TOPSIDES",LV2:"UPPERDECK_UD" ,LV3:'S2L1_UD',LV4: 'S2L1_UD_M',ID:'S2L1_UD_M',NAME:'Upper Mezz' ,PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 23,FABSTART_ACTUAL: 23, FABRICATION_TOTAL: 23,FABRICATION_ACTUAL: 23, PAINTING_TOTAL: 23,PAINTING_ACTUAL: 23, ASSEMBLY_TOTAL: 23,ASSEMBLY_ACTUAL: 23, ERECTION_TOTAL: 23,ERECTION_ACTUAL: 23,},
      {LV1:"TOPSIDES",LV2:"UPPERDECK_UD" ,LV3:'S2L1_UD',LV4: 'S2L1_UD_D',ID:'S2L1_UD_D',NAME:'Upper Deck' ,PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 14,FABSTART_ACTUAL: 14, FABRICATION_TOTAL: 14,FABRICATION_ACTUAL: 14, PAINTING_TOTAL: 14,PAINTING_ACTUAL: 14, ASSEMBLY_TOTAL: 14,ASSEMBLY_ACTUAL: 14, ERECTION_TOTAL: 14,ERECTION_ACTUAL: 14,},
      {LV1:"TOPSIDES",LV2:"MAINDECK_MD"  ,LV3:'S2L2_MD',LV4: 'S2L2_MD_M',ID:'S2L2_MD_M',NAME:'Main Mezz'  ,PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 18,FABSTART_ACTUAL: 18, FABRICATION_TOTAL: 18,FABRICATION_ACTUAL: 18, PAINTING_TOTAL: 18,PAINTING_ACTUAL: 18, ASSEMBLY_TOTAL: 18,ASSEMBLY_ACTUAL: 18, ERECTION_TOTAL: 18,ERECTION_ACTUAL: 18,},
      {LV1:"TOPSIDES",LV2:"MAINDECK_MD"  ,LV3:'S2L2_MD',LV4: 'S2L2_MD_D',ID:'S2L2_MD_D',NAME:'Main Deck'  ,PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 12,FABSTART_ACTUAL: 12, FABRICATION_TOTAL: 12,FABRICATION_ACTUAL: 12, PAINTING_TOTAL: 12,PAINTING_ACTUAL: 12, ASSEMBLY_TOTAL: 12,ASSEMBLY_ACTUAL: 12, ERECTION_TOTAL: 12,ERECTION_ACTUAL: 12,},
      {LV1:"TOPSIDES",LV2:"LOWERDECK_LD" ,LV3:'S2R1_LD',LV4: 'S2R1_LD_M',ID:'S2R1_LD_M',NAME:'Lower Mezz' ,PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 20,FABSTART_ACTUAL: 20, FABRICATION_TOTAL: 20,FABRICATION_ACTUAL: 20, PAINTING_TOTAL: 20,PAINTING_ACTUAL: 20, ASSEMBLY_TOTAL: 20,ASSEMBLY_ACTUAL: 20, ERECTION_TOTAL: 20,ERECTION_ACTUAL: 20,},
      {LV1:"TOPSIDES",LV2:"LOWERDECK_LD" ,LV3:'S2R1_LD',LV4: 'S2R1_LD_D',ID:'S2R1_LD_D',NAME:'Lower Deck' ,PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 15,FABSTART_ACTUAL: 15, FABRICATION_TOTAL: 15,FABRICATION_ACTUAL: 15, PAINTING_TOTAL: 15,PAINTING_ACTUAL: 15, ASSEMBLY_TOTAL: 15,ASSEMBLY_ACTUAL: 15, ERECTION_TOTAL: 15,ERECTION_ACTUAL: 15,},
      {LV1:"TOPSIDES",LV2:"CELLARDECK_CD",LV3:'S2R2_CD',LV4: 'S2R2_CD_M',ID:'S2R2_CD_M',NAME:'Cellar Mezz',PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 16,FABSTART_ACTUAL: 16, FABRICATION_TOTAL: 16,FABRICATION_ACTUAL: 16, PAINTING_TOTAL: 16,PAINTING_ACTUAL: 16, ASSEMBLY_TOTAL: 16,ASSEMBLY_ACTUAL: 16, ERECTION_TOTAL: 16,ERECTION_ACTUAL: 16,},
      {LV1:"TOPSIDES",LV2:"CELLARDECK_CD",LV3:'S2R2_CD',LV4: 'S2R2_CD_D',ID:'S2R2_CD_D',NAME:'Cellar Deck',PLAN:100,ACTUAL:100,DELTA:0,FABSTART_TOTAL: 13,FABSTART_ACTUAL: 13, FABRICATION_TOTAL: 13,FABRICATION_ACTUAL: 13, PAINTING_TOTAL: 13,PAINTING_ACTUAL: 13, ASSEMBLY_TOTAL: 13,ASSEMBLY_ACTUAL: 13, ERECTION_TOTAL: 13,ERECTION_ACTUAL: 13,},
    ]
  },
  DataItems:[
    { ID: 'S2L1_UD_M_UP75', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP75', Stage: null, SHAPE: 'UNIT', STATUS: 'ASSEMBLY', EREC: null, },
    { ID: 'S2L1_UD_M_UP77', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP77', Stage: null, SHAPE: 'UNIT', STATUS: 'ASSEMBLY', EREC: null, },
    { ID: 'S2L1_UD_M_UP76', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP76', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UP78', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP78', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UP7A', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP7A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UP7C', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP7C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UP7B', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP7B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UP7D', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UP7D', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UV7C', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UV7C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MV84', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MV84', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UK13', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UK13', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD72', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD72', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD75', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD75', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD71', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD71', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD74', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD74', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD77', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD77', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD7A', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD7A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD7D', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD7D', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD76', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD76', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD79', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD79', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD7C', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD7C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD70', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD70', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD73', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD73', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD78', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD78', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_D_UD7B', Lv2: 'UPPERDECK', Lv3: 'DECK', BLOCK: 'UD7B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UK12', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UK12', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UV72', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UV72', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MZ82', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MZ82', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MZ85', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MZ85', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MZ81', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MZ81', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UV70', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UV70', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UD7E', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UD7E', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MZ84', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MZ84', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MZ83', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MZ83', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MV82', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MV82', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_UK11', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'UK11', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L1_UD_M_MV85', Lv2: 'UPPERDECK', Lv3: 'MEZZ', BLOCK: 'MV85', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP52', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP52', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP63', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP63', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP50', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP50', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP5A', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP5A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP57', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP57', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP56', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP56', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP55', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP55', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP58', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP58', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MP59', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MP59', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD52', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD52', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD54', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD54', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD57', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD57', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD5A', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD5A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD51', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD51', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD53', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD53', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD56', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD56', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD59', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD59', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD50', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD50', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD55', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD55', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD58', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD58', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_D_MD5B', Lv2: 'MAINDECK', Lv3: 'DECK', BLOCK: 'MD5B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MZ66', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MZ66', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MZ65', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MZ65', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MZ64', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MZ64', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MZ63', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MZ63', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MZ62', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MZ62', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MZ61', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MZ61', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MV53', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MV53', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_MV56', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'MV56', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2L2_MD_M_UV79', Lv2: 'MAINDECK', Lv3: 'MEZZ', BLOCK: 'UV79', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP3A', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP3A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP32', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP32', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP35', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP35', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP38', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP38', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP3B', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP3B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP3D', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP3D', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP30', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP30', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP33', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP33', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP36', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP36', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP39', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP39', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_MP45', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'MP45', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP3C', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP3C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LP3E', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LP3E', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD32', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD32', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD35', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD35', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD38', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD38', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD3B', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD3B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD3D', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD3D', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD31', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD31', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD34', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD34', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD37', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD37', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD3A', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD3A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD3C', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD3C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD30', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD30', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD33', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD33', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD36', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD36', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD39', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD39', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_D_LD3E', Lv2: 'LOWERDECK', Lv3: 'DECK', BLOCK: 'LD3E', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_MZ42', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'MZ42', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_MZ41', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'MZ41', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_MZ45', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'MZ45', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_MZ44', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'MZ44', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LV3A', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LV3A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_LV3C', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'LV3C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_LD_M_MZ43', Lv2: 'LOWERDECK', Lv3: 'MEZZ', BLOCK: 'MZ43', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD11', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD11', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD14', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD14', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD13', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD13', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD17', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD17', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD1A', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD1A', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD1C', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD1C', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD10', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD10', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD12', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD12', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD16', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD16', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD19', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD19', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD15', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD15', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD18', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD18', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_D_CD1B', Lv2: 'CELLARDECK', Lv3: 'DECK', BLOCK: 'CD1B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R1_CD_M_CV13', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV13', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CV10', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV10', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CV11', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV11', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ27', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ27', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ26', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ26', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ25', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ25', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ28', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ28', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CV15', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV15', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CP13', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CP13', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ23', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ23', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ21', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ21', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ22', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ22', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_MZ24', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'MZ24', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CV19', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV19', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CV17', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV17', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },
    { ID: 'S2R2_CD_M_CV1B', Lv2: 'CELLARDECK', Lv3: 'MEZZ', BLOCK: 'CV1B', Stage: null, SHAPE: 'UNIT', STATUS: 'ERECTION', EREC: 'Y', },

  ],
}