
export default {
  Canvas: {
    CanvasWidth: 0,
    CanvasHeight: 0,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {AREA:'Area 01',  EREC: 'BK342' , PE2: 'BK43' , PE1: 'B276' , PLAN_START: 1154, PLAN_FINISH: 35, ACT_START: 1232, ACT_FINISH: 40, DELTA: -24, PROG: 84.7,},
    {AREA:'Area 02',  EREC: 'BK345' , PE2: 'BK43' , PE1: 'B654' , PLAN_START: 2214, PLAN_FINISH: 45, ACT_START: 1524, ACT_FINISH: 254, DELTA: -2,  PROG: 24.5,},
    {AREA:'Area 03',  EREC: 'BK346' , PE2: 'BK43' , PE1: 'B341' , PLAN_START: 3342, PLAN_FINISH: 45, ACT_START: 1324, ACT_FINISH: 264, DELTA: 10,  PROG: 57.4,},
    {AREA:'Area 04',  EREC: 'BK349' , PE2: 'BK43' , PE1: ''     , PLAN_START: 12234, PLAN_FINISH: 44, ACT_START: 2322, ACT_FINISH: 242, DELTA: 7,   PROG: 45.3,},
    {AREA:'Area 05',  EREC: 'BK442' , PE2: 'BK23' , PE1: ''     , PLAN_START: 2100, PLAN_FINISH: 32, ACT_START: 1223, ACT_FINISH: 265, DELTA: 9,   PROG: 54.0,},
    {AREA:'Area 06',  EREC: 'BK372' , PE2: ''     , PE1: 'AP234', PLAN_START: 1287, PLAN_FINISH: 2237, ACT_START: 3156, ACT_FINISH: 2100, DELTA: 0,   PROG: 100.0,},
    {AREA:'Area 07',  EREC: 'BK362' , PE2: ''     , PE1: 'AP765', PLAN_START: 1536, PLAN_FINISH: 2287, ACT_START: 4154, ACT_FINISH: 288, DELTA: -2,  PROG: 87.7,},
    {AREA:'Area 08',  EREC: 'BK371' , PE2: ''     , PE1: 'AP987', PLAN_START: 1847, PLAN_FINISH: 1269, ACT_START: 2178, ACT_FINISH: 241, DELTA: -14, PROG: 74.4,},
    {AREA:'Area 09',  EREC: 'BK341' , PE2: ''     , PE1: ''     , PLAN_START: 1151, PLAN_FINISH: 54, ACT_START: 1816, ACT_FINISH: 246, DELTA: -9,  PROG: 71.2,},
    {AREA:'Area 10',  EREC: 'BK312' , PE2: ''     , PE1: 'AE23' , PLAN_START: 1364, PLAN_FINISH: 1587, ACT_START: 2110, ACT_FINISH: 156, DELTA: 14,  PROG: 25.1,},
    {AREA:'Area 11',  EREC: 'BK322' , PE2: ''     , PE1: 'AE34' , PLAN_START: 1079, PLAN_FINISH: 45, ACT_START: 1187, ACT_FINISH: 128, DELTA: 13,  PROG: 39.9,},
    {AREA:'Area 12',  EREC: 'BK332' , PE2: ''     , PE1: 'AE33' , PLAN_START: 198, PLAN_FINISH: 61, ACT_START: 1165, ACT_FINISH: 39, DELTA: 45,  PROG: 49.6,},
  ],

  Queries: {

    
    SQL1: [ // --> Header
      {
        X: 20,
        Y: 20,
        HEIGHT: 30,
        BACKGROUND: '#E0E0DF',
        OPACITY: 0.3,
        LINE_COLOR: '#333',
        LINE_SIZE: .5,
        FONT_COLOR: '#000',
        FONT_WEIGHT: 'nomal',
        FONT_SIZE: 12,
      },
    ],

    SQL2: [ // --> Data 
      {
        HEIGHT: 20,
        LINE_COLOR: '#bcbcbc',
        LINE_SIZE: 0.2,
        FONT_COLOR: 'gray',
        FONT_WEIGHT: 'nomal',
        FONT_SIZE: 11,
        HOVOR: '#bcbcbc'
      },
    ],

    SQL3: [ // --> Columns
      { code: 'AREA',       name: 'AREA',         type: 'BUTTON',     width: 60,  align: 'middle',  padding: 0,  tColor: 'gray'},
      { code: 'EREC',       name: 'EREC',         type: '',     width: 50,  align: 'middle',  padding: 0,  tColor: 'gray'},
      { code: 'PE2',        name: 'PE2',          type: '',     width: 50,  align: 'middle',  padding: 0,  tColor: 'gray'},
      { code: 'PE1',        name: 'PE1',          type: '',     width: 50,  align: 'middle',  padding: 0,  tColor: 'gray'},
      { code: 'PLAN_START', name: 'Start/Plan',   type: 'LINK$', width: 50,  align: 'end',     padding: 10, tColor: 'gray'},
      { code: 'PLAN_FINISH',name: 'Finish',       type: 'LINK$', width: 50,  align: 'end',     padding: 10, tColor: '#F35E90'},
      { code: 'ACT_START',  name: 'Start/Actual', type: 'LINK$', width: 50,  align: 'end',     padding: 10, tColor: 'gray'},
      { code: 'ACT_FINISH', name: 'Finish',       type: 'LINK$', width: 50,  align: 'end',     padding: 10, tColor: '#F35E90'},
      { code: 'DELTA',      name: 'Delta',        type: 'DELTA',width: 60,  align: 'middle',  padding: 10, tColor: '#83D2F5'},
      { code: 'PROG',       name: 'Progress',     type: 'PROG', width: 150, align: '',        padding: 10, tColor: 'gray', bColor: 'pink'},
    ],
  },

}