const OutfittingStatus = [

  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'Piping Support' ,TITLE:'Fabrication', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'Piping Support' ,TITLE:'Delivered',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 75.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'Piping Support' ,TITLE:'Installed',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 65.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'E&I Support'    ,TITLE:'Fabrication', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'E&I Support'    ,TITLE:'Delivered',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 25.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'E&I Support'    ,TITLE:'Installed',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 75.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'HVAC Support'   ,TITLE:'Fabrication', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'HVAC Support'   ,TITLE:'Delivered',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 85.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Supports',   ACT: 'HVAC Support'   ,TITLE:'Installed',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 90.5, },

  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'Piping Spool'   ,TITLE:'Fabrication', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'Piping Spool'   ,TITLE:'In-Position', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'Piping Spool'   ,TITLE:'Installed',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'Cable Tray'     ,TITLE:'Fabrication', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'Cable Tray'     ,TITLE:'Delivered',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'Cable Tray'     ,TITLE:'Installed',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'HVAC Duct'      ,TITLE:'Fabrication', TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'HVAC Duct'      ,TITLE:'Delivered',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
  { AREA: 'Hull', MODULE: 'NP1UH', GROUP: 'Status of Outfitting', ACT: 'HVAC Duct'      ,TITLE:'Installed',   TOTAL: 100, ACTUAL: 100, REMAIN: 100, PROG: 98.5, },
]

export default OutfittingStatus