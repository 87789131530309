import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 945,
      CanvasHeight: 635,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: 'OGM Status', x: 20, y: 10, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Structure",TARGET_PROG:100,PLAN_PROG:44.26,ACTUAL_PROG:45.92,DELTA:1.65,DISC:"36"},
      {DISC_DESCR:"Piping",TARGET_PROG:82.3,PLAN_PROG:5.84,ACTUAL_PROG:0.98,DELTA:-4.86,DISC:"13"},
      {DISC_DESCR:"Electrical",TARGET_PROG:89.96,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"16"},
      {DISC_DESCR:"Instrument",TARGET_PROG:92.97,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"15"},
      {DISC_DESCR:"Mechanical",TARGET_PROG:100,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"68"},
      {DISC_DESCR:"Painting",TARGET_PROG:96,PLAN_PROG:19.84,ACTUAL_PROG:6.99,DELTA:-12.84,DISC:"23"},
      {DISC_DESCR:"Insulation",TARGET_PROG:9,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"22"},
      {DISC_DESCR:"Fire & Safety",TARGET_PROG:8.46,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"61"}
    ],
    Queries: {
      SQL1: [
        {DISC:"36",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES PREFABRICATION PRIMARY SECONDARY",WCCODE:"36210",UOM:"TONS",INITIALQTY:720.65,TARGET_QTY:720.6,TARGET_PROG:100,ACTUAL_CUM_QTY:545,ACTUAL_CUM_PROG:75.63,ACTUAL_WEEK_QTY:7.8,ACTUAL_WEEK_PROG:1.08,REMAIN_QTY:175.6,REMAIN_PROG:-24.37},
        {DISC:"36",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES ASSEMBLY/ERECTION PRIMARY SECONDARY",WCCODE:"36220",UOM:"TONS",INITIALQTY:729.65,TARGET_QTY:729.6,TARGET_PROG:100,ACTUAL_CUM_QTY:250.6,ACTUAL_CUM_PROG:34.33,ACTUAL_WEEK_QTY:6.4,ACTUAL_WEEK_PROG:0.87,REMAIN_QTY:479.1,REMAIN_PROG:-65.67},
        {DISC:"36",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES ASSEMBLY/ERECTION TERTIARY",WCCODE:"36230",UOM:"TONS",INITIALQTY:1057.6,TARGET_QTY:1057.6,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1057.6,REMAIN_PROG:-100},
        {DISC:"13",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES PIPING FABRICATION",WCCODE:"13410",UOM:"DIA",INITIALQTY:27102,TARGET_QTY:27102,TARGET_PROG:100,ACTUAL_CUM_QTY:13.6,ACTUAL_CUM_PROG:0.05,ACTUAL_WEEK_QTY:13.6,ACTUAL_WEEK_PROG:0.05,REMAIN_QTY:27088.4,REMAIN_PROG:-99.95},
        {DISC:"13",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES PIPING INSTALLATION",WCCODE:"13420",UOM:"DIA",INITIALQTY:9156,TARGET_QTY:6063.1,TARGET_PROG:66.21,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:6063.1,REMAIN_PROG:-66.21},
        {DISC:"13",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT FABRICATION",WCCODE:"13810",UOM:"KG",INITIALQTY:43301.34,TARGET_QTY:43301.3,TARGET_PROG:100,ACTUAL_CUM_QTY:7240,ACTUAL_CUM_PROG:16.72,ACTUAL_WEEK_QTY:3122,ACTUAL_WEEK_PROG:7.2,REMAIN_QTY:36061.4,REMAIN_PROG:-83.28},
        {DISC:"13",GWBS_LV3:"ME01",WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT INSTALLATION",WCCODE:"13820",UOM:"KG",INITIALQTY:43301.34,TARGET_QTY:43301.3,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:43301.3,REMAIN_PROG:-100},
        {DISC:"13",GWBS_LV3:"ME01",WC_DESCRIPTION:"PRESSURE TEST",WCCODE:"13900",UOM:"U",INITIALQTY:685,TARGET_QTY:144.8,TARGET_PROG:21.14,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:144.8,REMAIN_PROG:-21.14},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"GROUNDING AND LIGHTNING SYSTEM",WCCODE:"16100",UOM:"U",INITIALQTY:5966,TARGET_QTY:5966,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:5966,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"OUTDOOR - ELECTRICAL CABLES",WCCODE:"16320",UOM:"LM",INITIALQTY:219,TARGET_QTY:219,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:219,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"OUTDOOR - ELECTRICAL CABLES CONNECTIONS",WCCODE:"16330",UOM:"U",INITIALQTY:176,TARGET_QTY:176,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:176,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"ELECTRICAL COMPONENTS (PANELS, JUNCTION BOXES)",WCCODE:"16340",UOM:"U",INITIALQTY:398,TARGET_QTY:398,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:398,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - CABLE TRAY",WCCODE:"16430",UOM:"LM",INITIALQTY:4013,TARGET_QTY:4013,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:4013,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"EICT AREA OUTFITTINGS FABRICATION - SUPPORT",WCCODE:"16450",UOM:"KG",INITIALQTY:7164.29,TARGET_QTY:7164.3,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:7164.3,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - SUPPORT",WCCODE:"16460",UOM:"KG",INITIALQTY:12742.11,TARGET_QTY:12742.1,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:12742.1,REMAIN_PROG:-100},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"ELECTRICAL HEAT TRACING SYSTEM",WCCODE:"16500",UOM:"U",INITIALQTY:17875,TARGET_QTY:8935.7,TARGET_PROG:49.99,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:8935.7,REMAIN_PROG:-49.99},
        {DISC:"16",GWBS_LV3:"ME01",WC_DESCRIPTION:"LIGHTING SYSTEM",WCCODE:"16800",UOM:"U",INITIALQTY:8122,TARGET_QTY:8122,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:8122,REMAIN_PROG:-100},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"OUTDOOR - INSTRUMENT CABLES & MULTIINSTRUMENT CABLES",WCCODE:"15220",UOM:"LM",INITIALQTY:12775,TARGET_QTY:12775,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:12775,REMAIN_PROG:-100},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"OUTDOOR - INSTRUMENT CABLES CONNECTIONS",WCCODE:"15230",UOM:"U",INITIALQTY:10973,TARGET_QTY:10973,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:10973,REMAIN_PROG:-100},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"INSTRUMENT COMPONENTS (PANELS, JUNCTION BOXES)",WCCODE:"15240",UOM:"U",INITIALQTY:27,TARGET_QTY:27,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:27,REMAIN_PROG:-100},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"FIELD INSTRUMENTATION WORKS",WCCODE:"15310",UOM:"U",INITIALQTY:707,TARGET_QTY:86.9,TARGET_PROG:12.28,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:86.9,REMAIN_PROG:-12.28},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"FIELD ON-LINE INSTRUMENTATION WORKS",WCCODE:"15350",UOM:"U",INITIALQTY:2405,TARGET_QTY:1275.9,TARGET_PROG:53.04,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1275.9,REMAIN_PROG:-53.04},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"ANALYZERS INSTALLATION",WCCODE:"15600",UOM:"U",INITIALQTY:6,TARGET_QTY:6,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:6,REMAIN_PROG:-100},
        {DISC:"15",GWBS_LV3:"ME01",WC_DESCRIPTION:"TELECOMMUNICATION EQUIPMENT INSTALLATION",WCCODE:"15700",UOM:"U",INITIALQTY:70,TARGET_QTY:70,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:70,REMAIN_PROG:-100},
        {DISC:"68",GWBS_LV3:"ME01",WC_DESCRIPTION:"HEAVY LIFTS IN ONE PIECE",WCCODE:"68110",UOM:"U",INITIALQTY:2,TARGET_QTY:2,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2,REMAIN_PROG:-100},
        {DISC:"68",GWBS_LV3:"ME01",WC_DESCRIPTION:"VERTICAL AND HORIZONTAL VESSELS/EXCHANGERS",WCCODE:"68210",UOM:"U",INITIALQTY:9,TARGET_QTY:9,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:9,REMAIN_PROG:-100},
        {DISC:"68",GWBS_LV3:"ME01",WC_DESCRIPTION:"TRAYS & INTERNALS INSTALLATION",WCCODE:"68610",UOM:"U",INITIALQTY:2,TARGET_QTY:2,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2,REMAIN_PROG:-100},
        {DISC:"68",GWBS_LV3:"ME01",WC_DESCRIPTION:"SKID, PACKAGES & MISC.",WCCODE:"68810",UOM:"U",INITIALQTY:6,TARGET_QTY:6,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:6,REMAIN_PROG:-100},
        {DISC:"23",GWBS_LV3:"ME01",WC_DESCRIPTION:"STEEL STRUCTURE PAINTING",WCCODE:"23110",UOM:"SQM",INITIALQTY:15923.52,TARGET_QTY:15923.5,TARGET_PROG:100,ACTUAL_CUM_QTY:2853.5,ACTUAL_CUM_PROG:17.91,ACTUAL_WEEK_QTY:479.3,ACTUAL_WEEK_PROG:3,REMAIN_QTY:13070,REMAIN_PROG:-82.09},
        {DISC:"23",GWBS_LV3:"ME01",WC_DESCRIPTION:"AREA PAINTING",WCCODE:"23120",UOM:"SQM",INITIALQTY:762.3,TARGET_QTY:494,TARGET_PROG:64.81,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:494,REMAIN_PROG:-64.81},
        {DISC:"23",GWBS_LV3:"ME01",WC_DESCRIPTION:"PIPING PAINTING",WCCODE:"23200",UOM:"SQM",INITIALQTY:3046.9,TARGET_QTY:3046.9,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:3046.9,REMAIN_PROG:-100},
        {DISC:"23",GWBS_LV3:"ME01",WC_DESCRIPTION:"PIPING SUPPORT PAINTING",WCCODE:"23210",UOM:"SQM",INITIALQTY:2225.7,TARGET_QTY:2225.7,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:2225.7,REMAIN_PROG:-100},
        {DISC:"23",GWBS_LV3:"ME01",WC_DESCRIPTION:"PASSIVE FIRE PROTECTION",WCCODE:"23500",UOM:"SQM",INITIALQTY:1576,TARGET_QTY:1576,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1576,REMAIN_PROG:-100},
        {DISC:"22",GWBS_LV3:"ME01",WC_DESCRIPTION:"EQUIPMENT INSULATION WORKS",WCCODE:"22110",UOM:"SQM",INITIALQTY:314,TARGET_QTY:314,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:314,REMAIN_PROG:-100},
        {DISC:"22",GWBS_LV3:"ME01",WC_DESCRIPTION:"PIPING INSULATION WORKS",WCCODE:"22210",UOM:"SQM",INITIALQTY:3010.2,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"61",GWBS_LV3:"ME01",WC_DESCRIPTION:"FIRE PROTECTION SYSTEM",WCCODE:"61100",UOM:"U",INITIALQTY:18.31,TARGET_QTY:3.7,TARGET_PROG:20,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:3.7,REMAIN_PROG:-20},
        {DISC:"61",GWBS_LV3:"ME01",WC_DESCRIPTION:"SAFETY EQUIPMENT",WCCODE:"61200",UOM:"U",INITIALQTY:93,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0}
      ]
    }
}