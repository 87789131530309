import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 350,
      CanvasHeight: 530,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 350,
      CanvasChartHeight: 530,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      { disc: 'Structure', activity: 'Steel Cutting',                          plan: 50, actual: 75},
      { disc: 'Structure', activity: 'Steel Outfitting Fabrication',           plan: 100, actual: 75},
      { disc: 'Structure', activity: 'Block Assembly',                         plan: 85, actual: 99.5},
      { disc: 'Structure', activity: 'Joint Welding',                          plan: 100, actual: 75},
      { disc: 'Structure', activity: 'Steel Outfitting Installation',          plan: 100, actual: 75},
      { disc: 'Structure', activity: 'Block Erection',                         plan: 60, actual: 45},

      { disc: 'Piping',     activity: 'Pipe Spool Fabrication',                 plan: null, actual: null},
      { disc: 'Piping',     activity: 'Pipe Support Installation',              plan: 100, actual: 75},
      { disc: 'Piping',     activity: 'Pipe Piece Installation',                plan: 100, actual: 100},

      { disc: 'Electrical & Instrument', activity: 'Cable Tray Support / Seat Installation', plan: 100, actual: 75},
      { disc: 'Electrical & Instrument', activity: 'Cable Tray Installation',                plan: 100, actual: 16},

      { disc: 'Mechanical', activity: 'Equipment Seat Installation',            plan: 100, actual: 14},

      { disc: 'HVAC',       activity: 'HVAC Support Installation',              plan: 100, actual: 75},
      { disc: 'HVAC',       activity: 'Duct / Fittings Installation',           plan: 100, actual: 75},
    
      { disc: 'Painting',   activity: 'Block Panting & PFP',                    plan: 100, actual: 75},
    ],
}