import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 550,
      CanvasChartHeight: 300,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [{ 
        RFS: '2020', 
        MOD: '41M001', 
        MOD_DESC: 'PBF Compressor - Train 4', 
        DEL_LEAD: 'Zaigul Dossaliyeva', 
        LEN: 70, WID: 16.3,HEI: 33.75, WT: 4600.44, 
        PAU: 3, 
        BLK: 55, 
        TSCD: '2018-11-02',
        RFS_ESD: '2020-06-09', 
        MC_TARGET: '2020-04-05', 
        QUARTER: '2nd-2020', 
        A1_T: 12895, A1_A: 822, 
        B1_T: 1439, B1_A: 0, 
        D1_T: 672, D1_A: 0, 
        PA: 511, PB: 210, PC: 0, 
        LI_T: 242, LI_A: 58, HT_T: 242, HT_A: 52, RE_T: 309, RE_A: 37, 
        WD_T: 151, WD_A: 4, DAC_T: 76, DAC_A: 0, FMC_T: 86, FMC_A: 0, 
        STR_P: 86.19, STR_A: 97.14, PIPE_P: 60.89, PIPE_A: 67.38, EI_P: 26.72, EI_A: 32.84, MECH_P: 54.16, MECH_A: 90.59, HVAC_P: 62.71, HVAC_A: 67.41, ARCH_P: 33.42, ARCH_A: 44.58, PAINT_P: 85.13, PAINT_A: 87.11, INSUL_P: 4.01, INSUL_A: 0.03, OVERALL_P: 59.33, OVERALL_A: 66.35, 
        CUT_T: 50, CUT_A: 50, ASSY_T: 50, ASSY_A: 50, PNT_T: 50, PNT_A: 50, EREC_T: 39, EREC_A: 39, 
      }],

}