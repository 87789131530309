import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 500,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Piping",PLAN:13.37,ACTUAL:9.14,DELTA:-4.229999999999999}
    ],
    Queries: {
      SQL1:[
        {WK_NO:"W2021_23",CUM_PLAN_PROG:60.63520703128881,CUM_ACTUAL_PROG:36.0875275317449,WK_PLAN_QTY:25962.52475,WK_ACTUAL_QTY:25801.993792,CUM_PLAN_QTY:1399624.461025,CUM_ACTUAL_QTY:860638.438107,WK_PLAN_PROG:1.952003572163178,WK_ACTUAL_PROG:1.097381594822939},
        {WK_NO:"W2021_24",CUM_PLAN_PROG:62.59663798289792,CUM_ACTUAL_PROG:37.12501240579745,WK_PLAN_QTY:26412.58729999999,WK_ACTUAL_QTY:26339.04806299998,CUM_PLAN_QTY:1426037.048325,CUM_ACTUAL_QTY:886977.4861700002,WK_PLAN_PROG:1.961430951609111,WK_ACTUAL_PROG:1.037484874052547},
        {WK_NO:"W2021_25",CUM_PLAN_PROG:64.55952424143966,CUM_ACTUAL_PROG:38.43350870098441,WK_PLAN_QTY:23420.5517,WK_ACTUAL_QTY:25213.06383899999,CUM_PLAN_QTY:1449457.600025,CUM_ACTUAL_QTY:912190.550009,WK_PLAN_PROG:1.962886258541749,WK_ACTUAL_PROG:1.308496295186963},
        {WK_NO:"W2021_26",CUM_PLAN_PROG:66.54945423322309,CUM_ACTUAL_PROG:40.36341153358323,WK_PLAN_QTY:21162.71600000001,WK_ACTUAL_QTY:22182.52280900004,CUM_PLAN_QTY:1470620.316025,CUM_ACTUAL_QTY:934373.0728180001,WK_PLAN_PROG:1.989929991783436,WK_ACTUAL_PROG:1.929902832598822},
        {WK_NO:"W2021_27",CUM_PLAN_PROG:68.52148343753367,CUM_ACTUAL_PROG:41.41275024920144,WK_PLAN_QTY:19032.17712499997,WK_ACTUAL_QTY:27581.86928499998,CUM_PLAN_QTY:1489652.49315,CUM_ACTUAL_QTY:961954.9421029999,WK_PLAN_PROG:1.972029204310587,WK_ACTUAL_PROG:1.049338715618205},
        {WK_NO:"W2021_28",CUM_PLAN_PROG:70.46911451343996,CUM_ACTUAL_PROG:42.41688421856723,WK_PLAN_QTY:16814.61990000002,WK_ACTUAL_QTY:21878.33085699998,CUM_PLAN_QTY:1506467.11305,CUM_ACTUAL_QTY:983833.27296,WK_PLAN_PROG:1.94763107590626,WK_ACTUAL_PROG:1.004133969365794,WKS4_TREND:42.41688421856723},
        {WK_NO:"W2021_29",CUM_PLAN_PROG:72.45490506428091,WK_PLAN_QTY:14910.55569999999,CUM_PLAN_QTY:1521377.66875,WK_PLAN_PROG:1.985790550840955,WKS4_TREND:43.73984302057528},
        {WK_NO:"W2021_30",CUM_PLAN_PROG:74.41793627360974,WK_PLAN_QTY:14199.5147,CUM_PLAN_QTY:1535577.18345,WK_PLAN_PROG:1.963031209328837,WKS4_TREND:45.06280182258331},
        {WK_NO:"W2021_31",CUM_PLAN_PROG:76.24858648237229,WK_PLAN_QTY:12975.5568,CUM_PLAN_QTY:1548552.74025,WK_PLAN_PROG:1.830650208762544,WKS4_TREND:46.38576062459134},
        {WK_NO:"W2021_32",CUM_PLAN_PROG:77.70884234757501,WK_PLAN_QTY:9765.974999999999,CUM_PLAN_QTY:1558318.71525,WK_PLAN_PROG:1.460255865202723,WKS4_TREND:47.70871942659938},
        {WK_NO:"W2021_33",CUM_PLAN_PROG:79.06316579098134,WK_PLAN_QTY:9357.00569999999,CUM_PLAN_QTY:1567675.72095,WK_PLAN_PROG:1.354323443406345,WKS4_TREND:49.03167822860741},
        {WK_NO:"W2021_34",CUM_PLAN_PROG:80.31516588591523,WK_PLAN_QTY:8389.003800000017,CUM_PLAN_QTY:1576064.72475,WK_PLAN_PROG:1.25200009493388,WKS4_TREND:50.35463703061544}
      ]
    }
}