import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1440,
      CanvasHeight: 940,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#f5f5f5',
      CanvasFillColor: '#f5f5f5',
      CanvasOpacity: 1,

      CanvasChartX: 20,
      CanvasChartY: 20,
      CanvasChartWidth: 1400,
      CanvasChartHeight: 900,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#f5f5f5',
      CanvasChartFillColor: '#f5f5f5',
      CanvasChartOpacity: 1,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
      CanvasChartAreaAllowed: 'Y'
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: [
        {
          query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
          text: 'Datasource: Shop Drawing Index 04-Oct-20',
          x: 10,
          y: 10,
          font: 'roboto',
          style: 'regular',
          size: 12,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      // drawShopTooltipGroup
      SQL1:[
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A1",PLAN:100,ACTUAL:65,DELTA:-35,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A2",PLAN:68.4,ACTUAL:83.2,DELTA:14.8,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A3",PLAN:83.7,ACTUAL:90.48,DELTA:6.780000000000001,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A4",PLAN:100,ACTUAL:99.05,DELTA:-0.9500000000000028,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3AA",PLAN:100,ACTUAL:99.2,DELTA:-0.7999999999999972,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B1",PLAN:91.9,ACTUAL:99.99,DELTA:8.08999999999999,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B2",PLAN:65.9,ACTUAL:9.25,DELTA:-56.65000000000001,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B3",PLAN:31.1,ACTUAL:65.98,DELTA:34.88,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B4",PLAN:100,ACTUAL:97.59,DELTA:-2.409999999999997,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B5",PLAN:65,ACTUAL:81,DELTA:16,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B6",PLAN:21.4,ACTUAL:33.5,DELTA:12.1,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B7",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B8",ACTUAL:0.01,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B9",ACTUAL:57.5,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C1",ACTUAL:57.5,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C2",ACTUAL:0.01,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C3",PLAN:100,ACTUAL:1,DELTA:-99,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C4",ACTUAL:0.01,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C5",PLAN:0,ACTUAL:66,DELTA:66,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3D1",PLAN:100,ACTUAL:94,DELTA:-6,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3D2",PLAN:100,ACTUAL:78.4,DELTA:-21.59999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3D3",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3DA",PLAN:100,ACTUAL:64.75,DELTA:-35.25,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3DB",PLAN:0,ACTUAL:66,DELTA:66,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F1",PLAN:100,ACTUAL:78.4,DELTA:-21.59999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F2",PLAN:74.5,ACTUAL:94,DELTA:19.5,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F3",PLAN:100,ACTUAL:34.99,DELTA:-65.00999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F4",PLAN:100,ACTUAL:42.68,DELTA:-57.32,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G1",PLAN:100,ACTUAL:94,DELTA:-6,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G2",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G1",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G2",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G3",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3H1",ACTUAL:97.23,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3H2",ACTUAL:87.59,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3H3",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3HA",PLAN:88.8,ACTUAL:98.92,DELTA:10.12000000000001,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J1",PLAN:100,ACTUAL:99.98,DELTA:-0.01999999999999602,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J2",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J3",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J4",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J5",ACTUAL:99.6,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J6",PLAN:87.5,ACTUAL:94.82,DELTA:7.319999999999993,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3J7",PLAN:100,ACTUAL:99.98,DELTA:-0.01999999999999602,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3JA",PLAN:100,ACTUAL:98.9,DELTA:-1.099999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3JB",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"E2B",PLAN:97.1,ACTUAL:92.49,DELTA:-4.609999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3K1",PLAN:70.3,ACTUAL:99.38,DELTA:29.08,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3K2",ACTUAL:87.52,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3K3",PLAN:94.3,ACTUAL:83.01,DELTA:-11.28999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3K4",PLAN:81.8,ACTUAL:87.29,DELTA:5.490000000000009,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3K5",PLAN:84.1,ACTUAL:77.94,DELTA:-6.159999999999997,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A1",PLAN:100,ACTUAL:99.57,DELTA:-0.4300000000000068,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A2",PLAN:80.2,ACTUAL:98.68,DELTA:18.48,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A3",PLAN:100,ACTUAL:99.09,DELTA:-0.9099999999999966,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A1",PLAN:100,ACTUAL:99.84,DELTA:-0.1599999999999966,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A2",PLAN:100,ACTUAL:98.75,DELTA:-1.25,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A3",PLAN:100,ACTUAL:99.59,DELTA:-0.4099999999999966,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3A4",PLAN:100,ACTUAL:99.4,DELTA:-0.5999999999999943,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3AA",PLAN:100,ACTUAL:99.69,DELTA:-0.3100000000000023,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B1",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B2",PLAN:100,ACTUAL:99.08,DELTA:-0.9200000000000017,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B3",PLAN:100,ACTUAL:99.54,DELTA:-0.4599999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B4",PLAN:100,ACTUAL:99.54,DELTA:-0.4599999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B5",PLAN:100,ACTUAL:99.67,DELTA:-0.3299999999999983,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B7",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B8",PLAN:100,ACTUAL:99.68,DELTA:-0.3199999999999932,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3B9",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C1",PLAN:100,ACTUAL:99.83,DELTA:-0.1700000000000017,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C2",PLAN:100,ACTUAL:99.79,DELTA:-0.2099999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C3",PLAN:100,ACTUAL:99.24,DELTA:-0.7600000000000051,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C4",PLAN:100,ACTUAL:99.54,DELTA:-0.4599999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3C5",PLAN:100,ACTUAL:99.39,DELTA:-0.6099999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3D1",PLAN:100,ACTUAL:95.93,DELTA:-4.069999999999993,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3D2",PLAN:100,ACTUAL:99.75,DELTA:-0.25,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3D3",PLAN:100,ACTUAL:99.7,DELTA:-0.2999999999999972,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3DA",PLAN:100,ACTUAL:99.85,DELTA:-0.1500000000000057,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3DB",PLAN:99,ACTUAL:54.13,DELTA:-44.87,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F1",PLAN:12.7,ACTUAL:53.93,DELTA:41.23,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F2",PLAN:100,ACTUAL:91.4,DELTA:-8.599999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F3",PLAN:17.1,ACTUAL:65.97,DELTA:48.87,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3F4",PLAN:100,ACTUAL:97.04,DELTA:-2.959999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G1",PLAN:54.1,ACTUAL:38.49,DELTA:-15.61,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G2",PLAN:100,ACTUAL:99.25,DELTA:-0.75,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G3",PLAN:100,ACTUAL:99.68,DELTA:-0.3199999999999932,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3G4",PLAN:100,ACTUAL:99.39,DELTA:-0.6099999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",BLOCK:"3H1",PLAN:100,ACTUAL:98.99,DELTA:-1.010000000000005,DELTA_CODE:"DELAY"}
      ],
      SQL2:[
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"DMOD01",TEXT1:"Lifting Plan",TEXT1_DATE:"30-Aug-21",TEXT2:"1st Target :",TEXT2_PROG:99.61},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"DMOD02",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"1st Target :",TEXT2_PROG:97.02},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"DMOD03",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"1st Target :",TEXT2_PROG:89.26},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"DMOD04",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"1st Target :",TEXT2_PROG:70.06},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"DMOD05",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"1st Target :",TEXT2_PROG:68.46},
        {LV1:"TOPSIDE",LV2:"OVERALL",TEXT1:"1st. Lifting Plan",TEXT1_DATE:"31-Aug-21",TEXT2:"1st Target :",TEXT2_PROG:69.6},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"FL01",TEXT1:"Lifting Plan",TEXT1_DATE:"12-Oct-21",TEXT2:"1st Target :",TEXT2_PROG:99.94},
      ],
      SQL3:[
        {CODE:"Shop_F",ITEM:"Fabrication Shop",PLAN:83.74,ACTUAL:83.94,DELTA:0.2,TOTAL:810,INPROGRESS:184,COMPLETED:389,NOTSTARTED:237},
        {CODE:"Shop_A",ITEM:"Assembly Shop",PLAN:70.67,ACTUAL:70.59,DELTA:-0.07,TOTAL:810,INPROGRESS:165,COMPLETED:385,NOTSTARTED:260},
        {CODE:"Shop_P",ITEM:"Painting Shop",PLAN:58.56,ACTUAL:56.43,DELTA:-2.13,TOTAL:810,INPROGRESS:8,COMPLETED:333,NOTSTARTED:469},
        {CODE:"Shop_E",ITEM:"Erection Area",PLAN:33.71,ACTUAL:32.98,DELTA:-0.73,TOTAL:485,INPROGRESS:21,COMPLETED:153,NOTSTARTED:311},
        {CODE:"Area_T",ITEM:"Topsides Area",PLAN:5.17,ACTUAL:5.06,DELTA:-0.11,OGM_GOAL:88},
        {CODE:"Area_M",ITEM:"Modules",PLAN:8.17,ACTUAL:5.06,DELTA:-0.11,OGM_GOAL:88},
        {CODE:"Area_C",ITEM:"TS Common",PLAN:5.17,ACTUAL:5.06,DELTA:-0.11,OGM_GOAL:88},
        {CODE:"Area_L",ITEM:"LQ Aera",PLAN:31.97,ACTUAL:22.27,DELTA:-9.69,OGM_GOAL:82},
        {CODE:"Area_H",ITEM:"HULL Area",PLAN:49.92,ACTUAL:51,DELTA:1.07,OGM_GOAL:78}
      ],
      SQL4: [
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"HVAC",PLAN:88.55,ACTUAL:58.49,DELTA:-35.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Instrument",PLAN:5.41,ACTUAL:0,DELTA:-5.41},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Mechanical",PLAN:87.08,ACTUAL:41.22,DELTA:-45.86},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Piping",PLAN:46.32,ACTUAL:25.18,DELTA:-21.13},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Electrical",PLAN:34.62,ACTUAL:1.75,DELTA:-32.86},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Structure",PLAN:80.79,ACTUAL:73.29,DELTA:-7.5},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Insulation",PLAN:0.66,ACTUAL:0,DELTA:-0.66},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Fire & Safety",PLAN:1.59,ACTUAL:0,DELTA:-1.59},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Painting",PLAN:68.78,ACTUAL:35.34,DELTA:-33.44},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",DISC:"Piping",PLAN:42.37,ACTUAL:27.11,DELTA:-15.26},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",DISC:"Structure",PLAN:75.94,ACTUAL:71.11,DELTA:-4.83},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",DISC:"Architecture",PLAN:85.37,ACTUAL:14.35,DELTA:-71.02},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD03",DISC:"Painting",PLAN:89.77,ACTUAL:59.87,DELTA:-29.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD03",DISC:"Piping",PLAN:83.36,ACTUAL:39.91,DELTA:-43.44},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD03",DISC:"Architecture",PLAN:85.37,ACTUAL:14.35,DELTA:-71.02},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD03",DISC:"Fire & Safety",PLAN:7.8,ACTUAL:0,DELTA:-7.8},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD03",DISC:"HVAC",PLAN:88.55,ACTUAL:53.49,DELTA:-35.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD04",DISC:"Painting",PLAN:89.77,ACTUAL:59.87,DELTA:-29.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD04",DISC:"Piping",PLAN:83.36,ACTUAL:39.91,DELTA:-43.44},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD04",DISC:"Architecture",PLAN:85.37,ACTUAL:14.35,DELTA:-71.02},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD04",DISC:"Fire & Safety",PLAN:7.8,ACTUAL:0,DELTA:-7.8},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD04",DISC:"HVAC",PLAN:88.55,ACTUAL:53.49,DELTA:-35.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD05",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD05",DISC:"Piping",PLAN:45.69,ACTUAL:19.41,DELTA:-26.28},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD05",DISC:"Structure",PLAN:73.84,ACTUAL:66.93,DELTA:-6.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD06",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD06",DISC:"Painting",PLAN:74.42,ACTUAL:33.43,DELTA:-40.99},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD06",DISC:"Piping",PLAN:49.19,ACTUAL:28.54,DELTA:-20.64},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD06",DISC:"Structure",PLAN:78.95,ACTUAL:74.04,DELTA:-4.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD05",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD05",DISC:"Structure",PLAN:95.08,ACTUAL:87.03,DELTA:-8.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD02",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD02",DISC:"Painting",PLAN:59.07,ACTUAL:42.51,DELTA:-16.55},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD02",DISC:"Piping",PLAN:60.34,ACTUAL:32.6,DELTA:-27.74},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD02",DISC:"Structure",PLAN:80.46,ACTUAL:76.8,DELTA:-3.65}
      ],
      SQL5: [
        {NO:1,LV1:"Project",LV2:"Construction",PLAN:50.7,ACTUAL:47.14,ACTUAL_CERT:33.81,DELTA:-3.55,PLAN_WK:1.25,ACTUAL_WK:0.7,ACTUAL_CERT_WK:0.31,DELTA_WK:-0.54}
      ],
      SQL6:[
        { GWBS_LV1: 'HULL & LQ', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
        { GWBS_LV1: 'LQ'       , DATAURL: '', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1602470089.jpeg' },
        { GWBS_LV1: 'TOPSIDES' , DATAURL: 'test', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1604565816.jpeg' },
        { GWBS_LV1: 'TOPSIDES COMMON' , DATAURL: 'test', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1604565816.jpeg' },
        { GWBS_LV1: 'TOPSIDES MODULES' , DATAURL: 'test', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1604565816.jpeg' },
      ]
    },
    DataItems: [
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD02",LV3_DESCR:"OPG Propane Compressor",PLAN_PROG:50.86,ACTUAL_PROG:38.33,DELTA:-12.53,PLAN_PROG_WK:2.53,ACTUAL_PROG_WK:1.18,DELTA_WK:-1.35,FAB_START_TOTAL:7,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:7,PAINTING_ACTUAL:5,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:4,EREC_TOTAL:7,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD01",LV3_DESCR:"All Compression Module",PLAN_PROG:51.57,ACTUAL_PROG:39.03,DELTA:-12.53,PLAN_PROG_WK:2.3,ACTUAL_PROG_WK:1.33,DELTA_WK:-0.96,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:8,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:5,EREC_TOTAL:10,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD08",LV3_DESCR:"Recpt. Facil. & Prod. Sepn.",PLAN_PROG:52.09,ACTUAL_PROG:34.79,DELTA:-17.29,PLAN_PROG_WK:2.9,ACTUAL_PROG_WK:3.18,DELTA_WK:0.27,FAB_START_TOTAL:12,FAB_START_ACTUAL:11,FAB_COMP_TOTAL:12,FAB_COMP_ACTUAL:11,PAINTING_TOTAL:12,PAINTING_ACTUAL:8,BLOCKASSY_TOTAL:12,BLOCKASSY_ACTUAL:5,EREC_TOTAL:12,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD05",LV3_DESCR:"OPG Gasoline Splitting",PLAN_PROG:83.03,ACTUAL_PROG:53.62,DELTA:-29.4,PLAN_PROG_WK:3.28,ACTUAL_PROG_WK:1.05,DELTA_WK:-2.22,FAB_START_TOTAL:7,FAB_START_ACTUAL:6,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:6,PAINTING_TOTAL:7,PAINTING_ACTUAL:5,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:4,EREC_TOTAL:7,EREC_ACTUAL:3},
      {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",PLAN_PROG:58.82,ACTUAL_PROG:35.99,DELTA:-22.83,PLAN_PROG_WK:2.67,ACTUAL_PROG_WK:1.76,DELTA_WK:-0.91,FAB_START_TOTAL:78,FAB_START_ACTUAL:73,FAB_COMP_TOTAL:78,FAB_COMP_ACTUAL:73,PAINTING_TOTAL:78,PAINTING_ACTUAL:59,BLOCKASSY_TOTAL:78,BLOCKASSY_ACTUAL:40,EREC_TOTAL:78,EREC_ACTUAL:34},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD03",LV3_DESCR:"OPG Crude Stabilisation",PLAN_PROG:80.74,ACTUAL_PROG:51.19,DELTA:-29.54,PLAN_PROG_WK:3.73,ACTUAL_PROG_WK:0.68,DELTA_WK:-3.05,FAB_START_TOTAL:7,FAB_START_ACTUAL:6,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:6,PAINTING_TOTAL:7,PAINTING_ACTUAL:5,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:4,EREC_TOTAL:7,EREC_ACTUAL:3},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD04",LV3_DESCR:"Utility Water System",PLAN_PROG:90.62,ACTUAL_PROG:61.45,DELTA:-29.16,PLAN_PROG_WK:3.45,ACTUAL_PROG_WK:2.82,DELTA_WK:-0.62,FAB_START_TOTAL:7,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:7,PAINTING_ACTUAL:6,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:6,EREC_TOTAL:7,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD06",LV3_DESCR:"HC Dewpt. & Conden. Stabil.",PLAN_PROG:56.09,ACTUAL_PROG:39.08,DELTA:-17.01,PLAN_PROG_WK:2.49,ACTUAL_PROG_WK:2.02,DELTA_WK:-0.47,FAB_START_TOTAL:11,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:11,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:11,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:11,BLOCKASSY_ACTUAL:5,EREC_TOTAL:11,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"DMOD07",LV3_DESCR:"Utility",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
    ],
}