import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 860,
    CanvasHeight: 515,
    CanvasBorderWeight: 0,
    CanvasBorderColor: 'Transparent',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 515,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [
      { text: 'Module', x: 100, y: 15, font: 'roboto', class: 'bold', size: 22, color: '#333333', align: 'start' },
      { text: 'MC Completion', x: 730, y: 43, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'end' },
      { text: 'RFS of Module', x: 880, y: 43, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'end' },
    ],
    Filter: [
      { name: 'MOD'   , x: 22, y: 15, font: 'roboto', class: 'bold', size: 22, color: '#333333', align: 'start' },
    ],
    Sql: [
      { text: 'PBF COMPESSOR AIR COOLER - TRAIN 4', x: 22, y: 38, font: 'roboto', style: 'regular', size: 14, color: '#BCBCBC', align: 'start' },
      { text: '3 Days', x: 730, y: 28, font: 'roboto', style: 'bold', size: 13, color: '#44A9DF', align: 'end' },
      { text: '123 Days', x: 880, y: 28, font: 'roboto', style: 'bold', size: 13, color: '#44A9DF', align: 'end' },
    ]
  },
  // -----------------------------------------------------------

  DataItems: [
    // C10UH
    { name: 'C110H', type: 'PE3',  status: 'Cutting'},
  
  ],
}