import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1300,
      CanvasHeight: 900,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

  // ----------------------------------------------- Text
  Text: {
    Text: [
      { text: 'Module', x: 130, y: 25, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start', direction: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 50, y: 25, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start', direction: 'start' },
    ],
    Sql: [
      {
        query: '', 
        text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
        x: 50,
        y: 50,
        font: 'roboto',
        style: 'regular',
        size: 14,
        color: '#757575',
        align: 'start',
        direction: 'start'
      },
    ]
  },

    DataItems: [
      { NO: 1, MOD: '41M001', PAU: 'A', DISC: 'HVAC', TAG: '41-EC-0136', DESCR: 'SPLIT ACCU-1 &2 (SKID UNIT) FOR 41-RIE-0101', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 1, MOD: '41M001', PAU: 'C', DISC: null, TAG: '41-F-129.4', DESCR: 'TBA(Navisworks Item Name and CPI Tag No. are not matched )', STATUS: 'INST', RAS: null, ETA_RAS: null, PND: null, FLOAT: null, ETA: null, ATA: null, EREC_DATE: '2019-08-20', },
      { NO: 1, MOD: '41M001', PAU: 'B', DISC: null, TAG: '41-SL-0137', DESCR: 'TBA(Navisworks Item Name and CPI Tag No. are not matched )', STATUS: 'INST', RAS: null, ETA_RAS: null, PND: null, FLOAT: null, ETA: null, ATA: null, EREC_DATE: '2019-07-08', },
      { NO: 2, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0104B', DESCR: 'BATTERY EXTRACT FAN (AXIAL FAN)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-07-04', FLOAT: -172, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-06', },
      { NO: 2, MOD: '41M001', PAU: 'C', DISC: 'Mech.', TAG: '41-EA-101.4A', DESCR: 'PBF COMPRESSION SUCTION COOLER TRAIN 4', STATUS: 'INST', RAS: '2019-05-09', ETA_RAS: -228, PND: '2019-08-16', FLOAT: -129, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-01', },
      { NO: 2, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-PU-9201', DESCR: 'GASEOUS EXTINGUISHING SKID - PBF COMPRESSOR - TRAIN 4', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-05-15', FLOAT: -222, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 3, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0104A', DESCR: 'BATTERY EXTRACT FAN (AXIAL FAN)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-07-04', FLOAT: -172, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-06', },
      { NO: 3, MOD: '41M001', PAU: 'C', DISC: 'Mech.', TAG: '41-EA-101.4B', DESCR: 'PBF COMPRESSION SUCTION COOLER TRAIN 4', STATUS: 'INST', RAS: '2019-05-09', ETA_RAS: -228, PND: '2019-08-16', FLOAT: -129, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-01', },
      { NO: 3, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-PU-9202', DESCR: 'GASEOUS EXTINGUISHING SKID - PBF COMPRESSOR - TRAIN 4', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-05-15', FLOAT: -222, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 4, MOD: '41M001', PAU: 'C', DISC: 'Mech.', TAG: '41-EA-101.4C', DESCR: 'PBF COMPRESSION SUCTION COOLER TRAIN 4', STATUS: 'INST', RAS: '2019-05-09', ETA_RAS: -228, PND: '2019-08-16', FLOAT: -129, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-01', },
      { NO: 4, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-EC-0106-1A', DESCR: 'AIR COOLED CONDENSER', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-07-03', },
      { NO: 4, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-PU-9255', DESCR: 'GASEOUS EXTINGUISHING SKID - PBF COMPRESSOR - TRAIN 4', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-05-15', FLOAT: -222, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 5, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0102A-ISW-2', DESCR: 'BATTERY TEST SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-30', },
      { NO: 5, MOD: '41M001', PAU: 'C', DISC: 'Mech.', TAG: '41-EA-101.4D', DESCR: 'PBF COMPRESSION SUCTION COOLER TRAIN 4', STATUS: 'INST', RAS: '2019-05-09', ETA_RAS: -228, PND: '2019-08-16', FLOAT: -129, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-01', },
      { NO: 5, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-EC-0106-1B', DESCR: 'AIR COOLED CONDENSER', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-07-03', },
      { NO: 6, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0102A-ISW-1', DESCR: 'BATTERY ISOLATION SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 6, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0103B', DESCR: 'EMERGENCY EXTRACT FAN (CENTRIFUGAL)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-03', },
      { NO: 6, MOD: '41M001', PAU: 'C', DISC: 'Mech.', TAG: '41-EA-108.4A', DESCR: 'PBF COMPRESSOR TRAIN 4 LUBEOIL COOLER', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-09-10', FLOAT: -104, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-01', },
      { NO: 7, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0102B-ISW-2', DESCR: 'BATTERY TEST SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-30', },
      { NO: 7, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0103A', DESCR: 'EMERGENCY EXTRACT FAN (CENTRIFUGAL)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-03', },
      { NO: 8, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0102B-ISW-1', DESCR: 'BATTERY ISOLATION SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 8, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0102B', DESCR: 'NORMAL EXTRACT FAN (CENTRIFUGAL)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-03', },
      { NO: 9, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0101B-ISW-2', DESCR: 'BATTERY TEST SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-11-02', },
      { NO: 9, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0102A', DESCR: 'NORMAL EXTRACT FAN (CENTRIFUGAL)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-03', },
      { NO: 10, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0101B-ISW-1', DESCR: 'BATTERY ISOLATION SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 10, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0105A', DESCR: 'BOOST SUPPLY FAN (AXIAL FAN)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-06', },
      { NO: 11, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0101A-ISW-1', DESCR: 'BATTERY ISOLATION SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 11, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-BH-0105B', DESCR: 'BOOST SUPPLY FAN (AXIAL FAN)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-06-07', FLOAT: -199, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-06', },
      { NO: 12, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-BAT-0101A-ISW-2', DESCR: 'BATTERY TEST SWITCH', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-10-30', },
      { NO: 12, MOD: '41M001', PAU: 'B', DISC: 'Mech.', TAG: '41-T-108.4', DESCR: 'TBA(Navisworks Item Name and CPI Tag No. are not matched )', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-05-17', FLOAT: -220, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-20', },
      { NO: 13, MOD: '41M001', PAU: 'A', DISC: 'HVAC', TAG: '41-CD-0136A', DESCR: 'SPLIT AC-1 FOR 41-RIE-0101', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-12', },
      { NO: 13, MOD: '41M001', PAU: 'B', DISC: null, TAG: '41-SL-0101', DESCR: 'TBA(Navisworks Item Name and CPI Tag No. are not matched )', STATUS: 'INST', RAS: null, ETA_RAS: null, PND: null, FLOAT: null, ETA: null, ATA: null, EREC_DATE: '2019-07-25', },
      { NO: 14, MOD: '41M001', PAU: 'A', DISC: 'HVAC', TAG: '41-CD-0136B', DESCR: 'SPLIT AC-2 FOR 41-RIE-0101', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-12', },
      { NO: 14, MOD: '41M001', PAU: 'B', DISC: null, TAG: '41-SL-0106', DESCR: 'TBA(Navisworks Item Name and CPI Tag No. are not matched )', STATUS: 'INST', RAS: null, ETA_RAS: null, PND: null, FLOAT: null, ETA: null, ATA: null, EREC_DATE: '2019-06-05', },
      { NO: 15, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-AF-0102', DESCR: 'CHEMICAL AIR FILTER (PACKAGE)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-22', },
      { NO: 15, MOD: '41M001', PAU: 'A', DISC: 'HVAC', TAG: '41-EC-0137', DESCR: 'SPLIT ACCU-1 &2 (SKID UNIT) FOR  41-EEO-0101', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 16, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CX-010001', DESCR: 'HVAC UCP', STATUS: 'DEL', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: null, },
      { NO: 16, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-PNL-0106', DESCR: 'SWITCHBOARD REMOTE CONTROL PANEL FOR 41-MCC-0106A/B/C', STATUS: 'DEL', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: null, },
      { NO: 17, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CD-0106A', DESCR: 'AIR CONDITIONING UNIT', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-22', },
      { NO: 17, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-PNL-0101', DESCR: 'ECS & CBM INTERFACE PANEL', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 18, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CD-0106B', DESCR: 'AIR CONDITIONING UNIT', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-22', },
      { NO: 18, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-PNL-0107', DESCR: 'SWITCHBOARD REMOTE CONTROL PANEL FOR 41-MCC-0107A/B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-21', },
      { NO: 19, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CX-010010', DESCR: 'HEATING COIL THYRISTOR PANEL', STATUS: 'DEL', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: null, },
      { NO: 19, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-MCC-0106C', DESCR: '380V SWITCHBOARD BUS C', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-07', },
      { NO: 20, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CX-010011', DESCR: 'HEATING COIL THYRISTOR PANEL', STATUS: 'DEL', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: null, },
      { NO: 20, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-MCC-0106B', DESCR: '380V SWITCHBOARD BUS B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-07', },
      { NO: 21, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CX-010012', DESCR: 'HEATING COIL THYRISTOR PANEL', STATUS: 'DEL', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: null, },
      { NO: 21, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-MCC-0106A', DESCR: '380V SWITCHBOARD BUS A', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-07', },
      { NO: 22, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-CX-010013', DESCR: 'HEATING COIL THYRISTOR PANEL', STATUS: 'DEL', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: null, },
      { NO: 22, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-MCC-0107B', DESCR: '380V SWITCHBOARD BUS B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-07', },
      { NO: 23, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-AHU-0101A', DESCR: 'AIR HANDLING UNIT', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-22', },
      { NO: 23, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-MCC-0107A', DESCR: '380V SWITCHBOARD BUS A', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-12-07', },
      { NO: 24, MOD: '41M001', PAU: 'B', DISC: 'HVAC', TAG: '41-AHU-0101B', DESCR: 'AIR HANDLING UNIT', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-03-26', FLOAT: -272, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-22', },
      { NO: 24, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TFR-0106C', DESCR: 'TRANSFORMER FOR 41-MCC-0106C: RATING 500 kVA', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 25, MOD: '41M001', PAU: 'B', DISC: 'Mech.', TAG: '41-F-103.4', DESCR: 'PBF COMPRESSOR SUCTION DRUM TRAIN 4', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-02-26', FLOAT: -300, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-10', },
      { NO: 25, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TFR-0106B', DESCR: 'TRANSFORMER FOR 41-MCC-0106B: RATING 1.6/2.24 MVA', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 26, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TFR-0106A', DESCR: 'TRANSFORMER FOR 41-MCC-0106A: RATING 1.6/2.24 MVA', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 27, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TFR-0107B', DESCR: 'TRANSFORMER FOR 41-MCC-0107B: RATING 1.6/2.24 MVA', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 28, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TFR-0107A', DESCR: 'TRANSFORMER FOR 41-MCC-0107A: RATING 1.6/2.24 MVA', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-04', FLOAT: -353, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 29, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TDB-0101', DESCR: 'PROCESS HEAT TRACING DISTRIBUTION BOARD - TYPE T2B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 30, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TDB-0104', DESCR: 'PROCESS HEAT TRACING DISTRIBUTION BOARD - TYPE T2B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 31, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-PDB-0103', DESCR: 'POWER DISTRIBUTION BOARD - TYPE P6T', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 32, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-LDB-0106', DESCR: 'STANDBY LIGHTING DISTRIBUTION BOARD - TYPE L3B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 33, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-LDB-0103', DESCR: 'STANDBY LIGHTING DISTRIBUTION BOARD - TYPE L3T', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 34, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-LDB-0105', DESCR: 'NORMAL LIGHTING B DISTRIBUTION BOARD - TYPE L4B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 35, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-LDB-0102', DESCR: 'NORMAL LIGHTING B DISTRIBUTION BOARD - TYPE L4T', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 36, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-PDB-0101', DESCR: 'POWER DISTRIBUTION BOARD - TYPE P4T', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 37, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-LDB-0104', DESCR: 'NORMAL LIGHTING A DISTRIBUTION BOARD - TYPE L4B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 38, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-LDB-0101', DESCR: 'NORMAL LIGHTING A DISTRIBUTION BOARD - TYPE L4T', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 39, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TDB-0103', DESCR: 'PROCESS HEAT TRACING DISTRIBUTION BOARD - TYPE T2B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 40, MOD: '41M001', PAU: 'A', DISC: 'Electrical', TAG: '41-TDB-0102', DESCR: 'WINTERISATION HEAT TRACING DISTRIBUTION BOARD - TYPE T2B', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-06-06', },
      { NO: 41, MOD: '41M001', PAU: 'A', DISC: 'HVAC', TAG: '41-CD-0137B', DESCR: 'SPLIT AC-2 FOR 41-EEO-0101', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 42, MOD: '41M001', PAU: 'A', DISC: 'HVAC', TAG: '41-CD-0137A', DESCR: 'SPLIT AC-1 FOR 41-EEO-0101', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-09', FLOAT: -348, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-09', },
      { NO: 43, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-GC-101.4', DESCR: 'PBF COMPRESSOR TRAIN 4', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-02-22', FLOAT: -304, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-19', },
      { NO: 44, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-E-170.4', DESCR: '41-MGC-101.4 PBF COMPRESSOR MOTOR PURGE ELECTRIC HEATER', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-04-15', FLOAT: -252, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-04-30', },
      { NO: 45, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-G-103.4A', DESCR: 'PBF COMPRESSOR SUCTION DRUM PUMP (TRAIN 4)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-02-18', FLOAT: -308, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-09-27', },
      { NO: 46, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-G-103.4B', DESCR: 'PBF COMPRESSOR SUCTION DRUM PUMP (TRAIN 4)', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-02-18', FLOAT: -308, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-09-27', },
      { NO: 47, MOD: '41M001', PAU: 'A', DISC: 'Mech.', TAG: '41-F-9240.4', DESCR: 'PBF COMPRESSOR SEAL GAS BUFFER VESSEL TRAIN 4', STATUS: 'INST', RAS: '2019-03-08', ETA_RAS: -290, PND: '2019-01-10', FLOAT: -347, ETA: '2019-12-23', ATA: '2019-12-23', EREC_DATE: '2019-05-10', },

    ],

    Queries: {
      SQL1: [
              { TOTAL: 100, REQ: 50, PO: 50, DELIVERED: 75, INSTALLED: 89},
      ],
    },

}


