import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 550,
      CanvasHeight: 310,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: 'Outfitting Commodities Status', x: 20, y: 10, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      {DISC_DESCR:"Structure",ITEM:"Structure Prefabrication",UNIT:"TON",TOTAL:9,PLAN:null,ACTUAL:9,DELTA:-5},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:0,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:0},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:0},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:0,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:0,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:-1},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},

    ],
}