import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 900,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      { DISC: 'Structure',  OUTFIT: 'Steel Outfitting',   DSME:756,   CUR_QTY: 1042,  MTL_ACT: 1038,  MTL_PRO: 99.6,  INSTALL: 935,   INS_PRO: 89.7, },
      { DISC: 'Structure',  OUTFIT: 'Grating',            DSME:244,   CUR_QTY: 4420,  MTL_ACT: 4414,  MTL_PRO: 99.9,  INSTALL: 3264,  INS_PRO: 73.8, },
      { DISC: 'Piping',     OUTFIT: 'Support',            DSME:1803,  CUR_QTY: 1797,  MTL_ACT: 1797,  MTL_PRO: 100,   INSTALL: 1572,  INS_PRO: 87.5, },
      { DISC: 'Piping',     OUTFIT: 'Spool',              DSME:2760,  CUR_QTY: 3019,  MTL_ACT: 3010,  MTL_PRO: 99.7,  INSTALL: 2643,  INS_PRO: 87.5, },
      { DISC: 'E&I',        OUTFIT: 'Support',            DSME:11230, CUR_QTY: 11370, MTL_ACT: 11369, MTL_PRO: 100,   INSTALL: 10572, INS_PRO: 93, },
      { DISC: 'E&I',        OUTFIT: 'Cable Tray',         DSME:6594,  CUR_QTY: 8378,  MTL_ACT: 8374,  MTL_PRO: 100,   INSTALL: 7704,  INS_PRO: 92, },
      { DISC: 'HVAC',       OUTFIT: 'Duct Support',       DSME:539,   CUR_QTY: 698,   MTL_ACT: 698,   MTL_PRO: 100,   INSTALL: 667,   INS_PRO: 95.6, },
      { DISC: 'HVAC',       OUTFIT: 'Duct',               DSME:1484,  CUR_QTY: 903,   MTL_ACT: 903,   MTL_PRO: 100,   INSTALL: 821,   INS_PRO: 90.9, },
      { DISC: 'Mechanical', OUTFIT: 'Seat',               DSME:40,    CUR_QTY: 210,   MTL_ACT: 209,   MTL_PRO: 99.5,  INSTALL: 22,    INS_PRO: 10.5, },    
    ],
}