import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 920,
      CanvasHeight: 470,
      CanvasBorderWeight: 0.05,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.1,

      CanvasChartX: 50,
      CanvasChartY: 50,
      CanvasChartWidth: 480,
      CanvasChartHeight: 250,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
                { name: '3B4', date: '2019-04-23', STATUS: 'EREC', PRO: '100', },			
                { name: '3D9', date: '2019-05-20', STATUS: 'EREC', PRO: '100', },			
                { name: '3G3', date: '2019-06-20', STATUS: 'EREC', PRO: '100', },			
                { name: '3G9', date: '2019-05-20', STATUS: 'EREC', PRO: '100', },			
                { name: '3K2', date: '2019-08-23', STATUS: 'EREC', PRO: '100', },			
                { name: '3C5', date: '2019-05-17', STATUS: 'EREC', PRO: '100', },			
                { name: '3J7', date: '2019-09-27', STATUS: 'EREC', PRO: '100', },			
                { name: '3B2', date: '2019-04-24', STATUS: 'EREC', PRO: '100', },			
                { name: '3B6', date: '2019-04-30', STATUS: 'EREC', PRO: '100', },			
                { name: '3C2', date: '2019-05-08', STATUS: 'EREC', PRO: '100', },			
                { name: '3F4', date: '2019-06-03', STATUS: 'EREC', PRO: '100', },			
                { name: '3G1', date: '2019-07-03', STATUS: 'EREC', PRO: '100', },			
                { name: '3G2', date: '2019-06-19', STATUS: 'EREC', PRO: '100', },			
                { name: '3B7', date: '2019-04-22', STATUS: 'EREC', PRO: '100', },			
                { name: '3C3', date: '2019-05-03', STATUS: 'EREC', PRO: '100', },			
                { name: '3X4', date: '2019-06-03', STATUS: 'EREC', PRO: '100', },			
                { name: '3A3', date: '2019-03-04', STATUS: 'EREC', PRO: '100', },			
                { name: '3B5', date: '2019-04-29', STATUS: 'EREC', PRO: '100', },			
                { name: '3C9', date: '2019-04-23', STATUS: 'EREC', PRO: '100', },			
                { name: '3D2', date: '2019-05-20', STATUS: 'EREC', PRO: '100', },			
                { name: '3F3', date: '2019-06-12', STATUS: 'EREC', PRO: '100', },			
                { name: '3K1', date: '2019-08-23', STATUS: 'EREC', PRO: '100', },			
                { name: '3AA', date: '2019-05-10', STATUS: 'EREC', PRO: '100', },			
                { name: '3DA', date: '2019-06-05', STATUS: 'EREC', PRO: '100', },			
                { name: '3DB', date: '2019-05-31', STATUS: 'EREC', PRO: '100', },			
                { name: '3A2', date: '2019-03-05', STATUS: 'EREC', PRO: '100', },			
                { name: '3A4', date: '2019-02-25', STATUS: 'EREC', PRO: '100', },			
                { name: '3G4', date: '2019-06-27', STATUS: 'EREC', PRO: '100', },			
                { name: '3H2', date: '2019-07-24', STATUS: 'EREC', PRO: '100', },			
                { name: '3HA', date: '2019-07-26', STATUS: 'EREC', PRO: '100', },			
                { name: '3JA', date: '2019-09-16', STATUS: 'EREC', PRO: '100', },			
                { name: '3B8', date: '2019-05-03', STATUS: 'EREC', PRO: '100', },			
                { name: '3C4', date: '2019-05-07', STATUS: 'EREC', PRO: '100', },			
                { name: '3H1', date: '2019-07-25', STATUS: 'EREC', PRO: '100', },			
                { name: '3K4', date: '2019-08-21', STATUS: 'EREC', PRO: '100', },			
                { name: '3B3', date: '2019-04-30', STATUS: 'EREC', PRO: '100', },			
                { name: '3J1', date: '2019-09-19', STATUS: 'EREC', PRO: '100', },			
                { name: '3K3', date: '2019-08-21', STATUS: 'EREC', PRO: '100', },			
                { name: '3X3', date: '2019-05-21', STATUS: 'EREC', PRO: '100', },			
  ],

  // DataItems2: [
  //     { name: 'M078-A', date: '2019-12-21', STATUS: 'NOT', PRO: 0, STAGE: 'A',},
  //     { name: 'M078-B', date: '2019-12-21', STATUS: 'NOT', PRO: 0, STAGE: 'A',},
  //     { name: 'M008-B', date: '2019-12-30', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M008-A', date: '2019-12-30', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M057-A', date: '2019-12-30', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M057-C', date: '2019-12-31', STATUS: 'NOT', PRO: 0, STAGE: 'B',},
  //     { name: 'M057-B', date: '2019-12-31', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M075-C', date: '2020-01-21', STATUS: 'CMP', PRO: 0, STAGE: null,},
  //     { name: 'M075-B', date: '2020-01-21', STATUS: 'CMP', PRO: 0, STAGE: null,},
  //     { name: 'M075-A', date: '2020-01-21', STATUS: 'CMP', PRO: 0, STAGE: 'B',},
  //     { name: 'M065-A', date: '2020-01-21', STATUS: 'CMP', PRO: 0, STAGE: null,},
  //     { name: 'M010-B', date: '2020-01-23', STATUS: 'CMP', PRO: 0, STAGE: null,},
  //     { name: 'M065-C', date: '2020-01-23', STATUS: 'CMP', PRO: 0, STAGE: 'A',},
  //     { name: 'M065-B', date: '2020-01-23', STATUS: 'CMP', PRO: 0, STAGE: 'A',},
  //     { name: 'M010-A', date: '2020-01-23', STATUS: 'CMP', PRO: 0, STAGE: 'B',},
  //     { name: 'M056-B', date: '2020-02-07', STATUS: 'PRO', PRO: 30, STAGE: null,},
  //     { name: 'M054-C', date: '2020-02-07', STATUS: 'PRO', PRO: 30, STAGE: null,},
  //     { name: 'M011-A', date: '2020-02-11', STATUS: 'PRO', PRO: 30, STAGE: null,},
  //     { name: 'M056-A', date: '2020-02-11', STATUS: 'PRO', PRO: 30, STAGE: 'A',},
  //     { name: 'M011-B', date: '2020-02-11', STATUS: 'PRO', PRO: 30, STAGE: null,},
  //     { name: 'M056-C', date: '2020-02-11', STATUS: 'PRO', PRO: 0, STAGE: null,},
  //     { name: 'M055-C', date: '2020-02-18', STATUS: 'CMP', PRO: 0, STAGE: null,},
  //     { name: 'M055-B', date: '2020-02-18', STATUS: 'CMP', PRO: 0, STAGE: 'B',},
  //     { name: 'M009-B', date: '2020-02-22', STATUS: 'PRO', PRO: 70, STAGE: null,},
  //     { name: 'M009-A', date: '2020-02-22', STATUS: 'PRO', PRO: 90, STAGE: null,},
  //     { name: 'M055-A', date: '2020-03-01', STATUS: 'CMP', PRO: 0, STAGE: null,},
  //     { name: 'M054-B', date: '2020-03-01', STATUS: 'PRO', PRO: 0, STAGE: null,},
  //     { name: 'M054-A', date: '2020-03-01', STATUS: 'PRO', PRO: 50, STAGE: 'A',},
  //     { name: 'M265-A', date: '2020-03-08', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M801-C', date: '2020-03-08', STATUS: 'PRO', PRO: 40, STAGE: null,},
  //     { name: 'M801-B', date: '2020-03-08', STATUS: 'PRO', PRO: 0, STAGE: null,},
  //     { name: 'M801-A', date: '2020-03-08', STATUS: 'PRO', PRO: 0, STAGE: null,},
  //     { name: 'M266-A', date: '2020-03-26', STATUS: 'NOT', PRO: 0, STAGE: 'B',},
  //     { name: 'M007-B', date: '2020-03-26', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M007-A', date: '2020-03-26', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M006-B', date: '2020-04-04', STATUS: 'PRO', PRO: 30, STAGE: null,},
  //     { name: 'M006-C', date: '2020-04-04', STATUS: 'PRO', PRO: 30, STAGE: null,},
  //     { name: 'M268-A', date: '2020-04-04', STATUS: 'NOT', PRO: 0, STAGE: 'A',},
  //     { name: 'M006-A', date: '2020-04-04', STATUS: 'PRO', PRO: 10, STAGE: null,},
  //     { name: 'M001-C', date: '2020-05-21', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M267-A', date: '2020-05-21', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M267-B', date: '2020-05-21', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M001-B', date: '2020-05-21', STATUS: 'NOT', PRO: 0, STAGE: 'B',},
  //     { name: 'M004-B', date: '2020-05-29', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M004-C', date: '2020-05-29', STATUS: 'NOT', PRO: 0, STAGE: 'A',},
  //     { name: 'M004-A', date: '2020-06-04', STATUS: 'NOT', PRO: 0, STAGE: 'A',},
  //     { name: 'M001-A', date: '2020-06-04', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M003-B', date: '2020-06-16', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M261-C', date: '2020-06-16', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M261-B', date: '2020-06-16', STATUS: 'NOT', PRO: 0, STAGE: 'B',},
  //     { name: 'M003-C', date: '2020-06-16', STATUS: 'NOT', PRO: 0, STAGE: 'A',},
  //     { name: 'M261-A', date: '2020-06-30', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M262-C', date: '2020-06-30', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M262-A', date: '2020-06-30', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M262-B', date: '2020-06-30', STATUS: 'NOT', PRO: 0, STAGE: 'B',},
  //     { name: 'M263-A', date: '2020-07-04', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M263-B', date: '2020-07-04', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //     { name: 'M003-A', date: '2020-07-04', STATUS: 'NOT', PRO: 0, STAGE: null,},
  //   ],
}