import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1470,
    CanvasHeight: 1000,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Note: {
    NoteDisplay: 'N',
    NoteX: 633,
    NoteY: 358,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',
    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },
  Text: {
    Text: [
      { text: 'HSSE Overview', x: 30, y: 20, font: 'roboto', class: 'regular', size: 24, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD', x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: 30,
        y: 65,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
      {
        query: "SELECT WEEK_NO FROM [T01_Project].[DataSource].[Document] WHERE PHASE = 'HSSE' AND CODE = 'KPI'", 
        text: 'W44',
        x: 100,
        y: 149,
        font: 'roboto',
        style: 'regular',
        size: 16,
        color: '#D81B60',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      { DESCR: 'LTIF', CUM: 0.074, },
      { DESCR: 'TRCF', CUM: 0.11, },
      { DESCR: 'Near Misses', CUM: 0, },
    ],
    SQL2: [
      {CODE:"Steel Cutting",SEQ:1,DESCR:"Workplace and Site Condition",PRE_WEEK:6,THIS_WEEK:9},
      {CODE:"Steel Cutting",SEQ:1,DESCR:"PPE",PRE_WEEK:4,THIS_WEEK:10},
      {CODE:"Steel Cutting",SEQ:1,DESCR:"Hazards and Activities",PRE_WEEK:3,THIS_WEEK:0},
      {CODE:"Steel Cutting",SEQ:1,DESCR:"HSE Management System",PRE_WEEK:0,THIS_WEEK:0},
      {CODE:"Assembly",SEQ:2,DESCR:"Workplace and Site Condition",PRE_WEEK:31,THIS_WEEK:16},
      {CODE:"Assembly",SEQ:2,DESCR:"PPE",PRE_WEEK:25,THIS_WEEK:24},
      {CODE:"Assembly",SEQ:2,DESCR:"Hazards and Activities",PRE_WEEK:10,THIS_WEEK:8},
      {CODE:"Assembly",SEQ:2,DESCR:"HSE Management System",PRE_WEEK:4,THIS_WEEK:6},
      {CODE:"Skidway",SEQ:3,DESCR:"Workplace and Site Condition",PRE_WEEK:72,THIS_WEEK:59},
      {CODE:"Skidway",SEQ:3,DESCR:"PPE",PRE_WEEK:36,THIS_WEEK:21},
      {CODE:"Skidway",SEQ:3,DESCR:"Hazards and Activities",PRE_WEEK:24,THIS_WEEK:16},
      {CODE:"Skidway",SEQ:3,DESCR:"HSE Management System",PRE_WEEK:8,THIS_WEEK:4},
      {CODE:"Outfitting",SEQ:4,DESCR:"Workplace and Site Condition",PRE_WEEK:19,THIS_WEEK:16},
      {CODE:"Outfitting",SEQ:4,DESCR:"PPE",PRE_WEEK:32,THIS_WEEK:30},
      {CODE:"Outfitting",SEQ:4,DESCR:"Hazards and Activities",PRE_WEEK:26,THIS_WEEK:22},
      {CODE:"Outfitting",SEQ:4,DESCR:"HSE Management System",PRE_WEEK:14,THIS_WEEK:10},
      {CODE:"Hull Section",SEQ:5,DESCR:"Workplace and Site Condition",PRE_WEEK:56,THIS_WEEK:49},
      {CODE:"Hull Section",SEQ:5,DESCR:"PPE",PRE_WEEK:34,THIS_WEEK:38},
      {CODE:"Hull Section",SEQ:5,DESCR:"Hazards and Activities",PRE_WEEK:27,THIS_WEEK:27},
      {CODE:"Hull Section",SEQ:5,DESCR:"HSE Management System",PRE_WEEK:4,THIS_WEEK:6},
      {CODE:"B&P",SEQ:6,DESCR:"Workplace and Site Condition",PRE_WEEK:0,THIS_WEEK:2},
      {CODE:"B&P",SEQ:6,DESCR:"PPE",PRE_WEEK:8,THIS_WEEK:9},
      {CODE:"B&P",SEQ:6,DESCR:"Hazards and Activities",PRE_WEEK:3,THIS_WEEK:0},
      {CODE:"B&P",SEQ:6,DESCR:"HSE Management System",PRE_WEEK:0,THIS_WEEK:0},
      {CODE:"Living Quarter",SEQ:7,DESCR:"Workplace and Site Condition",PRE_WEEK:28,THIS_WEEK:25},
      {CODE:"Living Quarter",SEQ:7,DESCR:"PPE",PRE_WEEK:16,THIS_WEEK:12},
      {CODE:"Living Quarter",SEQ:7,DESCR:"Hazards and Activities",PRE_WEEK:25,THIS_WEEK:17},
      {CODE:"Living Quarter",SEQ:7,DESCR:"HSE Management System",PRE_WEEK:5,THIS_WEEK:4},
      {CODE:"Nantong Yard",SEQ:8,DESCR:"Workplace and Site Condition",PRE_WEEK:10,THIS_WEEK:14},
      {CODE:"Nantong Yard",SEQ:8,DESCR:"PPE",PRE_WEEK:14,THIS_WEEK:22},
      {CODE:"Nantong Yard",SEQ:8,DESCR:"Hazards and Activities",PRE_WEEK:1,THIS_WEEK:2},
      {CODE:"Nantong Yard",SEQ:8,DESCR:"HSE Management System",PRE_WEEK:1,THIS_WEEK:1},
      {CODE:"Honghua Yard",SEQ:9,DESCR:"Workplace and Site Condition",PRE_WEEK:6,THIS_WEEK:4},
      {CODE:"Honghua Yard",SEQ:9,DESCR:"PPE",PRE_WEEK:4,THIS_WEEK:0},
      {CODE:"Honghua Yard",SEQ:9,DESCR:"Hazards and Activities",PRE_WEEK:2,THIS_WEEK:1},
      {CODE:"Honghua Yard",SEQ:9,DESCR:"HSE Management System",PRE_WEEK:0,THIS_WEEK:0}
    ],
    SQL3: [
      {SEQ:1,DESCR:'Project HSE Leadership Visits',WEEKLY:0,CUM:50},
      {SEQ:2,DESCR:'SV Total ',WEEKLY:10,CUM:440},
      {SEQ:2,DESCR:'HSE Mgt. Walkthrough',WEEKLY:1,CUM:70},
      {SEQ:3,DESCR:'Pulse for workforce',WEEKLY:0,CUM:210},
      {SEQ:3,DESCR:'Pulse for leadership',WEEKLY:0,CUM:2},
      {SEQ:4,DESCR:'Number of Safety flash ',WEEKLY:1,CUM:43},
      {SEQ:4,DESCR:'Number of HSE Training Man-hours',WEEKLY:279,CUM:16491},
      {SEQ:5,DESCR:'Number of TBT assessments',WEEKLY:131,CUM:3862},
      {SEQ:5,DESCR:'Number of Pre start  TBT',WEEKLY:540,CUM:19033},
      {SEQ:5,DESCR:'Number of Mass Toolbox Talks',WEEKLY:1,CUM:79},
      {SEQ:6,DESCR:'STOP Work Obs',WEEKLY:28,CUM:613},
      {SEQ:6,DESCR:'Number of hazard Observations Cards',WEEKLY:860,CUM:54278}
    ],
    SQL4: [
      {SEQ:1,DESCR:'Fatalities',WEEKLY:0,CUM:0},
      {SEQ:2,DESCR:'Lost Time Injuries',WEEKLY:0,CUM:2},
      {SEQ:3,DESCR:'Restricted Work Cases',WEEKLY:0,CUM:1},
      {SEQ:4,DESCR:'Medical Treat. Injuries',WEEKLY:0,CUM:0},
      {SEQ:5,DESCR:'First Aid Treat.',WEEKLY:0,CUM:4},
      {SEQ:6,DESCR:'Property damage',WEEKLY:0,CUM:4},
      {SEQ:6,DESCR:'LSR violation /UA/US',WEEKLY:3,CUM:66},
      {SEQ:6,DESCR:'Near Misses',WEEKLY:1,CUM:0}
    ],
    SQL5:[
      {DESCR:"Bypassing Safety Controls",CUM:9},
      {DESCR:"Driving",WEEKLY:1,CUM:5},
      {DESCR:"Energy Isolation",CUM:7},
      {DESCR:"Hot Work",WEEKLY:2,CUM:10},
      {DESCR:"Safe Mechanical Lifting",CUM:6},
      {DESCR:"Work Authorization",CUM:11},
      {DESCR:"Working at Height",WEEKLY:1,CUM:7},
      {DESCR:"Confined Space",WEEKLY:1,CUM:8},
      {DESCR:"Line of Fire",CUM:6}
    ],
    SQL6:[
      {MONTH:"Mar",DESCR:"Working at Height",COUNT:3},
      {MONTH:"Mar",DESCR:"Confined Space",COUNT:1},
      {MONTH:"Apr",DESCR:"Energy Isolation",COUNT:1},
      {MONTH:"Apr",DESCR:"Confined Space",COUNT:1},
      {MONTH:"Apr",DESCR:"Line of Fire",COUNT:2},
      {MONTH:"May",DESCR:"Safe Mechanical Lifting",COUNT:1},
      {MONTH:"May",DESCR:"Work Authorization",COUNT:1},
      {MONTH:"May",DESCR:"Confined Space",COUNT:1},
      {MONTH:"Jun",DESCR:"Working at Height",COUNT:2},
      {MONTH:"Jun",DESCR:"Confined Space",COUNT:3},
      {MONTH:"Jul",DESCR:"Working at Height",COUNT:1},
      {MONTH:"Jul",DESCR:"Confined Space",COUNT:2},
      {MONTH:"Aug",DESCR:"Safe Mechanical Lifting",COUNT:1},
      {MONTH:"Aug",DESCR:"Working at Height",COUNT:1},
      {MONTH:"Aug",DESCR:"Confined Space",COUNT:4},
      {MONTH:"Sep",DESCR:"Safe Mechanical Lifting",COUNT:1},
      {MONTH:"Sep",DESCR:"Work Authorization",COUNT:1},
      {MONTH:"Sep",DESCR:"Working at Height",COUNT:1},
      {MONTH:"Sep",DESCR:"Line of Fire",COUNT:1},
      {MONTH:"Oct",DESCR:"Work Authorization",COUNT:1},
      {MONTH:"Oct",DESCR:"Working at Height",COUNT:2},
      {MONTH:"Oct",DESCR:"Confined Space",COUNT:2},
      {MONTH:"Nov",DESCR:"Work Authorization",COUNT:1},
      {MONTH:"Nov",DESCR:"Working at Height",COUNT:1},
      {MONTH:"Nov",DESCR:"Confined Space",COUNT:2},
      {MONTH:"Dec",DESCR:"Safe Mechanical Lifting",COUNT:1},
      {MONTH:"Dec",DESCR:"Working at Height",COUNT:2},
      {MONTH:"Dec",DESCR:"Confined Space",COUNT:1}
    ],
    SQL7:[
      {SEQ:1,CODE:"Steel Cutting"},
      {SEQ:2,CODE:"Assembly"},
      {SEQ:3,CODE:"Skidway"},
      {SEQ:4,CODE:"Outfitting"},
      {SEQ:5,CODE:"Dry Dock"},
      {SEQ:6,CODE:"B&P"},
      {SEQ:7,CODE:"Living Quarter"},
      {SEQ:8,CODE:"Module Fitting Area"},
      {SEQ:9,CODE:"Nantong Yard"},
      {SEQ:10,CODE:"Honghua Yard"}
    ],
    SQL8:[
      {MONTH:"Feb"},
      {MONTH:"Mar"},
      {MONTH:"Apr"},
      {MONTH:"May"},
      {MONTH:"Jun"},
      {MONTH:"Jul"},
      {MONTH:"Aug"},
      {MONTH:"Sep"},
      {MONTH:"Oct"},
      {MONTH:"Nov"},
      {MONTH:"Dec"},
      {MONTH:"Jan"}
    ],
  },
  DataItems: [
    {WK_NO:"W2020_01",CUTOFF:"2020-01-03T00:00:00",MONTH:"Jan",CATEGORY:"LEADING",CODE:"LEADING_1_1",SEQ:1,DESCR:"Project HSE Leadership Visits",CUM:0},
    {WK_NO:"W2020_01",CUTOFF:"2020-01-03T00:00:00",MONTH:"Jan",CATEGORY:"LEADING",CODE:"LEADING_2_1",SEQ:2,DESCR:"HSE Mgt. Walkthrough",CUM:0},
    {WK_NO:"W2020_01",CUTOFF:"2020-01-03T00:00:00",MONTH:"Jan",CATEGORY:"LEADING",CODE:"LEADING_2_2",SEQ:2,DESCR:"SV Total ",CUM:0},
    {WK_NO:"W2020_01",CUTOFF:"2020-01-03T00:00:00",MONTH:"Jan",CATEGORY:"LEADING",CODE:"LEADING_3_1",SEQ:3,DESCR:"Pulse for leadership",CUM:0},
  ],
}