import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 290,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { DISC: 'Architecture', TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 14.54, },
      { DISC: 'Electrical',   TOTAL: 43742,   ACTUAL: 25361,  REMAIN: 18381,  PROG: 57.98, },
      { DISC: 'HVAC',         TOTAL: 6104,    ACTUAL: 4272,   REMAIN: 1832,   PROG: 69.99, },
      { DISC: 'Instrument',   TOTAL: 54380,   ACTUAL: 32939,  REMAIN: 21441,  PROG: 60.57, },
      { DISC: 'Mechanical',   TOTAL: 6512,    ACTUAL: 3533,   REMAIN: 2979,   PROG: 54.25, },
      { DISC: 'PI',           TOTAL: 35215,   ACTUAL: 21704,  REMAIN: 13511,  PROG: 61.63, },
      { DISC: 'Safety',       TOTAL: 915,     ACTUAL: 40,     REMAIN: 875,    PROG: 4.37, },
      { DISC: 'Telecom',      TOTAL: 8391,    ACTUAL: 3566,   REMAIN: 4825,   PROG: 42.5, },
      { DISC: 'Overall',      TOTAL: 156703,  ACTUAL: 91625,  REMAIN: 65078,  PROG: 58.47, },
    ],


    Queries: {
      // Rundown ITR A
      SQL1: [      
        // { CDATE: '2020-01-17', CUM_REM: 246, CUM_COM: 50, PERIOD: 6, },
        // { CDATE: '2020-01-24', CUM_REM: 246, CUM_COM: 50, PERIOD: null, },
        // { CDATE: '2020-01-31', CUM_REM: 242, CUM_COM: 54, PERIOD: 4, },
        // { CDATE: '2020-02-07', CUM_REM: 236, CUM_COM: 60, PERIOD: 6, },
        { CDATE: '2020-02-14', CUM_REM: 234, CUM_COM: 62, PERIOD: 2, },
        { CDATE: '2020-02-21', CUM_REM: 234, CUM_COM: 62, PERIOD: null, },
        { CDATE: '2020-02-28', CUM_REM: 232, CUM_COM: 64, PERIOD: 2, },
        { CDATE: '2020-03-06', CUM_REM: 231, CUM_COM: 65, PERIOD: 1, },        
      ],
 
      
      
      
    },
}