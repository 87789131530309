/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/
//-1. Common
import CHV2_MiniTable_A_V0_Data        from '../10_China1/Common/Mini_Tables/Mini_Table_A_V0/includes/primitives/data'
import CHV2_MiniTable_B_V0_Data        from '../10_China1/Common/Mini_Tables/Mini_Table_B_V0/includes/primitives/data'
import CHV2_MiniTable_C_V0_Data        from '../10_China1/Common/Mini_Tables/Mini_Table_C_V0/includes/primitives/data'
import CHV2_MiniTable_D_V0_Data        from '../10_China1/Common/Mini_Tables/Mini_Table_D_V0/includes/primitives/data'
import CHV2_MiniTable_D_V1_Data        from '../10_China1/Common/Mini_Tables/Mini_Table_D_V1/includes/primitives/data'

import CHV2_ReusableTable_v1_Data      from '../10_China1/Common/Data_Tables/ReusableTable_v1/includes/primitives/data'

//00_Home
//01.Project
import CHV2_InformationGuide_Data        from '../10_China1/Project/Information_Guide/includes/primitives/data'
import CHV2_InformationGuideV2_Data      from '../10_China1/Project/InformationGuide_v2/includes/primitives/data'
import CHV2_Construction_Photo_Data      from '../10_China1/Project/Construction_Photo/includes/primitives/data'
//02_Engineering
import CHV2_AreaSummary_Data             from '../10_China1/Engineering/Area_Summary/Progress/includes/primitives/data'
//03.Planning
import CHV2_Level1ScheduleNew_Data       from '../10_China1/Planning/Level1ScheduleNew/includes/primitives/Data'
//04_Construction
//05_Procurement
//06_QualityControl
//08.SystemCompletion
import CHV2_UtilityCommissioning_Data    from '../10_China1/SystemCompletion/UtilityCommissioning/includes/primitives/data'
import CHV2_UtilityCommissioningV2_Data  from '../10_China1/SystemCompletion/UtilityCommissioningV2/includes/primitives/data'
import CHV2_CsuSequence_P1_Data          from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P1/includes/primitives/data'
import CHV2_CsuSequence_P2_GPPE_Data     from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_GPPE/includes/primitives/data'
import CHV2_CsuSequence_P2_LDPE_Data     from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_LDPE/includes/primitives/data'
import CHV2_CsuSequence_P2_PP_Data       from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_PP/includes/primitives/data'
import CHV2_CsuSequence_P3_Data          from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P3/includes/primitives/data'
//09.Temp



export default {
  // -1_Common
  CHV2_MiniTable_A_V0_Data,
  CHV2_MiniTable_B_V0_Data,
  CHV2_MiniTable_C_V0_Data,
  CHV2_MiniTable_D_V0_Data,
  CHV2_MiniTable_D_V1_Data,
  CHV2_ReusableTable_v1_Data,
  //00_Home
  //01.Project
  CHV2_InformationGuide_Data,
  CHV2_InformationGuideV2_Data,
  CHV2_Construction_Photo_Data,
  //02_Engineering
  //03.Planning
  CHV2_Level1ScheduleNew_Data,
  //04_Construction
  //05_Procurement
  //06_QualityControl
  //08.SystemCompletion
  CHV2_UtilityCommissioning_Data,
  CHV2_UtilityCommissioningV2_Data,
  CHV2_CsuSequence_P1_Data,
  CHV2_CsuSequence_P2_GPPE_Data,
  CHV2_CsuSequence_P2_LDPE_Data,
  CHV2_CsuSequence_P2_PP_Data,
  CHV2_CsuSequence_P3_Data,
  //09.Temp
  CHV2_AreaSummary_Data,
}
