import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 860,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { title: 'System No.',            value: '1010', },
      { title: 'System Description',    value: 'Production Flowlines- FPU Components 1 & 2', },
      { title: 'Subsystem No.',         value: '1010-01', },
      { title: 'Subsystem Description', value: 'Production Flowlines 1', },
      { title: 'Discipline',            value: 'Process', },
      { title: 'System Owner',          value: 'David.Nealings@bp.com', },
      { title: 'Walkdown Date',         value: '2020-03-15', },
      { title: 'Walkdown Actual',       value: null, },
      { title: 'MC Date',               value: '2020-03-15', },
      { title: 'MC F/C',                value: '2020-03-15', },
      { title: 'MC Actual',             value: '2020-03-15', },
      { title: 'Commissioning Date',               value: '2020-03-15', },
      { title: 'COM F/C',                value: '2020-03-15', },
    ],


    Queries: {
      // ITR A Overall
      SQL1: [
              { TOTAL: 296, ACTUAL: 65, REMAIN: 231, PROG: 21.96, },
      ],
      // Punch Status
      SQL2: [      
              { CAT: 'A', TOTAL: 48, ACTUAL: 42, REMAIN: 6, PROG: 87.5, },
              { CAT: 'B', TOTAL: 86, ACTUAL: 71, REMAIN: 15, PROG: 82.56, },
      ],
      // ITR A by Discipline
      SQL3: [      
              { LINK1: 'IC', DISC: 'Instrument', TOTAL: 193, ACTUAL: 32, REMAIN: 161, PROG: 16.58, },
              { LINK1: 'ME', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 3, REMAIN: 7, PROG: 30, },
              { LINK1: 'PI', DISC: 'Piping', TOTAL: 93, ACTUAL: 30, REMAIN: 63, PROG: 32.26, },

      ],
      // Punch by Discipline
      SQL4: [      
              { CAT: 'A', LINK1: null, DISC: 'Overall', TOTAL: 48, ACTUAL: 42, REMAIN: 6, PROG: 87.5, },
              { CAT: 'A', LINK1: 'PI', DISC: 'Piping', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
              { CAT: 'A', LINK1: 'ME', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
              { CAT: 'A', LINK1: 'EL', DISC: 'Electrical', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
              { CAT: 'A', LINK1: 'IC', DISC: 'Instrument', TOTAL: 38, ACTUAL: 34, REMAIN: 4, PROG: 89.47, },
              { CAT: 'B', LINK1: null, DISC: 'Overall', TOTAL: 86, ACTUAL: 71, REMAIN: 15, PROG: 82.56, },
              { CAT: 'B', LINK1: 'PI', DISC: 'Piping', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
              { CAT: 'B', LINK1: 'ME', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
              { CAT: 'B', LINK1: 'IC', DISC: 'Instrument', TOTAL: 59, ACTUAL: 46, REMAIN: 13, PROG: 77.97, },
              { CAT: 'B', LINK1: 'HV', DISC: 'HVAC', TOTAL: 7, ACTUAL: 5, REMAIN: 2, PROG: 71.43, },
      ],

      // Rundown ITR A
      SQL5: [      
              { CDATE: '2019-08-16', CUM_REM: 294, CUM_COM: 2, PERIOD: null, },
              { CDATE: '2019-08-23', CUM_REM: 278, CUM_COM: 18, PERIOD: 16, },
              { CDATE: '2019-08-30', CUM_REM: 278, CUM_COM: 18, PERIOD: null, },
              { CDATE: '2019-09-06', CUM_REM: 278, CUM_COM: 18, PERIOD: null, },
              { CDATE: '2019-09-13', CUM_REM: 278, CUM_COM: 18, PERIOD: null, },
              { CDATE: '2019-09-20', CUM_REM: 278, CUM_COM: 18, PERIOD: null, },
              { CDATE: '2019-09-27', CUM_REM: 277, CUM_COM: 19, PERIOD: 1, },
              { CDATE: '2019-10-04', CUM_REM: 277, CUM_COM: 19, PERIOD: null, },
              { CDATE: '2019-10-11', CUM_REM: 277, CUM_COM: 19, PERIOD: null, },
              { CDATE: '2019-10-18', CUM_REM: 277, CUM_COM: 19, PERIOD: null, },
              { CDATE: '2019-10-25', CUM_REM: 275, CUM_COM: 21, PERIOD: 2, },
              { CDATE: '2019-11-01', CUM_REM: 275, CUM_COM: 21, PERIOD: null, },
              { CDATE: '2019-11-08', CUM_REM: 273, CUM_COM: 23, PERIOD: 2, },
              { CDATE: '2019-11-15', CUM_REM: 272, CUM_COM: 24, PERIOD: 1, },
              { CDATE: '2019-11-22', CUM_REM: 270, CUM_COM: 26, PERIOD: 2, },
              { CDATE: '2019-11-29', CUM_REM: 266, CUM_COM: 30, PERIOD: 4, },
              { CDATE: '2019-12-06', CUM_REM: 264, CUM_COM: 32, PERIOD: 2, },
              { CDATE: '2019-12-13', CUM_REM: 260, CUM_COM: 36, PERIOD: 4, },
              { CDATE: '2019-12-20', CUM_REM: 259, CUM_COM: 37, PERIOD: 1, },
              { CDATE: '2019-12-27', CUM_REM: 257, CUM_COM: 39, PERIOD: 2, },
              { CDATE: '2020-01-03', CUM_REM: 254, CUM_COM: 42, PERIOD: 3, },
              { CDATE: '2020-01-10', CUM_REM: 252, CUM_COM: 44, PERIOD: 2, },
              { CDATE: '2020-01-17', CUM_REM: 246, CUM_COM: 50, PERIOD: 6, },
              { CDATE: '2020-01-24', CUM_REM: 246, CUM_COM: 50, PERIOD: null, },
              { CDATE: '2020-01-31', CUM_REM: 242, CUM_COM: 54, PERIOD: 4, },
              { CDATE: '2020-02-07', CUM_REM: 236, CUM_COM: 60, PERIOD: 6, },
              { CDATE: '2020-02-14', CUM_REM: 234, CUM_COM: 62, PERIOD: 2, },
              { CDATE: '2020-02-21', CUM_REM: 234, CUM_COM: 62, PERIOD: null, },
              { CDATE: '2020-02-28', CUM_REM: 232, CUM_COM: 64, PERIOD: 2, },
              { CDATE: '2020-03-06', CUM_REM: 231, CUM_COM: 65, PERIOD: 1, },        
      ],
      
      // Rundown ITR A
      SQL6: [      
              { CDATE: '2019-08-16', ACTIVE: 72, RAISED: 9, CLOSED: null, },
              { CDATE: '2019-08-23', ACTIVE: 64, RAISED: null, CLOSED: 8, },
              { CDATE: '2019-08-30', ACTIVE: 64, RAISED: null, CLOSED: null, },
              { CDATE: '2019-09-06', ACTIVE: 67, RAISED: 6, CLOSED: 3, },
              { CDATE: '2019-09-13', ACTIVE: 65, RAISED: 1, CLOSED: 3, },
              { CDATE: '2019-09-20', ACTIVE: 56, RAISED: null, CLOSED: 9, },
              { CDATE: '2019-09-27', ACTIVE: 54, RAISED: 5, CLOSED: 7, },
              { CDATE: '2019-10-04', ACTIVE: 55, RAISED: 6, CLOSED: 5, },
              { CDATE: '2019-10-11', ACTIVE: 60, RAISED: 7, CLOSED: 2, },
              { CDATE: '2019-10-18', ACTIVE: 54, RAISED: null, CLOSED: 6, },
              { CDATE: '2019-10-25', ACTIVE: 54, RAISED: 4, CLOSED: 4, },
              { CDATE: '2019-11-01', ACTIVE: 59, RAISED: 6, CLOSED: 1, },
              { CDATE: '2019-11-08', ACTIVE: 58, RAISED: 1, CLOSED: 2, },
              { CDATE: '2019-11-15', ACTIVE: 55, RAISED: 1, CLOSED: 4, },
              { CDATE: '2019-11-22', ACTIVE: 50, RAISED: 2, CLOSED: 7, },
              { CDATE: '2019-11-29', ACTIVE: 49, RAISED: null, CLOSED: 1, },
              { CDATE: '2019-12-06', ACTIVE: 48, RAISED: null, CLOSED: 1, },
              { CDATE: '2019-12-13', ACTIVE: 46, RAISED: null, CLOSED: 2, },
              { CDATE: '2019-12-20', ACTIVE: 43, RAISED: 4, CLOSED: 7, },
              { CDATE: '2019-12-27', ACTIVE: 40, RAISED: null, CLOSED: 3, },
              { CDATE: '2020-01-03', ACTIVE: 35, RAISED: null, CLOSED: 5, },
              { CDATE: '2020-01-10', ACTIVE: 34, RAISED: null, CLOSED: 1, },
              { CDATE: '2020-01-17', ACTIVE: 31, RAISED: null, CLOSED: 3, },
              { CDATE: '2020-01-24', ACTIVE: 26, RAISED: null, CLOSED: 5, },
              { CDATE: '2020-01-31', ACTIVE: 26, RAISED: null, CLOSED: null, },
              { CDATE: '2020-02-07', ACTIVE: 28, RAISED: 5, CLOSED: 3, },
              { CDATE: '2020-02-14', ACTIVE: 26, RAISED: null, CLOSED: 2, },
              { CDATE: '2020-02-21', ACTIVE: 21, RAISED: null, CLOSED: 5, },
              { CDATE: '2020-02-28', ACTIVE: 21, RAISED: null, CLOSED: null, },
              { CDATE: '2020-03-06', ACTIVE: 21, RAISED: null, CLOSED: null, },
      ],

      // Test Package
      SQL7: [
              { LINK1: 'A-PI-00', DISC: 'Package Review', TOTAL: 15, ACTUAL: 10, REMAIN: 5, PROG: 66.67, },
              { LINK1: 'A-PI-01', DISC: 'Line Walk(A-PI-01)', TOTAL: 15, ACTUAL: 10, REMAIN: 5, PROG: 66.67, },
              { LINK1: 'A-PI-02', DISC: 'Flushing(A-PI-02)', TOTAL: 15, ACTUAL: 9, REMAIN: 6, PROG: 60, },
              { LINK1: 'A-PI-03', DISC: 'Pressure Test(A-PI-03)', TOTAL: 15, ACTUAL: 5, REMAIN: 10, PROG: 33.33, },
              { LINK1: 'A-PI-04', DISC: 'Cleanliness(A-PI-04)', TOTAL: 15, ACTUAL: 4, REMAIN: 11, PROG: 26.67, },
              { LINK1: 'A-PI-05', DISC: 'Reinstatement(A-PI-05)', TOTAL: 15, ACTUAL: 1, REMAIN: 14, PROG: 6.67, },
              { LINK1: 'A-PI-09', DISC: 'Certificate(A-PI-09)', TOTAL: 15, ACTUAL: 1, REMAIN: 14, PROG: 6.67, },
              { LINK1: 'A-PI-10', DISC: 'Insulation(A-PI-10)', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
              { LINK1: 'A-PI-11', DISC: 'Paint(A-PI-11)', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
              { LINK1: 'A-PI-12', DISC: 'Color Coding(A-PI-12)', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
      ]

    },
}