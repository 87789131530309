import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 300,
      CanvasChartHeight: 250,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      // C10UH
      { date: '2019-09-20', value: 15, },
      { date: '2019-09-27', value: 15, },
      { date: '2019-10-04', value: 15, },
      { date: '2019-10-04', value: 15, },
      { date: '2019-10-11', value: 12, },
      { date: '2019-10-18', value: 9, },
      { date: '2019-10-25', value: 18, },
      { date: '2019-11-01', value: 23, },
      { date: '2019-11-08', value: 20, },
      { date: '2019-11-15', value: 19, },
      { date: '2019-11-22', value: 17, },
      { date: '2019-11-29', value: 21, },
      { date: '2019-12-06', value: 20, },
      { date: '2019-12-13', value: 23, },
      { date: '2019-12-20', value: 25, },
      { date: '2019-12-27', value: 10, },
    ],

    Queries: {
      SQL1: [
        {title: 'Total',     value: 425},
        {title: 'Completed', value: 245},
        {title: 'Remaining', value: 178},
      ],
    },

}